<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4 *ngIf="vermoegenPersonOne">{{'summary.personal-data.household-check.personal-income.first-borrower.prefix' | translate}}</h4>
        <div class="row" *ngIf="kontoguthaben">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.household-check.assets.bank-balances.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span>{{haushaltsrechnung.vermoegen.kontoguthaben | currency: 'EUR'}}</span>
            </div>
        </div>
        <div class="row" *ngIf="bausparguthaben">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.household-check.assets.savings-balances.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span>{{haushaltsrechnung.vermoegen.bausparguthaben | currency: 'EUR'}}</span>
            </div>
        </div>
        <div class="row" *ngIf="kurswert">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.household-check.assets.marketable-balances.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span>{{haushaltsrechnung.vermoegen.kurswert | currency: 'EUR'}}</span>
            </div>
        </div>
        <div class="row" *ngIf="rueckkaufswertLebensversicherungen">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.household-check.assets.life-insurance-balances.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span>{{haushaltsrechnung.vermoegen.rueckkaufswertLebensversicherungen | currency: 'EUR'}}</span>
            </div>
        </div>
        <!-- commenting according to https://gitlab.widas.de/deutsche-bank/oap/general/-/issues/557#note_818959 -->
        <!-- <div class="row" *ngIf="sparplane">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.savings.plan.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span>{{haushaltsrechnung.vermoegen.sparplane | currency: 'EUR'}}</span>
            </div>
        </div> -->
        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr *ngIf="vermoegenPersonOne && vermoegenPersonTwo">
            <h4 *ngIf="vermoegenPersonTwo">{{'summary.personal-data.household-check.personal-income.second-borrower.prefix' | translate}}</h4>
            <div class="row" *ngIf="kontoguthabensecond">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.household-check.assets.bank-balances.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <span>{{haushaltsrechnungSecond.vermoegen.kontoguthaben | currency: 'EUR'}}</span>
                </div>
            </div>
            <div class="row" *ngIf="bausparguthabensecond">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.household-check.assets.savings-balances.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <span>{{haushaltsrechnungSecond.vermoegen.bausparguthaben | currency: 'EUR'}}</span>
                </div>
            </div>
            <div class="row" *ngIf="kurswertsecond">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.household-check.assets.marketable-balances.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <span>{{haushaltsrechnungSecond.vermoegen.kurswert | currency: 'EUR'}}</span>
                </div>
            </div>
            <div class="row" *ngIf="rueckkaufswertLebensversicherungensecond">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.household-check.assets.life-insurance-balances.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <span>{{haushaltsrechnungSecond.vermoegen.rueckkaufswertLebensversicherungen | currency: 'EUR'}}</span>
                </div>
            </div>
        </div>
    </div>