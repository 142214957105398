<form [formGroup]="formValidation">
    <div class="ui-control-label">
        {{ 'personal-additional-outgoings.label' | translate }}
    </div>
    <div class="{{ checkToggleClass('credit') }}">
        <db-intro-preview>
            <db-toggleable [label]="'personal-additional-outgoings.installment-credit.label' | translate" [open]="toggleOpenArray[0]" iconPosition="right"
                (openChange)="toggleClickAccordion(0, 'credit')">
                <div *ngFor="let creditFormGroup of getPaymentInfos('credit').controls; let i = index">
                    <hr class="my-sm-4 my-4" *ngIf="i > 0" />
                    <div [formGroup]="creditFormGroup">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6 ui-control">
                                <db-input type="text" autocomplete="off" formControlName="zahlungsempfaenger" [(model)]="credit[i].zahlungsempfaenger"
                                    [label]="'personal-additional-outgoings.installment-credit.lender.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungsempfaenger" [messages]="errors.zahlungsempfaengerCredit"> </db-validation-errors>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 ui-control">
                                <db-input type="number" autocomplete="off" formControlName="zahlungssumme" [(model)]="credit[i].zahlungssumme"
                                    [label]="'personal-additional-outgoings.installment-credit.loan-amount.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungssumme" [messages]="errors.zahlungssummeCredit"> </db-validation-errors>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 ui-control">
                                <db-input type="number" [(model)]="credit[i].monatlicheKosten" formControlName="monatlicheKosten" autocomplete="off"
                                    [label]="'personal-additional-outgoings.installment-credit.monthly-costs.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="monatlicheKosten" [messages]="errors.monatlicheKostenCredit"> </db-validation-errors>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 ui-control">
                                <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='tilgungsEnde' (modelChange)="dateChange('credit', $event, i, 'tilgungsEnde')"
                                    label="{{ 'personal-additional-outgoings.installment-credit.end-of-redemption.placeholder' | translate }}"></db-input-date>
                                <db-validation-errors for="tilgungsEnde" [messages]="errors.tilgungsEnde"> </db-validation-errors>
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-12 ui-control col-button">
                                <db-button design="primary" (click)="removePaymentInfo('credit', i)">
                                    {{ i + 1 }}. {{ 'personal-additional-outgoings.installment-credit.button.remove' | translate }}
                                </db-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <db-button design="primary" (click)="addPaymentInfo('credit')">
                        {{ 'personal-additional-outgoings.installment-credit.button.add' | translate }}
                    </db-button>
                </div>
            </db-toggleable>
        </db-intro-preview>
    </div>
    <div class="{{ checkToggleClass('lease') }}">
        <db-intro-preview>
            <db-toggleable [label]="'personal-additional-outgoings.leasing-contracts.label' | translate" [open]="toggleOpenArray[1]" iconPosition="right"
                (openChange)="toggleClickAccordion(1, 'lease')">
                <div *ngFor="let leaseFormGroup of getPaymentInfos('lease').controls; let j = index">
                    <hr class="my-sm-4 my-4" *ngIf="j > 0" />
                    <div [formGroup]="leaseFormGroup">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6 ui-control ui-control">
                                <db-input type="text" autocomplete="off" [(model)]="lease[j].zahlungsempfaenger" formControlName="zahlungsempfaenger"
                                    [label]="'personal-additional-outgoings.leasing-contracts.lessor.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungsempfaenger" [messages]="errors.zahlungsempfaengerLease"> </db-validation-errors>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 ui-control">
                                <db-input type="number" autocomplete="off" [(model)]="lease[j].zahlungssumme" formControlName="zahlungssumme"
                                    [label]="'personal-additional-outgoings.leasing-contracts.lease-sum.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungssumme" [messages]="errors.zahlungssummeLease"> </db-validation-errors>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6  ui-control">
                                <db-input type="number" [(model)]="lease[j].monatlicheKosten" autocomplete="off" formControlName="monatlicheKosten"
                                    [label]="'personal-additional-outgoings.leasing-contracts.monthly-costs.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="monatlicheKosten" [messages]="errors.monatlicheKostenLease"> </db-validation-errors>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 ui-control">
                                <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='tilgungsEnde' (modelChange)="dateChange('lease', $event, j, 'tilgungsEnde')"
                                    label="{{ 'personal-additional-outgoings.leasing-contracts.end-of-redemption.placeholder' | translate }}"></db-input-date>
                                <db-validation-errors for="tilgungsEnde" [messages]="errors.tilgungsEnde"> </db-validation-errors>
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-12 ui-control col-button">
                                <db-button design="primary" (click)="removePaymentInfo('lease', j)">
                                    {{ j + 1 }}. {{ 'personal-additional-outgoings.leasing-contracts.button.remove' | translate }}
                                </db-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <db-button design="primary" (click)="addPaymentInfo('lease')">
                        {{ 'personal-additional-outgoings.leasing-contracts.button.add' | translate }}
                    </db-button>
                </div>
            </db-toggleable>
        </db-intro-preview>
    </div>
    <div class="{{ checkToggleClass('finance') }}">
        <db-intro-preview>
            <db-toggleable [label]="'personal-additional-outgoings.building-loans.label' | translate" [open]="toggleOpenArray[2]" iconPosition="right"
                (openChange)="toggleClickAccordion(2, 'finance')">
                <div *ngFor="let financeFormGroup of getPaymentInfos('finance').controls; let k = index">
                    <hr class="my-sm-4 my-4" *ngIf="k > 0" />
                    <div [formGroup]="financeFormGroup">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                                <db-input type="text" autocomplete="off" [(model)]="finance[k].zahlungsempfaenger" formControlName="zahlungsempfaenger"
                                    [label]="'personal-additional-outgoings.building-loans.name-of-bank.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungsempfaenger" [messages]="errors.zahlungsempfaengerFinance"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                                <db-input type="number" autocomplete="off" [(model)]="finance[k].zahlungssumme" formControlName="zahlungssumme"
                                    [label]="'personal-additional-outgoings.building-loans.financing-amount.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungssumme" [messages]="errors.zahlungssummeFinance"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                                <db-input type="number" autocomplete="off" [(model)]="finance[k].monatlicheKosten" formControlName="monatlicheKosten"
                                    [label]="'personal-additional-outgoings.building-loans.monthly-rate.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="monatlicheKosten" [messages]="errors.monatlicheKostenFinance"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                                <db-input type="number" autocomplete="off" [(model)]="finance[k].aktuelleRestschuld" formControlName="aktuelleRestschuld"
                                    [label]="'personal-additional-outgoings.building-loans.current-residual-debt.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="aktuelleRestschuld" [messages]="errors.aktuelleRestschuldFinance"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                                <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='zinsbindungsEnde' (modelChange)="dateChange('finance', $event, k, 'zinsbindungsEnde')"
                                    label="{{ 'personal-additional-outgoings.building-loans.end-of-fixed-interest-period.placeholder' | translate }}"></db-input-date>
                                <db-validation-errors for="zinsbindungsEnde" [messages]="errors.zinsbindungsEndeFinance"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                                <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='tilgungsEnde' (modelChange)="dateChange('finance', $event, k, 'tilgungsEnde')"
                                    label="{{ 'personal-additional-outgoings.installment-credit.end-of-redemption.placeholder' | translate }}"></db-input-date>
                                <db-validation-errors for="tilgungsEnde" [messages]="errors.tilgungsEnde"> </db-validation-errors>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ui-control col-button">
                                <db-button design="primary" (click)="removePaymentInfo('finance', k)">
                                    {{ k + 1 }}. {{ 'personal-additional-outgoings.building-loans.button.remove' | translate }}
                                </db-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <db-button design="primary" (click)="addPaymentInfo('finance')">
                        {{ 'personal-additional-outgoings.building-loans.button.add' | translate }}
                    </db-button>
                </div>
            </db-toggleable>
        </db-intro-preview>
    </div>
    <div class="{{ checkToggleClass('liablity') }}">
        <db-intro-preview>
            <db-toggleable [label]="'personal-additional-outgoings.other-obligations.label' | translate" [open]="toggleOpenArray[3]" iconPosition="right"
                (openChange)="toggleClickAccordion(3, 'liablity')">
                <div *ngFor="let liablityFormGroup of getPaymentInfos('liablity').controls; let l = index">
                    <hr class="my-sm-4 my-4" *ngIf="l > 0" />
                    <div [formGroup]="liablityFormGroup">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 ui-control">
                                <db-input type="number" autocomplete="off" [(model)]="liablity[l].zahlungssumme" formControlName="zahlungssumme" label="Summe in €">
                                    <!--  TODO elenastoll clarify[label]="'personal-additional-outgoings.other-obligations.type.placeholder' | translate" -->
                                </db-input>
                                <db-validation-errors for="zahlungssumme" [messages]="errors.zahlungssummeLiablity"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 ui-control">
                                <db-input type="number" autocomplete="off" [(model)]="liablity[l].monatlicheKosten" formControlName="monatlicheKosten"
                                    [label]="'personal-additional-outgoings.other-obligations.costs.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="monatlicheKosten" [messages]="errors.monatlicheKostenLiablity"> </db-validation-errors>
                            </div>
                            <div class="col-sm-12 col-md-6 ui-control">
                                <db-input type="text" autocomplete="off" [(model)]="liablity[l].zahlungsType" formControlName="zahlungsType"
                                    [label]="'personal-additional-outgoings.other-obligations.type.placeholder' | translate">
                                </db-input>
                                <db-validation-errors for="zahlungsType" [messages]="errors.zahlungsTypeLiablity"> </db-validation-errors>
                            </div>
                            <div class="col-12 ui-control col-button">
                                <db-button design="primary" (click)="removePaymentInfo('liablity', l)">
                                    {{ l + 1 }}. {{ 'personal-additional-outgoings.other-obligations.button.remove' | translate }}
                                </db-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <db-button design="primary" (click)="addPaymentInfo('liablity')">
                        {{ 'personal-additional-outgoings.other-obligations.button.add' | translate }}
                    </db-button>
                </div>
            </db-toggleable>
        </db-intro-preview>
    </div>
    <div class="db-validation-errors__message form-validation-error" [ngClass]="{ 'is-visible': formValidation.invalid }">
        {{ 'personal-additional-outgoings.empty.hint' | translate }}
    </div>
</form>