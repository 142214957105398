<div class="main" [ngClass]="{ reduced: !isFooterVisible, fulled: isFooterVisible }">
    <db-modal></db-modal>
    <header>
        <app-header-banner></app-header-banner>
        <app-header-tab *ngIf='!isSummaryStatusPage'></app-header-tab>
    </header>
    <div id="content">
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </div>
    <footer>
        <app-navi-button-bar *ngIf="isFooterVisible"></app-navi-button-bar>
        <app-footer-bar></app-footer-bar>
    </footer>
</div>