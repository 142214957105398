import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-persoenlichen-daten-summary',
  templateUrl: './persoenlichen-daten-summary.component.html',
  styleUrls: ['../summary.component.scss', './persoenlichen-daten-summary.component.scss']
})
export class PersoenlichenDatenSummaryComponent extends BaseComponent implements OnInit {
  public togglePersoenlichenDatenArray: any[] = [false, false, false, false, false];
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.adobe.pushAdobeEvent("Personal_data_summary", "summary");
  }

  public togglePersoenlichenDatenClick(index: any) {
    this.togglePersoenlichenDatenArray = [false, false, false, false, false];
    this.togglePersoenlichenDatenArray[index] = true;
    this.scrollToElementId('persSum' + index);
  }
}
