import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthService } from '../core/auth.service';

@Injectable()
export class RouteGuard implements CanActivate {
  constructor(private authUser: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let shallNavigate = false;
    if (this.authUser.isUserLoggedIn) {
      shallNavigate = true;
    }
    if (!shallNavigate) {
      this.router.navigate(['/login']);
    } else {
      return shallNavigate;
    }
  }
}
