import { Haushaltsrechnung } from './haushaltsrechnung';
import { Personendaten } from './personendaten';
import { RentenInformation } from './renten-information';

export class Darlehensnehmer {
  personendaten: Personendaten;
  rentenInformation: RentenInformation;
  haushaltsrechnung: Haushaltsrechnung;

  constructor(darlehensnehmer?: Darlehensnehmer) {
    this.personendaten = darlehensnehmer ? new Personendaten(darlehensnehmer.personendaten) : new Personendaten();
    this.rentenInformation = darlehensnehmer ? new RentenInformation(darlehensnehmer.rentenInformation) : new RentenInformation();
    this.haushaltsrechnung = darlehensnehmer ? new Haushaltsrechnung(darlehensnehmer.haushaltsrechnung) : new Haushaltsrechnung();
  }
}
