import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Anstellungsverhaeltnis, DauerdesVertragsverhaeltnissesTyp } from 'src/app/shared/model/personendaten';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

import { BaseComponent } from '../../core/base.component';
import { OAPValidators } from '../../core/validators.directive';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';

@Component({
  selector: 'app-personal-work',
  templateUrl: './personal-work.component.html',
  styleUrls: ['./personal-work.component.scss'],
  providers: [FormBuilder]
})
export class PersonalWorkComponent extends BaseComponent implements OnInit, OnDestroy {
  private HOUSEWIFE_CHILD_VALUES: Anstellungsverhaeltnis[] = [Anstellungsverhaeltnis.Housewife, Anstellungsverhaeltnis.ChildcPupilStudent];
  private APPRENTICE_VALUES: Anstellungsverhaeltnis[] = [Anstellungsverhaeltnis.Apprentice, Anstellungsverhaeltnis.PublicServiceApprentice];
  private RETIRED_VALUES: Anstellungsverhaeltnis[] = [Anstellungsverhaeltnis.RetireePerson, Anstellungsverhaeltnis.Pensioner, Anstellungsverhaeltnis.GermaArmedForcesRetired];
  private UNLIMITED_VALUES: DauerdesVertragsverhaeltnissesTyp[] = [DauerdesVertragsverhaeltnissesTyp.Unbefristet, DauerdesVertragsverhaeltnissesTyp.Unkuendbar];

  private CONTACT_TYPE_NOT_REQUIRED_VALUES = this.HOUSEWIFE_CHILD_VALUES.concat(this.APPRENTICE_VALUES).concat(this.RETIRED_VALUES);
  private WORK_NOT_REQUIRED_VALUES = this.HOUSEWIFE_CHILD_VALUES.concat(this.RETIRED_VALUES);

  anstellungsverhaeltnisList = [];
  dauerdesVertragsverhaeltnissesTypOptions = [];

  isContractDurationVisible: boolean;
  isWorkVisible: boolean;
  isRetired: boolean;
  isUnlimited: boolean;
  isEmploymentFilled: boolean;

  oldAnstellungsverhaeltnis: any;

  public geplanterRentenEintrittConfig = this.getDatePickerConfig(this.calculateMinRenteEintritt(), undefined);
  public tatsaechlichRentenEintrittConfig = this.getDatePickerConfig(undefined, this.dateService.getDateUIFromModel(new Date()));
  errors: any = {
    anstellungsverhaeltnis: [
      {
        rule: 'required',
        message: 'personal-work.work-situation.type.hint'
      }
    ],
    dauerdesVertragsverhaeltnissesTyp: [
      {
        rule: 'required',
        message: 'personal-work.work-situation.contractual-relationship.hint'
      }
    ],
    beschaeftigtSeit: [
      {
        rule: 'required',
        message: 'personal-work.employed-since.hint'
      },
      {
        rule: 'date',
        message: 'personal-work.employed-since.format.hint'
      },
      {
        rule: 'futureDate',
        message: 'personal-work.employed-until.invalid.hint'
      }
    ],
    beschaeftigtBis: [
      {
        rule: 'required',
        message: 'personal-work.employed-until.empty.hint'
      },
      {
        rule: 'pastDate',
        message: 'personal-work.employed-until.invalid.hint'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Datum',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ],
    arbeitgeber: [
      {
        rule: 'required',
        message: 'personal-work.work-situation.name-of-employer.hint'
      }
    ],
    beruf: [
      {
        rule: 'required',
        message: 'personal-work.work-situation.name-of-profession.hint'
      }
    ],
    geplanterRentenEintritt: [
      {
        rule: 'pastDate',
        message: 'personal-work.retirement.warning-minimum-age.label'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Datum',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ],
    tatsaechlichRentenEintritt: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Renteneintritt'
      },
      {
        rule: 'futureDate',
        message: 'personal-work.retirement.warning-entrance-must-be-in-past.label'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Datum',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valuesService: ValuesService,
    private enumService: PersonalDataEnumOptionsService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initEmployments();
    this.dauerdesVertragsverhaeltnissesTypOptions = this.enumService.getOptionsFor(DauerdesVertragsverhaeltnissesTyp);
    this.isEmploymentFilled = this.personendaten.anstellungsverhaeltnis && this.personendaten.anstellungsverhaeltnis.length > 0;
    this.calcRegularPensionEntrance();
    this.createFormContols();

    this.handleEmploymentRelationshipValidity(false, null);
    this.handleUnlimitedValidity(false, null);
    this.headerService.changeText('personal-work.headline');
    this.translateErrors(this.errors);
    this.oldAnstellungsverhaeltnis = this.personendaten.anstellungsverhaeltnis;
    this.adobe.pushAdobeEvent("Personal_work", "personal data");
  }

  // getter & setter (for date fields)
  get regulaererRentenEintritt(): Date {
    if (this.rentenInformation.regulaererRentenEintritt) {
      return this.dateService.getDateUIFromModel(this.rentenInformation.regulaererRentenEintritt);
    }
    return;
  }

  get beschaeftigtSeit() {
    if (this.personendaten.beschaeftigtSeit) {
      return this.dateService.getDateUIFromModel(this.personendaten.beschaeftigtSeit);
    }
  }
  beschaeftigtSeitChange($event) {
    this.personendaten.beschaeftigtSeit = this.dateService.getDateModelFromDateUI($event);
  }
  get beschaeftigtBis() {
    if (this.personendaten.beschaeftigtBis) {
      return this.dateService.getDateUIFromModel(this.personendaten.beschaeftigtBis);
    }
  }
  beschaeftigtBisChange($event) {
    this.personendaten.beschaeftigtBis = this.dateService.getDateModelFromDateUI($event);
  }
  get geplanterRentenEintritt() {
    if (this.rentenInformation.geplanterRentenEintritt) {
      return this.dateService.getDateUIFromModel(this.rentenInformation.geplanterRentenEintritt);
    }
  }
  geplanterRentenEintrittChange($event) {
    this.rentenInformation.geplanterRentenEintritt = this.dateService.getDateModelFromDateUI($event);
  }

  // private init methods

  private createFormContols() {
    this.formValidation = this.formBuilder.group({
      anstellungsverhaeltnis: new FormControl(this.personendaten.anstellungsverhaeltnis, DBValidators.required()),
      dauerdesVertragsverhaeltnissesTyp: new FormControl(this.personendaten.dauerdesVertragsverhaeltnissesTyp),
      beschaeftigtSeit: new FormControl(this.beschaeftigtSeit),
      beschaeftigtBis: new FormControl(this.beschaeftigtBis),
      arbeitgeber: new FormControl(this.personendaten.arbeitgeber),
      beruf: new FormControl(this.personendaten.beruf),
      regulaererRentenEintritt: new FormControl(this.regulaererRentenEintritt),
      geplanterRentenEintritt: new FormControl(this.geplanterRentenEintritt),
      tatsaechlichRentenEintritt: new FormControl(this.geplanterRentenEintritt)
    });
    this.formValidation.get('anstellungsverhaeltnis').valueChanges.subscribe(value => {
      this.handleEmploymentRelationshipValidity(true, value);
      this.oldAnstellungsverhaeltnis = value;
      if (this.APPRENTICE_VALUES.indexOf(value) !== -1) {
        this.personendaten.dauerdesVertragsverhaeltnissesTyp = DauerdesVertragsverhaeltnissesTyp.Befristet;
      }
    });
    this.formValidation.get('dauerdesVertragsverhaeltnissesTyp').valueChanges.subscribe(value => {
      this.handleUnlimitedValidity(true, value);
    });
  }

  private handleEmploymentRelationshipValidity(reset: boolean, value: string) {
    value = !value ? this.personendaten.anstellungsverhaeltnis : value;
    if (value) {
      this.isEmploymentFilled = true;
      this.handleRetiredValidity(reset, value);
      this.handleWorkValidity(reset, value);
      this.handleContractDurationValidity(reset, value);
    }
  }

  private handleRetiredValidity(reset: boolean, value: any) {
    if (!this.RETIRED_VALUES.includes(value)) {
      this.isRetired = false;
      this.formValidation.get('geplanterRentenEintritt').setValidators(this.geplanterRentenEintrittValidators);
      this.calcRegularPensionEntrance();
      this.formValidation.get('tatsaechlichRentenEintritt').setValidators(null);
      if (reset && this.RETIRED_VALUES.includes(this.oldAnstellungsverhaeltnis)) {
        this.rentenInformation.geplanterRentenEintritt = undefined;
        this.formValidation.get('tatsaechlichRentenEintritt').reset();
        this.formValidation.get('geplanterRentenEintritt').reset();
      }
    } else {
      this.isRetired = true;
      this.formValidation.get('tatsaechlichRentenEintritt').setValidators(this.tatsaechlichRentenEintrittValidators);
      this.formValidation.get('geplanterRentenEintritt').setValidators(null);
      if (reset) {
        if (!this.RETIRED_VALUES.includes(this.oldAnstellungsverhaeltnis)) {
          this.rentenInformation.geplanterRentenEintritt = undefined;
          this.formValidation.get('geplanterRentenEintritt').reset();
          this.formValidation.get('tatsaechlichRentenEintritt').reset();
        }
        this.rentenInformation.regulaererRentenEintritt = undefined;
        this.formValidation.get('regulaererRentenEintritt').reset();
      }
    }
    this.formValidation.get('geplanterRentenEintritt').updateValueAndValidity();
    this.formValidation.get('tatsaechlichRentenEintritt').updateValueAndValidity();
  }

  private handleWorkValidity(reset: boolean, value: any) {
    if (!this.WORK_NOT_REQUIRED_VALUES.includes(value)) {
      this.isWorkVisible = true;
      this.formValidation.get('beschaeftigtSeit').setValidators(this.beschaeftigtSeitValidators);
      if (!this.UNLIMITED_VALUES.includes(this.personendaten.dauerdesVertragsverhaeltnissesTyp)) {
        this.formValidation.get('beschaeftigtBis').setValidators(this.beschaeftigtBisValidators);
      }
      this.formValidation.get('arbeitgeber').setValidators(DBValidators.required());
      this.formValidation.get('beruf').setValidators(DBValidators.required());
    } else {
      this.isWorkVisible = false;
      this.formValidation.get('beschaeftigtSeit').setValidators(null);
      this.formValidation.get('beschaeftigtBis').setValidators(null);
      this.formValidation.get('arbeitgeber').setValidators(null);
      this.formValidation.get('beruf').setValidators(null);
      if (reset) {
        this.personendaten.beruf = undefined;
        this.personendaten.arbeitgeber = undefined;
        this.personendaten.beschaeftigtBis = undefined;
        this.personendaten.beschaeftigtSeit = undefined;
        this.formValidation.get('beruf').reset();
        this.formValidation.get('arbeitgeber').reset();
        this.formValidation.get('beschaeftigtBis').reset();
        this.formValidation.get('beschaeftigtSeit').reset();
      }
    }
    this.formValidation.get('beschaeftigtSeit').updateValueAndValidity();
    this.formValidation.get('beschaeftigtBis').updateValueAndValidity();
    this.formValidation.get('arbeitgeber').updateValueAndValidity();
    this.formValidation.get('beruf').updateValueAndValidity();
  }

  private handleContractDurationValidity(reset: boolean, value: any) {
    if (!this.CONTACT_TYPE_NOT_REQUIRED_VALUES.includes(value)) {
      this.formValidation.get('dauerdesVertragsverhaeltnissesTyp').setValidators(DBValidators.required());
      this.isContractDurationVisible = true;
    } else {
      this.formValidation.get('dauerdesVertragsverhaeltnissesTyp').setValidators(null);
      if (reset) {
        this.personendaten.dauerdesVertragsverhaeltnissesTyp = undefined;
        this.formValidation.get('dauerdesVertragsverhaeltnissesTyp').reset();
      }
      this.isContractDurationVisible = false;
    }
    this.formValidation.get('dauerdesVertragsverhaeltnissesTyp').updateValueAndValidity();
  }

  private handleUnlimitedValidity(reset: boolean, value: any) {
    value = !value ? this.personendaten.dauerdesVertragsverhaeltnissesTyp : value;
    if (value) {
      this.isUnlimited = this.UNLIMITED_VALUES.includes(value);
      if (!this.UNLIMITED_VALUES.includes(value)) {
        this.formValidation.get('beschaeftigtBis').setValidators(this.beschaeftigtBisValidators);
      } else {
        this.formValidation.get('beschaeftigtBis').setValidators(null);
        if (reset) {
          this.personendaten.beschaeftigtBis = undefined;
          this.formValidation.get('beschaeftigtBis').reset();
        }
      }
      this.formValidation.get('beschaeftigtBis').updateValueAndValidity();
    }
  }

  private get tatsaechlichRentenEintrittValidators(): ValidatorFn | ValidatorFn[] {
    return [DBValidators.required(), OAPValidators.dateFormat(), OAPValidators.isDateAfterValidator(new Date())];
  }

  private get geplanterRentenEintrittValidators(): ValidatorFn | ValidatorFn[] {
    return [OAPValidators.dateFormat(), OAPValidators.isDateBeforeValidator(() => this.calculateMinRenteEintritt())];
  }

  private get beschaeftigtBisValidators(): ValidatorFn | ValidatorFn[] {
    return [DBValidators.required(), OAPValidators.dateFormat(), OAPValidators.isDateBeforeValidator(() => this.beschaeftigtSeit)];
  }

  private get beschaeftigtSeitValidators(): ValidatorFn | ValidatorFn[] {
    return [DBValidators.required(), OAPValidators.dateFormat(), OAPValidators.isDateAfterValidator(() => this.beschaeftigtBis)];
  }

  private initEmployments() {
    this.valuesService.loadEmployments().subscribe(employmentList => {
      this.anstellungsverhaeltnisList = Object.assign([], employmentList);
    });
  }

  private calcRegularPensionEntrance() {
    if (this.personendaten.geburtsdatum) {
      this.rentenInformation.regulaererRentenEintritt = moment(this.personendaten.geburtsdatum)
        .add(67, 'years')
        .toDate();
    }
  }

  private calculateMinRenteEintritt() {
    if (this.personendaten.geburtsdatum) {
      const datum60Jahre = moment(this.personendaten.geburtsdatum).add(60, 'years');
      return this.dateService.getDateUIFromModel(datum60Jahre.toDate());
    }
    return undefined;
  }
}
