<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4>{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.family-circumstances.family-status.label' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneFamilienStand}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.gueterstand && (personendaten.familienstand === 'VERHEIRATET' || personendaten.familienstand === 'GETRENNT_LEBEND')">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.family-circumstances.marital-estate.label' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneGueterStand}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.kinder && personendaten.kinder.length > 0">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.family-circumstances.amount-of-children.label' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.kinder.length}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.kinder && personendaten.kinder.length > 0">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="personendaten.kinder.length === 1">
                {{'summary.personal-data.personal-data.family-circumstances.birthdates-of-children.singular.label' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="personendaten.kinder.length > 1">
                {{'summary.personal-data.personal-data.family-circumstances.birthdates-of-children.multiple.label' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div *ngFor=" let geb of personendaten.kinder">
                    <span style="font-weight: normal;">{{geb.geburtsDatum | date:'mediumDate'}}</span>
                </div>
            </div>
        </div>

        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    <h4>{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.family-circumstances.family-status.label' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoFamilienStand}}
                </div>
            </div>
            <div class="row" *ngIf="personendatenSecond.gueterstand && (personendaten.familienstand === 'VERHEIRATET' || personendaten.familienstand === 'GETRENNT_LEBEND')">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.family-circumstances.marital-estate.label' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoGueterStand}}
                </div>
            </div>
            <div class="row" *ngIf="personendatenSecond.kinder && personendatenSecond.kinder.length > 0">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.family-circumstances.amount-of-children.label' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personendatenSecond.kinder.length}}
                </div>
            </div>
            <div class="row" *ngIf="personendatenSecond.kinder && personendatenSecond.kinder.length > 0">
                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="personendatenSecond.kinder.length === 1">
                    {{'summary.personal-data.personal-data.family-circumstances.birthdates-of-children.singular.label' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="personendatenSecond.kinder.length > 1">
                    {{'summary.personal-data.personal-data.family-circumstances.birthdates-of-children.multiple.label' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div *ngFor=" let geb of personendatenSecond.kinder">
                        <span style="font-weight: normal;">{{geb.geburtsDatum | date:'mediumDate'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>