<form [formGroup]="formValidation">
    <div class="ui-control-label">
        {{ 'loan-check-1.financing-type.headline' | translate }}
    </div>
    <div class="ui-control">
        <db-radio-group formControlName="verwendungsZweck">
            <db-radio-button *ngFor="let item of radioButtonVerwendungsZweck" [model]="item.value" [ngClass]="{ 'disabled-radio': item.disabled }">
                {{ item.label }}
            </db-radio-button>
        </db-radio-group>
        <db-validation-errors for="verwendungsZweck" [messages]="errors.verwendungszweckValidation"> </db-validation-errors>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-5">
            <div class="ui-control-label">
                {{ 'loan-check-rescheduling.date.label' | translate }}
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
            <db-input-date dbDateTimePicker [dbDateTimeConfig]="umschuldungsdatumConfig" formControlName='umschuldungsdatumFormControl'
                (modelChange)="umschuldungsdatumChange($event)" label="{{ 'loan-check-rescheduling.date.placeholder' | translate }}"></db-input-date>
            <db-validation-errors for="umschuldungsdatumFormControl" [messages]="errors.umschuldungsdatumValidation"> </db-validation-errors>
        </div>
    </div>

    <div class="ui-control-label">
        {{ 'loan-check-rescheduling.costs.label' | translate }}
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-5 ui-control">
            <db-slider [step]="500" [min]="minValue()" [max]="maxValue()" [unit]="'€'" [(model)]="finanzierungsbedarf"> </db-slider>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
            <db-input [(model)]="finanzierungsbedarf" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
        </div>
    </div>

    <div class="ui-control-label">
        {{ 'loan-check-2.number-of-debtors.label' | translate }}
    </div>
    <div class="ui-control">
        <db-radio-group formControlName="anzahlAntragnehmer">
            <db-radio-button *ngFor="let item of radioButtonAntragnehmer" [model]="item.value" (change)="updateanzahlAntragnehmer($event)">
                {{ item.name | translate }}
            </db-radio-button>
        </db-radio-group>
        <db-validation-errors for="anzahlAntragnehmer" [messages]="errors.anzahlAntragnehmerValidation"> </db-validation-errors>
    </div>
</form>