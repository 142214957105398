<div></div>
<!-- Modal PopUp Window templates -->
<ng-template #modalTemplate>
    <app-popup-window
        [title]="title"
        [leftButton]="leftButton"
        [centerButton]="centerButton"
        [rightButton]="rightButton"
        [content]="content"
        [contentText]="contentText"
        [error]="error"
        (closeClick)="onClose()"
    ></app-popup-window>
</ng-template>
