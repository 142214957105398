import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { environment } from 'src/environments/environment';

import { AntragStatus } from '../shared/model/antrag';
import { AdobeApiService } from '../shared/services/adobe-api.service';

@Component({
  selector: 'app-summary-status',
  templateUrl: './summary-status.component.html',
  styleUrls: ['./summary-status.component.scss']
})
export class SummaryStatusComponent extends BaseComponent implements OnInit {
  private statusFound = false;
  private timer: any;
  private docsRes: any = {};
  private mimeType = 'application/pdf;charset=utf-8';

  linkBhwConsentAgb: string;
  pdfErklaerungBHW: string;
  public downloadFileAvailable: boolean;
  public acceptTerms = false;
  public statusProgress = true;
  public statusError = false;

  private antragStatus: AntragStatus;

  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.statusChanged(this.appDataService.antragStatus);
    this.linkBhwConsentAgb = environment.consentAgbUrl;
    this.pdfErklaerungBHW = 'assets/docs/ErklaerungBHW.pdf';
    this.adobe.pushAdobeEvent("Summary_status", "summary")
  }

  get isRequested(): boolean {
    return this.antragStatus !== AntragStatus.Bereit && this.antragStatus !== AntragStatus.InBearbeitung;
  }

  public processAntrag() {
    this.appDataService.loadAntragStatus('/process').subscribe(antragProcessResult => {
      if (antragProcessResult && antragProcessResult.info) {
        // call /status endpoint
        this.statusChanged(AntragStatus.InProzess);
        this.timer = setInterval(() => {
          if (!this.statusFound) {
            this.statusCheckAntrag();
          }
        }, 3000);
      }
    });
  }

  public loadDocs() {
    this.appDataService.loadAntragStatus('/docs').subscribe(antragProcessResult => {
      if (antragProcessResult && antragProcessResult.document) {
        // call /status endpoint
        this.statusChanged(AntragStatus.DokumenteAbgerufen);
        this.docsRes = antragProcessResult.document;
      }
    });
  }

  public download_file() {
    const blob = this.base64toBlob(this.docsRes, this.mimeType);
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  private statusChanged(status: AntragStatus) {
    if (status && this.antragStatus !== status) {
      console.log('Status changed from %s to %s', this.antragStatus, status);
      this.antragStatus = status;
      this.translateHeaderText();
      if (this.antragStatus === AntragStatus.Angenommen || this.antragStatus === AntragStatus.Abgelehnt) {
        this.stopStatusRequest();
      } else if (this.antragStatus === AntragStatus.DokumenteAbgerufen) {
        this.stopStatusRequest();
        this.downloadFileAvailable = true;
      } else if (this.antragStatus === AntragStatus.Error || this.antragStatus === AntragStatus.TechnicalFailure) {
        this.statusError = true;
        this.stopStatusRequest();
      }
    }
  }

  private stopStatusRequest() {
    this.statusFound = true;
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.statusProgress = false;
  }

  private translateHeaderText() {
    let dynamicTextKey = 'final-summary-and-download.one-person.headline';
    let paramObject: any = this.correspondence;
    if (this.applicationData.antrag.anzahlAntragnehmer === 2) {
      dynamicTextKey = 'final-summary-and-download.two-persons.headline';
      paramObject = this.correspondenceTwoPersons;
    }
    this.translateService.get(dynamicTextKey, paramObject).subscribe((res: string) => {
      this.headerService.changeText(res);
    });
  }

  private base64toBlob(base64Data, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  private statusCheckAntrag() {
    this.appDataService.getAntragStatus().subscribe((status: AntragStatus) => {
      console.log('Summary-status page: loaded status=%s', status);
      this.statusChanged(status);
    });
  }
}
