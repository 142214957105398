import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopUpButton } from 'src/app/shared/components/popup-window/popup-window.component';
import { AntragStatus } from 'src/app/shared/model/antrag';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { environment } from 'src/environments/environment';

import { Mandant, MandantService } from './../../shared/services/mandant.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent implements OnInit {
  @ViewChild('modalQuestionContent', {
    read: TemplateRef,
    static: true,
  })
  private modalQuestionContentRef: TemplateRef<any>;

  @ViewChild('modalContactUsContent', {
    read: TemplateRef,
    static: true,
  })
  private modalContactUsContentRef: TemplateRef<any>;

  private contactConsultantButton: PopUpButton = {
    title: 'footer.contact.contactus.button.popup',
    click: () => {
      this.popupService.closeAndResetPopup();
      setTimeout(() => {
        this.contactConsultant();
      }, 500);
    },
    disabled: () => {
      return !this.agreed;
    },
  };

  private redirectButton: PopUpButton = {
    title: 'alert.all.button.ok',
    click: () => (this.isBhw || this.appDataService.applicationData.isModernisierung ? this.popupService.closeAndResetPopup() : this.redirectToHomePage()),
  };

  private okButton: PopUpButton = {
    title: 'alert.all.button.ok',
    click: () => this.popupService.closeAndResetPopup(),
  };

  public isBhw: boolean;
  public link: string;
  public datenschuetzhinweiseLink: string;
  public einwilligungserklaerungLink: string;
  public consentTextKey: string;
  public consentTextSuffixKey: string;
  public onLabelKey: string;
  public offLabelKey: string;

  contactEmail: string;
  contactConsultantText: string;
  agreed: boolean;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    mandantService: MandantService,
    private popupService: PopupService,
    public appDataService: ApplicationDataService,
    private loadingIndicatorService: LoadingIndicatorService,
    private translateService: TranslateService,
    private adobe: AdobeApiService
  ) {
    this.isBhw = mandantService.getMandant() === Mandant.BHW;
    this.link = environment.legalInformationUrl;
    this.datenschuetzhinweiseLink = 'assets/docs/Datenschutzhinweise_BHW.pdf';
    this.einwilligungserklaerungLink = 'assets/docs/Einwilligungserklaerung_DB_PB_BHW.pdf';
    this.contactEmail = environment.emailContactDB;
  }

  ngOnInit() {}

  clickOnQuestions() {
    this.popupService.resetPopup();
    this.popupService.title = 'footer.contact.questions';
    this.popupService.content = this.modalQuestionContentRef;
    this.popupService.centerButton = this.okButton;
    this.popupService.openPopup();
  }

  clickOnContactUs() {
    this.initConsent();
    this.popupService.resetPopup();
    this.popupService.title = 'footer.contact.contactus';
    this.popupService.centerButton = this.okButton;
    if (!this.appDataService.email || this.appDataService.email.trim() === '') {
      this.popupService.contentText = 'email.unavailable.popup';
    } else if (this.appDataService.isConsultantContacted) {
      this.popupService.contentText =
        this.isBhw || this.appDataService.applicationData.isModernisierung ? 'footer.contact.contactus.again.bhw.popup' : 'footer.contact.contactus.again.db.popup';
    } else {
      this.popupService.content = this.modalContactUsContentRef;
      this.popupService.centerButton = this.contactConsultantButton;
    }
    this.popupService.openPopup();
  }

  public changeAgree($event) {
    this.agreed = $event;
  }

  private initConsent() {
    this.consentTextKey = this.getConsentTextKey();
    this.consentTextSuffixKey =
      this.appDataService.applicationData.antrag.anzahlAntragnehmer === 2
        ? 'footer.contact.contactus.bhw.agreement.couple.popup.suffix'
        : 'footer.contact.contactus.bhw.agreement.single.popup.suffix';
    this.onLabelKey = 'general.yes';
    this.offLabelKey = 'general.no';
    this.agreed = false;
  }

  private getConsentTextKey(): string {
    if (this.isBhw || this.appDataService.applicationData.isModernisierung) {
      return this.appDataService.applicationData.antrag.anzahlAntragnehmer === 2
        ? 'footer.contact.contactus.bhw.agreement.couple.popup.prefix'
        : 'footer.contact.contactus.bhw.agreement.single.popup.prefix';
    } else {
      return this.appDataService.applicationData.antrag.anzahlAntragnehmer === 2
        ? 'footer.contact.contactus.db.agreement.couple.popup'
        : 'footer.contact.contactus.db.agreement.single.popup';
    }
  }

  private contactConsultant() {
    this.getAdobe();
    this.loadingIndicatorService.showLoadingText('general.loading-indicator.content');
    this.translateService.get('footer.contact.contactus.success.db.popup', { contactConsultantEmail: environment.emailSaveDB }).subscribe((res: string) => {
      this.contactConsultantText = res;
    });
    this.appDataService.save().subscribe(() => {
      this.appDataService.leadSubmit(false).subscribe(() => {
        this.loadingIndicatorService.hideLoadingText();
        console.log('Open success popup window after submit lead');
        this.popupService.resetPopup();
        this.popupService.title = 'footer.contact.contactus';
        this.popupService.contentText =
          this.isBhw || this.appDataService.applicationData.isModernisierung ? 'footer.contact.contactus.success.bhw.popup' : this.contactConsultantText;
        this.popupService.centerButton = this.redirectButton;
        // DB Core Bug: without giving time to hide loading indicator, the supposedly hidden loading indicator will be shown together with the popup
        setTimeout(() => {
          this.popupService.openPopup();
        }, 500);
      });
    });
  }

  private redirectToHomePage() {
    this.popupService.closeAndResetPopup();
    console.log('Good by application. Go to DB homepage');
    this.document.location.href = environment.redirectDBHomePage;
  }

  public checkAntragBeantragt(): boolean {
    return this.appDataService.antragStatus && this.appDataService.antragStatus !== AntragStatus.InBearbeitung;
  }

  getAdobe() {
    let option: any = {};
    option.pageName = 'Privatkunden';
    option.pageType = 'product application form (OPrA)';
    option.leadstage = 'SQL';
    option.productID = '12100';
    option.event = 'view_load';
    option.productCategory = 'Baufinanzierung';
    option.productName = 'Baufinanzierung';
    option.actionType = 'application_funnel';
    option.actionName = 'application funnel step loaded';
    option.opraId = this.antragId;
    this.adobe.triggerAdobe(option, option.event);
  }

  get antragId(): string {
    return this.appDataService.applicationData.antrag.id;
  }
}
