import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Familienstand, Gueterstand } from 'src/app/shared/model/personendaten';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-familie-summary',
  templateUrl: './familie-summary.component.html',
  styleUrls: ['../../summary.component.scss', './familie-summary.component.scss']
})
export class FamilieSummaryComponent extends BaseComponent implements OnInit {
  personOneFamilienStand: string;
  personTwoFamilienStand: string;
  personOneGueterStand: string;
  personTwoGueterStand: string;

  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initLocalProperties();
    console.log(this.personendatenSecond.kinder);
  }

  initLocalProperties() {
    this.personOneFamilienStand = this.getFamilienStand(this.personendaten.familienstand);
    this.personTwoFamilienStand = this.getFamilienStand(this.personendatenSecond.familienstand);
    this.personOneGueterStand = this.getGueterStand(this.personendaten.gueterstand);
    this.personTwoGueterStand = this.getGueterStand(this.personendatenSecond.gueterstand);
  }

  getGueterStand(gueterstand: Gueterstand) {
    switch (gueterstand) {
      case Gueterstand.Zugewinngemeinschaft: return 'Zugewinngemeinschaft';
      case Gueterstand.Guetergemeinschaft: return 'Gütergemeinschaft';
      case Gueterstand.Guetertrennung: return 'Gütertrennung';
      case Gueterstand.Unbekannt: return 'unbekannt';
    }
  }

  getFamilienStand(familienstand: Familienstand) {
    switch (familienstand) {
      case Familienstand.Ledig: return 'ledig';
      case Familienstand.Verheiratet: return 'verheiratet';
      case Familienstand.GetrenntLebend: return 'getrennt lebend';
      case Familienstand.Geschieden: return 'geschieden';
      case Familienstand.Verwittwet: return 'verwitwet';
      case Familienstand.EheaehnlicheLebensgemeinschaft: return 'eheähnliche Lebensgemeinschaft';
      case Familienstand.EingetrageneLebensgemeinschaft: return 'eingetragene Lebensgemeinschaft';
    }
  }

}
