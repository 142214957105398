<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4>{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'general.name' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{fullName}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.geburtsname!==null">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.basic-data.birth-name.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.geburtsname}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.basic-data.birth-date.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.geburtsdatum | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.basic-data.birth-place.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.geburtsort}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.basic-data.birth-country.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneBirthCountry}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.basic-data.nationality.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneNationality}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.basic-data.second-us-american-nationality.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneZweiteStaatsangehoerigkeitUSA}}
            </div>
        </div>
        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr>
            <h4>{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'general.name' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{fullNameSecond}}
                </div>
            </div>
            <div class="row" *ngIf="personendatenSecond.geburtsname!==null">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.personal-data.basic-data.birth-name.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personendatenSecond.geburtsname}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.personal-data.basic-data.birth-date.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personendatenSecond.geburtsdatum | date: 'mediumDate'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.personal-data.basic-data.birth-place.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personendatenSecond.geburtsort}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.personal-data.basic-data.birth-country.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoBirthCountry}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.personal-data.basic-data.nationality.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoNationality}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{'summary.personal-data.personal-data.basic-data.second-us-american-nationality.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoZweiteStaatsangehoerigkeitUSA}}
                </div>
            </div>
        </div>
    </div>
</div>