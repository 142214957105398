<form [formGroup]="formValidation">
  <div class="ui-control-label">{{ 'personal-additional-income-second.personal-additional-income-second.label' | translate: correspondenceSecond }}</div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" [(model)]="nebeneinkuenfte" type="number" formControlName="nebeneinkuenfte"
        label="{{ 'personal-additional-income.sideline-income-amount.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="nebeneinkuenfte" *ngIf="showError" [messages]="errors.nebeneinkuenfte"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
    <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='nebeneinkuenfteseitDatum' (modelChange)="nebeneinkuenfteseitDatumChange($event)"
        label="{{ 'personal-additional-income.sideline-income-amount-start-date.placeholder' | translate }}"></db-input-date>
      <db-validation-errors for="nebeneinkuenfteseitDatum" *ngIf="showError" [messages]="errors.nebeneinkuenfteseitDatum"> </db-validation-errors>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="mieteinnahmen" label="{{ 'personal-additional-income.rental-personal-income.label' | translate }}"> </db-input>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="sonstEinnahmenBetrag" formControlName="sonstEinnahmenBetrag"
        label="{{ 'personal-additional-income.other-personal-income.label' | translate }}">
      </db-input>
      <db-validation-errors for="sonstEinnahmenBetrag" *ngIf="showError" [messages]="errors.sonstEinnahmenBetrag"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" [(model)]="sonstEinnahmenText" formControlName="sonstEinnahmenText"
        label="{{ 'personal-additional-income.other-income-description.label' | translate }}">
      </db-input>
      <db-validation-errors for="sonstEinnahmenText" *ngIf="showError" [messages]="errors.sonstEinnahmenText"> </db-validation-errors>
    </div>
  </div>
</form>