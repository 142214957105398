<br>
<div class="ui-control-label ">
    <span *ngIf="produktTyp === 'FH'">{{ 'bhw-conclusion.subhead.FH' | translate }}</span>
    <span *ngIf="produktTyp === 'TH'">{{ 'bhw-conclusion.subhead.FH' | translate }}</span>
    <span *ngIf="produktTyp === 'FORWARD_FH'">{{ 'bhw-conclusion.subhead.FORWARD_FH' | translate }}</span>
    <span *ngIf="produktTyp === 'FORWARD_FH'">{{ 'bhw-conclusion.subhead.FORWARD_FH' | translate }}</span>
    <span *ngIf="produktTyp === 'KONSTANTEXPRESS'">{{ 'bhw-conclusion.subhead.KONSTANTEXPRESS' | translate }}</span>
</div>
<br>
<div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 card">
        <div class="card-block h-100">
            <div class="text-center">{{ 'bhw-conclusion.received.label' | translate }}</div>
            <div class="text-center my-3">
                <db-icon size="lg">checkbox</db-icon>
            </div>
            <div class="text-center">
                {{ 'bhw-conclusion.received.text' | translate }}
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 card">

        <div class="card-block h-100">
            <div class="text-center">{{ 'bhw-conclusion.inspection.label' | translate }}</div>
            <div class="text-center my-3">
                <db-icon size="lg">rough-book</db-icon>
            </div>
            <div class="text-center">
                {{ 'bhw-conclusion.inspection.text' | translate }}
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 card">
        <div class="card-block h-100">
            <div class="text-center">{{ 'bhw-conclusion.confirmation.label' | translate }}</div>
            <div class="text-center my-3">
                <db-icon size="lg">mail</db-icon>
            </div>
            <div class="text-center">
                {{ 'bhw-conclusion.confirmation.text' | translate }}
            </div>
        </div>
    </div>
</div>
<div class="row my-4">
    <div class="col-xs-12">
        <db-icon>arrow-right</db-icon><a href="https://www.bhw.de/docs/Broschuere_Beratungsinitiative_der_privaten_Bausparkassen.pdf" class="link-arrow-left" target="_blank">
            {{ "footer.info-brochure.text" | translate}}
        </a><br>
        <db-icon>arrow-right</db-icon><a href="https://www.bhw.de/docs/883_120_927609000_mL_5.pdf" class="link-arrow-left mt-2" target="_blank">
            {{ "footer.loan-conditions.text" | translate}}
        </a>
    </div>
</div>