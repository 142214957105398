<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4>{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.contact-data.adress.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneWohnadress}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.contact-data.federal-state.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneBundesland}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.contact-data.housing-situation.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneWohnverhaeltnis}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.contact-data.phone.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneTelefonnummer}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.contact-data.email.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.email}}
            </div>
        </div>
        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr>
            <h4>{{'summary.personal-data.personal-data.second-person.label' | translate}}</h4>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.contact-data.adress.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoWohnadress}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.contact-data.federal-state.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoBundesland}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.contact-data.housing-situation.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoWohnverhaeltnis}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.contact-data.phone.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoTelefonnummer}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.contact-data.email.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personendatenSecond.email}}
                </div>
            </div>
        </div>
    </div>
</div>