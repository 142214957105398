import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectBoxOption } from 'src/app/core/enum-options.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValuesService {
  private baseServiceUrl = '/values';

  private sort = true;

  constructor(private http: HttpClient) {}

  loadBranchen() {
    return this.loadValues('branch', '/branchen', this.sort);
  }

  loadCountries() {
    return this.loadValues('countries', '/laender', this.sort);
  }

  loadNationalities() {
    return this.loadValues('nationalities', '/laender', this.sort);
  }

  loadRegions() {
    return this.loadValues('regions', '/bundeslaender', this.sort);
  }

  loadEmployments() {
    return this.loadValues('employments', '/anstellungen', this.sort);
  }

  loadPropertType() {
    return this.loadValues('properttype', '/objektarten', this.sort);
  }

  loadModernisations() {
    return this.loadValues('modernization', '/modernisierungen', this.sort);
  }

  loadCondition() {
    return this.loadValues('conditions', '/zustaende', !this.sort);
  }

  loadLocations() {
    return this.loadValues('locations', '/lagestandorte', !this.sort);
  }

  loadOutfit() {
    return this.loadValues('outfits', '/ausstattungen', !this.sort);
  }

  loadConstruction() {
    return this.loadValues('construction', '/bauweisen', !this.sort);
  }

  loadAttics() {
    return this.loadValues('attics', '/dachgeschosse', !this.sort);
  }

  loadKeller() {
    return this.loadValues('keller', '/keller', !this.sort);
  }

  private loadValues(valuesType: string, serviceUrl: string, checkForSorting: boolean): Observable<any[]> {
    const serviceResult: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    this.http.get(environment.baseUrl + this.baseServiceUrl + serviceUrl).subscribe(
      (values: any[]) => {
        serviceResult.next(this.getValuesForType(values, valuesType, checkForSorting));
      },
      (error: HttpErrorResponse) => {
        // TODO: discuss error handling
        console.log('ERROR: ', error.message);
      }
    );
    return serviceResult.asObservable();
  }

  private getValuesForType(values: any[], valuesType: string, checkForSorting: boolean) {
    let result: SelectBoxOption[] = [];
    let valueKey: (data: any) => string;
    let labelKey: (data: any) => string;

    if (valuesType === 'countries') {
      labelKey = data => data.country_de;
      valueKey = data => data.iSO3;
      result = this.processValues(values, valueKey, labelKey, false, true);
      this.moveValueTo(result, 'deutsch');
    } else if (valuesType === 'nationalities') {
      labelKey = data => data.nationality_de.toLowerCase();
      valueKey = data => data.iSO3;
      result = this.processValues(values, valueKey, labelKey, true, true);
      this.moveValueTo(result, 'deutsch');
    } else if (valuesType === 'modernization') {
      labelKey = data => data.name;
      valueKey = data => data.value;
      result = this.processValues(values, valueKey, labelKey, false, true);
      // desc sort & move 'NULL' to the bottom
      if (checkForSorting) {
        result.sort((a, b) => (a.value < b.value ? 1 : -1));
      }
      this.moveValueTo(result, 'null', true);
    } else if (valuesType === 'propertytype') {
      labelKey = data => data.name;
      valueKey = data => data.value;
      result = this.processValues(values, valueKey, labelKey, true, true);
      result = result.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    } else {
      labelKey = data => data.name;
      valueKey = data => data.value;
      result = this.processValues(values, valueKey, labelKey, false, checkForSorting);
    }
    return result;
  }

  private processValues(
    values: any[],
    valueKey: (data: any) => string,
    labelKey: (data: any) => string,
    checkForDuplicates?: boolean,
    checkForSorting?: boolean
  ): SelectBoxOption[] {
    let result: SelectBoxOption[] = [];
    result = values
      .filter(item => labelKey(item) && labelKey(item) !== 'undefined')
      .map(item => {
        return {
          label: labelKey(item),
          value: valueKey(item)
        };
      });
    if (checkForSorting) {
      result = result.sort((a, b) => (a.label > b.label ? 1 : -1));
    }
    if (checkForDuplicates) {
      result = result.filter(
        (item, index, array) => index === 0 || item.label !== array[index - 1].label // remove duplicates
      );
    }
    return result;
  }

  private moveValueTo(values: SelectBoxOption[], valueToBeSorted: string, toTheEndOfList?: boolean) {
    let foundedValue = values.find(item => item.label.toLowerCase().includes(valueToBeSorted.toLowerCase()));
    foundedValue = foundedValue ? foundedValue : values.find(item => item.value.toLowerCase().includes(valueToBeSorted.toLowerCase()));
    if (values.indexOf(foundedValue) > 0) {
      if (!toTheEndOfList) {
        values.unshift(values.splice(values.indexOf(foundedValue), 1)[0]);
      } else {
        values.push(values.splice(values.indexOf(foundedValue), 1)[0]);
      }
    }
  }
}
