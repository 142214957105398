<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'personal-family.family-circumstances.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select
        [ngClass]="{
          'not-selected': !familienstand
        }"
        formControlName="familienstand"
        label="{{ 'personal-family.family-circumstances.label' | translate }}"
        placeholder="{{ 'personal-family.family-circumstances.placeholder' | translate }}"
        [options]="familienstandArray"
        [(model)]="familienstand"
      >
      </db-select>
      <db-validation-errors for="familienstand" [messages]="errors.familienstand"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="isGueterstandVisible">
      <db-select
        [ngClass]="{
          'not-selected': !gueterstand
        }"
        formControlName="gueterstand"
        label="{{ 'personal-family.matrimonial-estate.label' | translate }}"
        placeholder="{{ 'personal-family.matrimonial-estate.placeholder' | translate }}"
        [options]="gueterstandArray"
        [(model)]="gueterstand"
      >
      </db-select>
      <db-validation-errors for="gueterstand" [messages]="errors.gueterstand"> </db-validation-errors>
    </div>
  </div>
  <div class="ui-control-label">
    {{ 'personal-family.children-in-household.label' | translate }}
  </div>

  <div *ngFor="let kindFormGroup of kinderFormArray; let i = index">
    <hr class="my-sm-4 my-4" *ngIf="i > 0" />
    <div [formGroup]="kindFormGroup">
      <div class="row">
        <div class="col-sm-12 col-md-6 ui-control">
        <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='geburtsDatum' (modelChange)="dateChange($event, i)"
            label="{{ 'personal-family.children-birthdate.label' | translate }}"></db-input-date>
          <db-validation-errors for="geburtsDatum" [messages]="errors.kinderGeburtsdatum"> </db-validation-errors>
        </div>
        <div class="col-sm-6 col-md-3 ui-control">
          <db-checkbox [(model)]="kinder[i].erhaltKindergeld">{{ 'personal-family.child-benefit.placeholder.label' | translate }} </db-checkbox>
        </div>
        <div class="col-sm-6 col-md-3 ui-control col-button">
          <db-button design="primary" (click)="removeKind(i)">{{ 'personal-family.remove-child.button.text' | translate: { i: i + 1 } }} </db-button>
        </div>
      </div>
    </div>
  </div>
  <db-button design="primary" class="ui-control" (click)="addKind()">
    {{ 'personal-family.add-child.button.text' | translate }}
  </db-button>
</form>
