import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-grundbuch',
  templateUrl: './grundbuch.component.html',
  styleUrls: ['../../summary.component.scss', './grundbuch.component.scss']
})
export class GrundbuchComponent extends BaseComponent implements OnInit {
  grundbuchVon: string;
  band: string;
  blatt: string;
  flur: string;
  flurstueck: string;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.grundbuchVon = this.grundbuch.grundbuchVon;
    this.band = this.grundbuch.band;
    this.blatt = this.grundbuch.blattHeft;
    this.flur = this.grundbuch.flur;
    this.flurstueck = this.grundbuch.flurstueck;
  }
}
