<div class="row first">
    <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!isBhw">
        <div class="card h-100">
            <p>
                {{ 'application-selection.db.title' | translate }}<sup>1</sup>?
            </p>
            <h4>
                {{ 'application-selection.db.text.prefix' | translate }}
                <b>{{ 'application-selection.db.text.telefon' | translate }}</b>
                {{ 'application-selection.db.text.suffix' | translate }}
            </h4>
            <div class="button-container">
                <div class="row center">
                    <div class="col">
                        <db-button design="primary" type="button" (click)="goWithDB()">
                            {{ 'application-selection.db.button.text' | translate }}
                        </db-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card h-100">
            <p>
                {{ 'application-selection.bhw.title' | translate }}
            </p>
            <h4>
                {{ 'application-selection.bhw.text' | translate }}
            </h4>
            <div class="button-container">
                <div class="row center">
                    <div class="col">
                        <db-button design="primary" type="button" (click)="goWithBHW()">
                            {{ 'application-selection.bhw.button.text' | translate }}
                        </db-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bottom-card" *ngIf="!isBhw">
    <sup>1</sup> {{ 'application-selection.db.notice' | translate }}
</div>
<ng-template #modalDBProposeTeaser>
    <div class="popup-content">
        <div class="row">
            <div class="col-md-12">
                <img class="img" src="../../assets/img/products/onlinemarktpreiseinschaetzung.jpg">
            </div>
            <div class="col-md-12">
                <div class="col-md-12 teaser_background">
                    <h1 class="mt-0 teaser_color">
                        {{'db-application-popup.teaser.headline' | translate}}
                    </h1>
                    <p class="teaser_color">
                        {{'db-application-popup.teaser.content' | translate}}
                    </p>
                    <p class="mb-0 teaser_color">
                        {{'db-application-popup.teaser.content-2' | translate}}
                    </p>
                </div>
                <div class="col-md-12 teaser_background">
                    <div class="row  p-4">
                        <div class="col">
                            <db-button design="tertiary" type="button" (click)="teaserCloseButton()">
                                {{ 'alert.all.button.teaser' | translate }}
                            </db-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #modalBHWProposeContent>
    <div class="popup-content">
        <div class="row">
            <db-checkbox class="col-sm-2 col-md-1 col-lg-1 offset-5 offset-md-0" (modelChange)=" agreeBase($event)"></db-checkbox>
            <div class="col-md-11 col-lg-11 col-sm-12 text-center text-md-left">{{ consentTextKey | translate }}
                <a [attr.href]="pdfErklaerungBHW" target="_blank">{{ 'bhw-application-popup.consent-clause.1-2' | translate }}</a>
                {{ 'bhw-application-popup.consent-clause.1-3' | translate }}
                {{ 'bhw-application-popup.consent-clause.1-4' | translate }} <a [attr.href]="linkBhwConsentAgb" target="_blank">{{ 'bhw-application-popup.consent-clause.1-5' |
                    translate }}</a>
            </div>
        </div>
        <ul class="pl-sm-3 pl-3">
            <li class="row">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}"
                    [model]="agreenment.firstAgreeInvoice" (modelChange)="agree($event, 'firstAgreeInvoice')"></db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ 'bhw-application-popup.consent-clause.2' | translate: { fullname: fullName } }}</span>
                </div>
            </li>
            <hr class="d-sm-block d-md-none my-sm-4 my-4" *ngIf="isTwoPerson" />
            <li class="row" *ngIf="isTwoPerson">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}"
                    [model]="agreenment.secondAgreeInvoice" (modelChange)="agree($event, 'secondAgreeInvoice')"></db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ 'bhw-application-popup.consent-clause.2' | translate: { fullname: fullNameSecond } }}</span>
                </div>
            </li>
            <hr class="d-sm-block d-md-none my-sm-4 my-4" />
            <li class="row">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}"
                    [model]="agreenment.firstAgreeSCHUFA" (modelChange)="agree($event, 'firstAgreeSCHUFA')">
                </db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ 'bhw-application-popup.consent-clause.3-1' | translate: { fullname: fullName } }}</span>
                    <a [attr.href]="pdfConsent" target="_blank">{{ 'bhw-application-popup.consent-clause.3-2' | translate }}</a>
                    <span>{{ 'bhw-application-popup.consent-clause.3-3' | translate }}</span>
                </div>
            </li>
            <hr class="d-sm-block d-md-none my-sm-4 my-4" *ngIf="isTwoPerson" />
            <li class="row" *ngIf="isTwoPerson">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}"
                    [model]="agreenment.secondAgreeSCHUFA" (modelChange)="agree($event, 'secondAgreeSCHUFA')">
                </db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ 'bhw-application-popup.consent-clause.3-1' | translate: { fullname: fullNameSecond } }}</span>
                    <a [attr.href]="pdfConsent" target="_blank">{{ 'bhw-application-popup.consent-clause.3-2' | translate }}</a>
                    <span>{{ 'bhw-application-popup.consent-clause.3-3' | translate }}</span>
                </div>
            </li>
        </ul>
        <div class="bottom-card">
            {{ noticeText | translate }}
        </div>
    </div>
</ng-template>
<ng-template #modalDBProposeContent>
    <div class="popup-content">
        <div>{{ 'db-application-popup.headline' | translate }}</div>
        <br />
        <ul class="pl-sm-3 pl-3">
            <li class="row">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}" [model]="agreenment.baseAgree"
                    (modelChange)="agree($event, 'baseAgree')"></db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ consentTextKey | translate }}</span>
                    <a [attr.href]="link" target="_blank">{{ 'db-application-popup.consent-clause.1-2' | translate }}</a>
                </div>
            </li>
            <hr class="d-sm-block d-md-none my-sm-4 my-4" />
            <li class="row">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}"
                    [model]="agreenment.firstAgreeSCHUFA" (modelChange)="agree($event, 'firstAgreeSCHUFA')"></db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ 'db-application-popup.consent-clause.2-1' | translate: { fullname: fullName } }}</span>
                    <a [attr.href]="pdfConsent" target="_blank">{{ 'db-application-popup.consent-clause.2-2' | translate }}</a>
                    <span>{{ 'db-application-popup.consent-clause.2-3' | translate }}</span>
                </div>
            </li>

            <hr class="d-sm-block d-md-none my-sm-4 my-4" *ngIf="isTwoPerson" />
            <li class="row" *ngIf="isTwoPerson">
                <db-switch class="col-md-4 col-sm-12 mb-sm-4 mb-4" onLabel="{{ onLabelKey | translate }}" offLabel="{{ offLabelKey | translate }}"
                    [model]="agreenment.secondAgreeSCHUFA" (modelChange)="agree($event, 'secondAgreeSCHUFA')"></db-switch>
                <div class="col-md-8 col-sm-12 text-center text-md-left">
                    <span>{{ 'db-application-popup.consent-clause.2-1' | translate: { fullname: fullNameSecond } }}</span>
                    <a [attr.href]="pdfConsent" target="_blank">{{ 'db-application-popup.consent-clause.2-2' | translate }}</a>
                    <span>{{ 'db-application-popup.consent-clause.2-3' | translate }}</span>
                </div>
            </li>
        </ul>
        <div class="bottom-card">
            {{ noticeText | translate }}
        </div>
    </div>
</ng-template>