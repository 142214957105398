import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DBDocsService } from '@db/db-core';

import { Mandant, MandantService } from '../shared/services/mandant.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  private head: HTMLElement;
  private themeLinks: HTMLElement[] = [];

  constructor(private dBDocsService: DBDocsService, rendererFactory: RendererFactory2, private mandantService: MandantService) {
    this.head = document.head;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  switchTheme() {
    let theme: string;
    if (this.mandantService.getMandant() === Mandant.DB) {
      theme = 'oap-db-theme';
    } else {
      theme = 'oap-bhw-theme';
    }
    console.log('Switch to theme %s', theme);
    this.setTheme(theme);
    this.activateTheme();
    this.loadCss(theme);
    if (this.themeLinks.length === 2) {
      this.renderer.removeChild(this.head, this.themeLinks.shift());
    }
  }

  setTheme(theme: string) {
    this.dBDocsService.setActiveTheme(theme);
  }

  activateTheme() {
    this.dBDocsService.getActiveTheme().subscribe((theme: string) => {
      if (document.getElementById('main-html')) {
        document.getElementById('main-html').className = theme;
      }
    });
  }

  async loadCss(theme: string) {
    const filename: string = theme + '.css';
    return new Promise(resolve => {
      const linkEl: HTMLElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkEl, 'type', 'text/css');
      this.renderer.setAttribute(linkEl, 'href', filename);
      this.renderer.setProperty(linkEl, 'onload', resolve);
      this.renderer.appendChild(this.head, linkEl);
      this.themeLinks = [...this.themeLinks, linkEl];
    });
  }
}
