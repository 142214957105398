import { BsvDaten } from './bsv-daten';
import { ProduktBerechnung } from './product-berechnung';
import { ProduktParameter } from './produkt-parameter';

export class Produkt {
  produktParameter: ProduktParameter;
  produktBerechnung: ProduktBerechnung;
  bsvDaten: BsvDaten;
  sparBeitrag: number;

  constructor(produkt?: Produkt) {
    this.produktParameter = produkt ? new ProduktParameter(produkt.produktParameter) : new ProduktParameter();
    this.produktBerechnung = produkt ? new ProduktBerechnung(produkt.produktBerechnung) : new ProduktBerechnung();
    this.bsvDaten = produkt ? new BsvDaten(produkt.bsvDaten) : new BsvDaten();
    this.sparBeitrag = produkt ? produkt.sparBeitrag : 0;
  }
}
