import { ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { DBModalService } from '@db/db-core';

import { PopUpButton } from '../components/popup-window/popup-window.component';
import { PopupWrapperComponent } from '../components/popup-wrapper/popup-wrapper.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private popupWrapper: PopupWrapperComponent;

  constructor(private _injector: Injector, private resolver: ComponentFactoryResolver, private modalService: DBModalService) {
    const comRef: ComponentRef<PopupWrapperComponent> = this.resolver.resolveComponentFactory(PopupWrapperComponent).create(this._injector);
    this.popupWrapper = comRef.instance;
    this.popupWrapper.closeClick = this.closeAndResetPopup.bind(this);
  }

  set title(title: string) {
    this.popupWrapper.title = title;
  }
  set leftButton(leftButton: PopUpButton) {
    this.popupWrapper.leftButton = leftButton;
  }

  set centerButton(centerButton: PopUpButton) {
    this.popupWrapper.centerButton = centerButton;
  }

  set rightButton(rightButton: PopUpButton) {
    this.popupWrapper.rightButton = rightButton;
  }

  set contentText(contentText: string) {
    this.popupWrapper.contentText = contentText;
  }
  set content(content: TemplateRef<any>) {
    this.popupWrapper.content = content;
  }

  set error(error: boolean) {
    this.popupWrapper.error = error;
  }

  public openPopup() {
    this.close().then(() => {
      this.modalService.openWithTemplate(this.popupWrapper.modalTemplateRef).then((viewRef: EmbeddedViewRef<any>) => {
        console.log('Popup opened title=%s', this.popupWrapper.title);
      });
    });
  }

  public closeAndResetPopup() {
    this.close().then(() => {
      this.resetPopup();
    });
  }

  private close(): Promise<void> {
    if (this.modalService.isOpened()) {
      return this.modalService.close();
    }
    return new Promise(resolve => {
      setTimeout(() => {
        console.log('Nothing to close!');
        resolve();
      }, 1);
    });
  }

  public resetPopup() {
    this.title = undefined;
    this.leftButton = undefined;
    this.rightButton = undefined;
    this.centerButton = undefined;
    this.contentText = undefined;
    this.content = undefined;
    this.error = false;
  }
}
