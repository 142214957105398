import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { PopUpButton } from '../shared/components/popup-window/popup-window.component';
import { PopupService } from '../shared/services/popup.service';
import { AuthService } from './auth.service';

export abstract class BaseService {
  protected httpOptions;

  private okButton: PopUpButton = {
    title: 'alert.all.button.ok',
    click: () => this.popupService.closeAndResetPopup()
  };

  constructor(private popupService: PopupService, protected http: HttpClient, protected authService: AuthService) {
    authService
      .getAccessToken()
      .then(accessToken => {
        this.httpOptions = {
          headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + accessToken)
        };
      })
      .catch(error => {
        console.error('Access token service error: %o', error);
        this.openErrorModalWindow(undefined, undefined);
      });
  }

  protected errorHandling(serviceName: string, error: HttpErrorResponse) {
    console.error('By %s is an error is occurred', serviceName);
    let contentKey;
    if (error.status >= 400 && error.status < 500) {
      console.error('Invalid data error is occurred: status: %n, msg: %s', error.status, error.message);
      contentKey = 'app.invalid.service.error.popup.title';
    }
    this.openErrorModalWindow(undefined, contentKey);
  }

  protected openErrorModalWindow(headerKey: string, contentKey: string) {
    this.popupService.resetPopup();
    headerKey = headerKey ? headerKey : 'service.error.popup.title';
    contentKey = contentKey ? contentKey : 'service.error.popup';
    this.popupService.title = headerKey;
    this.popupService.centerButton = this.okButton;
    this.popupService.contentText = contentKey;
    this.popupService.error = true;
    this.popupService.openPopup();
  }
}

export enum HttpMethod {
  POST = 'post',
  GET = 'get',
  PUT = 'put'
}
