import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popup-window',
  templateUrl: './popup-window.component.html',
  styleUrls: ['./popup-window.component.scss']
})
export class PopupWindowComponent implements OnInit {
  @Input() leftButton: PopUpButton;
  @Input() centerButton: PopUpButton;
  @Input() rightButton: PopUpButton;
  @Input() content: TemplateRef<any>;
  @Input() contentText: string;
  @Input() title: string;
  @Input() error: boolean;

  @Output() closeClick: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onClose(): void {
    if (this.closeClick.next) {
      this.closeClick.next();
    }
  }

  public onLeftClick(): void {
    if (this.leftButton.click) {
      this.leftButton.click();
    }
  }

  public onRightClick() {
    if (this.rightButton.click) {
      this.rightButton.click();
    }
  }
  public onCenterClick() {
    this.centerButton.click();
  }

  public isDisabled(button: PopUpButton) {
    if (button && button.disabled) {
      return button.disabled();
    }
    return false;
  }
}
export interface PopUpButton {
  title: string;
  click: () => void;
  disabled?: () => boolean;
}
