<div class="ui-control-label" *ngIf="status && status === 'IN_BEARBEITUNG'">
  {{ 'summary.label' | translate }}
</div>
<db-intro-preview>
  <db-toggleable id="mainSum0" [label]="'summary.financing-check.label' | translate" [open]="toggleMainArray[0]" iconPosition="right" (openChange)="toggleMainClick(0)">
    <app-finanzierungscheck-summary></app-finanzierungscheck-summary>
  </db-toggleable>
</db-intro-preview>
<db-intro-preview>
  <db-toggleable id="mainSum1" [label]="'summary.product.label' | translate" [open]="toggleMainArray[1]" iconPosition="right" (openChange)="toggleMainClick(1)">
    <app-produkt-summary></app-produkt-summary>
  </db-toggleable>
</db-intro-preview>
<db-intro-preview>
  <db-toggleable id="mainSum2" [label]="'summary.personal-data.personal-data.label' | translate" [open]="toggleMainArray[2]" iconPosition="right" (openChange)="toggleMainClick(2)">
    <app-persoenlichen-daten-summary></app-persoenlichen-daten-summary>
  </db-toggleable>
</db-intro-preview>
<db-intro-preview>
  <db-toggleable id="mainSum3" [label]="'summary.personal-data.household-check.label' | translate" [open]="toggleMainArray[3]" iconPosition="right"
    (openChange)="toggleMainClick(3)">
    <app-haushaltscheck-summary></app-haushaltscheck-summary>
  </db-toggleable>
</db-intro-preview>
<db-intro-preview *ngIf="verwendungsZweck !== 'MODERNISIERUNG'">
  <db-toggleable id="mainSum4" [label]="'summary.object-data.label' | translate" [open]="toggleMainArray[4]" iconPosition="right" (openChange)="toggleMainClick(4)">
    <app-objektdaten-summary></app-objektdaten-summary>
  </db-toggleable>
</db-intro-preview>
<db-intro-preview>
  <db-toggleable id="mainSum5" [label]="'summary.personal-data.other-expenditure-and-assets.label' | translate" [open]="toggleMainArray[5]" iconPosition="right"
    (openChange)="toggleMainClick(5)">
    <app-sonstige-ausgaben-vermoegen-summary></app-sonstige-ausgaben-vermoegen-summary>
  </db-toggleable>
</db-intro-preview>