import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SelectBoxOption } from './enum-options.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateListService {
  constructor(private translateService: TranslateService) {}

  translateList(listToTranslate: any[], key: string) {
    listToTranslate.forEach(element => {
      this.translateService
        .get(element[key], element)
        .subscribe((text: string) => {
          element[key] = text;
        });
    });
  }

  translateOptions<T extends SelectBoxOption>(options: T[]) {
    options.forEach(option => {
      this.translateService.get(option.label).subscribe((text: string) => {
        option.label = text;
      });
    });
  }
}
