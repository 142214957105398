import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Zahlungsinformation } from 'src/app/shared/model/zahlungsinformation';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-leasingvertraege',
  templateUrl: './leasingvertraege.component.html',
  styleUrls: ['../../summary.component.scss', './leasingvertraege.component.scss']
})
export class LeasingvertraegeComponent extends BaseComponent implements OnInit {
  leasingContracts: Zahlungsinformation[];
  leasingContractsSecond: Zahlungsinformation[];
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.leasingContracts = this.haushaltsrechnung.ausgaben.leasing;
    this.leasingContractsSecond = this.haushaltsrechnungSecond.ausgaben.leasing;
  }
}
