<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'loan-check-repayment.fixed-interest-period.label' | translate }}
  </div>

  <div class="ui-control">
    <db-radio-group formControlName="interestPeriod">
      <db-radio-button *ngFor="let item of radioButtonLaufzeit" (change)="interestPeriodChange($event)" [model]="item.value">
        {{ item.label }}
      </db-radio-button>
    </db-radio-group>
    <db-validation-errors for="interestPeriod" [messages]="errors.interestPeriod"> </db-validation-errors>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-5">
      <div class="ui-control-label" *ngIf="isRescheduling">
        {{ 'loan-check-repayment.type-of-usage.rescheduling.label' | translate }}
      </div>
      <div class="ui-control-label" *ngIf="!isRescheduling">
        {{ 'loan-check-repayment.type-of-usage.label' | translate }}
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
      <db-select
        [ngClass]="{
          'not-selected': !nutzungsart
        }"
        formControlName="nutzungsart"
        placeholder="{{ 'loan-check-repayment.type-of-usage.placeholder' | translate }}"
        label="{{ 'loan-check-repayment.type-of-usage.field.label' | translate }}"
        (change)="usageChange($event)"
        [options]="NutzungsartList"
      >
      </db-select>
      <db-validation-errors for="nutzungsart" [messages]="errors.typeOfUsage"> </db-validation-errors>
    </div>
  </div>
  <div class="row" *ngIf="showForProductTyp">
    <div class="col-sm-12 col-md-6 col-lg-5">
      <div class="ui-control-label">
        {{ 'loan-check-repayment.type-of-usage.monthly-building-loan-rate.label' | translate }}
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
      <db-slider [step]="10" [min]="minValue('monatlicherSparbetrag')" [max]="maxValue('monatlicherSparbetrag')" [unit]="'€'" [(model)]="monatlicherSparbetrag"> </db-slider>
    </div>
    <div class="col-sm-12 ui-control slider-input">
      <db-input [(model)]="monatlicherSparbetrag" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
    </div>
  </div>
  <div class="row" *ngIf="showForProductTyp">
    <div class="col-sm-12 col-md-6 col-lg-5">
      <div class="ui-control-label">
        {{ 'loan-check-repayment.type-of-usage.monthly-amortization-contribution.label' | translate }}
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
      <db-slider [step]="10" [min]="minValue('monatlicherTilgungsbetrag')" [max]="maxValue('monatlicherTilgungsbetrag')" [unit]="'€'" [(model)]="monatlicherTilgungsbetrag">
      </db-slider>
    </div>
    <div class="col-sm-12 ui-control slider-input">
      <db-input [(model)]="monatlicherTilgungsbetrag" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
    </div>
  </div>
  <div class="row" *ngIf="!showForProductTyp">
    <div class="col-sm-12 col-md-6 col-lg-5">
      <div class="ui-control-label">
        {{ 'loan-check-repayment.desired-initial-repayment.label' | translate }}
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
      <db-slider
        [step]="1"
        [min]="minValue('anfaenglicherTilgungszinssatz')"
        [max]="maxValue('anfaenglicherTilgungszinssatz')"
        [unit]="'%'"
        [(model)]="anfaenglicherTilgungszinssatz"
      >
      </db-slider>
    </div>
    <div class="col-sm-12 ui-control slider-input">
      <db-input
        [(model)]="anfaenglicherTilgungszinssatz"
        formControlName="anfaenglicherTilgungszinssatz"
        type="number"
        autocomplete="off"
        label="{{ 'loan-check-repayment.desired-initial-repayment.input.label' | translate }}"
      >
        <span>%</span>
      </db-input>
      <db-validation-errors for="anfaenglicherTilgungszinssatz" [messages]="errors.anfaenglicherTilgungszinssatz"> </db-validation-errors>
    </div>
  </div>
</form>
