import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { VerwendungsZweck } from 'src/app/shared/model/produkt-parameter';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { MandantService } from 'src/app/shared/services/mandant.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-finanzierungscheck-summary',
  templateUrl: './finanzierungscheck-summary.component.html',
  styleUrls: ['../summary.component.scss', './finanzierungscheck-summary.component.scss']
})
export class FinanzierungscheckSummaryComponent extends BaseComponent implements OnInit {
  isBhw: boolean;
  verwendungsZweckLabel: string;
  produktTypLabel: string;
  monatlicheRate: number;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    mandantService: MandantService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
    this.isBhw = this.isMandantBhw(mandantService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initLocalProperties();
    this.adobe.pushAdobeEvent("Finance_check_summary", "summary")
  }

  initLocalProperties() {
    this.verwendungsZweckLabel = this.getVerwendungszweckLabel();
    this.produktTypLabel = this.getProduktLabel(this.isBhw);
    console.log(this.produkt.produktParameter.produkttyp);
    this.monatlicheRate = this.appDataService.calculateMonatlicheRate(this.produkt);
  }

  getVerwendungszweckLabel() {
    if (!this.verwendungsZweck) {
      return '';
    }
    switch (this.verwendungsZweck) {
      case VerwendungsZweck.Modernization:
        return 'Modernisierung';
      case VerwendungsZweck.Rescheduling:
        return 'Umschuldung';
      case VerwendungsZweck.Purchase:
        return 'Kauf';
      case VerwendungsZweck.NewBuild:
        return 'Neubauvorhaben';
      case VerwendungsZweck.KaufAltbau:
        return 'Kauf Altbau ';
      case VerwendungsZweck.InternRescheduling:
        return 'Interne Umschuldung';
      case VerwendungsZweck.PurchasePlot:
        return 'Kauf Grundstück';
      case VerwendungsZweck.Expansion:
        return 'Anbau / Umbau';
    }
  }
}
