export class ProduktBerechnung {
  darlehenssumme: number;
  tilgungSollzins: number;
  tilgungEffektivzins: number;
  tilgungLaufzeitMonate: number;
  grundbuchKosten: number;
  gesamtEffektivzins: number;
  tilgungAbDatum: Date;
  tilgungBisDatum: Date;
  tilgungsplanSumme: number;
  risikozuschlag: number;
  umschuldungsperiode: number;
  restSchuldTR: string;

  constructor(produktBerechnung?: ProduktBerechnung) {
    this.darlehenssumme = produktBerechnung ? produktBerechnung.darlehenssumme : 0;
    this.tilgungSollzins = produktBerechnung ? produktBerechnung.tilgungSollzins : 0;
    this.tilgungEffektivzins = produktBerechnung ? produktBerechnung.tilgungEffektivzins : 0;
    this.risikozuschlag = produktBerechnung ? produktBerechnung.risikozuschlag : 0;
    this.tilgungLaufzeitMonate = produktBerechnung ? produktBerechnung.tilgungLaufzeitMonate : null;
    this.tilgungsplanSumme = produktBerechnung ? produktBerechnung.tilgungsplanSumme : 0;
    this.tilgungAbDatum = produktBerechnung && produktBerechnung.tilgungAbDatum ? new Date(produktBerechnung.tilgungAbDatum) : null;
    this.tilgungBisDatum = produktBerechnung && produktBerechnung.tilgungBisDatum ? new Date(produktBerechnung.tilgungBisDatum) : null;
    this.umschuldungsperiode = produktBerechnung ? produktBerechnung.umschuldungsperiode : null;
    this.restSchuldTR = produktBerechnung ? produktBerechnung.restSchuldTR : null;
    this.gesamtEffektivzins = produktBerechnung ? produktBerechnung.gesamtEffektivzins : 0;
    this.grundbuchKosten = produktBerechnung ? produktBerechnung.grundbuchKosten : 0;
  }
}
