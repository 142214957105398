import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { ConditionalRequiredSettings, OAPValidators } from '../../core/validators.directive';

@Component({
  selector: 'app-personal-additional-income-second',
  templateUrl: './personal-additional-income-second.component.html',
  styleUrls: ['./personal-additional-income-second.component.scss'],
  providers: [FormBuilder]
})
export class PersonalAdditionalIncomeSecondComponent extends BaseComponent implements OnInit {
  showError = true;
  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    popupService: PopupService,
    translateService: TranslateService,
    headerService: HeaderService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  errors: any = {
    nebeneinkuenfte: [
      {
        rule: 'required',
        message: 'personal-additional-income.sideline-income-amount.hint'
      }
    ],
    nebeneinkuenfteseitDatum: [
      {
        rule: 'required',
        message: 'personal-additional-income.sideline-income-amount-start-date.empty.hint'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Datum',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ],
    sonstEinnahmenBetrag: [
      {
        rule: 'required',
        message: 'personal-additional-income.other-income-amount.hint'
      }
    ],
    sonstEinnahmenText: [
      {
        rule: 'required',
        message: 'personal-additional-income.other-income-description.hint'
      }
    ]
  };

  ngOnInit() {
    super.ngOnInit();
    this.headerService.changeText('personal-additional-income.headline');
    this.formSettings();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_additional_income_second", "personal data");
  }

  formSettings() {
    const requiredSetting: ConditionalRequiredSettings[] = new Array();
    requiredSetting.push({
      controlNames: ['nebeneinkuenfte'],
      andConditions: [{ conditionalControlName: 'nebeneinkuenfteseitDatum' }]
    });
    requiredSetting.push({
      controlNames: ['nebeneinkuenfteseitDatum'],
      andConditions: [{ conditionalControlName: 'nebeneinkuenfte' }]
    });
    requiredSetting.push({
      controlNames: ['sonstEinnahmenBetrag'],
      andConditions: [{ conditionalControlName: 'sonstEinnahmenText' }]
    });
    requiredSetting.push({
      controlNames: ['sonstEinnahmenText'],
      andConditions: [{ conditionalControlName: 'sonstEinnahmenBetrag' }]
    });
    this.formValidation = this.formBuilder.group(
      {
        nebeneinkuenfte: new FormControl(this.nebeneinkuenfte),
        nebeneinkuenfteseitDatum: new FormControl(this.nebeneinkuenfteseitDatum, OAPValidators.dateFormat()),
        sonstEinnahmenBetrag: new FormControl(this.sonstEinnahmenBetrag),
        sonstEinnahmenText: new FormControl(this.sonstEinnahmenText)
      },
      {
        validator: OAPValidators.conditionalRequiredValuesFormValidator(requiredSetting)
      }
    );
  }

  // getter & setter
  get nebeneinkuenfte() {
    return this.haushaltsrechnungSecond.einnahmen.nebeneinkuenfte;
  }

  set nebeneinkuenfte(nebeneinkuenfte: number) {
    this.haushaltsrechnungSecond.einnahmen.nebeneinkuenfte = +nebeneinkuenfte;
  }

  nebeneinkuenfteseitDatumChange($event) {
    this.haushaltsrechnungSecond.einnahmen.nebeneinkuenfteseitDatum = this.dateService.getDateModelFromDateUI($event);
  }

  get nebeneinkuenfteseitDatum() {
    if (this.haushaltsrechnungSecond.einnahmen.nebeneinkuenfteseitDatum) {
      return this.dateService.getDateUIFromModel(this.haushaltsrechnungSecond.einnahmen.nebeneinkuenfteseitDatum);
    }
  }

  get mieteinnahmen() {
    return this.haushaltsrechnungSecond.einnahmen.mieteinnahmen;
  }

  set mieteinnahmen(mieteinnahmen: number) {
    this.haushaltsrechnungSecond.einnahmen.mieteinnahmen = +mieteinnahmen;
  }

  get sonstEinnahmenBetrag() {
    return this.haushaltsrechnungSecond.einnahmen.sonstEinnahmenBetrag;
  }

  set sonstEinnahmenBetrag(sonstEinnahmenBetrag: number) {
    this.haushaltsrechnungSecond.einnahmen.sonstEinnahmenBetrag = +sonstEinnahmenBetrag;
  }

  get sonstEinnahmenText() {
    return this.haushaltsrechnungSecond.einnahmen.sonstEinnahmenText;
  }

  set sonstEinnahmenText(sonstEinnahmenText: string) {
    this.haushaltsrechnungSecond.einnahmen.sonstEinnahmenText = sonstEinnahmenText;
  }
}
