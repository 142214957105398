import { Component, Input, OnInit } from '@angular/core';

import { NavButtonBarService, NavButtonType } from '../../services/nav-button-bar.service';

@Component({
  selector: 'app-navi-button',
  templateUrl: './navi-button.component.html',
  styleUrls: ['./navi-button.component.scss']
})
export class NaviButtonComponent implements OnInit {
  @Input() label: string;
  @Input() navButtonType: NavButtonType;
  @Input() buttonType: string;

  buttondesign: string;

  constructor(private navBarService: NavButtonBarService) { }

  ngOnInit() {
    this.initDesign();
  }

  click() {
    this.navBarService.onNavBarClicked(this.navButtonType);
  }

  private initDesign() {
    switch (this.navButtonType) {
      case NavButtonType.Next: {
        this.buttondesign = 'primary';
        break;
      }
      case NavButtonType.Back: {
        this.buttondesign = 'primary';
        break;
      }
      case NavButtonType.Save: {
        this.buttondesign = 'secondary';
        break;
      }
      default: {
        console.log('Unknown buttonType=%s set secondary design', this.buttondesign);
        this.buttondesign = 'secondary';
        break;
      }
    }
  }
}
