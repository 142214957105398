import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { PersonalDataEnumOptionsService } from 'src/app/personal-data/personal-data-enum-options.service';
import { YesNoConfirmation } from 'src/app/shared/model/application-data';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import { TranslateListService } from '../../core/translate-list.service';
import { OAPValidators } from '../../core/validators.directive';
import { ProdukTyp } from '../../shared/model/produkt-parameter';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-loan-check-two',
  templateUrl: './loan-check-two.component.html',
  styleUrls: ['./loan-check-two.component.scss'],
  providers: [FormBuilder]
})
export class LoanCheckTwoComponent extends BaseComponent implements OnInit, AfterContentChecked, OnDestroy {
  isBaufiOptionsVisible: boolean;
  anzahlAntragnehmerRadioArray: any[] = [];
  BaufinaRadioArray: any[] = [];

  errors: any = {
    baufinaOptions: [
      {
        rule: 'required',
        message: 'loan-check-2.previous-loan.hint'
      }
    ],
    anzahloptions: [
      {
        rule: 'required',
        message: 'loan-check-2.number-of-debtors.hint'
      }
    ],
    sonstigeDarlehen: [
      {
        rule: 'multiplyThousand',
        message: 'loan-check-2.other-financial-resources.checkbox.hint'
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private enumService: PersonalDataEnumOptionsService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormGroup();
    this.getRadiobuttonArray();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Loan_check_two", "loan data");
  }

  private initFormGroup() {
    this.formValidation = this.formBuilder.group({
      baufinaOptions: new FormControl(this.baufiLetzteZweiJahre, OAPValidators.conditionRequiredValidator(this.isBaufiOptionsVisible)),
      anzahloptions: new FormControl(this.anzahlAntragnehmer, DBValidators.required()),
      sonstigeDarlehen: new FormControl(this.sonstDarlehenBetrag, [OAPValidators.multiplyThousandValidator])
    });
  }

  initAppData() {
    super.initAppData();
    this.isBaufiOptionsVisible = this.produktTyp && this.produktTyp === ProdukTyp.ConstantExpress;
  }

  getRadiobuttonArray() {
    this.anzahlAntragnehmerRadioArray = [
      { name: '1', value: 1 },
      { name: '2', value: 2 }
    ];
    this.BaufinaRadioArray = this.enumService.getOptionsFor(YesNoConfirmation);
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  anzahlAntragnehmerChange($event) {
    this.antrag.anzahlAntragnehmer = this.convertFromDBCore($event.target.value, 'number');
    if (this.antrag.anzahlAntragnehmer < 2) {
      this.clearNebensdarlehensnehmer();
    }
  }

  baufinaChange($event) {
    this.antrag.ausschlussKriterien.baufiLetzteZweiJahre = this.convertFromDBCore($event.target.value, 'boolean');
  }

  minValue() {
    return 0;
  }

  maxValue() {
    if (this.isBaufiOptionsVisible) {
      return 50000;
    } else {
      return 750000;
    }
  }

  // get set
  get baufiLetzteZweiJahre() {
    return this.convertToDBCore(this.antrag.ausschlussKriterien.baufiLetzteZweiJahre);
  }

  get anzahlAntragnehmer() {
    return this.antrag.anzahlAntragnehmer;
  }

  get eigenleistungen() {
    return this.produkt.produktParameter.eigenleistungen;
  }

  set eigenleistungen(eigenleistungen: number) {
    this.produkt.produktParameter.eigenleistungen = +eigenleistungen > this.maxValue() ? this.maxValue() : +eigenleistungen;
  }

  get sonstDarlehenBetrag() {
    return this.produkt.produktParameter.sonstDarlehenBetrag;
  }

  set sonstDarlehenBetrag(sonstDarlehenBetrag: number) {
    this.produkt.produktParameter.sonstDarlehenBetrag = +sonstDarlehenBetrag;
  }

  get sonstDarlehenText() {
    return this.produkt.produktParameter.sonstDarlehenText;
  }

  set sonstDarlehenText(sonstDarlehenText: string) {
    this.produkt.produktParameter.sonstDarlehenText = sonstDarlehenText;
  }

  // overriden
  protected validationPopupIntern(): boolean {
    if (this.appDataService.berechneFinanzierungsbedarf() < 10000) {
      this.openValidationPopupWindow('alert.loan-too-low.text');
      return false;
    }
    if (this.appDataService.berechneFinanzierungsbedarf() > 50000 && this.isBaufiOptionsVisible) {
      this.openValidationPopupWindow('alert.loan-too-high.text');
      return false;
    }
    return true;
  }
}
