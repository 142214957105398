<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'personal-residential.adress.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        [(model)]="personendaten.strasse"
        formControlName="strasse"
        type="text"
        [label]="'personal-residential.adress.street.placeholder' | translate"
        (modelChange)="addressManuallyModified()"
        autocomplete="off"
        label=""
      >
      </db-input>
      <db-validation-errors for="strasse" [messages]="errors.strasse"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        [(model)]="personendaten.hausnummer"
        formControlName="hausnummer"
        [label]="'personal-residential.adress.house-number.placeholder' | translate"
        autocomplete="off"
        (modelChange)="addressManuallyModified()"
        label=""
      >
      </db-input>
      <db-validation-errors for="hausnummer" [messages]="errors.hausnummer"> </db-validation-errors>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        [(model)]="personendaten.plz"
        formControlName="plz"
        type="text"
        [label]="'personal-residential.adress.postcode.placeholder' | translate"
        autocomplete="off"
        (modelChange)="addressManuallyModified()"
        label=""
      >
      </db-input>
      <db-validation-errors for="plz" [messages]="errors.plz"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        [(model)]="personendaten.ort"
        formControlName="ort"
        type="text"
        [label]="'personal-residential.adress.place.placeholder' | translate"
        (modelChange)="addressManuallyModified()"
        autocomplete="off"
        label=""
      >
      </db-input>
      <db-validation-errors for="ort" [messages]="errors.ort"> </db-validation-errors>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select
        [ngClass]="{
          'not-selected': !personendaten.bundesland || personendaten.bundesland === ''
        }"
        formControlName="bundesLand"
        placeholder="{{ 'general.select.placeholder' | translate }}"
        label="{{ 'general.federal-state.label' | translate }}"
        [options]="bundeslandList"
        [(model)]="personendaten.bundesland"
        (modelChange)="addressManuallyModified()"
      >
      </db-select>
      <db-validation-errors for="bundesLand" [messages]="errors.bundesLand"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6"></div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        [(model)]="personendaten.telefonnummerVorwahl"
        type="text"
        formControlName="telefonnummerVorwahl"
        [label]="'personal-residential.adress.phone-area-code.placeholder' | translate"
        autocomplete="off"
        label=""
      >
      </db-input>
      <db-validation-errors for="telefonnummerVorwahl" [messages]="errors.telefonnummerVorwahl"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        [(model)]="personendaten.telefonnummerDurchwahl"
        formControlName="telefonnummerDurchwahl"
        type="text"
        [label]="'personal-residential.adress.phone-number.placeholder' | translate"
        autocomplete="off"
        label=""
      >
      </db-input>
      <db-validation-errors for="telefonnummerDurchwahl" [messages]="errors.telefonnummerDurchwahl"> </db-validation-errors>
    </div>
  </div>
  <div class="ui-control-label">
    {{ 'personal-residential.housing-situation.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select
        [ngClass]="{
          'not-selected': !personendaten.wohnverhaeltnis
        }"
        formControlName="wohnverhaeltnis"
        placeholder="{{ 'general.select.placeholder' | translate }}"
        label="{{ 'personal-residential.housing-situation.field.label' | translate }}"
        [options]="wohnverhaeltnisList"
        [(model)]="personendaten.wohnverhaeltnis"
      >
      </db-select>
      <db-validation-errors for="wohnverhaeltnis" [messages]="errors.wohnverhaeltnis"> </db-validation-errors>
    </div>
  </div>
</form>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': isAddressAutoModified }">
  {{ 'general.warning-address-adjusted.label' | translate }}
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': isAddressInvalid }">
  {{ 'general.warning-address-not-found.label' | translate }}
</div>
