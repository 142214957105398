import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as routes from '../../routing-path';
import { ApplicationData } from '../model/application-data';
import { ApplicationDataService } from './application-data.service';
import { Mandant, MandantService } from './mandant.service';

export enum NavButtonType {
  Next = 'next',
  Back = 'back',
  Save = 'save'
}

@Injectable({
  providedIn: 'root'
})
export class NavButtonBarService {
  private _currentPath: string;
  private validate: boolean;
  private redirectUrl: string;
  private isBHW: boolean;
  private subscribeRoutePath: any;

  private onSaveSource = new Subject();
  onSave = this.onSaveSource.asObservable();

  // Observable for event: on form  submitted
  private onFormSubmitSource = new Subject();
  onFormSubmit = this.onFormSubmitSource.asObservable();

  // Observable for event: change current path
  private currentPathSource = new BehaviorSubject('');
  currentPathObs = this.currentPathSource.asObservable();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private mandantService: MandantService, private appDataService: ApplicationDataService) {
    this.isBHW = this.mandantService.getMandant() !== Mandant.DB;
    this.setCurrentPathFromRoute();
  }

  setValid(isValid: boolean) {
    this.validate = isValid;
  }

  get valid(): boolean {
    return this.validate;
  }

  setRedirectUrl() {
    this.redirectUrl = routes.calculateNextPath(this._currentPath, this.isBHW, this.appData);
    console.log('NavButtonBarService: next calculated=%s', this.redirectUrl);
  }

  set currentPath(currPath: string) {
    if (!currPath || currPath === '' || currPath === routes.SLASH) {
      this._currentPath = routes.PROD_MAIN_PATH;
    } else {
      this._currentPath = currPath.substring(0, 1) === routes.SLASH ? currPath : routes.SLASH + currPath;
    }
    this.currentPathSource.next(this._currentPath);
  }

  onNavBarClicked(navButtonType: NavButtonType) {
    switch (navButtonType) {
      case NavButtonType.Next: {
        this.onFormSubmitSource.next();
        break;
      }
      case NavButtonType.Back: {
        this.redirectUrl = routes.calculateBackPath(this._currentPath, this.isBHW, this.appData);
        console.log('NavButtonBarService: back calculated=%s', this.redirectUrl);
        this.navigate();
        break;
      }
      case NavButtonType.Save: {
        this.onSaveSource.next();
        break;
      }
      default: {
        console.log('Unknown nav button type=%s do nothing', navButtonType);
      }
    }
  }

  public navigate() {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.currentPath = this.redirectUrl;
    }
  }

  private get appData(): ApplicationData {
    return this.appDataService.applicationData;
  }

  private async setCurrentPathFromRoute() {
    this.subscribeRoutePath = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        map(route => route.snapshot.url[0].path)
      )
      .subscribe(path => {
        console.log('NavButtonBarService: set currentPath from route %s', path);
        this.currentPath = path === routes.MANDANT_PATH ? routes.PROD_MAIN_PATH : path;
        this.unsubscribe();
      });
  }

  private unsubscribe() {
    this.subscribeRoutePath.unsubscribe();
    console.log('Unubscribing from route events');
  }
}
