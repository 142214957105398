import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ProdukTyp } from 'src/app/shared/model/produkt-parameter';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-produkt-summary',
  templateUrl: './produkt-summary.component.html',
  styleUrls: ['../summary.component.scss', './produkt-summary.component.scss']
})
export class ProduktSummaryComponent extends BaseComponent implements OnInit {
  zinsbindung: string;
  anzahlRaten: number;
  isExpress: boolean;
  isTH: boolean;
  bausparsumme: number;
  monZahlBetrag: number;
  restschuld: number;
  auszahlungskurs: number;
  bereitstellungszinsfreieZeit: string;
  bereitstellungszins: string;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isTH = this.produktTyp === ProdukTyp.TH || this.produktTyp === ProdukTyp.ForwardTH;
    this.isExpress = this.produktTyp === ProdukTyp.ConstantExpress;
    this.zinsbindung = this.appDataService.calculateZinsbindung(this.produkt);
    this.anzahlRaten = this.appDataService.calculateAnzahlRaten(this.produkt);
    this.bausparsumme = this.produkt.bsvDaten.teilbausparSumme ? this.produkt.bsvDaten.teilbausparSumme : this.produkt.bsvDaten.bausparSumme;
    this.monZahlBetrag = this.appDataService.calculateMonatlicheRate(this.produkt);
    this.restschuld = this.produkt.produktBerechnung.darlehenssumme - this.produkt.produktBerechnung.tilgungsplanSumme;
    this.auszahlungskurs = 100;
    this.bereitstellungszinsfreieZeit = '2 Monate';
    this.bereitstellungszins = '3 %';
    this.adobe.pushAdobeEvent("Product_summary", "summary");
  }
}
