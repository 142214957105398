<form [formGroup]="formValidation">
    <div class="ui-control-label">
        {{ 'property-modernization.label' | translate }}
    </div>
    <div class="row ui-control">
        <div class="col-sm-12 col-md-5">
            <db-input [(model)]="jahrLetzteModMassnahme" type="number" formControlName="jahrLetzteModMassnahme"
                label="{{ 'property-modernization.last-modernization.label' | translate }} ({{ 'property-modernization.last-modernization.placeholder' | translate }})"
                autocomplete="off">
            </db-input>
            <db-validation-errors for="jahrLetzteModMassnahme" [messages]="errors.jahrLetzteModMassnahme"> </db-validation-errors>
        </div>

        <div class="col-md-7"></div>
    </div>

    <div class="row ui-control">
        <div class="col-sm-12 col-md-5">
            <div class="ui-control">
                <db-select formControlName="kernsanierung" [ngClass]="{ 'not-selected': !objektWert.kernsanierung, '': hasModernisierung() }"
                    placeholder="{{ 'property-modernization.core-refurbishment.placeholder' | translate }}" [options]="kernsanierungList" [(model)]="objektWert.kernsanierung"
                    label="{{ 'property-modernization.core-refurbishment.label' | translate }}">
                </db-select>
            </div>
            <div class="ui-control">
                <db-select formControlName="dacherneuerung" [ngClass]="{ 'not-selected': !objektWert.dacherneuerung, '': hasKernsanierung('dacherneuerung') }"
                    placeholder="{{ 'property-modernization.roof-renovation.placeholder' | translate }}" [options]="modernisationsList" [(model)]="objektWert.dacherneuerung"
                    label="{{ 'property-modernization.roof-renovation.label' | translate }}">
                </db-select>
            </div>
            <div class="ui-control">
                <db-select formControlName="daemmungAussenwaende" [ngClass]="{
            'not-selected': !objektWert.daemmungAussenwaende,
            '': hasKernsanierung('daemmungAussenwaende')
          }" placeholder="{{ 'property-modernization.exterior-wall-insulation.placeholder' | translate }}" [options]="modernisationsList"
                    [(model)]="objektWert.daemmungAussenwaende" label="{{ 'property-modernization.exterior-wall-insulation.label' | translate }}">
                </db-select>
            </div>
            <div class="ui-control">
                <db-select formControlName="modernisierungBaeder" [ngClass]="{
            'not-selected': !objektWert.modernisierungBaeder,
            '': hasKernsanierung('modernisierungBaeder')
          }" placeholder="{{ 'property-modernization.bathrooms.placeholder' | translate }}" [options]="modernisationsList" [(model)]="objektWert.modernisierungBaeder"
                    label="{{ 'property-modernization.bathrooms.label' | translate }}">
                </db-select>
            </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-sm-12 col-md-5">
            <div class="ui-control">
                <db-select formControlName="modernisierungHeizung" [ngClass]="{
            'not-selected': !objektWert.modernisierungHeizung,
            '': hasKernsanierung('modernisierungHeizung')
          }" placeholder="{{ 'property-modernization.heating.placeholder' | translate }}" [options]="modernisationsList" [(model)]="objektWert.modernisierungHeizung"
                    label="{{ 'property-modernization.heating.label' | translate }}">
                </db-select>
            </div>
            <div class="ui-control">
                <db-select formControlName="modernisierungInnenausbau" [ngClass]="{
            'not-selected': !objektWert.modernisierungInnenausbau,
            '': hasKernsanierung('modernisierungInnenausbau')
          }" placeholder="{{ 'property-modernization.interior-finishing.placeholder' | translate }}" [options]="modernisationsList"
                    [(model)]="objektWert.modernisierungInnenausbau" label="{{ 'property-modernization.interior-finishing.label' | translate }}">
                </db-select>
            </div>
            <div class="ui-control">
                <db-select formControlName="verbesserungFenster" [ngClass]="{ 'not-selected': !objektWert.verbesserungFenster, '': hasKernsanierung('verbesserungFenster') }"
                    placeholder="{{ 'property-modernization.windows.placeholder' | translate }}" [options]="modernisationsList" [(model)]="objektWert.verbesserungFenster"
                    label="{{ 'property-modernization.windows.label' | translate }}">
                </db-select>
            </div>
            <div class="ui-control">
                <db-select formControlName="verbesserungGrundriss" [ngClass]="{ 'not-selected': !objektWert.verbesserungGrundriss, '': hasKernsanierung('verbesserungGrundriss') }"
                    placeholder="{{ 'property-modernization.layout-improvement.placeholder' | translate }}" [options]="modernisationsList"
                    [(model)]="objektWert.verbesserungGrundriss" label="{{ 'property-modernization.layout-improvement.label' | translate }}">
                </db-select>
            </div>
        </div>
    </div>
    <div [ngClass]="{ 'is-visible': checkIsFormYearInvalid() }" class="db-validation-errors__message  form-validation-error">
        <b>{{ 'property-modernization.empty.year.hint' | translate }}</b>
    </div>
    <div [ngClass]="{ 'is-visible': checkIsFormTypeInvalid() }" class="db-validation-errors__message  form-validation-error">
        <b>{{ 'property-modernization.empty.type.hint' | translate }}</b>
    </div>
</form>