import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopUpButton } from 'src/app/shared/components/popup-window/popup-window.component';
import { VerwendungsZweck } from 'src/app/shared/model/produkt-parameter';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';
import { Mandant, MandantService } from 'src/app/shared/services/mandant.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { environment } from 'src/environments/environment';

import { APPLICATION_SELECTION_PATH } from '../../routing-path';
import { NavButtonBarService, NavButtonType } from '../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-navi-button-bar',
  templateUrl: './navi-button-bar.component.html',
  styleUrls: ['./navi-button-bar.component.scss']
})
export class NaviButtonBarComponent implements OnInit, OnDestroy {
  next = NavButtonType.Next;
  back = NavButtonType.Back;
  save = NavButtonType.Save;

  isProdmain: boolean;
  saveEmail: string;
  hideNextButton: boolean;
  @ViewChild('modalSuccessContent', {
    read: TemplateRef,
    static: true
  })
  private modalSuccessContentRef: TemplateRef<any>;

  private currPathSubscription: Subscription;
  private saveSubscription: Subscription;

  okButton: PopUpButton = {
    title: 'alert.all.button.ok',
    click: () => this.popupService.closeAndResetPopup()
  };

  constructor(
    private navBarService: NavButtonBarService,
    private mandantService: MandantService,
    private appDataService: ApplicationDataService,
    private popupService: PopupService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    this.currPathSubscription = this.navBarService.currentPathObs.subscribe(currentPath => this.currentPathChanged(currentPath));
    this.saveSubscription = this.navBarService.onSave.subscribe(() => this.onSave());
  }

  ngOnInit() {
    this.saveEmail = this.mandantService.getMandant() === Mandant.BHW || this.appDataService.applicationData.isModernisierung ? environment.emailSaveBHW : environment.emailSaveDB;
  }

  ngOnDestroy(): void {
    this.currPathSubscription.unsubscribe();
    this.saveSubscription.unsubscribe();
  }

  isBhw(): boolean {
    return this.mandantService.getMandant() === Mandant.BHW || this.appDataService.getVerwendungszweck() === VerwendungsZweck.Modernization;
  }

  onSave(): void {
    this.popupService.resetPopup();
    this.popupService.title = 'navbar.right.save.popup.title';
    this.popupService.centerButton = this.okButton;
    if (this.email && this.email.trim() !== '') {
      this.loadingIndicatorService.showLoadingText('general.save.loading-indicator.content');
      this.appDataService.save().subscribe(() => {
        this.appDataService.interruptApplication().subscribe(() => {
          this.loadingIndicatorService.hideLoadingText();
          console.log('Open success popup window after saving data and seinding mail');
          this.popupService.content = this.modalSuccessContentRef;
          this.popupService.contentText = undefined;
          // DB Core Bug: without giving time to hide loading indicator, the supposedly hidden loading indicator will be shown together with the popup
          setTimeout(() => {
            this.popupService.openPopup();
          }, 500);
        });
      });
    } else {
      this.popupService.content = undefined;
      this.popupService.contentText = 'email.unavailable.popup';
      this.popupService.openPopup();
    }
  }

  // private getter & setter methods
  private get email(): string {
    return this.appDataService.email;
  }
  private currentPathChanged(currentPath: string) {
    this.hideNextButton = this.isApplicationSelection(currentPath);
  }
  private isApplicationSelection(currentPath: string) {
    return !currentPath || currentPath.indexOf(APPLICATION_SELECTION_PATH) > 0;
  }
}
