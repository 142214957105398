<form [formGroup]="formValidation">
  <div class="ui-control-label ">
    {{ 'bank-account.label' | translate }}
  </div>
  <div class="ui-control">
    <db-input autocomplete="off" type="text" formControlName="iban" [(model)]="iban" label="{{ 'bank-account.iban.placeholder' | translate }}"> </db-input>
    <db-validation-errors for="iban" [messages]="errors.iban"></db-validation-errors>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 ui-control">
      <db-select
        formControlName="kontoinhaber"
        [options]="accountOwnerList"
        [(model)]="kontoinhaber"
        label="{{ 'bank-account.account-holder.label' | translate }}"
        placeholder="{{ 'bank-account.account-holder.placeholder' | translate }}"
      >
      </db-select>
      <db-validation-errors for="kontoinhaber" [messages]="errors.kontoinhaber"></db-validation-errors>
    </div>
    <div class="col-12 col-sm-6 ui-control">
      <db-select
        formControlName="auszahlungsweg"
        [options]="disbursementList"
        [(model)]="auszahlungsweg"
        label="{{ 'bank-account.payout-channel.label' | translate }}"
        placeholder="{{ 'general.select.placeholder' | translate }}"
      >
      </db-select>
      <db-validation-errors for="auszahlungsweg" [messages]="errors.auszahlungsweg"></db-validation-errors>
    </div>
  </div>
</form>
