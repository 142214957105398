<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.last-modernization.year.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="antrag.objektWert.jahrLetzteModMassnahme === null">{{ 'summary.object-data.last-modernization.empty.suffix' | translate }}</span>
                <span *ngIf="antrag.objektWert.jahrLetzteModMassnahme !== null">{{ jahrLetzteModMassnahme }}</span>
            </div>
        </div>
        <div *ngIf="antrag.objektWert.jahrLetzteModMassnahme !== null">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{ 'summary.object-data.last-modernization.core-refurbishment.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <span *ngIf="antrag.objektWert.kernsanierung === 'NULL' || antrag.objektWert.kernsanierung === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                    <span *ngIf="antrag.objektWert.kernsanierung === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                </div>
            </div>
            <div *ngIf="antrag.objektWert.kernsanierung === 'NULL' || antrag.objektWert.kernsanierung === null">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.roof-renovation.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.dacherneuerung === 'NULL' || antrag.objektWert.dacherneuerung === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.dacherneuerung === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.dacherneuerung === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.dacherneuerung === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.exterior-wall-insulation.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.daemmungAussenwaende === 'NULL' || antrag.objektWert.daemmungAussenwaende === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.daemmungAussenwaende === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.daemmungAussenwaende === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.daemmungAussenwaende === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.bathrooms.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.modernisierungBaeder === 'NULL' || antrag.objektWert.modernisierungBaeder === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungBaeder === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungBaeder === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungBaeder === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.heating.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.modernisierungHeizung === 'NULL' || antrag.objektWert.modernisierungHeizung === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungHeizung === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungHeizung === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungHeizung === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.interior-finishing.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.modernisierungInnenausbau === 'NULL' || antrag.objektWert.modernisierungInnenausbau === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungInnenausbau === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungInnenausbau === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.modernisierungInnenausbau === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
                <div class=" row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.windows.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.verbesserungFenster === 'NULL' || antrag.objektWert.verbesserungFenster === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.verbesserungFenster === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.verbesserungFenster === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.verbesserungFenster === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        {{ 'summary.object-data.last-modernization.layout-improvement.prefix' | translate }}
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span *ngIf="antrag.objektWert.verbesserungGrundriss === 'NULL' || antrag.objektWert.verbesserungGrundriss === null">{{
            'summary.object-data.last-modernization.not-performed' | translate
          }}</span>
                        <span *ngIf="antrag.objektWert.verbesserungGrundriss === 'GERING'">{{ 'summary.object-data.last-modernization.low' | translate }}</span>
                        <span *ngIf="antrag.objektWert.verbesserungGrundriss === 'UMFASSEND'">{{ 'summary.object-data.last-modernization.comprehensive' | translate }}</span>
                        <span *ngIf="antrag.objektWert.verbesserungGrundriss === 'UEBERWIEGEND'">{{ 'summary.object-data.last-modernization.predominant' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>