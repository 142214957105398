import { Antrag } from './antrag';
import { BackendFinanzierungsZweck, VerwendungsZweck } from './produkt-parameter';

export class Finanzierungswunsch {
  finanzierungsZweck: BackendFinanzierungsZweck;
  darlehensSumme: number;

  constructor(antrag?: Antrag) {
    if (antrag !== undefined && antrag.produkt !== undefined && antrag.produkt.produktBerechnung !== undefined && antrag.produkt.produktBerechnung.darlehenssumme !== undefined) {
      this.darlehensSumme = antrag.produkt.produktBerechnung.darlehenssumme;
    } else {
      this.darlehensSumme = 15000;
    }
    this.finanzierungsZweck = this.getFinanzierungsZweck(antrag);
  }

  private getFinanzierungsZweck(antrag): BackendFinanzierungsZweck {
    if (antrag !== undefined && antrag.produkt !== undefined && antrag.produkt.produktParameter !== undefined) {
      if (antrag.produkt.produktParameter.verwendungszweck !== undefined) {
        if (
          antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.Purchase || //KAUF_AltBAU
          antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.PurchasePlot || //'KAUF_GRUNDSTUECK'
          antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.KaufAltbau //'KAUF_NEUBAU'
        ) {
          return BackendFinanzierungsZweck.Kauf; //KAUF
        } else if (antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.NewBuild) {
          //NEUBAUVORHABEN
          return BackendFinanzierungsZweck.Neubau;
        } else if (
          antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.Rescheduling || //UMSCHULDUNG
          antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.InternRescheduling //'INTERNE_UMSCHULDUNG'
        ) {
          return BackendFinanzierungsZweck.Umschuldung;
        } else {
          return BackendFinanzierungsZweck.Modernisierung;
        }
      }
    }
    return BackendFinanzierungsZweck.Modernisierung;
  }
}
