<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.street.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ antrag.objektWert.strasse }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.house-number.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ antrag.objektWert.hausnummer }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.zip-code.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ antrag.objektWert.plz }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.place.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ antrag.objektWert.ort }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.federal-state.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ objektBundesland }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.property-area.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">{{ antrag.objektWert.grundstuecksFlaeche }} qm</div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.heritable-building-right.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="antrag.objektWert.erbbauRecht">{{ 'general.yes' | translate }}</span>
                <span *ngIf="!antrag.objektWert.erbbauRecht">{{ 'general.no' | translate }}</span>
            </div>
        </div>
        <div class="row" *ngIf="antrag.objektWert.erbbauRecht">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.heritable-building-right-remaining-term.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">{{ antrag.objektWert.restlaufzeitErbbau }} Jahre</div>
        </div>
        <div class="row" *ngIf="antrag.objektWert.erbbauRecht">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ 'summary.object-data.object-address.heritable-building-right-amount.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ antrag.objektWert.hoeheErbbauZins | currency: 'EUR' }}
            </div>
        </div>
    </div>
</div>