<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'property-land-register.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" formControlName="grundbuchVon" [label]="'property-land-register.origin.label' | translate" [(model)]="grundbuch.grundbuchVon"> </db-input>
      <db-validation-errors for="grundbuchVon" [messages]="errors.grundbuchVon"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="text" [(model)]="grundbuch.band" [label]="'property-land-register.band.label' | translate"> </db-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        autocomplete="off"
        type="text"
        formControlName="blattHeft"
        [label]="'property-land-register.sheet-magazine-number.label' | translate"
        [(model)]="grundbuch.blattHeft"
      >
      </db-input>
      <db-validation-errors for="blattHeft" [messages]="errors.blattHeft"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="text" [label]="'property-land-register.cadastral-plan.label' | translate" [(model)]="grundbuch.flur"> </db-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="text" formControlName="flurstueck" [label]="'property-land-register.cadastral-unit.label' | translate" [(model)]="grundbuch.flurstueck">
      </db-input>
      <db-validation-errors for="flurstueck" [messages]="errors.flurstueck"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control"></div>
  </div>

  <span class="ui-control-label">{{ 'property-land-register.prior-burdens.label' | translate }}</span>
  <db-radio-group formControlName="gpNrSG1" [(model)]="gpNrSG1">
    <db-radio-button *ngFor="let item of gpNrSG1RadioArray" [model]="item.value">
      {{ item.label | translate }}
    </db-radio-button>
  </db-radio-group>
  <db-validation-errors for="gpNrSG1" [messages]="errors.gpNrSG1"> </db-validation-errors>
</form>
