<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'personal-income.salaries-per-year.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 ui-control">
      <div (click)="anzahlRegelmaessigesNettoeinkommenChange()">
        <db-slider [step]="1" [min]="MIN_REGULAT_NET_INCOME_NUMBER" [max]="MAX_REGULAT_NET_INCOME_NUMBER" [unit]="'personal-income.quantity-unit.text.suffix' | translate"
          [(model)]="anzahlRegelmaessigesNettoeinkommen">
        </db-slider>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 ui-control slider-input">
      <db-input [(model)]="anzahlRegelmaessigesNettoeinkommen" (modelChange)="anzahlRegelmaessigesNettoeinkommenChange()" type="number" autocomplete="off"
        label="{{ 'personal-income.quantity-unit.text.suffix' | translate }}">
      </db-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-9">
      <div class="ui-control-label">
        {{ 'personal-income.monthly-net-personal-income.label' | translate }}
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-3 ui-control">
      <db-input [(model)]="regelmaessigesNettoeinkommen" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"
        formControlName="regelmaessigesNettoeinkommen">
      </db-input>
      <db-validation-errors for="regelmaessigesNettoeinkommen" [messages]="errors.regelmaessigesNettoeinkommen"> </db-validation-errors>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6">
      <div class="ui-control-label">{{ 'personal-income.alimony-payments.label' | translate }}</div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-3 ui-control">
      <db-radio-group formControlName="receivingAlimonyOptions">
        <db-radio-button *ngFor="let item of yesNoConfirmationOptionsArray" (change)="receivingAlimonyChange($event.target.value)" [model]="item.value">
          {{ item.label | translate }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="receivingAlimonyOptions" [messages]="errors.receivingAlimonyOptions"> </db-validation-errors>
    </div>
    <div [hidden]="!haushaltsrechnung.einnahmen.showReceivingAlimony" class="col-sm-6 col-md-6 col-lg-3 ui-control">
      <db-input formControlName="erhaltUnterhalt" [(model)]="erhaltUnterhalt" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
      <db-validation-errors for="erhaltUnterhalt" [messages]="errors.erhaltUnterhalt"> </db-validation-errors>
    </div>
  </div>

  <div class="row ui-control">
    <div class="col-sm-12 col-md-12 col-lg-6">
      <div class="ui-control-label">
        {{ 'personal-income.additional-pension.label' | translate }}
      </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-3 ui-control">
      <db-radio-group formControlName="additionalPensionIncomeOptions">
        <db-radio-button *ngFor="let item of yesNoConfirmationOptionsArray" (change)="zusaetzlicheRenteneinkommenChange($event.target.value)" [model]="item.value">
          {{ item.label | translate }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="additionalPensionIncomeOptions" [messages]="errors.additionalPensionIncome"> </db-validation-errors>
    </div>

    <div [hidden]="!haushaltsrechnung.einnahmen.showAdditionalPensionIncome" class="col-sm-6 col-md-6 col-lg-3 ui-control">
      <db-input formControlName="zusaetzlicheRenteneinkommen" [(model)]="zusaetzlicheRenteneinkommen" type="number" autocomplete="off"
        label="{{ 'general.input.number.label' | translate }}">
      </db-input>
      <db-validation-errors for="zusaetzlicheRenteneinkommen" [messages]="errors.zusaetzlicheRenteneinkommen"> </db-validation-errors>
    </div>
  </div>
</form>