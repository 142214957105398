<div class="toggle-container">
    <db-intro-preview>
        <db-toggleable id="sonstSum0" [label]="'summary.personal-data.other-expenditure-and-assets.credits.label' | translate" [open]="toggleAusgabenVermoegenArray[0]"
            iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(0)">
            <app-kredite></app-kredite>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="sonstSum1" [label]="'summary.personal-data.other-expenditure-and-assets.leasing-contracts.label' | translate" [open]="toggleAusgabenVermoegenArray[1]"
            iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(1)">
            <app-leasingvertraege></app-leasingvertraege>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="sonstSum2" [label]="'summary.personal-data.other-expenditure-and-assets.building.label' | translate" [open]="toggleAusgabenVermoegenArray[2]"
            iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(2)">
            <app-baufinanzierung></app-baufinanzierung>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="sonstSum3" [label]="'summary.personal-data.other-expenditure-and-assets.other-obligations.label' | translate" [open]="toggleAusgabenVermoegenArray[3]"
            iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(3)">
            <app-sonstige-verpflichtungen></app-sonstige-verpflichtungen>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="sonstSum4" [label]="'summary.personal-data.other-expenditure-and-assets.own-real-estate.label' | translate" [open]="toggleAusgabenVermoegenArray[4]"
            iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(4)">
            <app-eigene-immobilien></app-eigene-immobilien>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="sonstSum5" [label]="'summary.personal-data.other-expenditure-and-assets.leased-out-real-estate.label' | translate"
            [open]="toggleAusgabenVermoegenArray[5]" iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(5)">
            <app-vermietete-immobilien></app-vermietete-immobilien>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="sonstSum6" [label]="'summary.personal-data.other-expenditure-and-assets.undeveloped-plots.label' | translate" [open]="toggleAusgabenVermoegenArray[6]"
            iconPosition="right" (openChange)="toggleAusgabenVermoegenClick(6)">
            <app-unbebaute-grundstuecke></app-unbebaute-grundstuecke>
        </db-toggleable>
    </db-intro-preview>
</div>