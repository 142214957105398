<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'personal-residential-second.adress.label' | translate: correspondenceSecond }}
  </div>
  <div *ngIf="!applicationData.isZweitePersonAdressAusgewaehlt && !personendatenSecond.strasse">
    <div class="ui-control-label">
      {{ 'personal-residential.second-person.adopt-first-address.label' | translate }}
    </div>
    <div class="ui-control">
      <db-radio-group formControlName="isUebernehmeAdress">
        <db-radio-button *ngFor="let item of isUebernehmeAdressRadioArray" (change)="isUebernehmeAdressChange($event)" [model]="item.value">
          {{ item.name | translate }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="isUebernehmeAdress" [messages]="errors.isUebernehmeAdress"> </db-validation-errors>
    </div>
  </div>

  <div [hidden]="!applicationData.isZweitePersonAdressAusgewaehlt && !personendatenSecond.strasse">
    <div class="row">
      <div class="col-sm-12 col-md-6 ui-control">
        <db-input [(model)]="personendatenSecond.strasse" formControlName="strasse" type="text" [label]="'personal-residential.adress.street.placeholder' | translate"
          autocomplete="off" label="" (modelChange)="addressManuallyModified()">
        </db-input>
        <db-validation-errors for="strasse" [messages]="errors.strasse"> </db-validation-errors>
      </div>
      <div class="col-sm-12 col-md-6 ui-control">
        <db-input [(model)]="personendatenSecond.hausnummer" formControlName="hausnummer" [label]="'personal-residential.adress.house-number.placeholder' | translate"
          autocomplete="off" label="" (modelChange)="addressManuallyModified()">
        </db-input>
        <db-validation-errors for="hausnummer" [messages]="errors.hausnummer"> </db-validation-errors>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 ui-control">
        <db-input [(model)]="personendatenSecond.plz" formControlName="plz" type="text" [label]="'personal-residential.adress.postcode.placeholder' | translate" autocomplete="off"
          label="" (modelChange)="addressManuallyModified()">
        </db-input>
        <db-validation-errors for="plz" [messages]="errors.plz"> </db-validation-errors>
      </div>
      <div class="col-sm-12 col-md-6 ui-control">
        <db-input [(model)]="personendatenSecond.ort" formControlName="ort" type="text" [label]="'personal-residential.adress.place.placeholder' | translate" autocomplete="off"
          label="" (modelChange)="addressManuallyModified()">
        </db-input>
        <db-validation-errors for="ort" [messages]="errors.ort"> </db-validation-errors>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 ui-control">
        <db-select [ngClass]="{
            'not-selected': !personendatenSecond.bundesland || personendatenSecond.bundesland === ''
          }" formControlName="bundesLand" placeholder="{{ 'general.select.placeholder' | translate }}" label="{{ 'general.federal-state.label' | translate }}"
          [options]="bundeslandList" [(model)]="personendatenSecond.bundesland" (modelChange)="addressManuallyModified()">
        </db-select>
        <db-validation-errors for="bundesLand" [messages]="errors.bundesLand"> </db-validation-errors>
      </div>
      <div class="col-sm-12 col-md-6"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input [(model)]="personendatenSecond.telefonnummerVorwahl" type="text" formControlName="telefonnummerVorwahl"
        [label]="'personal-residential.adress.phone-area-code.placeholder' | translate" autocomplete="off" label="">
      </db-input>
      <db-validation-errors for="telefonnummerVorwahl" [messages]="errors.telefonnummerVorwahl"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input [(model)]="personendatenSecond.telefonnummerDurchwahl" formControlName="telefonnummerDurchwahl" type="text"
        [label]="'personal-residential.adress.phone-number.placeholder' | translate" autocomplete="off" label="">
      </db-input>
      <db-validation-errors for="telefonnummerDurchwahl" [messages]="errors.telefonnummerDurchwahl"> </db-validation-errors>
    </div>
  </div>
  <div class="ui-control-label">
    {{ 'personal-residential.housing-situation.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select [ngClass]="{
          'not-selected': !personendatenSecond.wohnverhaeltnis
        }" formControlName="wohnverhaeltnis" placeholder="{{ 'general.select.placeholder' | translate }}"
        label="{{ 'personal-residential.housing-situation.field.label' | translate }}" [options]="wohnverhaeltnisList" [(model)]="personendatenSecond.wohnverhaeltnis">
      </db-select>
      <db-validation-errors for="wohnverhaeltnis" [messages]="errors.wohnverhaeltnis"> </db-validation-errors>
    </div>
  </div>
</form>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': isAddressAutoModified }">
  {{ 'general.warning-address-adjusted.label' | translate }}
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': isAddressInvalid }">
  {{ 'general.warning-address-not-found.label' | translate }}
</div>