export class Immobilie {
  wert: number;
  plz: string;
  ort: string;
  strasse: string;
  hausnummer: string;

  constructor(immobilie?: Immobilie) {
    this.wert = immobilie ? immobilie.wert : null;
    this.plz = immobilie ? immobilie.plz : null;
    this.ort = immobilie ? immobilie.ort : null;
    this.strasse = immobilie ? immobilie.strasse : null;
    this.hausnummer = immobilie ? immobilie.hausnummer : null;
  }
}
