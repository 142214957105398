import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { TranslateListService } from '../../core/translate-list.service';
import { Zahlungsinformation } from '../../shared/model/zahlungsinformation';
import { BaseComponent } from './../../core/base.component';
import { OAPValidators } from './../../core/validators.directive';
import { ApplicationDataService } from './../../shared/services/application-data.service';
import { NavButtonBarService } from './../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-personal-additional-outgoings',
  templateUrl: './personal-additional-outgoings.component.html',
  styleUrls: ['./personal-additional-outgoings.component.scss'],
  providers: [FormBuilder]
})
export class PersonalAdditionalOutgoingsComponent extends BaseComponent implements OnInit, AfterContentChecked {
  private formControlsPayment: string[] = ['zahlungsempfaenger', 'zahlungssumme', 'monatlicheKosten', 'tilgungsEnde'];
  private formControlsLiablity: string[] = ['zahlungssumme', 'monatlicheKosten', 'zahlungsType'];
  private formControlsFinance: string[] = ['zahlungsempfaenger', 'zahlungssumme', 'monatlicheKosten', 'aktuelleRestschuld', 'zinsbindungsEnde', 'tilgungsEnde'];
  private formControlNames = { credit: this.formControlsPayment, lease: this.formControlsPayment, liablity: this.formControlsLiablity, finance: this.formControlsFinance };

  errors: any = {
    zahlungsempfaengerCredit: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.installment-credit.lender.hint'
      }
    ],
    zahlungssummeCredit: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.installment-credit.loan-amount.hint'
      }
    ],
    monatlicheKostenCredit: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.installment-credit.monthly-costs.hint'
      }
    ],
    tilgungsEnde: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Tilgungsende'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Tilgungsende',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ],
    zahlungsempfaengerLease: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.leasing-contracts.lessor.hint'
      }
    ],
    zahlungssummeLease: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.leasing-contracts.lease-sum.hint'
      }
    ],
    monatlicheKostenLease: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.leasing-contracts.monthly-costs.hint'
      }
    ],
    zahlungsempfaengerFinance: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.building-loans.name-of-bank.hint'
      }
    ],
    zahlungssummeFinance: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.building-loans.financing-amount.hint'
      }
    ],
    monatlicheKostenFinance: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.building-loans.monthly-rate.hint'
      }
    ],
    aktuelleRestschuldFinance: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.building-loans.current-residual-debt.hint'
      }
    ],
    zinsbindungsEndeFinance: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.building-loans.end-of-fixed-interest-period.hint'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Zinsbindungsende',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ],
    zahlungssummeLiablity: [
      {
        rule: 'required',
        message: 'Bitte Summe angeben'
        // TODO elenastoll clarify message: 'personal-additional-outgoings.other-obligations.type.hint'
      }
    ],
    monatlicheKostenLiablity: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.other-obligations.costs.hint'
      }
    ],
    zahlungsTypeLiablity: [
      {
        rule: 'required',
        message: 'personal-additional-outgoings.other-obligations.type.hint'
      }
    ]
  };
  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
    this.toggleOpenArray = [false, false, false, false];
  }

  ngOnInit() {
    super.ngOnInit();
    this.headerService.changeText('personal-additional-outgoings.headline');
    this.formSettings();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_additional_outgoings", "personal data");
  }
  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  // public template methods
  public getPaymentInfos(prefix: string) {
    return this.formValidation.controls[prefix] as FormArray;
  }
  public addPaymentInfo(prefix: string) {
    if (!this.formValidation.invalid) {
      this[prefix].push(new Zahlungsinformation());
      this.addPaymentInfoFormControlsFor(prefix, this[prefix].length - 1);
    } else {
      DBValidatorsUtil.markFormAsDirtyAndTouched(this.formValidation);
    }
  }
  public removePaymentInfo(prefix: string, index: number) {
    this[prefix].splice(index, 1);
    this.removePaymentInfoFormControlsFor(prefix, index);
  }

  // private form setting methods
  private formSettings() {
    this.formValidation = this.formBuilder.group({
      credit: new FormArray([]),
      lease: new FormArray([]),
      finance: new FormArray([]),
      liablity: new FormArray([])
    });
    this.buildAllFormGroups();
  }

  private buildAllFormGroups() {
    this.initFormGroupFor('liablity');
    this.initFormGroupFor('credit');
    this.initFormGroupFor('finance');
    this.initFormGroupFor('lease');
  }

  private getConditionalValidator(prefix: string): ValidatorFn {
    return OAPValidators.conditionRequiredValidator(() => this[prefix].length > 0);
  }
  private initFormGroupFor(prefix: string) {
    this[prefix].forEach(paymentInfo => this.addPaymentInfoFormControlsFor(prefix, this[prefix].indexOf(paymentInfo)));
  }

  private removePaymentInfoFormControlsFor(prefix: string, index: number) {
    (this.formValidation.controls[prefix] as FormArray).removeAt(index);
  }

  private addPaymentInfoFormControlsFor(prefix: string, index: number) {
    const formControlGroup: {
      [key: string]: any;
    } = {};
    this.formControlNames[prefix].forEach(formControlName => {
      let value: any = this[prefix][index][formControlName];
      let validators: ValidatorFn | ValidatorFn[] = this.getConditionalValidator(prefix);
      if (value && value.constructor.name === 'Date') {
        value = this.dateService.getDateUIFromModel(value);
      }
      if (formControlName === 'zinsbindungsEnde' || formControlName === 'tilgungsEnde') {
        validators = DBValidatorsUtil.chain([validators, OAPValidators.dateFormat()]);
      }
      formControlGroup[formControlName] = new FormControl(value, validators);
    });
    const formGroup = this.formBuilder.group(formControlGroup);
    (this.formValidation.controls[prefix] as FormArray).push(formGroup);
  }

  // getter & setter
  get liablity(): Zahlungsinformation[] {
    return this.haushaltsrechnung.ausgaben.sonstMonatlicheKosten;
  }
  get credit(): Zahlungsinformation[] {
    return this.haushaltsrechnung.ausgaben.ratenkredit;
  }
  get finance(): Zahlungsinformation[] {
    return this.haushaltsrechnung.ausgaben.baufinanzierungAndereBank;
  }
  get lease(): Zahlungsinformation[] {
    return this.haushaltsrechnung.ausgaben.leasing;
  }
  public dateChange(prefix: string, $event: any, index: number, fieldlName: string) {
    this[prefix][index][fieldlName] = this.dateService.getDateModelFromDateUI($event);
  }
}
