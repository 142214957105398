export enum AddressResultStatus {
  Green = 'GRUEN',
  Yellow = 'GELB',
  Red = 'ROT',
  Blue = 'BLAU'
}

export class AddressValidierungObjekt {
  street: string;
  hno: string;
  zip: string;
  city: string;
  district: string;
  country: string;
  points: string;
  resultcode: string;
  resulttext: string;
  trafficlight: AddressResultStatus;

  constructor() {
    this.street = '';
    this.hno = '';
    this.zip = '';
    this.city = '';
    this.district = '';
    this.country = '';
    this.points = '';
    this.resultcode = '';
    this.resulttext = '';
    this.trafficlight = AddressResultStatus.Green;
  }
}
