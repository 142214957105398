import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { ValuesService } from '../../shared/services/values.service';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent extends BaseComponent implements OnInit {
  conditionList: any[] = [];
  outfit: any[] = [];
  construction: any[] = [];
  attics: any[] = [];
  kellerOption: any[] = [];
  locationOption: any[] = [];

  baujahr: number;

  public errors: any = {
    baujahr: [
      {
        rule: 'required',
        message: 'property-details.year-of-construction.empty.hint'
      },
      {
        rule: 'max',
        message: 'property-details.year-of-construction.invalid.hint'
      },
      {
        rule: 'min',
        message: 'property-details.year-of-construction.empty.hint'
      }
    ],
    wohnFlaecheEigennutz: [
      {
        rule: 'required',
        message: 'property-details.owner-occupied-living-space.empty.hint'
      }
    ],
    ausstattung: [
      {
        rule: 'required',
        message: 'property-details.endowment.empty.hint'
      }
    ],
    verwertbarkeit: [
      {
        rule: 'required',
        message: 'property-details.utilizability.empty.hint'
      }
    ],
    wohnFlaecheFremdnutz: [
      {
        rule: 'required',
        message: 'property-details.third-party-occupied-living-space.empty.hint'
      }
    ],
    objektZustand: [
      {
        rule: 'required',
        message: 'property-details.state.empty.hint'
      }
    ],
    bauweise: [
      {
        rule: 'required',
        message: 'property-details.construction-style.hint'
      }
    ],
    dachgeschoss: [
      {
        rule: 'required',
        message: 'property-details.attic.empty.hint'
      }
    ],
    keller: [
      {
        rule: 'required',
        message: 'property-details.basement.empty.hint'
      }
    ],
    lageStandort: [
      {
        rule: 'required',
        message: 'property-details.location.hint'
      }
    ]
  };
  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valueService: ValuesService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.baujahr = this.getDateYearAsNumber(this.objektWert.baujahr);
    this.formSettings();
    this.headerService.changeText('property-details.headline');
    this.getOptions();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Property_details", "property data");
  }

  getOptions() {
    this.valueService.loadCondition().subscribe(conditionList => {
      this.conditionList = conditionList;
    });
    this.valueService.loadOutfit().subscribe(option => {
      this.outfit = option;
    });
    this.valueService.loadLocations().subscribe(option => {
      this.locationOption = option;
    });
    this.valueService.loadConstruction().subscribe(construction => {
      this.construction = construction;
    });
    this.valueService.loadAttics().subscribe(attics => {
      this.attics = attics;
    });
    this.valueService.loadKeller().subscribe(keller => {
      this.kellerOption = keller;
    });
  }

  formSettings() {
    this.formValidation = this.formBuilder.group({
      baujahr: new FormControl(this.baujahr, [DBValidators.required(), Validators.min(1000), Validators.max(new Date().getFullYear() + 1)]),
      wohnFlaecheEigennutz: new FormControl(this.wohnFlaecheEigennutz, DBValidators.required()),
      ausstattung: new FormControl(this.ausstattung, DBValidators.required()),
      verwertbarkeit: new FormControl(this.verwertbarkeit, DBValidators.required()),
      wohnFlaecheFremdnutz: new FormControl(this.wohnFlaecheFremdnutz, DBValidators.required()),
      objektZustand: new FormControl(this.objektZustand, DBValidators.required()),
      bauweise: new FormControl(this.bauweise, DBValidators.required()),
      dachgeschoss: new FormControl(this.dachgeschoss, DBValidators.required()),
      keller: new FormControl(this.keller, DBValidators.required()),
      lageStandort: new FormControl(this.lageStandort, DBValidators.required())
    });
  }

  protected validationFormIntern(): boolean {
    if (this.formValidation.invalid) {
      DBValidatorsUtil.markFormAsDirtyAndTouched(this.formValidation);
      return false;
    }
    this.objektWert.baujahr = this.getDateFromYear(this.baujahr);
    console.log('set baujahr=%o', this.objektWert.baujahr);
    return true;
  }

  get wohnFlaecheEigennutz() {
    return this.objektWert.wohnFlaecheEigennutz;
  }
  set wohnFlaecheEigennutz(wohnFlaecheEigennutz: number) {
    this.objektWert.wohnFlaecheEigennutz = this.convertFromDBCore(wohnFlaecheEigennutz, 'number');
  }
  get ausstattung() {
    return this.objektWert.ausstattung;
  }
  set ausstattung(ausstattung: string) {
    this.objektWert.ausstattung = ausstattung;
  }
  get verwertbarkeit() {
    return this.objektWert.verwertbarkeit;
  }
  set verwertbarkeit(verwertbarkeit: string) {
    this.objektWert.verwertbarkeit = verwertbarkeit;
  }
  get wohnFlaecheFremdnutz() {
    return this.objektWert.wohnFlaecheFremdnutz;
  }
  set wohnFlaecheFremdnutz(wohnFlaecheFremdnutz: number) {
    this.objektWert.wohnFlaecheFremdnutz = this.convertFromDBCore(wohnFlaecheFremdnutz, 'number');
  }
  get objektZustand() {
    return this.objektWert.objektZustand;
  }
  set objektZustand(objektZustand: string) {
    this.objektWert.objektZustand = objektZustand;
  }
  get lageStandort() {
    return this.objektWert.lageStandort;
  }
  set lageStandort(lageStandort: string) {
    this.objektWert.lageStandort = lageStandort;
  }
  get bauweise() {
    return this.objektWert.bauweise;
  }
  set bauweise(bauweise: string) {
    this.objektWert.bauweise = bauweise;
  }
  get dachgeschoss() {
    return this.objektWert.dachgeschoss;
  }
  set dachgeschoss(dachgeschoss: string) {
    this.objektWert.dachgeschoss = dachgeschoss;
  }
  get keller() {
    return this.objektWert.keller;
  }
  set keller(keller: string) {
    this.objektWert.keller = keller;
  }
  get anzahlCarport() {
    return this.objektWert.anzahlCarport;
  }
  set anzahlCarport(anzahlCarport: number) {
    this.objektWert.anzahlCarport = this.convertFromDBCore(anzahlCarport, 'number');
  }
  get anzahlKellerGaragen() {
    return this.objektWert.anzahlKellerGaragen;
  }
  set anzahlKellerGaragen(anzahlKellerGaragen: number) {
    this.objektWert.anzahlKellerGaragen = this.convertFromDBCore(anzahlKellerGaragen, 'number');
  }
  get anzahlDoppelgaragen() {
    return this.objektWert.anzahlDoppelgaragen;
  }
  set anzahlDoppelgaragen(anzahlDoppelgaragen: number) {
    this.objektWert.anzahlDoppelgaragen = this.convertFromDBCore(anzahlDoppelgaragen, 'number');
  }
  get anzahlStellplatz() {
    return this.objektWert.anzahlStellplatz;
  }
  set anzahlStellplatz(anzahlStellplatz: number) {
    this.objektWert.anzahlStellplatz = this.convertFromDBCore(anzahlStellplatz, 'number');
  }
  get anzahlGaragen() {
    return this.objektWert.anzahlGaragen;
  }
  set anzahlGaragen(anzahlGaragen: number) {
    this.objektWert.anzahlGaragen = this.convertFromDBCore(anzahlGaragen, 'number');
  }
  get anzahlTGStellplatz() {
    return this.objektWert.anzahlTGStellplatz;
  }
  set anzahlTGStellplatz(anzahlTGStellplatz: number) {
    this.objektWert.anzahlTGStellplatz = this.convertFromDBCore(anzahlTGStellplatz, 'number');
  }
}
