import { Antrag } from './antrag';
import { VerwendungsZweck } from './produkt-parameter';

export enum ProdMainTyp {
  Modernization = 'MODERNISIERUNG',
  PurchaseNewBuild = 'KAUF_NEUBAU',
  Rescheduling = 'UMSCHULDUNG'
}

export enum YesNoConfirmation {
  Yes = 'true',
  No = 'false'
}

export class ApplicationData {
  antrag: Antrag;
  isZuteilungValid = true;
  isTilgungsplanValid = true;
  isProduktValid = true;
  isShorterThreeMonths = true;
  erhaltUnterhalt: boolean;
  zusaetzlicheRenteneinkommen: boolean;
  zusatzausgabenSecondary: boolean;
  prodmainTyp: ProdMainTyp;
  isZweitePersonAdressAusgewaehlt: boolean;
  isPropertyVisited: boolean;

  get isModernisierung(): boolean {
    return this.antrag.produkt.produktParameter.verwendungszweck === VerwendungsZweck.Modernization;
  }
  get isSaved(): boolean {
    return this.antrag.id && this.antrag.id !== '';
  }
}
