import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ProduktBerechnung } from 'src/app/shared/model/product-berechnung';
import { Produkt } from 'src/app/shared/model/produkt';
import { ProduktParameter, ProdukTyp, VerwendungsZweck } from 'src/app/shared/model/produkt-parameter';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { Mandant, MandantService } from '../../shared/services/mandant.service';
import { ApplicationDataService } from './../../shared/services/application-data.service';
import { LoanCheckSummaryService } from './loan-check-summary.service';

@Component({
  selector: 'app-loan-check-summary',
  templateUrl: './loan-check-summary.component.html',
  styleUrls: ['./loan-check-summary.component.scss']
})
export class LoanCheckSummaryComponent extends BaseComponent implements OnInit {
  isZuteilungVorEndeZinsbindung: boolean;
  isTilgungsplanValid: boolean;
  monatlicheRate: number;
  monatlicheRateNachZuteilung: number;
  zinsbindung: string;
  anzahlRaten: number;
  auszahlungskurs: number;
  gesamtLaufzeit: string;
  bereitstellungszins: number;
  bereitstellungszinsfreieZeit: string;
  tilgungAbDatum: string;
  isBhw: boolean;
  isDbModernization: boolean;
  isFH: boolean;
  isTH: boolean;
  isExpress: boolean;
  isRescheduling: boolean;
  produkttyplabel: string;
  isBsvSelected: boolean;

  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private mandantService: MandantService,
    private loanCheckSummaryService: LoanCheckSummaryService,
    dateService: DateService,
    private loadingIndicatorService: LoadingIndicatorService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isBhw = this.isMandantBhw(this.mandantService);
    this.isDbModernization = this.mandantService.getMandant() === Mandant.DB && this.verwendungsZweck === VerwendungsZweck.Modernization;
    this.isFH = this.produktTyp === ProdukTyp.FH || this.produktTyp === ProdukTyp.ForwardFH;
    this.isTH = this.produktTyp === ProdukTyp.TH || this.produktTyp === ProdukTyp.ForwardTH;
    this.isExpress = this.produktTyp === ProdukTyp.ConstantExpress;
    this.isBsvSelected = this.produktParameter.isBsvSelected;
    const verwendungszweckRescheduling = [VerwendungsZweck.Rescheduling, VerwendungsZweck.InternRescheduling];
    this.isRescheduling = verwendungszweckRescheduling.indexOf(this.verwendungsZweck) >= 0;
    this.applicationData.isShorterThreeMonths = this.calculateShorterThreeMonths();
    this.produkttyplabel = this.getProduktLabel(this.isBhw);
    this.getHeaderText();
    this.initSummaryData();
    this.adobe.pushAdobeEvent("Loan_check_summary", "loan data");
  }

  private calculateShorterThreeMonths(): boolean {
    if (!this.produktParameter.umschuldungsdatum) {
      return true;
    }
    const now = moment(new Date());
    const umschuldungsdatum = moment(this.produktParameter.umschuldungsdatum);
    return umschuldungsdatum.diff(now.startOf('day'), 'months') < 3;
  }

  private getHeaderText() {
    if (this.produkt.produktParameter.produkttyp === ProdukTyp.ConstantExpress) {
      this.headerService.changeText('loan-check-summary.headline.modernization');
    } else {
      this.headerService.changeText('loan-check-summary.headline.purchase-build');
    }
  }

  initSummaryData() {
    this.loadingIndicatorService.showLoadingText('loan-check-summary.loading-indicator.content');
    this.loanCheckSummaryService.getSummaryData(this.applicationData).subscribe(
      data => {
        this.loadingIndicatorService.hideLoadingText();
        console.log('summary data: ', data);
        if (data !== null) {
          this.fillSummaryData(data);
        }
      },
      error => {
        console.log('Summary service HTTP error: ', error);
      }
    );
  }

  fillSummaryData(data: Produkt) {
    this.produkt.produktParameter = new ProduktParameter(data.produktParameter);
    this.produkt.produktParameter.isBsvSelected = this.isBsvSelected;
    this.produkt.produktBerechnung = new ProduktBerechnung(data.produktBerechnung);

    this.tilgungAbDatum = this.dateService.getQuarter(this.produkt.produktBerechnung.tilgungAbDatum);

    if (data.bsvDaten) {
      this.produkt.bsvDaten.bsvTarif = data.bsvDaten.bsvTarif;
      this.produkt.bsvDaten.sparplanSollzins = data.bsvDaten.sparplanSollzins;
      this.produkt.bsvDaten.sparplanEffektivzins = data.bsvDaten.sparplanEffektivzins;
      this.produkt.bsvDaten.guthabenBeiZuteilung = data.bsvDaten.guthabenBeiZuteilung;
      this.produkt.bsvDaten.sparAbschlussGebuehr = data.bsvDaten.sparAbschlussGebuehr;
      this.produkt.bsvDaten.bausparSumme = this.loanCheckSummaryService.calculateBausparsumme(data);
    }

    this.monatlicheRate = this.appDataService.calculateMonatlicheRate(data);
    this.zinsbindung = this.loanCheckSummaryService.calculateZinsbindung(data);
    this.anzahlRaten = this.loanCheckSummaryService.calculateAnzahlRaten(data);
    this.monatlicheRateNachZuteilung = this.appDataService.calculateFinanzierungsrate(data);
    this.gesamtLaufzeit = this.loanCheckSummaryService.calculateGesamtLaufzeit(data);

    this.auszahlungskurs = 100;
    this.bereitstellungszins = 3;
    this.bereitstellungszinsfreieZeit = '2 Monate';
  }

  // overriden
  protected validationFormIntern(): boolean {
    return this.applicationData.isZuteilungValid && this.applicationData.isTilgungsplanValid && this.applicationData.isProduktValid;
  }
}
