import { Injectable } from '@angular/core';

import { EnumOptionsService, SelectBoxOption } from '../core/enum-options.service';
import { TranslateListService } from '../core/translate-list.service';
import { YesNoConfirmation } from '../shared/model/application-data';
import { Anrede, DauerdesVertragsverhaeltnissesTyp, Familienstand, Gueterstand, Titel, Wohnen } from '../shared/model/personendaten';
import { Auszahlungsweg } from '../shared/model/kontodaten';
import { Nutzungsart, VerwendungsZweck, VollGeschosse } from '../shared/model/produkt-parameter';

@Injectable({
  providedIn: 'root'
})
export class PersonalDataEnumOptionsService extends EnumOptionsService {
  constructor(translate: TranslateListService) {
    super(translate);
  }

  protected getOptionForEnumKey(typeName, enumKey: string): SelectBoxOption {
    switch (typeName[enumKey]) {
      // Labels for DauerdesVertragsverhaeltnissesTyp
      case DauerdesVertragsverhaeltnissesTyp.Befristet: {
        return { value: typeName[enumKey], label: 'personal-work.work-situation.contractual-relationship.limited' };
      }
      case DauerdesVertragsverhaeltnissesTyp.Unbefristet: {
        return { value: typeName[enumKey], label: 'personal-work.work-situation.contractual-relationship.indefinite' };
      }
      case DauerdesVertragsverhaeltnissesTyp.Unkuendbar: {
        return { value: typeName[enumKey], label: 'personal-work.work-situation.contractual-relationship.irredeemable' };
      }
      case DauerdesVertragsverhaeltnissesTyp.Probezeit: {
        return { value: typeName[enumKey], label: 'personal-work.work-situation.contractual-relationship.probation' };
      }

      // Labels for YesNoConfirmation
      case YesNoConfirmation.Yes: {
        return { value: 'true', label: 'general.yes' };
      }
      case YesNoConfirmation.No: {
        return { value: 'false', label: 'general.no' };
      }

      // Labels for Familienstand
      case Familienstand.Ledig: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.single' };
      }
      case Familienstand.Verheiratet: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.married' };
      }
      case Familienstand.GetrenntLebend: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.separated-living' };
      }
      case Familienstand.Geschieden: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.divorced' };
      }
      case Familienstand.Verwittwet: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.widowed' };
      }
      case Familienstand.EheaehnlicheLebensgemeinschaft: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.marriage-like-relationship' };
      }
      case Familienstand.EingetrageneLebensgemeinschaft: {
        return { value: typeName[enumKey], label: 'personal-family.family-circumstances.registered-partnership' };
      }

      // Labels for Gueterstand
      case Gueterstand.Zugewinngemeinschaft: {
        return { value: typeName[enumKey], label: 'personal-family.matrimonial-estate.profit-association' };
      }
      case Gueterstand.Guetergemeinschaft: {
        return { value: typeName[enumKey], label: 'personal-family.matrimonial-estate.community-of-goods' };
      }
      case Gueterstand.Guetertrennung: {
        return { value: typeName[enumKey], label: 'personal-family.matrimonial-estate.separation-of-goods' };
      }
      case Gueterstand.Unbekannt: {
        return { value: typeName[enumKey], label: 'personal-family.matrimonial-estate.unknown' };
      }

      // Labels for Anrede
      case Anrede.Herr: {
        return { value: typeName[enumKey], label: 'personal-name-birth-tax.salutation-selection.checkbox.male.label' };
      }
      case Anrede.Frau: {
        return { value: typeName[enumKey], label: 'personal-name-birth-tax.salutation-selection.checkbox.female.label' };
      }

      // Labels for Titel
      case Titel.Prof: {
        return { value: typeName[enumKey], label: 'personal-name-birth-tax.title-selection.select.prof' };
      }
      case Titel.Dr: {
        return { value: typeName[enumKey], label: 'personal-name-birth-tax.title-selection.select.dr' };
      }

      // Labels for Wohnen
      case Wohnen.Miete: {
        return { value: typeName[enumKey], label: 'personal-residential.housing-situation.for-rent' };
      }
      case Wohnen.Eigentum: {
        return { value: typeName[enumKey], label: 'personal-residential.housing-situation.ownership' };
      }
      case Wohnen.Eltern: {
        return { value: typeName[enumKey], label: 'personal-residential.housing-situation.at-the-parents' };
      }

      // Labels for Auszahlungsweg
      case Auszahlungsweg.Rateneinzugskonto: {
        return { value: typeName[enumKey], label: 'bank-account.payout-channel.option.installment-collection-account' };
      }
      case Auszahlungsweg.SeparateBeantragung: {
        return { value: typeName[enumKey], label: 'bank-account.payout-channel.option.separate-application' };
      }
      // Label for verwendungsZweck for property for general

      case VerwendungsZweck.NewBuild: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.new-build-project' };
      }
      case VerwendungsZweck.Purchase: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.purchase-new-building' };
      }
      case VerwendungsZweck.PurchasePlot: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.purchase-land' };
      }
      case VerwendungsZweck.Expansion: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.extension-conversion' };
      }
      case VerwendungsZweck.KaufAltbau: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.purchase-old-building' };
      }
      case VerwendungsZweck.InternRescheduling: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.internal-debt-rescheduling' };
      }
      case VerwendungsZweck.Rescheduling: {
        return { value: typeName[enumKey], label: 'property.purpose-of-use.debt-rescheduling' };
      }

      // label VollGeschosse
      case VollGeschosse.Erdgeschoss: {
        return { value: typeName[enumKey], label: 'property.full-floor.ground-floor' };
      }
      case VollGeschosse.Erdgeschoss_und_Obergeschoss: {
        return { value: typeName[enumKey], label: 'property.full-floor.ground-floor-and-upper-floor' };
      }

      // Labels for Nutzungsart
      case Nutzungsart.Eigennutzung: {
        return { value: typeName[enumKey], label: 'property.usage-type.personal-use' };
      }
      case Nutzungsart.Fremdnutzung: {
        return { value: typeName[enumKey], label: 'property.usage-type.third-party-usage' };
      }
      case Nutzungsart.Eigen_und_Fremdnutzung: {
        return { value: typeName[enumKey], label: 'property.usage-type.personal-and-third-party-usage' };
      }
    }
  }

  protected getDynamicOptionForEnumKey(typeName, enumKey: string, forInclude: (value) => boolean): any {
    switch (typeName[enumKey]) {
      case VerwendungsZweck.KaufAltbau: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.purchase-old-building' } : null;
      }
      case VerwendungsZweck.NewBuild: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.new-build-project' } : null;
      }
      case VerwendungsZweck.Purchase: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.purchase-new-building' } : null;
      }
      case VerwendungsZweck.PurchasePlot: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.purchase-land' } : null;
      }
      case VerwendungsZweck.Expansion: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.extension-conversion' } : null;
      }
      case VerwendungsZweck.InternRescheduling: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.internal-debt-rescheduling' } : null;
      }
      case VerwendungsZweck.Rescheduling: {
        return forInclude(typeName[enumKey]) ? { value: typeName[enumKey], label: 'property.purpose-of-use.debt-rescheduling' } : null;
      }
    }
  }
}
