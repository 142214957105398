import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApplicationSelectionComponent } from './application-selection/application-selection.component';
import { ConclusionComponent } from './conclusion/conclusion.component';
import { RouteGuard } from './guard/route-guard';
import { BankAccountComponent } from './personal-data/bank-account/bank-account.component';
import {
  PersonalAdditionalIncomeSecondComponent,
} from './personal-data/personal-additional-income-second/personal-additional-income-second.component';
import {
  PersonalAdditionalIncomeComponent,
} from './personal-data/personal-additional-income/personal-additional-income.component';
import {
  PersonalAdditionalOutgoingsSecondComponent,
} from './personal-data/personal-additional-outgoings-second/personal-additional-outgoings-second.component';
import {
  PersonalAdditionalOutgoingsComponent,
} from './personal-data/personal-additional-outgoings/personal-additional-outgoings.component';
import {
  PersonalEmailNationalitySecondComponent,
} from './personal-data/personal-email-nationality-second/personal-email-nationality-second.component';
import {
  PersonalEmailNationalityComponent,
} from './personal-data/personal-email-nationality/personal-email-nationality.component';
import { PersonalFamilySecondComponent } from './personal-data/personal-family-second/personal-family-second.component';
import { PersonalFamilyComponent } from './personal-data/personal-family/personal-family.component';
import { PersonalFortuneSecondComponent } from './personal-data/personal-fortune-second/personal-fortune-second.component';
import { PersonalFortuneComponent } from './personal-data/personal-fortune/personal-fortune.component';
import { PersonalIncomeSecondComponent } from './personal-data/personal-income-second/personal-income-second.component';
import { PersonalIncomeComponent } from './personal-data/personal-income/personal-income.component';
import {
  PersonalNameBirthTaxSecondComponent,
} from './personal-data/personal-name-birth-tax-second/personal-name-birth-tax-second.component';
import { PersonalNameBirthTaxComponent } from './personal-data/personal-name-birth-tax/personal-name-birth-tax.component';
import {
  PersonalOutgoingsSecondComponent,
} from './personal-data/personal-outgoings-second/personal-outgoings-second.component';
import { PersonalOutgoingsComponent } from './personal-data/personal-outgoings/personal-outgoings.component';
import {
  PersonalResidentialSecondComponent,
} from './personal-data/personal-residential-second/personal-residential-second.component';
import { PersonalResidentialComponent } from './personal-data/personal-residential/personal-residential.component';
import { PersonalWorkSecondComponent } from './personal-data/personal-work-second/personal-work-second.component';
import { PersonalWorkComponent } from './personal-data/personal-work/personal-work.component';
import { PropertyDetailsComponent } from './personal-data/property-details/property-details.component';
import { PropertyLandRegisterComponent } from './personal-data/property-land-register/property-land-register.component';
import { PropertyModernizationComponent } from './personal-data/property-modernization/property-modernization.component';
import { PropertyPlotComponent } from './personal-data/property-plot/property-plot.component';
import { PropertyComponent } from './personal-data/property/property.component';
import {
  BhwBuildingLoanSelectionComponent,
} from './product/bhw-building-loan-selection/bhw-building-loan-selection.component';
import { LoanCheckOneComponent } from './product/loan-check-one/loan-check-one.component';
import { LoanCheckRepaymentComponent } from './product/loan-check-repayment/loan-check-repayment.component';
import { LoanCheckReschedulingComponent } from './product/loan-check-rescheduling/loan-check-rescheduling.component';
import { LoanCheckSummaryComponent } from './product/loan-check-summary/loan-check-summary.component';
import { LoanCheckTwoComponent } from './product/loan-check-two/loan-check-two.component';
import { ProdmainComponent } from './product/prodmain/prodmain.component';
import * as ROUTE_PATHS from './routing-path';
import { AntragComponent } from './shared/components/antrag/antrag.component';
import { MandantComponent } from './shared/components/mandant/mandant.component';
import { SummaryStatusComponent } from './summary-status/summary-status.component';
import { SummaryComponent } from './summary/summary.component';

export const routes: Routes = [
  {
    path: ROUTE_PATHS.DEFAULT_PATH,
    redirectTo: ROUTE_PATHS.MANDANT_PATH + ROUTE_PATHS.SLASH,
    pathMatch: 'full'
  },
  {
    path: ROUTE_PATHS.ANTRAG_PATH + ROUTE_PATHS.PARAM + 'antragId',
    component: AntragComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.MANDANT_PATH + ROUTE_PATHS.PARAM + 'mandant',
    component: MandantComponent,
    canActivate: [RouteGuard]
  },

  {
    path: ROUTE_PATHS.PROD_MAIN_PATH,
    component: ProdmainComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.LOAN_CHECK_ONE_PATH,
    component: LoanCheckOneComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.LOAN_CHECK_TWO_PATH,
    component: LoanCheckTwoComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.LOAN_CHECK_RESCHEDULING_PATH,
    component: LoanCheckReschedulingComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.BHW_BUILDING_LOAN_SELECTION_PATH,
    component: BhwBuildingLoanSelectionComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.LOAN_CHECK_REPAYMENT_PATH,
    component: LoanCheckRepaymentComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.LOAN_CHECK_SUMMARY_PATH,
    component: LoanCheckSummaryComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_NAME_BIRTH_TAX_PATH,
    component: PersonalNameBirthTaxComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_EMAIL_NATIONALITY_PATH,
    component: PersonalEmailNationalityComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_RESIDENTIAL_PATH,
    component: PersonalResidentialComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_WORK_PATH,
    component: PersonalWorkComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_FAMILY_PATH,
    component: PersonalFamilyComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_INCOME_PATH,
    component: PersonalIncomeComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_ADDITIONAL_INCOME_PATH,
    component: PersonalAdditionalIncomeComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_OUTGOINGS_PATH,
    component: PersonalOutgoingsComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_ADDITIONAL_OUTGOINGS_PATH,
    component: PersonalAdditionalOutgoingsComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_FORTUNE_PATH,
    component: PersonalFortuneComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_NAME_BIRTH_TAX_SECOND_PATH,
    component: PersonalNameBirthTaxSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_EMAIL_NATIONALITY_SECOND_PATH,
    component: PersonalEmailNationalitySecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_RESIDENTIAL_SECOND_PATH,
    component: PersonalResidentialSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_WORK_SECOND_PATH,
    component: PersonalWorkSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_FAMILY_SECOND_PATH,
    component: PersonalFamilySecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_INCOME_SECOND_PATH,
    component: PersonalIncomeSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_ADDITIONAL_INCOME_SECOND_PATH,
    component: PersonalAdditionalIncomeSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_OUTGOINGS_SECOND_PATH,
    component: PersonalOutgoingsSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_ADDITIONAL_OUTGOINGS_SECOND_PATH,
    component: PersonalAdditionalOutgoingsSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PERSONAL_FORTUNE_SECOND_PATH,
    component: PersonalFortuneSecondComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PROPERTY_PATH,
    component: PropertyComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PROPERTY_PLOT_PATH,
    component: PropertyPlotComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PROPERTY_LAND_REGISTER_PATH,
    component: PropertyLandRegisterComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PROPERTY_MODERNIZATION_PATH,
    component: PropertyModernizationComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.PROPERTY_DETAILS_PATH,
    component: PropertyDetailsComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.BANK_ACCOUNT_PATH,
    component: BankAccountComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.SUMMARY_PATH,
    component: SummaryComponent,
    canActivate: [RouteGuard]
  },

  {
    path: ROUTE_PATHS.CONCLUSION_PATH,
    component: ConclusionComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.APPLICATION_SELECTION_PATH,
    component: ApplicationSelectionComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.SUMMARY_STATUS_PATH,
    component: SummaryStatusComponent,
    canActivate: [RouteGuard]
  },
  {
    path: ROUTE_PATHS.OTHERS,
    redirectTo: ROUTE_PATHS.MANDANT_PATH + ROUTE_PATHS.SLASH
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
