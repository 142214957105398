import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

@Component({
  selector: 'app-vermoegen-summary',
  templateUrl: './vermoegen-summary.component.html',
  styleUrls: ['../../summary.component.scss', './vermoegen-summary.component.scss']
})
export class VermoegenSummaryComponent extends BaseComponent implements OnInit {
  kontoguthaben: boolean;
  kurswert: boolean;
  bausparguthaben: boolean;
  rueckkaufswertLebensversicherungen: boolean;
  kontoguthabensecond: boolean;
  kurswertsecond: boolean;
  bausparguthabensecond: boolean;
  rueckkaufswertLebensversicherungensecond: boolean;
  vermoegenPersonOne: boolean;
  vermoegenPersonTwo: boolean;
  // commenting according to https://gitlab.widas.de/deutsche-bank/oap/general/-/issues/557#note_818959
  // sparplane: boolean;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private valuesService: ValuesService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.kontoguthaben = this.haushaltsrechnung.vermoegen.kontoguthaben !== null && this.haushaltsrechnung.vermoegen.kontoguthaben > 0;
    this.kurswert = this.haushaltsrechnung.vermoegen.kurswert !== null && this.haushaltsrechnung.vermoegen.kurswert > 0;
    this.bausparguthaben = this.haushaltsrechnung.vermoegen.bausparguthaben !== null && this.haushaltsrechnung.vermoegen.bausparguthaben > 0;
    this.rueckkaufswertLebensversicherungen =
      this.haushaltsrechnung.vermoegen.rueckkaufswertLebensversicherungen !== null && this.haushaltsrechnung.vermoegen.rueckkaufswertLebensversicherungen > 0;
    this.vermoegenPersonOne = this.kontoguthaben || this.kurswert || this.bausparguthaben || this.rueckkaufswertLebensversicherungen;
    // commenting according to https://gitlab.widas.de/deutsche-bank/oap/general/-/issues/557#note_818959
    // this.sparplane = this.haushaltsrechnung.vermoegen.sparplane !== null && this.haushaltsrechnung.vermoegen.sparplane > 0;

    this.kontoguthabensecond = this.haushaltsrechnungSecond.vermoegen.kontoguthaben !== null && this.haushaltsrechnungSecond.vermoegen.kontoguthaben > 0;
    this.kurswertsecond = this.haushaltsrechnungSecond.vermoegen.kurswert !== null && this.haushaltsrechnungSecond.vermoegen.kurswert > 0;
    this.bausparguthabensecond = this.haushaltsrechnungSecond.vermoegen.bausparguthaben !== null && this.haushaltsrechnungSecond.vermoegen.bausparguthaben > 0;
    this.rueckkaufswertLebensversicherungensecond =
      this.haushaltsrechnungSecond.vermoegen.rueckkaufswertLebensversicherungen !== null && this.haushaltsrechnungSecond.vermoegen.rueckkaufswertLebensversicherungen > 0;
    this.vermoegenPersonTwo = this.kontoguthabensecond || this.kurswertsecond || this.bausparguthabensecond || this.rueckkaufswertLebensversicherungensecond;
  }
}
