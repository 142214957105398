import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBSelectOption, DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { SelectBoxOption } from 'src/app/core/enum-options.service';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { OAPValidators } from 'src/app/core/validators.directive';
import { Anrede, Titel } from 'src/app/shared/model/personendaten';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

import { ApplicationData } from '../../shared/model/application-data';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';
import { ApplicationDataService } from './../../shared/services/application-data.service';

@Component({
  selector: 'app-personal-name-birth-tax',
  templateUrl: './personal-name-birth-tax.component.html',
  styleUrls: ['./personal-name-birth-tax.component.scss'],
  providers: [FormBuilder]
})
export class PersonalNameBirthTaxComponent extends BaseComponent implements OnInit, AfterContentChecked {
  anredeOptions: SelectBoxOption[];
  titleOptions: SelectBoxOption[];
  config: { }
  countryList: Array<DBSelectOption> = [];

  errors: any = {
    anredeValidation: [
      {
        rule: 'required',
        message: 'personal-name-birth-tax.salutation-selection.hint'
      }
    ],
    vornameValidation: [
      {
        rule: 'required',
        message: 'personal-name-birth-tax.name.first-name.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-name-birth-tax.name.first-name.hint'
      }
    ],
    nachnameValidation: [
      {
        rule: 'required',
        message: 'personal-name-birth-tax.name.last-name.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-name-birth-tax.name.last-name.hint'
      }
    ],
    geburtsdatumValidation: [
      {
        rule: 'required',
        message: 'personal-name-birth-tax.birth.date.hint'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Geburtsdatum',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ],
    geburtsortValidation: [
      {
        rule: 'required',
        message: 'personal-name-birth-tax.birth.country.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-name-birth-tax.birth.country.hint'
      }
    ],
    geburtslandValidation: [
      {
        rule: 'required',
        message: 'personal-name-birth-tax.birth.country.hint'
      }
    ],
    steuerIdValidation: [
      {
        rule: 'pattern',
        message: 'personal-name-birth-tax.tax-id.format.hint'
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valuesService: ValuesService,
    private enumService: PersonalDataEnumOptionsService,
    private cd: ChangeDetectorRef,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initCountries();
    this.initFormControls();
    this.translateErrors(this.errors);
    this.anredeOptions = this.enumService.getOptionsFor(Anrede);
    this.titleOptions = this.enumService.getOptionsFor(Titel);
    this.headerService.changeText('personal-name-birth-tax.headline');
    this.adobe.pushAdobeEvent('Personal_name_birth_tax', 'personal data');
    this.config = {
          dateFormat: "dd-mm-yyyy",
          defaultHour: 12,
    }
  }

  private initFormControls() {
    this.formValidation = this.formBuilder.group({
      anrede: new FormControl(this.personendaten.anrede, DBValidators.required()),
      vorname: new FormControl(this.personendaten.vorname, [DBValidators.required(), DBValidators.minLength(2)]),
      nachname: new FormControl(this.personendaten.nachname, [DBValidators.required(), DBValidators.minLength(2)]),
      geburtsdatum: new FormControl(this.geburtsdatum, DBValidatorsUtil.chain([DBValidators.required(), OAPValidators.dateFormat()])),
      geburtsort: new FormControl(this.personendaten.geburtsort, [DBValidators.required(), DBValidators.minLength(2)]),
      geburtsland: new FormControl(this.personendaten.geburtsland, DBValidators.required()),
      steuerId: new FormControl(this.personendaten.steuerid, DBValidators.pattern(/^[0-9]{11}$/))
    });
  }

  initCountries() {
    this.valuesService.loadCountries().subscribe(countryList => {
      this.countryList.length = 0; // reset countries
      this.countryList = countryList;
    });
  }

  // getter for Date
  get geburtsdatum(): Date {
    if (this.personendaten.geburtsdatum) {
      return this.dateService.getDateUIFromModel(this.personendaten.geburtsdatum);
    }
    return;
  }

  // setter for Date
  geburtsdatumChange($event) {
    this.personendaten.geburtsdatum = this.dateService.getDateModelFromDateUI($event);
  }

  countryChange($event) {
    this.personendaten.geburtsland = $event.target.value;
  }

  updateAnrede($event) {
    this.applicationData.antrag.hauptdarlehensnehmer.personendaten.anrede = $event.target.value;
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
}
