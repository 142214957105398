import { Ignore } from 'src/app/core/serializer.service';

export class Einnahmen {
  anzahlRegelmaessigesNettoeinkommen: number;
  regelmaessigesNettoeinkommen: number;
  erhaltUnterhalt: number;
  zusaetzlicheRenteneinkommen: number;
  nebeneinkuenfte: number;
  nebeneinkuenfteseitDatum: Date;
  mieteinnahmen: number;
  sonstEinnahmenBetrag: number;
  sonstEinnahmenText: string;
  @Ignore
  showReceivingAlimony: boolean = undefined;
  @Ignore
  showAdditionalPensionIncome: boolean = undefined;

  constructor(einnahmen?: Einnahmen) {
    this.anzahlRegelmaessigesNettoeinkommen = einnahmen ? einnahmen.anzahlRegelmaessigesNettoeinkommen : 12;
    this.regelmaessigesNettoeinkommen = einnahmen ? einnahmen.regelmaessigesNettoeinkommen : null;
    this.erhaltUnterhalt = einnahmen ? einnahmen.erhaltUnterhalt : null;
    this.zusaetzlicheRenteneinkommen = einnahmen ? einnahmen.zusaetzlicheRenteneinkommen : null;
    this.nebeneinkuenfte = einnahmen ? einnahmen.nebeneinkuenfte : null;
    this.nebeneinkuenfteseitDatum = einnahmen && einnahmen.nebeneinkuenfteseitDatum ? new Date(einnahmen.nebeneinkuenfteseitDatum) : null;
    this.mieteinnahmen = einnahmen ? einnahmen.mieteinnahmen : null;
    this.sonstEinnahmenBetrag = einnahmen ? einnahmen.sonstEinnahmenBetrag : null;
    this.sonstEinnahmenText = einnahmen ? einnahmen.sonstEinnahmenText : null;
  }
}
