import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ApplicationData, ProdMainTyp } from 'src/app/shared/model/application-data';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import * as routes from '../../routing-path';
import { VerwendungsZweck } from '../../shared/model/produkt-parameter';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { Mandant, MandantService } from '../../shared/services/mandant.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-prodmain',
  templateUrl: './prodmain.component.html',
  styleUrls: ['./prodmain.component.scss'],
})
export class ProdmainComponent extends BaseComponent implements OnInit {
  isBuildingLoanSelectionNeeded: boolean;
  constructor(
    private router: Router,
    private mandantService: MandantService,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);

    let option: any = {};
    option.pageName = 'Privatkunden';
    option.pageType = 'product application form (OPrA)';
    option.leadstage = 'SAL';
    option.productID = '12100';
    option.productCategory = 'Baufinanzierung';
    option.productName = 'Baufinanzierung';
    option.actionType = 'application_funnel';
    option.actionName = 'application funnel step loaded';
    option.opraId = '';
    this.adobe.triggerAdobe(option);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isBuildingLoanSelectionNeeded = !(this.mandantService.getMandant() === Mandant.DB);
    this.applicationData.prodmainTyp = undefined;
    this.appDataService.setVerwendungszweck(undefined);
    this.adobe.pushAdobeEvent("Application_started", "initial page load");
  }

  goToModernizationPage() {
    this.applicationData.prodmainTyp = ProdMainTyp.Modernization;
    this.appDataService.setVerwendungszweck(VerwendungsZweck.Modernization);
    this.goToNext(this.applicationData);
  }

  goToPurchasePage() {
    this.applicationData.prodmainTyp = ProdMainTyp.PurchaseNewBuild;
    this.appDataService.setVerwendungszweck(undefined);
    this.goToNext(this.applicationData);
  }

  goToReschedulingPage() {
    this.applicationData.prodmainTyp = ProdMainTyp.Rescheduling;
    this.appDataService.setVerwendungszweck(VerwendungsZweck.Rescheduling);
    this.goToNext(this.applicationData);
  }

  private goToNext(appData: ApplicationData) {
    const currPath = routes.calculateNextPath(routes.PROD_MAIN_PATH, this.isBuildingLoanSelectionNeeded, appData);
    this.router.navigate([currPath]);
    this.navBarService.currentPath = currPath;
  }
}
