<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.land-register.origin.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{grundbuchVon}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.land-register.band.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="band !== null">{{band}}</span>
                <span *ngIf="band === null">{{'summary.object-data.land-register.not-specified.prefix' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.land-register.sheet-magazine-number.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{blatt}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.land-register.cadastral-plan.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="flur !== null">{{flur}}</span>
                <span *ngIf="flur === null">{{'summary.object-data.land-register.not-specified.prefix' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.land-register.cadastral-unit.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{flurstueck}}
            </div>
        </div>
    </div>
</div>