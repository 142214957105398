<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'property-plot.label' | translate }}
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 ui-control">
      <db-input [(model)]="objektWert.strasse" formControlName="strasse" type="text" [label]="'property-plot.street.placeholder' | translate"
        (modelChange)="addressManuallyModified()" autocomplete="off" label="">
      </db-input>
      <db-validation-errors for="strasse" [messages]="errors.strasse"> </db-validation-errors>
    </div>
    <div class="col-12 col-sm-6 col-md-6 ui-control">
      <db-input [(model)]="objektWert.hausnummer" formControlName="hausnummer" [label]="'property-plot.house-number.placeholder' | translate"
        (modelChange)="addressManuallyModified()" autocomplete="off" label="">
      </db-input>
      <db-validation-errors for="hausnummer" [messages]="errors.hausnummer"> </db-validation-errors>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 ui-control">
      <db-input [(model)]="objektWert.plz" formControlName="plz" type="text" [label]="'property-plot.zip-code.placeholder' | translate" (modelChange)="addressManuallyModified()"
        autocomplete="off" label="">
      </db-input>
      <db-validation-errors for="plz" [messages]="errors.plz"> </db-validation-errors>
    </div>
    <div class="col-12 col-sm-6 col-md-6 ui-control">
      <db-input [(model)]="objektWert.ort" formControlName="ort" type="text" [label]="'property-plot.place.placeholder' | translate" autocomplete="off"
        (modelChange)="addressManuallyModified()" label="">
      </db-input>
      <db-validation-errors for="ort" [messages]="errors.ort"> </db-validation-errors>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 ui-control">
      <db-select [ngClass]="{ 'not-selected': !objektWert.bundesland }" formControlName="bundesLand" placeholder="{{ 'property-plot.federal-state.placeholder' | translate }}"
        label="{{ 'general.federal-state.label' | translate }}" [options]="bundeslandList" [(model)]="objektWert.bundesland" (modelChange)="addressManuallyModified()">
      </db-select>
      <db-validation-errors for="bundesLand" [messages]="errors.bundesLand"> </db-validation-errors>
    </div>
    <div class="col-12 col-sm-6 col-md-6"></div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 ui-control">
      <db-input [(model)]="objektWert.grundstuecksFlaeche" formControlName="grundstuecksFlaeche" type="number" label="{{ 'property-plot.property-area.label' | translate }}"
        autocomplete="off">
      </db-input>
      <db-validation-errors for="grundstuecksFlaeche" [messages]="errors.grundstuecksFlaeche"> </db-validation-errors>
    </div>
    <div class="col-6 col-sm-2">
      <div class="ui-control-label">{{ 'property-plot.heritable-building-right.label' | translate }}</div>
    </div>
    <div class="col-6 col-sm-4 ui-control">
      <db-radio-group formControlName="erbbauRecht" [(model)]="erbbauRecht">
        <db-radio-button *ngFor="let item of erbbauRechtRadioArray" [model]="item.value">
          {{ item.label | translate }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="erbbauRecht" [messages]="errors.erbbauRecht"> </db-validation-errors>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-6"></div>
    <div class="col-12 col-sm-6 col-md-6 ui-control">
      <div class="row" *ngIf="showErbbauRecht">
        <div class="col-12 ui-control">
          <db-input [(model)]="objektWert.restlaufzeitErbbau" formControlName="restlaufzeitErbbau" type="number" [label]="'property-plot.remaining-term.label' | translate"
            autocomplete="off">
          </db-input>
          <db-validation-errors for="restlaufzeitErbbau" [messages]="errors.restlaufzeitErbbau"> </db-validation-errors>
        </div>
        <div class="col-12 ui-control">
          <db-input [(model)]="objektWert.hoeheErbbauZins" formControlName="hoeheErbbauZins" type="text" [label]="'property-plot.ground-rent.label' | translate" autocomplete="off">
          </db-input>
          <db-validation-errors for="hoeheErbbauZins" [messages]="errors.hoeheErbbauZins"> </db-validation-errors>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': isAddressAutoModified }">
  {{ 'general.warning-address-adjusted.label' | translate }}
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': isAddressInvalid }">
  {{ 'general.warning-address-not-found.label' | translate }}
</div>