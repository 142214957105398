import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { BaseService, HttpMethod } from 'src/app/core/base.service';
import { SerializerService } from 'src/app/core/serializer.service';
import { environment } from 'src/environments/environment';

import { Anfrage } from '../model/anfrage';
import { Antrag, AntragStatus } from '../model/antrag';
import { ApplicationData } from '../model/application-data';
import { Produkt } from '../model/produkt';
import { ProdukTyp, VerwendungsZweck } from '../model/produkt-parameter';
import { Mandant, MandantService } from './mandant.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDataService extends BaseService {
  private _applicationData: ApplicationData;
  private _isConsultantContacted: boolean;

  private BAUFI_ANTRAG_SERVICE_URL = '/antrag/';
  private BAUFI_ANTRAG_INTERRUPT_URL = '/interrupt';
  private BAUFI_LEAD_SUBMIT_URL = '/lead-service/';
  private BAUFI_ANTRAG_READY_URL = '/ready';
  private _status: AntragStatus = AntragStatus.InBearbeitung;

  constructor(popupService: PopupService, http: HttpClient, authService: AuthService, private serializeService: SerializerService, private mandantService: MandantService) {
    super(popupService, http, authService);
    this._applicationData = this.initAppData();
  }

  // private initialization methods
  private initAppData() {
    const appData = new ApplicationData();
    appData.antrag = new Antrag();
    if (!appData.antrag.vgeNummer) {
      appData.antrag.vgeNummer = this.mandantService.getVgeNumber();
    }
    return appData;
  }

  // public getter & setter
  set applicationData(applicationdata: ApplicationData) {
    this._applicationData = applicationdata;
  }

  get applicationData(): ApplicationData {
    if (!this._applicationData) {
      this._applicationData = this.initAppData();
    }
    return this._applicationData;
  }

  get antragStatus(): AntragStatus {
    return this._status;
  }

  setVerwendungszweck(verwendungszweck: VerwendungsZweck) {
    this.applicationData.antrag.produkt.produktParameter.verwendungszweck = verwendungszweck;
  }

  getVerwendungszweck(): VerwendungsZweck {
    return this.applicationData.antrag.produkt.produktParameter.verwendungszweck;
  }

  setBsvSelected(isBsvSelected: boolean) {
    this.applicationData.antrag.produkt.produktParameter.isBsvSelected = isBsvSelected;
  }

  setUmschulungsdatum(umschuldungsdatum: Date) {
    this.applicationData.antrag.produkt.produktParameter.setUmschuldungsdatum(umschuldungsdatum, this.isForwardProduct(umschuldungsdatum));
  }

  getProduktTyp() {
    return this.applicationData.antrag.produkt.produktParameter.produkttyp;
  }
  get isConsultantContacted(): boolean {
    return this._isConsultantContacted;
  }
  get antragId(): string {
    return this.applicationData.antrag.id;
  }
  get email(): string {
    return this.applicationData.antrag.hauptdarlehensnehmer.personendaten.email;
  }

  // public methods
  public isForwardProduct(umschuldungsdatum: Date) {
    const inputformat = 'DD.MM.YYYY';
    const viewDate = moment(umschuldungsdatum, inputformat);
    const minLimit = moment()
      .add(4, 'months')
      .set('date', 1);
    const maxLimit = moment()
      .add(4, 'years')
      .add(9, 'months')
      .set('date', 2);

    return viewDate.isAfter(minLimit, 'day') && viewDate.isBefore(maxLimit);
  }

  public save(): Observable<any> {
    if (this.applicationData.isSaved) {
      return this.saveApplication(this.BAUFI_ANTRAG_SERVICE_URL + this.antragId, HttpMethod.PUT);
    } else {
      return this.saveApplication(this.BAUFI_ANTRAG_SERVICE_URL, HttpMethod.POST);
    }
  }

  public leadSubmit(finalNotification: boolean): Observable<any> {
    const contactLeadResult: Subject<any> = new Subject<any>();
    if (this.mandantService.getMandant() === Mandant.DB && this.applicationData.antrag.produkt.produktParameter.verwendungszweck !== VerwendungsZweck.Modernization) {
      this.http.get(environment.baseUrl + this.BAUFI_LEAD_SUBMIT_URL + this.antragId + '?isDBFinalNotification=' + finalNotification, this.httpOptions).subscribe(
        submitResult => {
          console.log('Submit DB lead result %o', submitResult);
          this._isConsultantContacted = true;
          contactLeadResult.next(submitResult);
        },
        (error: HttpErrorResponse) => {
          this.errorHandling('DB Lead Submit', error);
        }
      );
    } else {
      const leadAnfrage = new Anfrage(this.applicationData.antrag, this.calculateMonatlicheRate(this.applicationData.antrag.produkt));
      this.http.post(environment.baseUrl + this.BAUFI_LEAD_SUBMIT_URL, leadAnfrage, this.httpOptions).subscribe(
        submitResult => {
          console.log('Submit not DB lead result %o', submitResult);
          this._isConsultantContacted = true;
          contactLeadResult.next(submitResult);
        },
        (error: HttpErrorResponse) => {
          this.errorHandling('Not DB Lead Submit', error);
        }
      );
    }
    return contactLeadResult.asObservable();
  }

  public loadAntrag(antragId: string, accessToken: string): Observable<Antrag> {
    const serviceResult: Subject<Antrag> = new Subject<Antrag>();
    this.httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', "Bearer " + accessToken)
    };
    this.http.get(environment.baseUrl + this.BAUFI_ANTRAG_SERVICE_URL + antragId, this.httpOptions).subscribe(
      (antrag: any) => {
        console.log('GET ANTRAG response:%o', antrag);
        serviceResult.next(antrag);
      },
      (error: HttpErrorResponse) => {
        console.error('ERROR: ', error.message);
        serviceResult.next(null);
      }
    );
    return serviceResult.asObservable();
  }

  public getAntragStatus(antragId?: string): Observable<AntragStatus> {
    const statusResult: Subject<AntragStatus> = new Subject<AntragStatus>();
    this.loadAntragStatus('/status', antragId).subscribe(antragStatusResult => {
      console.log('Antrag status loaded %o: ', antragStatusResult);
      if (antragStatusResult) {
        this._status = antragStatusResult.status;
        statusResult.next(antragStatusResult.status);
      }
    });
    return statusResult;
  }

  public loadAntragStatus(serviceName: string, antragId?: string): Observable<any> {
    const serviceResult: Subject<any> = new Subject<any>();
    antragId = antragId ? antragId : this.antragId;
    console.log('Cal %s for antragId %s', serviceName, antragId);
    this.http.get(environment.baseUrl + this.BAUFI_ANTRAG_SERVICE_URL + antragId + serviceName, this.httpOptions).subscribe(
      (statusResponse: any) => {
        console.log('%s Service response %o: ', serviceName, statusResponse);
        serviceResult.next(statusResponse);
      },
      (error: HttpErrorResponse) => {
        console.log('ERROR: ', error.message);
        serviceResult.next(null);
      }
    );
    return serviceResult.asObservable();
  }
  public completeAntrag(): Observable<any> {
    const completeResult: Subject<any> = new Subject<any>();
    this.http.put(environment.baseUrl + this.BAUFI_ANTRAG_SERVICE_URL + this.antragId + this.BAUFI_ANTRAG_READY_URL, null, this.httpOptions).subscribe(
      readyResult => {
        console.log('Complete antrag result %o', readyResult);
        completeResult.next(readyResult);
      },
      (error: HttpErrorResponse) => {
        this.errorHandling('Complete antrag error: %o', error);
      }
    );
    return completeResult.asObservable();
  }

  // private service methods
  private saveApplication(serviceUrl: string, keyMethod: string): Observable<any> {
    const saveResult: Subject<any> = new Subject<any>();
    console.log('Antrag to save %o', this.applicationData.antrag);
    this.http[keyMethod](environment.baseUrl + serviceUrl, this.serializeService.serialize(this.applicationData.antrag), this.httpOptions).subscribe(
      (result: { id: string }) => {
        console.log('App data is saved!');
        if (HttpMethod.POST === keyMethod) {
          this.applicationData.antrag.id = result.id;
          console.log('Saved antrag id=%s', result.id);
        }
        saveResult.next(result);
      },
      (error: HttpErrorResponse) => {
        console.log('App data is not saved!');
        this.errorHandling('Save Application', error);
      }
    );
    return saveResult.asObservable();
  }

  public interruptApplication(): Observable<any> {
    const interruptResult: Subject<any> = new Subject<any>();
    this.http
      .put(
        environment.baseUrl + this.BAUFI_ANTRAG_SERVICE_URL + this.antragId + this.BAUFI_ANTRAG_INTERRUPT_URL,
        this.serializeService.serialize(this.applicationData.antrag),
        this.httpOptions
      )
      .subscribe(
        result => {
          interruptResult.next(result);
          console.log('Interrupted result info=%s', result);
        },
        (error: HttpErrorResponse) => {
          this.errorHandling('Interrupt Application', error);
        }
      );
    return interruptResult;
  }

  berechneFinanzierungsbedarf() {
    const produkt: Produkt = this.applicationData.antrag.produkt;
    return (
      produkt.produktParameter.finanzierungsbedarf -
      produkt.produktParameter.eigenkapitalOhneBausparen -
      produkt.produktParameter.eigenleistungen -
      produkt.produktParameter.sonstDarlehenBetrag
    );
  }

  calculateMonatlicheRate(data: Produkt) {
    if (data.produktParameter.produkttyp === ProdukTyp.ConstantExpress) {
      return this.calculateFinanzierungsrate(data);
    } else if (data.produktParameter.produkttyp === ProdukTyp.FH || data.produktParameter.produkttyp === ProdukTyp.ForwardFH) {
      return this.calculateMonatlicheRateFH(data);
    } else {
      return this.calculateMonatlicheRateTH(data);
    }
  }

  calculateFinanzierungsrate(data: Produkt): number {
    return data.produktBerechnung.darlehenssumme / 100 || 0;
  }

  private calculateMonatlicheRateFH(data: Produkt): number {
    return ((data.produktBerechnung.tilgungSollzins / 100) * data.produktBerechnung.darlehenssumme) / 12 + data.bsvDaten.monatlicherSparbetrag;
  }

  private calculateMonatlicheRateTH(data: Produkt): number {
    return (((data.produktBerechnung.tilgungSollzins + data.produktParameter.anfaenglicherTilgungszinssatz) / 100) * data.produktBerechnung.darlehenssumme) / 12;
  }

  calculateZinsbindung(data: Produkt): string {
    let zinsbindung = '';

    if (data.produktParameter.produkttyp === ProdukTyp.ConstantExpress) {
      zinsbindung = 'Fest bis Zuteilung';
    } else {
      zinsbindung = data.produktParameter.laufzeit + ' Jahre';
    }
    return zinsbindung;
  }

  calculateAnzahlRaten(data: Produkt): number {
    const sparplanLM = data.bsvDaten ? data.bsvDaten.sparplanLaufzeitMonate : 0;
    const tilgungLM = data.produktBerechnung.tilgungLaufzeitMonate;
    return sparplanLM + tilgungLM;
  }

  calculateGesamtLaufzeit(data: Produkt): string {
    const gesamtLaufzeitMonate = this.calculateAnzahlRaten(data);
    const monate = gesamtLaufzeitMonate % 12;
    const jahre = (gesamtLaufzeitMonate - monate) / 12;
    const result = 'ca. ' + jahre + ' Jahre';
    return monate !== 0 ? result.concat(' und ' + monate + ' Monate') : result;
  }

  calculateBausparsumme(data: Produkt) {
    return data.bsvDaten.teilbausparSumme !== 0 ? data.bsvDaten.teilbausparSumme : data.bsvDaten.bausparSumme;
  }
}
