import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

export enum Mandant {
  DB = 'db',
  BHW = 'bhw',
  POSTBANK = 'postbank',
  TEST = 'test',
  IS24 = 'is24'
}

@Injectable({
  providedIn: 'root'
})
export class MandantService {
  private vgeNummer: string;

  constructor(private route: ActivatedRoute) {}

  getMandant(): Mandant {
    const mandantFromLocalStorage = localStorage.getItem('mandant');
    if (mandantFromLocalStorage) {
      return Mandant[mandantFromLocalStorage.toUpperCase()];
    }
  }

  initializeMandant() {
    const mandant: Mandant = this.resolveMandant();
    localStorage.setItem('mandant', mandant);
    this.configureVgeNumber(mandant);
  }

  getVgeNumber() {
    if (!this.vgeNummer) {
      this.initializeMandant();
    }
    return this.vgeNummer;
  }

  configureVgeNumber(mandant: Mandant) {
    const is24Referrer = 'https://meineimmobilie.postbank.de/lp-baufinanzierung-2';
    if (document.referrer === is24Referrer) {
      this.vgeNummer = '07302026500';
    } else {
      if (mandant === Mandant.POSTBANK) {
        this.vgeNummer = '18001381393';
      } else if (mandant === Mandant.DB) {
        this.vgeNummer = environment.vgeNumberDB;
      } else if (mandant === Mandant.BHW) {
        this.vgeNummer = '08580171350';
      } else if (mandant === Mandant.TEST) {
        this.vgeNummer = '01910577154';
      } else if (mandant === Mandant.IS24) {
        this.vgeNummer = '07302026500';
      } else {
        this.vgeNummer = '08580171350';
      }
    }
  }

  resolveMandant(): Mandant {
    const mandantFromService = this.getMandant() !== undefined ? this.getMandant() : Mandant.DB;
    const mandant: string = this.route.firstChild && this.route.firstChild.snapshot ? this.route.firstChild.snapshot.paramMap.get('mandant') : this.resolveandantFromUrl();
    return mandant !== '' ? Mandant[mandant.toUpperCase()] : mandantFromService;
  }

  private resolveandantFromUrl(): string {
    // TODO rework: not a good way to resolve the mandant from URL
    // for outside components like footer =>
    // because it's not included into router outlet (don't have a snapshot route element)
    const currentURL = window.location.href;
    if (currentURL.includes('mandant/db')) {
      return Mandant.DB;
    } else if (currentURL.includes('mandant/bhw')) {
      return Mandant.BHW;
    }
    return '';
  }
}
