import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import { TranslateListService } from '../../core/translate-list.service';
import { ProdukTyp, VerwendungsZweck } from '../../shared/model/produkt-parameter';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';
import { ProductEnumOptionsService } from '../product-enum-options.service';

@Component({
  selector: 'app-loan-check-one',
  templateUrl: './loan-check-one.component.html',
  styleUrls: ['./loan-check-one.component.scss'],
  providers: [FormBuilder]
})
export class LoanCheckOneComponent extends BaseComponent implements OnInit, AfterContentChecked {
  radioButtonArray: any[] = [];
  questionLabel: string;

  errorMessages: any = [
    {
      rule: 'required',
      message: 'financing-type.hint'
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private enumService: ProductEnumOptionsService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.formValidation = this.formBuilder.group({
      options: new FormControl(this.getVerwendungsZweckOptionValue(this.verwendungsZweck), DBValidators.required())
    });
    this.questionLabel = this.defineQuestionLabel();
    this.radioButtonArray = this.enumService.getDisabledOptionsFor(VerwendungsZweck, this.isVerwendungZweckOptionDisable.bind(this));
    this.translateListService.translateList(this.errorMessages, 'message');
    this.adobe.pushAdobeEvent("Loan_check_one", "loan data");
  }

  typeChange($event) {
    this.verwendungsZweck = $event.target.value;
    this.questionLabel = this.defineQuestionLabel();
    this.refresh();
  }

  refresh() {
    this.appDataService.setVerwendungszweck(this.verwendungsZweck);
    this.defineQuestionLabel();
  }

  defineQuestionLabel(): string {
    switch (this.verwendungsZweck) {
      case VerwendungsZweck.Modernization:
        return 'loan-check-1.costs.modernization.label';
      case VerwendungsZweck.KaufAltbau:
      case VerwendungsZweck.PurchasePlot:
      case VerwendungsZweck.Purchase:
        return 'loan-check-1.costs.purchase.label';
      case VerwendungsZweck.Expansion:
      case VerwendungsZweck.NewBuild:
        return 'loan-check-1.costs.build.label';
      case VerwendungsZweck.InternRescheduling:
      case VerwendungsZweck.Rescheduling:
        return 'loan-check-1.costs.debt-rescheduling.label';
      default:
        return 'Wie hoch sind die Kosten für?';
    }
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  get finanzierungsbedarf() {
    return this.produkt.produktParameter.finanzierungsbedarf;
  }

  set finanzierungsbedarf(finanzierungsbedarf: number) {
    this.produkt.produktParameter.finanzierungsbedarf = +finanzierungsbedarf > this.maxValue() ? this.maxValue() : +finanzierungsbedarf;
  }

  get eigenkapitalOhneBausparen() {
    return this.produkt.produktParameter.eigenkapitalOhneBausparen;
  }

  set eigenkapitalOhneBausparen(eigenkapitalOhneBausparen: number) {
    this.produkt.produktParameter.eigenkapitalOhneBausparen = +eigenkapitalOhneBausparen > this.maxValue() ? this.maxValue() : +eigenkapitalOhneBausparen;
  }

  minValue(sliderName: string) {
    if (sliderName === 'finanzierungsbedarf') {
      return 10000;
    } else {
      return 0;
    }
  }

  maxValue() {
    if (this.produktTyp && this.produktTyp === ProdukTyp.ConstantExpress) {
      return 50000;
    } else {
      return 750000;
    }
  }

  // overriden
  protected validationPopupIntern(): boolean {
    if (this.appDataService.berechneFinanzierungsbedarf() < 10000) {
      this.openValidationPopupWindow('alert.loan-too-low.text');
      return false;
    }
    return true;
  }
}
