<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4>{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div class="row">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.number-of-regular-salaries.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.anzahlRegelmaessigesNettoeinkommen}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.net-personal-income.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.regelmaessigesNettoeinkommen | currency: 'EUR'}}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.einnahmen.erhaltUnterhalt">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.receive-maintenance.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.erhaltUnterhalt | currency: 'EUR'}}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.einnahmen.zusaetzlicheRenteneinkommen">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.additional-pension-income.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.zusaetzlicheRenteneinkommen | currency: 'EUR'}}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.einnahmen.nebeneinkuenfte">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.sideline-income.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.nebeneinkuenfte | currency: 'EUR'}}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.einnahmen.nebeneinkuenfteseitDatum">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.sideline-income-since.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.nebeneinkuenfteseitDatum | date:'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.einnahmen.mieteinnahmen">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.rental-income.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.mieteinnahmen | currency: 'EUR'}}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.einnahmen.sonstEinnahmenText && haushaltsrechnung.einnahmen.sonstEinnahmenBetrag">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.household-check.personal-income.additional-income.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{haushaltsrechnung.einnahmen.sonstEinnahmenText}} {{haushaltsrechnung.einnahmen.sonstEinnahmenBetrag | currency: 'EUR'}}
            </div>
        </div>

        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr>
            <h4>{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.number-of-regular-salaries.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.anzahlRegelmaessigesNettoeinkommen}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.net-personal-income.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.regelmaessigesNettoeinkommen | currency: 'EUR'}}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.einnahmen.erhaltUnterhalt">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.receive-maintenance.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.erhaltUnterhalt | currency: 'EUR'}}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.additional-pension-income.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen | currency: 'EUR'}}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.einnahmen.nebeneinkuenfte">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.sideline-income.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.nebeneinkuenfte | currency: 'EUR'}}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.einnahmen.nebeneinkuenfteseitDatum">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.sideline-income-since.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.nebeneinkuenfteseitDatum | date:'mediumDate'}}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.einnahmen.mieteinnahmen">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.rental-income.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.mieteinnahmen | currency: 'EUR'}}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.einnahmen.sonstEinnahmenText && haushaltsrechnungSecond.einnahmen.sonstEinnahmenBetrag">
                <div class="col-sm-12 col-lg-6">
                    {{'summary.personal-data.household-check.personal-income.additional-income.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{haushaltsrechnungSecond.einnahmen.sonstEinnahmenText}} {{haushaltsrechnungSecond.einnahmen.sonstEinnahmenBetrag | currency: 'EUR'}}
                </div>
            </div>
        </div>
    </div>
</div>