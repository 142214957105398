export class Adresse {
  hausnummer: string;
  ort: string;
  postleitzahl: string;
  strasse: string;

  constructor(antragssubdaten?: Adresse) {
    this.hausnummer = antragssubdaten ? antragssubdaten.hausnummer : null;
    this.ort = antragssubdaten ? antragssubdaten.ort : null;
    this.postleitzahl = antragssubdaten ? antragssubdaten.postleitzahl : null;
    this.strasse = antragssubdaten ? antragssubdaten.strasse : null;
  }
}
