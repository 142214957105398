import { Injectable } from '@angular/core';
import { DBLoadingOverlayService } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {

  constructor(protected translateService: TranslateService, protected loadingOverlayService: DBLoadingOverlayService) { }

  showLoadingText(loadingTextKey: string) {
    const loadingText = this.translateService.instant(loadingTextKey);
    this.loadingOverlayService.show(loadingText);
    console.log('show loading indicator ', loadingText);
  }

  hideLoadingText() {
    this.loadingOverlayService.hide();
    console.log('hide loading indicator');
  }
}
