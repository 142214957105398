import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { SelectBoxOption } from 'src/app/core/enum-options.service';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { YesNoConfirmation } from 'src/app/shared/model/application-data';
import { ObjektArt } from 'src/app/shared/model/objekt-wert';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

import { Nutzungsart, VerwendungsZweck, VollGeschosse } from '../../shared/model/produkt-parameter';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';
import { ConditionalRequiredSettings, OAPValidators } from './../../core/validators.directive';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  providers: [FormBuilder]
})
export class PropertyComponent extends BaseComponent implements OnInit {
  private RESCEDULING_VALUES = [VerwendungsZweck.Rescheduling, VerwendungsZweck.InternRescheduling];
  verwendungszweck: VerwendungsZweck;
  isVollGeschosseVisible: boolean;
  isModernisationVisible: boolean;
  verwendungsZweckarray: any[] = [];
  objektWertArray: SelectBoxOption[] = [];
  fertighausArray: any[] = [];
  vollGeschosseArray: any[] = [];
  nutzungsartArray: any[] = [];
  conditionalFieldForDisabling = [
    ObjektArt.EinZweifamilienhaus33Gewerbe,
    ObjektArt.MehrfamilienhausDreiMieter,
    ObjektArt.Mehrfamilienhaus33Gewerbe,
    ObjektArt.Eigentumswohnung,
    ObjektArt.Fertighaus
  ];

  errors: any = {
    verwendungszweck: [
      {
        rule: 'required',
        message: 'property.purpose-of-use.hint'
      }
    ],
    objektArt: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Objektart'
      }
    ],
    mitEigentumsAnteil: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Miteigentumsanteil'
      }
    ],
    gesamtEigentumsAnteile: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Gasammtanteil'
      }
    ],
    vollGeschosse: [
      {
        rule: 'required',
        message: 'property.number-floors.hint',
        fieldName: 'Anzahl Geschosse'
      }
    ],
    anzahlWohnEinheiten: [
      {
        rule: 'required',
        message: 'property.number-of-residential-units.hint'
      }
    ],
    kaufPreis: [
      {
        rule: 'required',
        message: 'property.purchase-price.hint'
      },
      {
        rule: 'minnumber',
        message: 'property.purchase-price.amount.hint'
      }
    ]
  };
  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private enumService: PersonalDataEnumOptionsService,
    private valuesService: ValuesService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.verwendungszweck = this.applicationData.isPropertyVisited ? this.produktParameter.verwendungszweck : null;
    this.verwendungsZweckarray = this.enumService.getDynamicOptionsFor(VerwendungsZweck, this.getDynamicVerwendungszweck.bind(this));
    this.fertighausArray = this.enumService.getOptionsFor(YesNoConfirmation);
    this.vollGeschosseArray = this.enumService.getOptionsFor(VollGeschosse);
    this.nutzungsartArray = this.enumService.getOptionsFor(Nutzungsart);
    this.headerService.changeText('property.headline');
    this.initPropertyType();
    this.formSettings();
    this.translateErrors(this.errors);
    this.disablingFormControls();
    this.preassignmentValues(true);
    this.updateVollGeschosseVisibility();
    this.adobe.pushAdobeEvent("Property", "property data");
  }

  // private form init methods
  private initPropertyType(): any {
    this.valuesService.loadPropertType().subscribe(properType => {
      this.objektWertArray = Object.assign([], properType);
      this.objektWertArray = this.objektWertArray.map(item => {
        if (item.label === undefined || item.label === 'undefined') {
          let fertigHausLabel;
          this.translateService.get('property.prefab.label').subscribe((text: string) => {
            fertigHausLabel = text;
          });
          return {
            label: fertigHausLabel,
            value: ObjektArt.Fertighaus
          };
        }
        return {
          label: item.label,
          value: item.value
        };
      });
      console.log('this.objektWertArray ', this.objektWertArray);
    });
  }

  private getDynamicVerwendungszweck(verwendungszweck: VerwendungsZweck): boolean {
    if (this.RESCEDULING_VALUES.includes(this.produktParameter.verwendungszweck)) {
      return this.RESCEDULING_VALUES.includes(verwendungszweck);
    }
    return !this.RESCEDULING_VALUES.includes(verwendungszweck);
  }

  // template change event methods
  public changeVerwendungsZweck($event) {
    this.verwendungszweck = $event;
    this.disablingFormControls();
    this.preassignmentValues(false);
    this.updateModernisationVisibility();
  }
  public changeObjektArt($event) {
    this.objektWert.objektArt = $event;
    this.disablingFormControls();
    this.preassignmentValues(false);
    this.updateVollGeschosseVisibility();
  }

  private formSettings() {
    const requiredSetting: ConditionalRequiredSettings[] = new Array();
    requiredSetting.push({
      controlNames: ['vollGeschosse'],
      andConditions: [{ conditionalControlName: 'objektArt', conditionalNotRequiredValues: [ObjektArt.Eigentumswohnung, ObjektArt.UnbebautesGrundstueck] }]
    });
    this.formValidation = this.formBuilder.group(
      {
        verwendungszweck: new FormControl(this.verwendungszweck, DBValidators.required()),
        objektArt: new FormControl(this.objektWert.objektArt, DBValidators.required()),
        kaufPreis: new FormControl(this.objektWert.kaufPreis, [
          DBValidators.minNumber(this.produkt.produktBerechnung.darlehenssumme ? this.produkt.produktBerechnung.darlehenssumme : 0),
          DBValidators.required()
        ]),
        fertighaus: new FormControl(this.fertighaus),
        anzahlWohnEinheiten: new FormControl(this.objektWert.anzahlWohnEinheiten, DBValidators.required()),
        mitEigentumsAnteil: new FormControl(this.objektWert.mitEigentumsAnteil, DBValidators.required()),
        gesamtEigentumsAnteile: new FormControl(this.objektWert.gesamtEigentumsAnteile, DBValidators.required()),
        vollGeschosse: new FormControl(this.objektWert.vollGeschosse)
      },
      {
        validator: OAPValidators.conditionalRequiredValuesFormValidator(requiredSetting)
      }
    );
  }

  get fertighaus(): string {
    return this.convertToDBCore(this.objektWert.fertighaus);
  }
  set fertighaus(fertighaus: string) {
    this.objektWert.fertighaus = this.convertFromDBCore(fertighaus, 'boolean');
  }

  protected successValidation() {
    super.successValidation();
    this.produktParameter.verwendungszweck = this.verwendungszweck;
    this.applicationData.isPropertyVisited = true;
  }

  // dynamic disabling methods
  private disablingFormControls() {
    if (this.produktParameter.verwendungszweck === VerwendungsZweck.PurchasePlot) {
      this.formValidation.controls.objektArt.disable();
    } else {
      this.formValidation.controls.objektArt.enable();
    }
    if (this.objektWert.objektArt !== ObjektArt.Eigentumswohnung) {
      this.formValidation.controls.mitEigentumsAnteil.disable();
      this.formValidation.controls.gesamtEigentumsAnteile.disable();
    } else {
      this.formValidation.controls.mitEigentumsAnteil.enable();
      this.formValidation.controls.gesamtEigentumsAnteile.enable();
    }
    if (this.conditionalFieldForDisabling.includes(this.objektWert.objektArt)) {
      this.formValidation.controls.fertighaus.disable();
      this.formValidation.controls.anzahlWohnEinheiten.enable();
    } else {
      this.formValidation.controls.fertighaus.enable();
      this.formValidation.controls.anzahlWohnEinheiten.disable();
    }
  }

  private preassignmentValues(initial: boolean) {
    setTimeout(() => {
      if (this.produktParameter.verwendungszweck === VerwendungsZweck.PurchasePlot) {
        this.objektWert.objektArt = ObjektArt.UnbebautesGrundstueck;
        this.objektWert.mitEigentumsAnteil = 1;
        this.objektWert.gesamtEigentumsAnteile = 1;
      }
      if (this.objektWert.objektArt && this.objektWert.objektArt.includes('EINFAMILIENHAUS')) {
        this.objektWert.anzahlWohnEinheiten = 1;
      } else if (this.objektWert.objektArt && this.objektWert.objektArt.includes('ZWEIFAMILIENHAUS')) {
        this.objektWert.anzahlWohnEinheiten = 2;
      } else if (this.objektWert.objektArt && this.objektWert.objektArt === ObjektArt.Fertighaus) {
        this.objektWert.fertighaus = true;
      } else if (this.objektWert.objektArt === ObjektArt.UnbebautesGrundstueck) {
        this.objektWert.anzahlWohnEinheiten = 0;
      } else if (this.objektWert.objektArt !== ObjektArt.Eigentumswohnung) {
        this.objektWert.mitEigentumsAnteil = 1;
        this.objektWert.gesamtEigentumsAnteile = 1;
      } else if (!initial) {
        this.objektWert.mitEigentumsAnteil = null;
        this.objektWert.gesamtEigentumsAnteile = null;
      }
    }, 10);
  }
  private updateVollGeschosseVisibility() {
    this.isVollGeschosseVisible =
      this.objektWert.objektArt && this.objektWert.objektArt !== ObjektArt.Eigentumswohnung && this.objektWert.objektArt !== ObjektArt.UnbebautesGrundstueck;
    if (!this.isVollGeschosseVisible) {
      this.objektWert.vollGeschosse = null;
    }
  }
  private updateModernisationVisibility() {
    this.isModernisationVisible = this.produktParameter.verwendungszweck && this.produktParameter.verwendungszweck === VerwendungsZweck.KaufAltbau;
    if (!this.isModernisationVisible) {
      this.objektWert.modernisierungsKosten = null;
    }
  }
}
