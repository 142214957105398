import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-bhw-building-loan-selection',
  templateUrl: './bhw-building-loan-selection.component.html',
  styleUrls: ['./bhw-building-loan-selection.component.scss'],
  providers: [FormBuilder]
})
export class BhwBuildingLoanSelectionComponent extends BaseComponent implements OnInit, OnDestroy {
  dirty = false;

  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.adobe.pushAdobeEvent("Building_loan_selection", "loan data");
  }

  get isBsvSelected(): boolean {
    return this.produkt.produktParameter.isBsvSelected;
  }

  set isBsvSelected(isWithBsvSelected: boolean) {
    this.dirty = true;
    this.produkt.produktParameter.isBsvSelected = isWithBsvSelected;
  }

  get invalid() {
    return this.dirty && (this.isBsvSelected === null || this.isBsvSelected === undefined);
  }

  // overriden
  protected validationFormIntern(): boolean {
    this.dirty = true;
    return !this.invalid;
  }
}
