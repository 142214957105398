<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'property.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select [ngClass]="{
          'not-selected': !produktParameter.verwendungszweck
        }" placeholder="{{ 'property.purpose-of-use.placeholder' | translate }}" formControlName="verwendungszweck" [options]="verwendungsZweckarray"
        label="{{ 'property.purpose-of-use.label' | translate }}" [model]="verwendungszweck" (modelChange)="changeVerwendungsZweck($event)">
      </db-select>
      <db-validation-errors for="verwendungszweck" [messages]="errors.verwendungszweck"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="isModernisationVisible">
      <db-input type="number" [(model)]="objektWert.modernisierungsKosten" autocomplete="off" label="{{ 'property.modernisation-costs.placeholder' | translate }}"> </db-input>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select [ngClass]="{ 'not-selected': !objektWert.objektArt }" placeholder="{{ 'property.object-type.placeholder' | translate }}" formControlName="objektArt"
        [options]="objektWertArray" label="{{ 'property.object-type.label' | translate }}" [model]="objektWert.objektArt" (modelChange)="changeObjektArt($event)">
      </db-select>
      <db-validation-errors for="objektArt" [messages]="errors.objektArt"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="isVollGeschosseVisible">
      <db-select [ngClass]="{ 'not-selected': !objektWert.vollGeschosse || objektWert.vollGeschosse === '' }" placeholder="{{ 'property.full-floor.placeholder' | translate }}"
        [options]="vollGeschosseArray" formControlName="vollGeschosse" label="{{ 'property.full-floor.label' | translate }}" [(model)]="objektWert.vollGeschosse">
      </db-select>
      <db-validation-errors for="vollGeschosse" [messages]="errors.vollGeschosse"> </db-validation-errors>
    </div>
  </div>
  <div class="ui-control-label">{{ 'property.rental-property-shares.label' | translate }}</div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <div class="row">
        <div class="col-5">
          <db-input type="number" label=" {{ 'property.rental-property-shares.own.placeholder' | translate }}" [(model)]="objektWert.mitEigentumsAnteil"
            formControlName="mitEigentumsAnteil" autocomplete="off">
          </db-input>
          <db-validation-errors for="mitEigentumsAnteil" [messages]="errors.mitEigentumsAnteil"> </db-validation-errors>
        </div>
        <div class="col-2 mt-4">
          <span class="ui-control-label">/</span>
        </div>
        <div class="col-5">
          <db-input type="number" label="{{ 'property.rental-property-shares.all.placeholder' | translate }}" [(model)]="objektWert.gesamtEigentumsAnteile"
            formControlName="gesamtEigentumsAnteile" autocomplete="off">
          </db-input>
          <db-validation-errors for="gesamtEigentumsAnteile" [messages]="errors.gesamtEigentumsAnteile"> </db-validation-errors>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select [options]="fertighausArray" [(model)]="fertighaus" formControlName="fertighaus" label="{{ 'property.prefab.label' | translate }}"> </db-select>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input type="number" [(model)]="objektWert.anzahlWohnEinheiten" formControlName="anzahlWohnEinheiten"
        label="{{ 'property.number-of-residential-units.placeholder' | translate }}" autocomplete="off">
      </db-input>
      <db-validation-errors for="anzahlWohnEinheiten" [messages]="errors.anzahlWohnEinheiten"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select [ngClass]="{ 'not-selected': !objektWert.nutzungsart }" [options]="nutzungsartArray" [(model)]="objektWert.nutzungsart"
        placeholder="{{ 'loan-check-repayment.type-of-usage.placeholder' | translate }}" label=" {{ 'property.usage-type.label' | translate }}" disabled="true">
      </db-select>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input type="number" [(model)]="objektWert.kaufPreis" formControlName="kaufPreis" label=" {{ 'property.purchase-price.placeholder' | translate }}" autocomplete="off">
      </db-input>
      <db-validation-errors for="kaufPreis" [messages]="errors.kaufPreis"> </db-validation-errors>
    </div>
  </div>
</form>