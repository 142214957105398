<div class="toggle-container row" *ngIf="!isTH">
    <div class="col-sm-12 col-lg-6">
        <div class="summary-title">{{'loan-check-summary.product-details.title' | translate}}</div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.loan-amount.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ produkt.produktBerechnung.darlehenssumme | number: '1.2-2' }} €
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.payout-rate.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ auszahlungskurs | number: '1.0-2' }} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.commitment-interest.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ bereitstellungszins }}
            </div>
        </div>
        <div class="row" *ngIf="applicationData.isShorterThreeMonths">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.time-free-of-commitment-interest.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ bereitstellungszinsfreieZeit }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.fixed-borrowing-rate.prefix'| translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktBerechnung.tilgungSollzins | number: '1.0-2'}} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.effective-interest-rate.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktBerechnung.tilgungEffektivzins | number: '1.0-2'}} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.fixed-interest.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{zinsbindung}}
            </div>
        </div>
        <div class="row" *ngIf="produkt.produktParameter.umschuldungsdatum">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="applicationData.isShorterThreeMonths">
                {{'summary.product.product-details.rescheduling-date.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!applicationData.isShorterThreeMonths">
                {{'summary.product.product-details.rescheduling-date.three-months.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktParameter.umschuldungsdatum | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.total-effective-interest-rate.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ produkt.produktBerechnung.gesamtEffektivzins | number: '1.0-2' }} %
            </div>
        </div>

    </div>

    <div class="col-sm-12 col-lg-6">
        <div class="summary-title">{{'summary.product.building-loan-agreement-details.label' | translate}}</div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.building-society-tarif.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.bsvDaten.bsvTarif}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.fixed-borrowing-rate-after-allotment.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ produkt.bsvDaten.sparplanSollzins | number: '1.0-2' }} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.effective-interest-rate-after-allotment.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ produkt.bsvDaten.sparplanEffektivzins | number: '1.0-2' }} %
            </div>
        </div>
        <div class="row" *ngIf="!isExpress">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.redemption-fee-bsv.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ produkt.produktParameter.monatlicherTilgungsbetrag | number: '1.2-2' }} €
            </div>
        </div>
        <div class="row" *ngIf="!isExpress">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.house-saving-sum.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ bausparsumme | number: '1.2-2' }} €
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.acquisition-fee.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.bsvDaten.sparAbschlussGebuehr| number: '1.2-2' }} €
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.expected-allocation.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktBerechnung.tilgungAbDatum | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.building-loan-agreement-details.credit-at-allotment.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.bsvDaten.guthabenBeiZuteilung | number: '1.2-2' }} €
            </div>
        </div>
    </div>
</div>

<div class="toggle-container row" *ngIf="isTH">
    <div class="col-sm-12 col-lg-12">
        <div class="summary-title">{{'loan-check-summary.product-details.title' | translate}}</div>
    </div>
    <div class="col-sm-12 col-lg-6">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.loan-amount.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ produkt.produktBerechnung.darlehenssumme | number: '1.2-2' }} €
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.payout-rate.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ auszahlungskurs | number: '1.0-2' }} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.commitment-interest.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ bereitstellungszins }}
            </div>
        </div>
        <div class="row" *ngIf="applicationData.isShorterThreeMonths">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.time-free-of-commitment-interest.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ bereitstellungszinsfreieZeit }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.fixed-borrowing-rate.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktBerechnung.tilgungSollzins | number: '1.2-2' }} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.effective-interest-rate.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktBerechnung.tilgungEffektivzins | number: '1.2-2' }} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.initial-repayment.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktParameter.anfaenglicherTilgungszinssatz | number: '1.2-2' }} %
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.monthly-amount-paid.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{monZahlBetrag | number: '1.2-2' }} €
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.fixed-interest.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktParameter.laufzeit}} Jahre
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.product.product-details.remaining-debt.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{ restschuld | number: '1.2-2' }} €
            </div>
        </div>
        <div class="row" *ngIf="produkt.produktParameter.umschuldungsdatum">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="applicationData.isShorterThreeMonths">
                {{'summary.product.product-details.rescheduling-date.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!applicationData.isShorterThreeMonths">
                {{'summary.product.product-details.rescheduling-date.three-months.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktParameter.umschuldungsdatum | date: 'mediumDate'}}
            </div>
        </div>
    </div>
</div>