<div class="toggle-container">
    <db-intro-preview>
        <db-toggleable id="persSum0" [label]="'summary.personal-data.personal-data.basic-data.label' | translate" [open]="togglePersoenlichenDatenArray[0]" iconPosition="right"
            (openChange)="togglePersoenlichenDatenClick(0)">
            <app-grunddaten-summary></app-grunddaten-summary>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="persSum1" [label]="'summary.personal-data.personal-data.contact-data.label' | translate" [open]="togglePersoenlichenDatenArray[1]" iconPosition="right"
            (openChange)="togglePersoenlichenDatenClick(1)">
            <app-kontaktdaten-summary></app-kontaktdaten-summary>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="persSum2" [label]="'summary.personal-data.personal-data.employment-details.label' | translate" [open]="togglePersoenlichenDatenArray[2]"
            iconPosition="right" (openChange)="togglePersoenlichenDatenClick(2)">
            <app-beschaeftigung-summary></app-beschaeftigung-summary>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="persSum3" [label]="'summary.personal-data.personal-data.family-circumstances.label' | translate" [open]="togglePersoenlichenDatenArray[3]"
            iconPosition="right" (openChange)="togglePersoenlichenDatenClick(3)">
            <app-familie-summary></app-familie-summary>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="persSum4" [label]="'summary.personal-data.personal-data.account-details.label' | translate" [open]="togglePersoenlichenDatenArray[4]"
            iconPosition="right" (openChange)="togglePersoenlichenDatenClick(4)">
            <app-kontodaten-summary></app-kontodaten-summary>
        </db-toggleable>
    </db-intro-preview>
</div>