import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { YesNoConfirmation } from 'src/app/shared/model/application-data';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { ConditionalRequiredSettings, OAPValidators } from '../../core/validators.directive';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';

@Component({
  selector: 'app-personal-income-second',
  templateUrl: './personal-income-second.component.html',
  styleUrls: ['./personal-income-second.component.scss']
})
export class PersonalIncomeSecondComponent extends BaseComponent implements OnInit {
  DEFAULT_REGULAT_NET_INCOME_NUMBER = 12;
  MAX_REGULAT_NET_INCOME_NUMBER = 15;
  MIN_REGULAT_NET_INCOME_NUMBER = 0;

  private contentTextKey: string;
  private title = 'alert.all.attention.title';
  private previousSalary = 0;

  yesNoConfirmationOptionsArray: any[] = [];

  errors: any = {
    regelmaessigesNettoeinkommen: [
      {
        rule: 'required',
        message: 'personal-income.salaries-per-year.hint'
      }
    ],
    receivingAlimonyOptions: [
      {
        rule: 'required',
        message: 'personal-income.alimony-payments.selection.hint'
      }
    ],
    additionalPensionIncome: [
      {
        rule: 'required',
        message: 'personal-income.additional-pension.selection.hint'
      }
    ],
    erhaltUnterhalt: [
      {
        rule: 'required',
        message: 'personal-income.alimony-payments.amount.hint'
      }
    ],
    zusaetzlicheRenteneinkommen: [
      {
        rule: 'required',
        message: 'personal-income.additional-pension.amount.hint'
      }
    ]
  };

  private readonly okButton = {
    title: 'general.yes',
    click: () => {
      this.popupService.closeAndResetPopup();
    }
  };

  private readonly noButton = {
    title: 'general.no',
    click: () => this.cancelClicked()
  };

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private enumService: PersonalDataEnumOptionsService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    const requiredSetting: ConditionalRequiredSettings[] = new Array();
    requiredSetting.push({
      controlNames: ['erhaltUnterhalt'],
      andConditions: [{ conditionalControlName: 'receivingAlimonyOptions', conditionalNotRequiredValues: ['false'] }]
    });
    requiredSetting.push({
      controlNames: ['zusaetzlicheRenteneinkommen'],
      andConditions: [{ conditionalControlName: 'additionalPensionIncomeOptions', conditionalNotRequiredValues: ['false'] }]
    });
    this.initRadioButton();
    this.formValidation = this.formBuilder.group(
      {
        receivingAlimonyOptions: new FormControl(this.showReceivingAlimony, DBValidators.required()),
        additionalPensionIncomeOptions: new FormControl(this.showAdditionalPensionIncome, DBValidators.required()),
        erhaltUnterhalt: new FormControl(this.erhaltUnterhalt),
        zusaetzlicheRenteneinkommen: new FormControl(this.zusaetzlicheRenteneinkommen),
        regelmaessigesNettoeinkommen: new FormControl(this.regelmaessigesNettoeinkommen, DBValidators.required())
      },
      {
        validator: OAPValidators.conditionalRequiredValuesFormValidator(requiredSetting)
      }
    );
    this.yesNoConfirmationOptionsArray = this.enumService.getOptionsFor(YesNoConfirmation);
    this.headerService.changeText('personal-income.headline');
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_income_second", "personal data");
  }

  private initRadioButton() {
    if (this.haushaltsrechnungSecond.einnahmen.erhaltUnterhalt && this.haushaltsrechnungSecond.einnahmen.erhaltUnterhalt > 0) {
      this.receivingAlimonyChange('true');
    } else if (this.haushaltsrechnungSecond.einnahmen.erhaltUnterhalt === 0) {
      this.receivingAlimonyChange('false');
    }
    if (this.haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen && this.haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen > 0) {
      this.zusaetzlicheRenteneinkommenChange('true');
    } else if (this.haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen === 0) {
      this.zusaetzlicheRenteneinkommenChange('false');
    }
  }

  protected initAppData() {
    super.initAppData();
    this.previousSalary = this.anzahlRegelmaessigesNettoeinkommen;
  }

  cancelClicked() {
    this.anzahlRegelmaessigesNettoeinkommen = this.previousSalary;
    this.popupService.closeAndResetPopup();
  }

  receivingAlimonyChange(value: string) {
    this.showReceivingAlimony = value;
    if (!this.haushaltsrechnungSecond.einnahmen.showReceivingAlimony) {
      this.erhaltUnterhalt = null; // set value 0 while choose the option 'No'
    }
  }

  zusaetzlicheRenteneinkommenChange(value: string) {
    this.showAdditionalPensionIncome = value;
    if (!this.haushaltsrechnungSecond.einnahmen.showAdditionalPensionIncome) {
      this.zusaetzlicheRenteneinkommen = null;
    }
  }

  anzahlRegelmaessigesNettoeinkommenChange() {
    if (this.anzahlRegelmaessigesNettoeinkommen < this.DEFAULT_REGULAT_NET_INCOME_NUMBER) {
      this.translateService
        .get('alert.salary.text', {
          numberOfSalaries: this.anzahlRegelmaessigesNettoeinkommen
        })
        .subscribe((res: string) => {
          this.contentTextKey = res;
        });
      this.popupService.resetPopup();
      this.popupService.title = this.title;
      this.popupService.rightButton = this.okButton;
      this.popupService.leftButton = this.noButton;
      this.popupService.contentText = this.contentTextKey;
      this.popupService.openPopup();
    }
  }

  // getter & settter
  get showAdditionalPensionIncome(): string {
    return this.convertToDBCore(this.haushaltsrechnungSecond.einnahmen.showAdditionalPensionIncome);
  }
  set showAdditionalPensionIncome(showAdditionalPensionIncome: string) {
    this.haushaltsrechnungSecond.einnahmen.showAdditionalPensionIncome = this.convertFromDBCore(showAdditionalPensionIncome, 'boolean');
  }
  get showReceivingAlimony(): string {
    return this.convertToDBCore(this.haushaltsrechnungSecond.einnahmen.showReceivingAlimony);
  }
  set showReceivingAlimony(showReceivingAlimony: string) {
    this.haushaltsrechnungSecond.einnahmen.showReceivingAlimony = this.convertFromDBCore(showReceivingAlimony, 'boolean');
  }
  get anzahlRegelmaessigesNettoeinkommen() {
    return this.haushaltsrechnungSecond.einnahmen.anzahlRegelmaessigesNettoeinkommen;
  }

  set anzahlRegelmaessigesNettoeinkommen(anzahlRegelmaessigesNettoeinkommen) {
    anzahlRegelmaessigesNettoeinkommen = +anzahlRegelmaessigesNettoeinkommen;
    if (anzahlRegelmaessigesNettoeinkommen !== this.anzahlRegelmaessigesNettoeinkommen) {
      this.previousSalary = this.anzahlRegelmaessigesNettoeinkommen;
      this.haushaltsrechnungSecond.einnahmen.anzahlRegelmaessigesNettoeinkommen = anzahlRegelmaessigesNettoeinkommen;
    }
  }

  get regelmaessigesNettoeinkommen() {
    return this.haushaltsrechnungSecond.einnahmen.regelmaessigesNettoeinkommen;
  }

  set regelmaessigesNettoeinkommen(regelmaessigesNettoeinkommen: number) {
    this.haushaltsrechnungSecond.einnahmen.regelmaessigesNettoeinkommen = +regelmaessigesNettoeinkommen;
  }

  get zusaetzlicheRenteneinkommen() {
    return this.haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen;
  }

  set zusaetzlicheRenteneinkommen(zusaetzlicheRenteneinkommen: number) {
    this.haushaltsrechnungSecond.einnahmen.zusaetzlicheRenteneinkommen = +zusaetzlicheRenteneinkommen;
  }

  get erhaltUnterhalt() {
    return this.haushaltsrechnungSecond.einnahmen.erhaltUnterhalt;
  }

  set erhaltUnterhalt(erhaltUnterhalt: number) {
    this.haushaltsrechnungSecond.einnahmen.erhaltUnterhalt = +erhaltUnterhalt;
  }
}
