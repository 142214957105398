import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'src/app/core/auth.service';
import { BaseService } from 'src/app/core/base.service';
import { SerializerService } from 'src/app/core/serializer.service';
import { ApplicationData } from 'src/app/shared/model/application-data';
import { Produkt } from 'src/app/shared/model/produkt';
import { ProdukTyp } from 'src/app/shared/model/produkt-parameter';
import { PopupService } from 'src/app/shared/services/popup.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoanCheckSummaryService extends BaseService {
  private baseServiceUrl = '/produkt';

  constructor(popupService: PopupService, http: HttpClient, authService: AuthService, private serializeService: SerializerService) {
    super(popupService, http, authService);
  }
  getSummaryData(applicationData: ApplicationData): Observable<any> {
    const summaryDataResult: Subject<any> = new Subject<any>();

    this.http.post(environment.baseUrl + this.baseServiceUrl, this.serializeService.serialize(applicationData.antrag), this.httpOptions).subscribe(
      data => {
        applicationData.isZuteilungValid = true;
        applicationData.isTilgungsplanValid = true;
        applicationData.isProduktValid = true;
        summaryDataResult.next(data);
      },
      error => {
        console.error('Summary service HTTP error: ', error);
        if (error.status === 409) {
          applicationData.isZuteilungValid = false;
          summaryDataResult.next(error.error);
        } else if (error.status === 412) {
          applicationData.isTilgungsplanValid = false;
          summaryDataResult.next(error.error);
        } else {
          applicationData.isProduktValid = false;
          setTimeout(() => {
            this.openErrorModalWindow(undefined, 'alert.no-interest-information.text');
          }, 500);
        }
      }
    );
    return summaryDataResult;
  }

  calculateZinsbindung(data: Produkt): string {
    let zinsbindung = '';

    if (data.produktParameter.produkttyp === ProdukTyp.ConstantExpress) {
      zinsbindung = 'Fest bis Zuteilung';
    } else {
      zinsbindung = data.produktParameter.laufzeit + ' Jahre';
    }
    return zinsbindung;
  }

  calculateAnzahlRaten(data: Produkt): number {
    const sparplanLM = data.bsvDaten ? data.bsvDaten.sparplanLaufzeitMonate : 0;
    const tilgungLM = data.produktBerechnung.tilgungLaufzeitMonate;
    return sparplanLM + tilgungLM;
  }

  calculateGesamtLaufzeit(data: Produkt): string {
    const gesamtLaufzeitMonate = this.calculateAnzahlRaten(data);
    const monate = gesamtLaufzeitMonate % 12;
    const jahre = (gesamtLaufzeitMonate - monate) / 12;
    const result = 'ca. ' + jahre + ' Jahre';
    const monatLabel = monate > 1 ? ' Monate' : ' Monat';
    return monate !== 0 ? result.concat(' und ' + monate + monatLabel) : result;
  }

  calculateBausparsumme(data: Produkt) {
    return data.bsvDaten.teilbausparSumme !== 0 ? data.bsvDaten.teilbausparSumme : data.bsvDaten.bausparSumme;
  }
}
