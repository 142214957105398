<div class="header-banner">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-6">
        <div>
          <span>{{ titleKey | translate }}</span>
          <h4>
            {{ textKey | translate }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
