<form [formGroup]="formValidation">
  <div class="ui-control-label ">
    {{ 'property-details.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="row">
        <div class="col-12 ui-control">
          <db-input autocomplete="off" type="number" formControlName="baujahr" [(model)]="baujahr" label="{{ 'property-details.year-of-construction.placeholder' | translate }}">
          </db-input>
          <db-validation-errors for="baujahr" [messages]="errors.baujahr"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-input autocomplete="off" type="number" formControlName="wohnFlaecheEigennutz" [(model)]="wohnFlaecheEigennutz"
            label="{{ 'property-details.owner-occupied-living-space.placeholder' | translate }}">
          </db-input>
          <db-validation-errors for="wohnFlaecheEigennutz" [messages]="errors.wohnFlaecheEigennutz"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-input autocomplete="off" type="number" formControlName="wohnFlaecheFremdnutz" [(model)]="wohnFlaecheFremdnutz"
            label="{{ 'property-details.third-party-occupied-living-space.placeholder' | translate }}">
          </db-input>
          <db-validation-errors for="wohnFlaecheFremdnutz" [messages]="errors.wohnFlaecheFremdnutz"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-select [options]="locationOption" [(model)]="objektWert.lageStandort" formControlName="lageStandort" label="{{ 'property-details.location.label' | translate }}"
            placeholder="{{ 'property-details.location.placeholder' | translate }}" [ngClass]="{ 'not-selected': !objektWert.lageStandort }">
          </db-select>
          <db-validation-errors for="lageStandort" [messages]="errors.lageStandort"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-select formControlName="bauweise" [options]="construction" [(model)]="objektWert.bauweise" label="{{ 'property-details.construction-style.label' | translate }}"
            placeholder="{{ 'property-details.construction-style.placeholder' | translate }}" [ngClass]="{ 'not-selected': !objektWert.bauweise }">
          </db-select>
          <db-validation-errors for="bauweise" [messages]="errors.bauweise"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-select formControlName="dachgeschoss" [options]="attics" [(model)]="objektWert.dachgeschoss" label="{{ 'property-details.attic.label' | translate }}"
            placeholder="{{ 'property-details.attic.empty.hint' | translate }}" [ngClass]="{ 'not-selected': !objektWert.dachgeschoss }">
          </db-select>
          <db-validation-errors for="dachgeschoss" [messages]="errors.dachgeschoss"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-select formControlName="keller" [options]="kellerOption" [(model)]="objektWert.keller" label="{{ 'property-details.basement.label' | translate }}"
            placeholder="{{ 'property-details.basement.placeholder' | translate }}" [ngClass]="{ 'not-selected': !objektWert.keller }">
          </db-select>
          <db-validation-errors for="keller" [messages]="errors.keller"></db-validation-errors>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="row">
        <div class="col-12 ui-control">
          <db-select formControlName="ausstattung" [options]="outfit" [(model)]="objektWert.ausstattung" label="{{ 'property-details.endowment.label' | translate }}"
            placeholder="{{ 'property-details.endowment.placeholder' | translate }}" [ngClass]="{ 'not-selected': !objektWert.ausstattung }">
          </db-select>
          <db-validation-errors for="ausstattung" [messages]="errors.ausstattung"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-select formControlName="verwertbarkeit" [options]="conditionList" [(model)]="objektWert.verwertbarkeit"
            label="{{ 'property-details.utilizability.label' | translate }}" placeholder="{{ 'property-details.utilizability.placeholder' | translate }}"
            [ngClass]="{ 'not-selected': !objektWert.verwertbarkeit }">
          </db-select>
          <db-validation-errors for="verwertbarkeit" [messages]="errors.verwertbarkeit"></db-validation-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ui-control">
          <db-select formControlName="objektZustand" [options]="conditionList" [(model)]="objektWert.objektZustand" label="{{ 'property-details.state.label' | translate }}"
            [ngClass]="{ 'not-selected': !objektWert.objektZustand }" placeholder="{{ 'property-details.state.placeholder' | translate }}">
          </db-select>
          <db-validation-errors for="objektZustand" [messages]="errors.objektZustand"></db-validation-errors>
        </div>
      </div>
      <div class="row row-label">
        <div class="col-12 ui-control">
          <div class="ui-control-label">{{ 'property-details.parking-facilities.label' | translate }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 ui-control">
          <db-input autocomplete="off" [(model)]="anzahlCarport" type="number" label="{{ 'property-details.parking-facilities.carport.placeholder' | translate }}"> </db-input>
        </div>
        <div class="col-6 ui-control">
          <db-input autocomplete="off" [(model)]="anzahlKellerGaragen" type="number" label="{{ 'property-details.parking-facilities.basement-garage.placeholder' | translate }}">
          </db-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6 ui-control">
          <db-input autocomplete="off" [(model)]="anzahlDoppelgaragen" type="number" label="{{ 'property-details.parking-facilities.double-garage.placeholder' | translate }}">
          </db-input>
        </div>
        <div class="col-6 ui-control">
          <db-input autocomplete="off" [(model)]="anzahlStellplatz" type="number" label="{{ 'property-details.parking-facilities.parking-space.placeholder' | translate }}">
          </db-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <db-input autocomplete="off" [(model)]="anzahlGaragen" type="number" label="{{ 'property-details.parking-facilities.garage.placeholder' | translate }}"> </db-input>
        </div>
        <div class="col-6">
          <db-input autocomplete="off" [(model)]="anzahlTGStellplatz" type="number"
            label="{{ 'property-details.parking-facilities.underground-parking-space.placeholder' | translate }}">
          </db-input>
        </div>
      </div>
    </div>
  </div>
</form>