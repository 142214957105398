<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.account-details.account-holder.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{antrag.kontodaten.kontoinhaber}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.account-details.iban.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{antrag.kontodaten.iban}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.personal-data.personal-data.account-details.payout.channel.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{auszahlungsweg}}
            </div>
        </div>
    </div>
</div>