export enum Auszahlungsweg {
  Rateneinzugskonto = 'Rateneinzugskonto',
  SeparateBeantragung = 'Separatbeantragen',
}

export class Kontodaten {
  iban: string;
  kontoinhaber: string;
  auszahlungsweg: Auszahlungsweg;

  constructor(kontodaten?: Kontodaten) {
    this.iban = kontodaten ? kontodaten.iban : null;
    this.kontoinhaber = kontodaten ? kontodaten.kontoinhaber : null;
    this.auszahlungsweg = kontodaten ? kontodaten.auszahlungsweg : null;
  }
}
