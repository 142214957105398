import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Personendaten } from 'src/app/shared/model/personendaten';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

@Component({
  selector: 'app-kontaktdaten-summary',
  templateUrl: './kontaktdaten-summary.component.html',
  styleUrls: ['../../summary.component.scss', './kontaktdaten-summary.component.scss']
})
export class KontaktdatenSummaryComponent extends BaseComponent implements OnInit {
  personOneWohnadress: string;
  personTwoWohnadress: string;
  personOneBundesland: string;
  personTwoBundesland: string;
  personOneWohnverhaeltnis: string;
  personTwoWohnverhaeltnis: string;
  personOneTelefonnummer: string;
  personTwoTelefonnummer: string;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private valuesService: ValuesService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initLocalProperties();
    this.getBundeslandFirstPerson();
    this.getBundeslandSecondPerson();
  }

  initLocalProperties() {
    this.personOneWohnadress = this.getAdress(this.personendaten);
    this.personTwoWohnadress = this.getAdress(this.personendatenSecond);
    this.personOneWohnverhaeltnis = this.getWohnverhaeltnis(this.personendaten.wohnverhaeltnis);
    this.personTwoWohnverhaeltnis = this.getWohnverhaeltnis(this.personendatenSecond.wohnverhaeltnis);
    this.personOneTelefonnummer = this.getTelefonnummer(this.personendaten);
    this.personTwoTelefonnummer = this.getTelefonnummer(this.personendatenSecond);
  }

  getAdress(personenDaten: Personendaten): string {
    const strasse = personenDaten.strasse ? personenDaten.strasse + ' ' : '';
    const hausnr = personenDaten.hausnummer ? personenDaten.hausnummer + ' ' : '';
    const plz = personenDaten.plz ? personenDaten.plz + ' ' : '';
    const ort = personenDaten.ort ? personenDaten.ort : '';
    return strasse + hausnr + ', ' + plz + ort;
  }

  getTelefonnummer(personenDaten: Personendaten): string {
    const vorwahl = personenDaten.telefonnummerVorwahl ? personenDaten.telefonnummerVorwahl + '/' : '';
    const durchwahl = personenDaten.telefonnummerDurchwahl ? personenDaten.telefonnummerDurchwahl + ' ' : '';
    return vorwahl + durchwahl;
  }

  getWohnverhaeltnis(wohnverhaeltnis): string {
    switch (wohnverhaeltnis) {
      case 'MIETE':
        return 'Zur Miete';
      case 'EIGENTUM':
        return 'Eigentum';
      case 'ELTERN':
        return 'Bei den Eltern';
    }
    return '';
  }

  getBundeslandFirstPerson() {
    this.valuesService.loadRegions().subscribe(bundeslandList => {
      bundeslandList.forEach(bundesland => {
        if (this.personendaten.bundesland === bundesland.value) {
          this.personOneBundesland = bundesland.label;
        }
      });
    });
  }

  getBundeslandSecondPerson() {
    this.valuesService.loadRegions().subscribe(bundeslandList => {
      bundeslandList.forEach(bundesland => {
        if (this.personendatenSecond.bundesland === bundesland.value) {
          this.personTwoBundesland = bundesland.label;
        }
      });
    });
  }
}
