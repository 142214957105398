<div class="toggle-container">
    <db-intro-preview>
        <db-toggleable id="haushSum0" [label]="'summary.personal-data.household-check.personal-income.label' | translate" [open]="toggleHaushaltscheckArray[0]" iconPosition="right"
            (openChange)="toggleHaushaltscheckClick(0)">
            <app-einnahmen-summary></app-einnahmen-summary>
        </db-toggleable>
    </db-intro-preview>

    <db-intro-preview>
        <db-toggleable id="haushSum1" [label]="'summary.personal-data.household-check.personal-outgoings.label' | translate" [open]="toggleHaushaltscheckArray[1]"
            iconPosition="right" (openChange)="toggleHaushaltscheckClick(1)">
            <app-ausgaben-summary></app-ausgaben-summary>
        </db-toggleable>
    </db-intro-preview>

    <db-intro-preview>
        <db-toggleable id="haushSum2" [label]="'summary.personal-data.household-check.assets.label' | translate" [open]="toggleHaushaltscheckArray[2]" iconPosition="right"
            (openChange)="toggleHaushaltscheckClick(2)">
            <app-vermoegen-summary></app-vermoegen-summary>
        </db-toggleable>
    </db-intro-preview>
</div>