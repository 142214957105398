import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenServiceUrl = '/token-srv/token';
  private c1 = CryptoJS.AES.decrypt(CryptoJS.enc.Hex.parse(environment.c1).toString(CryptoJS.enc.Base64), '994@9s#eI4Vt').toString(CryptoJS.enc.Utf8);
  private c2 = CryptoJS.AES.decrypt(CryptoJS.enc.Hex.parse(environment.c2).toString(CryptoJS.enc.Base64), '994@9s#eI4Vt').toString(CryptoJS.enc.Utf8);

  private accessToken: string;

  constructor(private http: HttpClient) { }

  isUserLoggedIn(): boolean {
    let user;
    try {
      user = JSON.parse(localStorage.getItem('token'));
      console.log('user', user);
    } catch (error) {
      console.error('ERROR::', error.message);
    }
    return user !== undefined;
  }

  async getAccessToken(): Promise<string> {
    if (this.accessToken === undefined) {
      const token = await this.getCidaasToken();
      this.accessToken = token.access_token;
      sessionStorage.setItem('cidaas_token', this.accessToken);
      console.log('get new access token');
    }
    return this.accessToken;
  }

  private getCidaasToken(): Promise<any> {
    const body = `grant_type=client_credentials&client_id=${this.c1}&client_secret=${this.c2}`;
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http.post(environment.baseUrlCidaas + this.tokenServiceUrl, body, httpOptions).toPromise();
  }

}
