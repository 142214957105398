import { Ignore } from 'src/app/core/serializer.service';

export enum VerwendungsZweck {
  Modernization = 'MODERNISIERUNG',
  KaufAltbau = 'KAUF_ALTBAU',
  Purchase = 'KAUF_NEUBAU',
  NewBuild = 'NEUBAUVORHABEN',
  PurchasePlot = 'KAUF_GRUNDSTUECK',
  Expansion = 'ANBAU_UMBAU',
  Rescheduling = 'UMSCHULDUNG',
  InternRescheduling = 'INTERNE_UMSCHULDUNG'
}

export enum BackendFinanzierungsZweck {
  Kauf = 'KAUF',
  Neubau = 'NEUBAU',
  Umschuldung = 'UMSCHULDUNG',
  Modernisierung = 'MODERNISIERUNG'
}

export enum ProdukTyp {
  ConstantExpress = 'KONSTANTEXPRESS',
  FH = 'FH',
  TH = 'TH',
  ForwardFH = 'FORWARD_FH',
  ForwardTH = 'FORWARD_TH'
}

export enum InterestPeriod {
  FiveYears = 5,
  TenYears = 10,
  FifteenYears = 15,
  TwentyYears = 20
}

export enum Nutzungsart {
  Eigennutzung = 'EIGENNUTZUNG',
  Fremdnutzung = 'FREMDNUTZUNG',
  Eigen_und_Fremdnutzung = 'EIGEN_UND_FREMDNUTZUNG'
}

export enum VollGeschosse {
  Erdgeschoss = 'ERDGESCHOSS',
  Erdgeschoss_und_Obergeschoss = 'ERD_OBERGESCHOSS'
}

export class ProduktParameter {
  private _verwendungszweck: VerwendungsZweck;
  finanzierungsbedarf: number;
  private _produkttyp: ProdukTyp;
  eigenkapitalOhneBausparen: number;
  eigenleistungen: number;
  sonstDarlehenBetrag: number;
  sonstDarlehenText: string;
  private _umschuldungsdatum: Date;
  laufzeit: number;
  anfaenglicherTilgungszinssatz: number;
  monatlicherTilgungsbetrag: number;
  zinsbindungsende: Date;
  @Ignore
  private _isBsvSelected: boolean = undefined;
  vertragsbeginn: Date;
  bereitstellungszinsAb: number;
  kondiflexart: string;
  kondiflex: number;

  constructor(produktParameter?: ProduktParameter) {
    this._produkttyp = produktParameter ? produktParameter.produkttyp : ProdukTyp.ConstantExpress;
    this._verwendungszweck = produktParameter ? produktParameter.verwendungszweck : null;
    this._umschuldungsdatum = produktParameter && produktParameter.umschuldungsdatum ? new Date(produktParameter.umschuldungsdatum) : null;
    this.monatlicherTilgungsbetrag = produktParameter ? produktParameter.monatlicherTilgungsbetrag : 0;
    this.anfaenglicherTilgungszinssatz = produktParameter ? produktParameter.anfaenglicherTilgungszinssatz : 0;
    this.vertragsbeginn = produktParameter && produktParameter.vertragsbeginn ? new Date(produktParameter.vertragsbeginn) : null;
    this.finanzierungsbedarf = produktParameter ? produktParameter.finanzierungsbedarf : 25000;
    this.eigenleistungen = produktParameter ? produktParameter.eigenleistungen : 0;
    this.eigenkapitalOhneBausparen = produktParameter ? produktParameter.eigenkapitalOhneBausparen : 0;
    this.sonstDarlehenText = produktParameter ? produktParameter.sonstDarlehenText : null;
    this.sonstDarlehenBetrag = produktParameter ? produktParameter.sonstDarlehenBetrag : 0;
    this.laufzeit = produktParameter ? produktParameter.laufzeit : 10;
    this.bereitstellungszinsAb = produktParameter ? produktParameter.bereitstellungszinsAb : 0;
    this.kondiflexart = produktParameter ? produktParameter.kondiflexart : null;
    this.kondiflex = produktParameter ? produktParameter.kondiflex : 0;
    this.zinsbindungsende = produktParameter && produktParameter.zinsbindungsende ? new Date(produktParameter.zinsbindungsende) : null;
  }

  set isBsvSelected(isBsvSelected: boolean) {
    this._isBsvSelected = isBsvSelected;
    this.updateProdukttyp(this._isBsvSelected, undefined);
  }

  get isBsvSelected() {
    return this._isBsvSelected;
  }

  get produkttyp(): ProdukTyp {
    return this._produkttyp;
  }

  updateProdukttyp(isBsv: boolean, isForwardProduct: boolean) {
    if (this._verwendungszweck === VerwendungsZweck.Modernization) {
      this._produkttyp = ProdukTyp.ConstantExpress;
    } else {
      if (isBsv) {
        if (isForwardProduct) {
          this._produkttyp = ProdukTyp.ForwardFH;
        } else {
          this._produkttyp = ProdukTyp.FH;
        }
      } else {
        if (isForwardProduct) {
          this._produkttyp = ProdukTyp.ForwardTH;
        } else {
          this._produkttyp = ProdukTyp.TH;
        }
      }
    }
  }

  get verwendungszweck(): VerwendungsZweck {
    return this._verwendungszweck;
  }

  set verwendungszweck(verwendungsZweck: VerwendungsZweck) {
    this._verwendungszweck = verwendungsZweck;
    this.updateProdukttyp(this._isBsvSelected, undefined);
  }

  get umschuldungsdatum() {
    return this._umschuldungsdatum;
  }

  setUmschuldungsdatum(umschuldungsdatum: Date, isForwardProduct: boolean) {
    this._umschuldungsdatum = umschuldungsdatum;
    this.updateProdukttyp(this.isBsvSelected, isForwardProduct);
  }
}
