<div class="footer-bar">
  <div class="row">
    <div class="col-6" [hidden]="isBhw || appDataService.applicationData.isModernisierung || checkAntragBeantragt()">
      <a (click)="clickOnQuestions()">
        <db-icon size="20px">phone</db-icon>
        <db-icon size="20px">mail</db-icon>
        <span>
          {{ 'footer.contact.questions' | translate }}
        </span>
      </a>
    </div>
    <div
      class="col-6"
      [ngClass]="{ disabled: checkAntragBeantragt() }"
      [style.text-align]="isBhw || appDataService.applicationData.isModernisierung || checkAntragBeantragt() ? 'center' : 'left'"
    >
      <a (click)="clickOnContactUs()">
        <db-icon size="25px">contact</db-icon>
        <span>
          {{ 'footer.contact.contactus' | translate }}
        </span>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <db-icon size="12px">safety</db-icon>
      <span>{{ 'footer.supplier.ssl-notice' | translate }}</span>
    </div>
  </div>
</div>

<!-- Modal PopUp Content templates -->
<ng-template #modalQuestionContent>
  <div class="popup-content">
    <div>{{ 'footer.contact.questions.popup.1' | translate }}</div>
    <br />
    <div>{{ 'footer.contact.questions.popup.2' | translate }}</div>
    <div class="popup-email">{{ contactEmail }}</div>
  </div>
</ng-template>
<ng-template #modalContactUsContent>
  <div class="popup-content">
    <div *ngIf="!isBhw && !appDataService.applicationData.isModernisierung">{{ 'footer.contact.contactus.title.db.popup' | translate }}</div>
    <div *ngIf="isBhw || appDataService.applicationData.isModernisierung">{{ 'footer.contact.contactus.title.bhw.popup' | translate }}</div>
    <br />
    <ul class="pl-sm-3 pl-3">
      <li class="row">
        <db-switch
          class="col-md-4 col-sm-12 mb-sm-4 mb-4"
          onLabel="{{ onLabelKey | translate }}"
          offLabel="{{ offLabelKey | translate }}"
          (modelChange)="changeAgree($event)"
        ></db-switch>
        <div *ngIf="!isBhw && !appDataService.applicationData.isModernisierung" class="col-md-8 col-sm-12 text-center text-md-left">
          <span>{{ consentTextKey | translate }}</span>
          <a [attr.href]="link" target="_blank">{{ 'footer.contact.contactus.db.agreement.link.popup' | translate }}</a>
        </div>
        <div *ngIf="isBhw || appDataService.applicationData.isModernisierung" class="col-md-8 col-sm-12 text-center text-md-left">
          <span>{{ consentTextKey | translate }}</span>
          <a [attr.href]="einwilligungserklaerungLink" target="_blank" download="Einwilligungserklärung_DB_PB_BHW.pdf">{{
            'footer.contact.contactus.bhw.agreement.consent.declaration' | translate
          }}</a>
          <span>{{ 'footer.contact.contactus.bhw.agreement.and' | translate }}</span>
          <a [attr.href]="datenschuetzhinweiseLink" target="_blank" download="Datenschutzhinweise_BHW.pdf">{{ 'footer.contact.contactus.bhw.data.protection' | translate }}</a>
          <span>{{ consentTextSuffixKey | translate }}</span>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
