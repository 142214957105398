import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DBIconService } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ThemeService } from './core/theme.service';
import { CONCLUSION_PATH, MANDANT_PATH, PROD_MAIN_PATH, SUMMARY_STATUS_PATH } from './routing-path';
import { Mandant, MandantService } from './shared/services/mandant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isFooterVisible: boolean;
  private dbIconPath = './assets/img/db.ico';
  private bhwIconPath = './assets/img/bhw2014.ico';
  routeSubscription: Subscription;
  isSummaryStatusPage: boolean;

  constructor(translate: TranslateService, private iconService: DBIconService, private themeService: ThemeService, private mandantService: MandantService, private router: Router) {
    translate.setDefaultLang('de');
    mandantService.initializeMandant();
    this.settingIcon();
    this.settingTheme();
    this.routeSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentPathChanged(e.url);
      }
    });
  }

  settingIcon() {
    const faviconPath = this.mandantService.getMandant() === Mandant.DB ? this.dbIconPath : this.bhwIconPath;
    const nodeList: any = document.getElementsByTagName('link');

    for (const node of nodeList) {
      if (node.getAttribute('rel') === 'icon') {
        node.setAttribute('href', faviconPath);
      }
    }
  }

  ngOnInit() {
    this.loadicon();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  settingTheme() {
    this.themeService.switchTheme();
  }

  async loadicon() {
    const icons: Array<string> = await this.iconService.use('./assets/icons/db-icons.svg');
    this.iconService
      .use()
      .then(() => {
        // empty
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }

  private currentPathChanged(currentPath: string) {
    this.isFooterVisible = !this.isProdmain(currentPath) && !this.isSummaryStatus(currentPath) && !this.isConclusion(currentPath);
    this.isSummaryStatusPage = this.isSummaryStatus(currentPath);
  }
  private isProdmain(currentPath: string): boolean {
    return !currentPath || currentPath.indexOf(PROD_MAIN_PATH) > 0 || currentPath.indexOf(MANDANT_PATH) > 0;
  }
  private isSummaryStatus(currentPath: string) {
    return !currentPath || currentPath.indexOf(SUMMARY_STATUS_PATH) > 0;
  }
  private isConclusion(currentPath: string): boolean {
    return !currentPath || currentPath.indexOf(CONCLUSION_PATH) > 0;
  }
}
