import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { AdobeApiService } from '../shared/services/adobe-api.service';

@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.scss'],
})
export class ConclusionComponent extends BaseComponent implements OnInit {
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);

    this.getAdobe();
  }

  ngOnInit() {
    let dynamicTextKey = 'bhw-conclusion.one-person.headline';
    let paramObject: any = this.correspondence;
    if (this.antrag.anzahlAntragnehmer === 2) {
      dynamicTextKey = 'bhw-conclusion.two-persons.headline';
      paramObject = this.correspondenceTwoPersons;
    }
    this.translateService.get(dynamicTextKey, paramObject).subscribe((res: string) => {
      this.headerService.changeText(res);
    });
    super.ngOnInit();
  }

  getAdobe() {
    let option: any = {};
    option.pageName = 'Privatkunden';
    option.pageType = 'product application form (OPrA)';
    option.leadstage = 'SQL';
    option.productID = '12100';
    option.event = 'view_load';
    option.productCategory = 'Baufinanzierung';
    option.productName = 'Baufinanzierung';
    option.actionType = 'application_funnel';
    option.actionName = 'application funnel step loaded';
    option.opraId = this.antragId;
    this.adobe.triggerAdobe(option, option.event);
  }

  get antragId(): string {
    return this.applicationData.antrag.id;
  }
}
