export const environment = {
  production: true,
  baseUrl: 'https://baufi-deutschebank.de',
  baseUrlCidaas: 'https://oap.cidaas.de',
  redirectDBHomePage: 'https://www.deutsche-bank.de/pk/Immobilie/immobilie-und-finanzierung-im-ueberblick.html',
  legalInformationUrl: 'https://www.deutsche-bank.de/datenschutz',
  consentDeclarationUrl: 'https://baufilive.postbank.de/pdf/ErklaerungBHW.pdf',
  consentAgbUrl: 'https://www.bhw.de/allgemeine_geschaeftsbedingungen.html?pfb_toggle=8740-8747',
  emailSaveDB: 'antrag@baufi-deutschebank.de',
  emailSaveBHW: 'boxl@bhw.de',
  emailContactDB: 'baufinanzierung.direkt@db.com',
  vgeNumberDB: '18002104338',
  c1: '53616c7465645f5f499b1dd45e45f0c3b929635b55008a300027c3e7260f63425a6aa9a76a7a8cb3737526e14c551db546fed4eb2c12c67f5769b66226f5e9b1',
  c2: '53616c7465645f5f0e56aeedaa3a9248213eb080bc1411bb20f926bc2c3fb8fe9d799bb1d2afceee457524843743f14c2676a7c0d24440f3e71b2218f26488ba',
  adressValidationUrl: 'https://baufi-deutschebank.de/validate/address?street={applicantStreet}&hno={applicantHno}&zip={applicantZip}&city={applicantCity}',
};
