import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() { }

  getDateUIFromModel(date: Date): Date {
    if (!date) {
      return null;
    }
    const dateUI = new Date(date);
    dateUI.toString = () => moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
    return dateUI;
  }

  getDateModelFromDateUI(dateUI: Date): Date {
    if (!dateUI) {
      return null;
    }
    const momentDate = moment(dateUI, 'DD.MM.YYYY');
    const date = momentDate.toDate();
    console.log('Date from moment => %o', date);
    return date;
  }

  getQuarter(date: Date): string {
    const quarter = moment(date)
      .quarter()
      .toString();
    const year = moment(date)
      .year()
      .toString();
    return quarter + 'Q / ' + year;
  }
}
