import { AusschlussKriterien } from './ausschluss-kriterien';
import { Darlehensnehmer } from './darlehensnehmer';
import { Kontodaten } from './kontodaten';
import { ObjektWert } from './objekt-wert';
import { Produkt } from './produkt';

export enum AntragStatus {
  InBearbeitung = 'IN_BEARBEITUNG',
  Bereit = 'ANTRAG_BEREIT',
  InProzess = 'ANTRAG_IM_PROZESS',
  Abgelehnt = 'ANTRAG_ABGELEHNT',
  Angenommen = 'ANTRAG_ANGENOMMEN',
  DokumenteAbgerufen = 'DOKUMENTE_ABGERUFEN',
  Error = 'ERROR',
  TechnicalFailure = 'FAILURE_TECHNICAL'
}

export class Antrag {
  id: string;
  produkt: Produkt;
  anzahlAntragnehmer: number;
  ausschlussKriterien: AusschlussKriterien;
  objektWert: ObjektWert;
  hauptdarlehensnehmer: Darlehensnehmer;
  nebendarlehensnehmer: Darlehensnehmer;
  vgeNummer: string;
  kontodaten: Kontodaten;
  ewz: string;

  constructor(antrag?: Antrag) {
    if (antrag && antrag.id) {
      this.id = antrag.id;
    }
    this.produkt = antrag ? new Produkt(antrag.produkt) : new Produkt();
    this.anzahlAntragnehmer = antrag ? antrag.anzahlAntragnehmer : null;
    this.ausschlussKriterien = antrag ? new AusschlussKriterien(antrag.ausschlussKriterien) : new AusschlussKriterien();
    this.objektWert = antrag ? new ObjektWert(antrag.objektWert) : new ObjektWert();
    this.hauptdarlehensnehmer = antrag ? new Darlehensnehmer(antrag.hauptdarlehensnehmer) : new Darlehensnehmer();
    this.nebendarlehensnehmer = antrag ? new Darlehensnehmer(antrag.nebendarlehensnehmer) : new Darlehensnehmer();
    this.vgeNummer = antrag ? antrag.vgeNummer : null;
    this.kontodaten = antrag ? new Kontodaten(antrag.kontodaten) : new Kontodaten();
  }
}
