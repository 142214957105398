import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../core/base.component';
import { TranslateListService } from '../core/translate-list.service';
import * as routes from '../routing-path';
import { PopUpButton } from '../shared/components/popup-window/popup-window.component';
import { AdobeApiService } from '../shared/services/adobe-api.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { DateService } from '../shared/services/date.service';
import { HeaderService } from '../shared/services/header.service';
import { LoadingIndicatorService } from '../shared/services/loading-indicator.service';
import { MandantService } from '../shared/services/mandant.service';
import { NavButtonBarService } from '../shared/services/nav-button-bar.service';
import { PopupService } from '../shared/services/popup.service';

@Component({
  selector: 'app-application-selection',
  templateUrl: './application-selection.component.html',
  styleUrls: ['./application-selection.component.scss'],
})
export class ApplicationSelectionComponent extends BaseComponent implements OnInit {
  isBhw: boolean;
  link: string;
  linkBhwConsentAgb: string;

  agreenment: { baseAgree: boolean; firstAgreeSCHUFA: boolean; secondAgreeSCHUFA: boolean; firstAgreeInvoice?: boolean; secondAgreeInvoice?: boolean };
  isTwoPerson: boolean;

  onLabelKey: string;
  offLabelKey: string;
  noticeText: string;
  consentTextKey: string;
  pdfConsent: string;
  pdfErklaerungBHW: string;
  contactConsultantText: string;
  sendDBButton: PopUpButton = {
    title: 'general.send.button',
    click: () => {
      this.popupService.closeAndResetPopup();
      setTimeout(() => {
        this.completeAntrag(false);
      }, 500);
    },
    disabled: () => {
      return !this.agreed(false);
    },
  };
  sendBHWButton: PopUpButton = {
    title: 'general.send.button',
    click: () => {
      this.popupService.closeAndResetPopup();
      setTimeout(() => {
        this.completeAntrag(true);
      }, 500);
    },
    disabled: () => {
      return !this.agreed(true);
    },
  };
  @ViewChild('modalDBProposeContent', {
    read: TemplateRef,
    static: true,
  })
  private modalDBcontent: TemplateRef<any>;
  @ViewChild('modalBHWProposeContent', {
    read: TemplateRef,
    static: true,
  })
  private modalBHWcontent: TemplateRef<any>;
    @ViewChild('modalDBProposeTeaser', {
    read: TemplateRef,
    static: true,
  })
  private modalDBTeasercontent: TemplateRef<any>;
  redirectBHWButton: PopUpButton = {
    title: 'alert.all.button.ok',
    click: () => {
      this.router.navigate([routes.CONCLUSION_PATH]);
    },
  };
  private redirectDBButton: PopUpButton = {
    title: 'alert.all.button.ok',
    click: () => this.redirectToHomePage(),
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    mandantService: MandantService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
    this.isBhw = this.isMandantBhw(mandantService);
    this.link = environment.legalInformationUrl;
    this.linkBhwConsentAgb = environment.consentAgbUrl;
    this.getAdobe();
    this.resetAgreement();
  }

  ngOnInit() {
    super.ngOnInit();
    this.isTwoPerson = this.antrag.anzahlAntragnehmer === 2;
    this.getHeaderText();
    this.onLabelKey = 'general.yes';
    this.offLabelKey = 'general.no';
    this.pdfConsent = 'assets/docs/schufa-konditionsanfrage.pdf';
    this.pdfErklaerungBHW = 'assets/docs/ErklaerungBHW.pdf';
  }

  getAdobe() {
    let option: any = {};
    option.pageName = 'Privatkunden';
    option.pageType = 'product application form (OPrA)';
    option.leadstage = 'SQL';
    option.productID = '12100';
    option.event = 'view_load';
    option.productCategory = 'Baufinanzierung';
    option.productName = 'Baufinanzierung';
    option.actionType = 'application_funnel';
    option.actionName = 'application funnel step loaded';
    option.opraId = this.antragId;
    this.adobe.triggerAdobe(option, option.event);
  }

  get antragId(): string {
    return this.applicationData.antrag.id;
  }

  // public template methods
  public agreeBase($event) {
    this.agreenment.baseAgree = $event;
  }

  public agreed(isBhw: boolean): boolean {
    const secondAgreeSCHUFA = !this.isTwoPerson || this.agreenment.secondAgreeSCHUFA;
    const secondAgreeInvoice = !this.isTwoPerson || this.agreenment.secondAgreeInvoice;
    const agreeInvoice = isBhw ? this.agreenment.firstAgreeInvoice && secondAgreeInvoice : true;
    return this.agreenment.baseAgree && this.agreenment.firstAgreeSCHUFA && secondAgreeSCHUFA && agreeInvoice;
  }
  public goWithBHW() {
    this.resetAgreement();
    this.initBHWConsent();
    this.popupService.resetPopup();
    this.popupService.title = 'application-selection.bhw.button.text';
    this.popupService.centerButton = this.sendBHWButton;
    this.popupService.content = this.modalBHWcontent;
    this.popupService.openPopup();
  }

  public goWithDB() {
    this.resetAgreement();
    this.initDBConsent();
    this.popupService.resetPopup();
    this.popupService.title = 'application-selection.db.button.text';
    this.popupService.centerButton = this.sendDBButton;
    this.popupService.content = this.modalDBcontent;
    this.popupService.openPopup();
  }

  public agree($event, type) {
    this.agreenment[type] = $event;
  }

  // private popup methods
  private completeAntrag(isBhw: boolean) {
    this.loadingIndicatorService.showLoadingText('general.loading-indicator.content');
    this.translateService.get('footer.contact.contactus.success.db.popup', { contactConsultantEmail: environment.emailSaveDB }).subscribe((res: string) => {
      this.contactConsultantText = res;
    });
    this.appDataService.save().subscribe(() => {
      if (!isBhw) {
        this.appDataService.leadSubmit(true).subscribe(() => {
          this.loadingIndicatorService.hideLoadingText();
          console.log('Open success popup window after completion of antrag');
          this.popupService.resetPopup();
          this.popupService.contentText = this.contactConsultantText;
          this.popupService.title = 'db-application-popup.success.header';
          this.popupService.centerButton = this.redirectDBButton;
          // DB Core Bug: without giving time to hide loading indicator, the supposedly hidden loading indicator will be shown together with the popup
          setTimeout(() => {
            this.popupService.openPopup();
            this.appDataService.getAntragStatus();
          }, 500);
        });
      } else {
        this.appDataService.completeAntrag().subscribe(() => {
          this.loadingIndicatorService.hideLoadingText();
          this.router.navigate([routes.CONCLUSION_PATH]);
          this.appDataService.getAntragStatus();
        });
      }
    });
  }

  private redirectToHomePage() {
    this.popupService.closeAndResetPopup();
    // console.log('Good by application. Go to DB homepage');
    // this.document.location.href = environment.redirectDBHomePage;
    
    setTimeout(() => {
      this.openTeaser();
          }, 500);
  }
  public openTeaser(){
    this.popupService.resetPopup();
    this.popupService.content = this.modalDBTeasercontent;
    setTimeout(() => {
      this.popupService.openPopup();
          }, 500); 
  }
  public teaserCloseButton(){
    this.popupService.closeAndResetPopup();
    console.log('Good by application. Go to DB homepage');
    window.open('https://immobilien.postbank.de/marktpreis?aktion=db-marktpreis&ad=baufi-seite&campaign=juni2022&channel=db', '_blank');
    this.document.location.href = environment.redirectDBHomePage;
  }

  // private init methods
  private resetAgreement() {
    this.agreenment = { baseAgree: false, firstAgreeSCHUFA: false, secondAgreeSCHUFA: false };
  }
  private initDBConsent() {
    this.noticeText = 'db-application-popup.notice';
    this.consentTextKey = this.isTwoPerson ? 'db-application-popup.consent-clause.1-1.two' : 'db-application-popup.consent-clause.1-1.one';
  }
  private initBHWConsent() {
    this.noticeText = 'bhw-application-popup.consent-clause.notice';
    this.consentTextKey = this.isTwoPerson ? 'bhw-application-popup.consent-clause.1-1.two' : 'bhw-application-popup.consent-clause.1-1.one';
  }
  private getHeaderText() {
    let dynamicTextKey = 'application-selection.one-person.headline';
    let paramObject: any = this.correspondence;
    if (this.isTwoPerson) {
      dynamicTextKey = 'application-selection.two-persons.headline';
      paramObject = this.correspondenceTwoPersons;
    }
    this.translateService.get(dynamicTextKey, paramObject).subscribe((res: string) => {
      this.headerService.changeText(res);
    });
  }
}
