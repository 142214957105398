<div class="row">
  <div class="col-sm-12 col-md-9">
    <form [formGroup]="formValidation">
      <div class="ui-control-label">
        {{ 'personal-email-nationality.email.label' | translate }}
      </div>
      <div class="ui-control">
        <db-input autocomplete="off" formControlName="emailAddress" [(model)]="email" label="{{ 'personal-email-nationality.email.placeholder' | translate }}"> </db-input>
        <db-validation-errors for="emailAddress" [messages]="errors.emailAddress"> </db-validation-errors>
      </div>
      <div class="ui-control" *ngIf="showEmailConfirmation">
        <db-input autocomplete="off" formControlName="confirmEmailAddress" label="{{ 'personal-email-nationality.email.confirm.placeholder' | translate }}"> </db-input>
        <db-validation-errors for="confirmEmailAddress" [messages]="errors.confirmEmailAddress"> </db-validation-errors>
      </div>
      <div class="ui-control-label">
        {{ 'personal-email-nationality.nationality.label' | translate }}
      </div>
      <div class="ui-control">
        <db-select
          [ngClass]="{
            'not-selected': !personendaten.staatsangehoerigkeit || personendaten.staatsangehoerigkeit === ''
          }"
          formControlName="staatsangehoerigkeit"
          label="{{ 'personal-email-nationality.nationality.placeholder' | translate }}"
          placeholder="{{ 'general.select.placeholder' | translate }}"
          [options]="nationalityList"
          [(model)]="personendaten.staatsangehoerigkeit"
        >
        </db-select>
        <db-validation-errors for="staatsangehoerigkeit" [messages]="errors.staatsangehoerigkeit"> </db-validation-errors>
      </div>
      <div class="ui-control">
        <db-select
          [ngClass]="{
            'not-selected': !zweiteStaatsangehoerigkeitUSA || zweiteStaatsangehoerigkeitUSA === ''
          }"
          formControlName="zweiteStaatsangehoerigkeitUSA"
          placeholder="{{ 'general.select.placeholder' | translate }}"
          label="{{ 'personal-email-nationality.second-us-american-nationality.label' | translate }}"
          [options]="zweiteStaatsangehoerigkeitUSAOptions"
          [(model)]="zweiteStaatsangehoerigkeitUSA"
        >
        </db-select>
        <db-validation-errors for="zweiteStaatsangehoerigkeitUSA" [messages]="errors.zweiteStaatsangehoerigkeitUSA">
          -
        </db-validation-errors>
      </div>
    </form>
  </div>
</div>
