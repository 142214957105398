<div class="toggle-container row">
    <div class="col-sm-12 col-lg-6">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.financing-check.financing-type.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{verwendungsZweckLabel}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.financing-check.costs.prefix'| translate: { financingType: verwendungsZweckLabel } }}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktParameter.finanzierungsbedarf | currency: 'EUR'}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.financing-check.net-loan-amount.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produkt.produktBerechnung.darlehenssumme | currency: 'EUR'}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.financing-check.monthly-rate.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{monatlicheRate | currency: 'EUR'}}
            </div>
        </div>
    </div>

    <div class="col-sm-12 col-lg-6">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.financing-check.product.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{produktTypLabel}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.financing-check.first-borrower.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{fullName}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="antrag.anzahlAntragnehmer === 2">
                {{'summary.financing-check.second-borrower.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="antrag.anzahlAntragnehmer === 2">
                {{fullNameSecond}}
            </div>
            <div *ngIf="antrag.anzahlAntragnehmer === 1" class="col-sm-12 col-md-6 col-lg-6"></div>
        </div>
    </div>
</div>