<form [formGroup]="formValidation">
  <div class="ui-control-label ">
    {{ 'personal-outgoings-second.monthly-costs.label' | translate: correspondenceSecond }}
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="eigenmieteKalt" label="{{ 'personal-outgoings.monthly-costs.base-rent.placeholder' | translate }}"> </db-input>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="eigenmieteNebenkosten" label="{{ 'personal-outgoings.monthly-costs.ancillary-costs.placeholder' | translate }}">
      </db-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="praemieKrankenversicherung"
        label="{{ 'personal-outgoings.monthly-costs.private-health-insurance.placeholder' | translate }}">
      </db-input>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="unterhaltszahlungen" label="{{ 'personal-outgoings.monthly-costs.alimony-payments.placeholder' | translate }}">
      </db-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" formControlName="sonstAusgabenBetrag" [(model)]="sonstAusgabenBetrag"
        label="{{ 'personal-outgoings.monthly-costs.other-expenses-sum.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="sonstAusgabenBetrag" [messages]="errors.sonstAusgabenBetrag"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="text" formControlName="sonstAusgabenText" [(model)]="sonstAusgabenText"
        label="{{ 'personal-outgoings.monthly-costs.other-expenses-description.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="sonstAusgabenText" [messages]="errors.sonstAusgabenText"></db-validation-errors>
    </div>
  </div>
<!-- commenting according to https://gitlab.widas.de/deutsche-bank/oap/general/-/issues/557#note_818959 -->
    <!-- <div class="row">
        <div class="col-sm-12 col-md-6 ui-control">
            <db-input autocomplete="off" type="number" [(model)]="kfzAnzahl" label="{{ 'personal-outgoings.kfz-anzahl.placeholder' | translate }}">
            </db-input>
        </div>
        <div class="col-sm-12 col-md-6 ui-control">
            <db-input autocomplete="off" type="number" [(model)]="kfzKosten" label="{{ 'personal-outgoings.kfz-kosten.placeholder' | translate }}">
            </db-input>
    
        </div>
    </div> -->

  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" formControlName="lebensHaltungsKosten" [(model)]="lebensHaltungsKosten"
        label="{{ 'personal-outgoings.monthly-costs.cost-of-living.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="lebensHaltungsKosten" [messages]="errors.lebensHaltungsKosten"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="freiwilligeSparLeistungen" label="{{ 'personal-outgoings.monthly-costs.voluntary-savings.placeholder' | translate }}">
      </db-input>
    </div>
  </div>
</form>