import { Injectable } from '@angular/core';

import { TranslateListService } from './translate-list.service';

@Injectable({
  providedIn: 'root'
})
export class EnumOptionsService {
  constructor(private translate: TranslateListService) {}

  getOptionsFor<T extends { [name: string]: any }>(enumObject: T): SelectBoxOption[] {
    const options: SelectBoxOption[] = new Array();
    Object.keys(enumObject).forEach(element => {
      const enumOption = this.getOptionForEnumKey(enumObject, element);
      if (enumOption) {
        options.push(enumOption);
      }
    });
    this.translate.translateOptions(options);
    return options;
  }

  getDynamicOptionsFor<T extends { [name: string]: any }>(enumObject: T, forInclude: (value: T) => boolean): SelectBoxOption[] {
    const options: SelectBoxOption[] = new Array();
    Object.keys(enumObject).forEach(element => {
      const enumOption = this.getDynamicOptionForEnumKey(enumObject, element, forInclude);
      if (enumOption) {
        options.push(enumOption);
      }
    });
    this.translate.translateOptions(options);
    return options;
  }

  getDisabledOptionsFor<T extends { [name: string]: any }>(enumObject: T, disabledFunc: (value: T) => boolean): DisabledSelectBoxOption[] {
    const options: DisabledSelectBoxOption[] = new Array();
    Object.keys(enumObject).forEach(element => {
      const enumOption = this.getDisabledOptionForEnumKey(enumObject, element, disabledFunc);
      if (enumOption) {
        options.push(enumOption);
      }
    });
    this.translate.translateOptions(options);
    return options;
  }

  // method to override
  protected getOptionForEnumKey(typeName, enumKey: string): SelectBoxOption {
    return undefined;
  }

  // method to override
  protected getDisabledOptionForEnumKey(typeName, enumKey: string, disabledFunc: (value) => boolean): DisabledSelectBoxOption {
    return undefined;
  }

  protected getDynamicOptionForEnumKey(typeName, enumKey: string, forInclude: (value) => boolean): SelectBoxOption {
    return undefined;
  }
}
export interface SelectBoxOption {
  value: any;
  label: string;
}
export interface DisabledSelectBoxOption extends SelectBoxOption {
  disabled: boolean;
}
