import { TranslateService } from '@ngx-translate/core';

import { AddressResultStatus, AddressValidierungObjekt } from '../shared/model/adress-validierung-objekt';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { DateService } from '../shared/services/date.service';
import { HeaderService } from '../shared/services/header.service';
import { NavButtonBarService } from '../shared/services/nav-button-bar.service';
import { PopupService } from '../shared/services/popup.service';
import { AddressValidator } from './addressvalidator.service';
import { BaseComponent } from './base.component';
import { TranslateListService } from './translate-list.service';

export abstract class AddressBaseComponent extends BaseComponent {
  public isAddressInvalid: boolean;
  public isAddressAutoModified: boolean;
  private isAddressManuallyModified: boolean;

  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private addressValidator: AddressValidator,
    isNoRouterComponent?: boolean
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, isNoRouterComponent);
  }

  public addressManuallyModified() {
    this.isAddressManuallyModified = true;
    this.isAddressInvalid = false;
    this.isAddressAutoModified = false;
  }

  protected onAdresseCheck(addressPropertyKey: string, success: () => {}) {
    if (!this.formValidation.invalid && this.isAddressManuallyModified) {
      this.isAddressInvalid = false;
      this.addressValidator
        .validiereAdresse(this[addressPropertyKey].strasse, this[addressPropertyKey].hausnummer, this[addressPropertyKey].plz, this[addressPropertyKey].ort)
        .subscribe((addressResult: AddressValidierungObjekt) => {
          if (this.evaluateResult(addressResult, addressPropertyKey)) {
            success();
          }
        });
    } else {
      if (!this.isAddressInvalid) {
        return success();
      }
    }
  }

  private evaluateResult(addressResult: AddressValidierungObjekt, addressPropertyKey: string) {
    let success = false;
    this.isAddressInvalid = false;
    this.isAddressAutoModified = false;
    this.isAddressManuallyModified = false;
    console.log('Address validation state: %s', addressResult.trafficlight);
    if (this.isInvalid(addressResult)) {
      console.log('Address is invalid => display error message');
      this.isAddressInvalid = true;
    } else if (this.isOk(addressResult) || this.isError(addressResult)) {
      console.log(this.isOk(addressResult) ? 'Address is valid => go to the next' : 'Address cant checked (technical error) => go to the next');
      success = true;
    } else if (addressResult.trafficlight === AddressResultStatus.Green || addressResult.trafficlight === AddressResultStatus.Yellow) {
      console.log('modified => display message => by next click => go to the next');
      this.modifyAddress(addressResult, addressPropertyKey);
      this.isAddressAutoModified = true;
      this.isAddressManuallyModified = false;
      this.formValidation.markAsUntouched();
      this.formValidation.updateValueAndValidity();
    } else {
      console.log('Address was not checked (unknown state) => go to the next');
      success = true;
    }
    return success;
  }

  private isInvalid(addressResult: AddressValidierungObjekt): boolean {
    return addressResult.trafficlight === AddressResultStatus.Red;
  }

  private isOk(addressResult: AddressValidierungObjekt): boolean {
    return (
      addressResult.trafficlight === AddressResultStatus.Green &&
      addressResult.resulttext.includes('OK') &&
      (addressResult.resultcode.substr(0, 3) === '111' || addressResult.resultcode.substr(0, 3) === 'xxx') // service send in test-system just xxx
    );
  }

  private isError(addressResult: AddressValidierungObjekt): boolean {
    return addressResult.trafficlight === AddressResultStatus.Blue;
  }

  private modifyAddress(addressResult: AddressValidierungObjekt, addressPropertyKey: string) {
    this[addressPropertyKey].strasse = addressResult.street;
    this[addressPropertyKey].hausnummer = addressResult.hno;
    this[addressPropertyKey].plz = addressResult.zip;
    this[addressPropertyKey].ort = addressResult.city;
  }
}
