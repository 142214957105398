<div class="row">
    <div class="col-12">
        <h4>{{ 'prodmain.headline' | translate }}</h4>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 card">
        <img src="./assets/img/products/modernisation.jpg" />
        <div class="card-block">
            <div>{{ 'financing-type.modernization' | translate }}</div>
            <div>
                {{ 'prodmain.card.modernization.description' | translate }}
            </div>
            <db-button [design]="'tertiary'" [type]="'button'" (click)="goToModernizationPage()">
                {{ 'prodmain.card.apply-button.label' | translate }}
            </db-button>
        </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 card">
        <img src="./assets/img/products/newhousing.jpg" />
        <div class="card-block">
            <div>{{ 'prodmain.card.purchase-build.title' | translate }}</div>
            <div>
                {{ 'prodmain.card.purchase-build.description' | translate }}
            </div>
            <db-button [design]="'tertiary'" [type]="'button'" (click)="goToPurchasePage()">
                {{ 'prodmain.card.apply-button.label' | translate }}
            </db-button>
        </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 card">
        <img src="./assets/img/products/rescheduling.jpg" />
        <div class="card-block">
            <div>{{ 'financing-type.debt-rescheduling' | translate }}</div>
            <div>
                {{ 'prodmain.card.debt-rescheduling.description' | translate }}
            </div>
            <db-button [design]="'tertiary'" [type]="'button'" (click)="goToReschedulingPage()">
                {{ 'prodmain.card.apply-button.label' | translate }}
            </db-button>
        </div>
    </div>
</div>
<div *ngIf="!isBuildingLoanSelectionNeeded" class="row">
    <div class="col-12">
        <div class="bottom-card">
            {{ 'footer.supplier-notice-db-1' | translate }}<br />
            {{ 'footer.supplier-notice-db-2' | translate }}<br />
            {{ 'footer.supplier-notice-db-3' | translate }}<br />
            {{ 'footer.supplier-notice-db-4' | translate }}
        </div>
    </div>
</div>