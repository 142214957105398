<div class="nav-bar">
  <div class="row">
    <div class="col-sm-6 col-md-5 col-lg-4">
      <ul>
        <li>
          <app-navi-button [buttonType]="'button'" [navButtonType]="back" [label]="'footer.button.back'"></app-navi-button>
        </li>
        <li *ngIf="!hideNextButton">
          <app-navi-button [buttonType]="'submit'" [navButtonType]="next" [label]="'footer.button.next'"></app-navi-button>
        </li>
        <li>
          <app-navi-button [buttonType]="'button'" [navButtonType]="save" [label]="'footer.button.save'"></app-navi-button>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Modal PopUp Cntent templates -->
<ng-template #modalSuccessContent>
  <div class="popup-content">
    <div>{{ 'navbar.right.save.popup1' | translate }}</div>
    <div *ngIf="!isBhw()">{{ 'navbar.right.save.popup2.db' | translate }}</div>
    <div *ngIf="isBhw()">{{ 'navbar.right.save.popup2.bhw' | translate }}</div>
    <div>{{ 'navbar.right.save.popup3' | translate }}</div>
    <div class="popup-email">
      {{saveEmail}}
    </div>
  </div>
</ng-template>