<div class="ui-control-label">
    {{ 'bhw-building-loan-selection.label' | translate }}
</div>

<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card h-100" (click)="isBsvSelected = true">
            <p>
                {{ 'bhw-building-loan-selection.card.with-building-loan-contract.title' | translate }}
            </p>
            <div [ngClass]="{ isBsvSelectedBGcolor: isBsvSelected === true }">
                {{ 'bhw-building-loan-selection.card.with-building-loan-contract.text' | translate }}
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card h-100" (click)="isBsvSelected = false">
            <p>
                {{ 'bhw-building-loan-selection.card.without-building-loan-contract.title' | translate }}
            </p>
            <div [ngClass]="{ isBsvSelectedBGcolor: isBsvSelected === false }" class="h-100">
                {{ 'bhw-building-loan-selection.card.without-building-loan-contract.text' | translate }}
            </div>
        </div>
    </div>
</div>

<div [ngClass]="{ 'is-visible': invalid }" class="db-validation-errors__message">
    <span class="text-danger"><db-icon>info</db-icon>&nbsp;{{ 'bhw-building-loan-selection.Error.message.text' | translate }}</span>
</div>
