<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'loan-check-1.financing-type.headline' | translate }}
  </div>
  <div class="row">
    <div class="col-12 ui-control">
      <db-radio-group formControlName="options">
        <db-radio-button *ngFor="let item of radioButtonArray" (change)="typeChange($event)" [model]="item.value" [ngClass]="{ 'disabled-radio': item.disabled }">
          {{ item.label }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="options" [messages]="errorMessages"> </db-validation-errors>
    </div>
  </div>

  <div class="ui-control-label">
    {{ questionLabel | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-9 col-lg-9 ui-control">
      <db-slider [step]="500" [min]="minValue('finanzierungsbedarf')" [max]="maxValue()" [unit]="'€'" [(model)]="finanzierungsbedarf"> </db-slider>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3 ui-control">
      <db-input [(model)]="finanzierungsbedarf" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
    </div>
  </div>

  <div class="ui-control-label">
    {{ 'loan-check-1.equity.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-9 col-lg-9 ui-control">
      <db-slider [step]="500" [min]="minValue('eigenkapitalOhneBausparen')" [max]="maxValue()" [unit]="'€'" [(model)]="eigenkapitalOhneBausparen"> </db-slider>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3 ui-control">
      <db-input [(model)]="eigenkapitalOhneBausparen" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
    </div>
  </div>
</form>
