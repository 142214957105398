import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  DBCheckboxModule,
  DBDocsService,
  DBIconModule,
  DBIconService,
  DBInputModule,
  DBLoadingOverlayModule,
  DBModalModule,
  DBModalService,
  DBPopoverModule,
  DBRadioButtonModule,
  DBSelectModule,
  DBSliderModule,
  DBSwitchModule,
  DBTabsModule,
  DBToggleableModule,
  DBValidationModule,
  DBInputDateModule
} from '@db/db-core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationSelectionComponent } from './application-selection/application-selection.component';
import { ConclusionComponent } from './conclusion/conclusion.component';
import { AuthService } from './core/auth.service';
import { EnumOptionsService } from './core/enum-options.service';
import { ThemeService } from './core/theme.service';
import { TranslateListService } from './core/translate-list.service';
import { FooterBarComponent } from './footer/footer-bar/footer-bar.component';
import { NaviButtonBarComponent } from './footer/navi-button-bar/navi-button-bar.component';
import { RouteGuard } from './guard/route-guard';
import { HeaderBannerComponent } from './header/header-banner/header-banner.component';
import { HeaderTabComponent } from './header/header-tab/header-tab.component';
import { BankAccountComponent } from './personal-data/bank-account/bank-account.component';
import { PersonalAdditionalIncomeSecondComponent } from './personal-data/personal-additional-income-second/personal-additional-income-second.component';
import { PersonalAdditionalIncomeComponent } from './personal-data/personal-additional-income/personal-additional-income.component';
import { PersonalAdditionalOutgoingsSecondComponent } from './personal-data/personal-additional-outgoings-second/personal-additional-outgoings-second.component';
import { PersonalAdditionalOutgoingsComponent } from './personal-data/personal-additional-outgoings/personal-additional-outgoings.component';
import { PersonalEmailNationalitySecondComponent } from './personal-data/personal-email-nationality-second/personal-email-nationality-second.component';
import { PersonalEmailNationalityComponent } from './personal-data/personal-email-nationality/personal-email-nationality.component';
import { PersonalFamilySecondComponent } from './personal-data/personal-family-second/personal-family-second.component';
import { PersonalFamilyComponent } from './personal-data/personal-family/personal-family.component';
import { PersonalFortuneSecondComponent } from './personal-data/personal-fortune-second/personal-fortune-second.component';
import { PersonalFortuneComponent } from './personal-data/personal-fortune/personal-fortune.component';
import { PersonalIncomeSecondComponent } from './personal-data/personal-income-second/personal-income-second.component';
import { PersonalIncomeComponent } from './personal-data/personal-income/personal-income.component';
import { PersonalNameBirthTaxSecondComponent } from './personal-data/personal-name-birth-tax-second/personal-name-birth-tax-second.component';
import { PersonalNameBirthTaxComponent } from './personal-data/personal-name-birth-tax/personal-name-birth-tax.component';
import { PersonalOutgoingsSecondComponent } from './personal-data/personal-outgoings-second/personal-outgoings-second.component';
import { PersonalOutgoingsComponent } from './personal-data/personal-outgoings/personal-outgoings.component';
import { PersonalResidentialSecondComponent } from './personal-data/personal-residential-second/personal-residential-second.component';
import { PersonalResidentialComponent } from './personal-data/personal-residential/personal-residential.component';
import { PersonalWorkSecondComponent } from './personal-data/personal-work-second/personal-work-second.component';
import { PersonalWorkComponent } from './personal-data/personal-work/personal-work.component';
import { PropertyDetailsComponent } from './personal-data/property-details/property-details.component';
import { PropertyLandRegisterComponent } from './personal-data/property-land-register/property-land-register.component';
import { PropertyModernizationComponent } from './personal-data/property-modernization/property-modernization.component';
import { PropertyPlotComponent } from './personal-data/property-plot/property-plot.component';
import { PropertyComponent } from './personal-data/property/property.component';
import { BhwBuildingLoanSelectionComponent } from './product/bhw-building-loan-selection/bhw-building-loan-selection.component';
import { LoanCheckOneComponent } from './product/loan-check-one/loan-check-one.component';
import { LoanCheckRepaymentComponent } from './product/loan-check-repayment/loan-check-repayment.component';
import { LoanCheckReschedulingComponent } from './product/loan-check-rescheduling/loan-check-rescheduling.component';
import { LoanCheckSummaryComponent } from './product/loan-check-summary/loan-check-summary.component';
import { LoanCheckTwoComponent } from './product/loan-check-two/loan-check-two.component';
import { ProdmainComponent } from './product/prodmain/prodmain.component';
import { MandantComponent } from './shared/components/mandant/mandant.component';
import { ApplicationDataService } from './shared/services/application-data.service';
import { MandantService } from './shared/services/mandant.service';
import { PopupService } from './shared/services/popup.service';
import { SharedModule } from './shared/shared.module';
import { SummaryStatusComponent } from './summary-status/summary-status.component';
import { FinanzierungscheckSummaryComponent } from './summary/finanzierungscheck-summary/finanzierungscheck-summary.component';
import { AusgabenSummaryComponent } from './summary/haushaltscheck-summary/ausgaben-summary/ausgaben-summary.component';
import { EinnahmenSummaryComponent } from './summary/haushaltscheck-summary/einnahmen-summary/einnahmen-summary.component';
import { HaushaltscheckSummaryComponent } from './summary/haushaltscheck-summary/haushaltscheck-summary.component';
import { VermoegenSummaryComponent } from './summary/haushaltscheck-summary/vermoegen-summary/vermoegen-summary.component';
import { GrundbuchComponent } from './summary/objektdaten-summary/grundbuch/grundbuch.component';
import { LetzteModernisierungComponent } from './summary/objektdaten-summary/letzte-modernisierung/letzte-modernisierung.component';
import { ObjektadresseComponent } from './summary/objektdaten-summary/objektadresse/objektadresse.component';
import { ObjektdatenSummaryComponent } from './summary/objektdaten-summary/objektdaten-summary.component';
import { ObjektdetailsComponent } from './summary/objektdaten-summary/objektdetails/objektdetails.component';
import { ObjektuebersichtComponent } from './summary/objektdaten-summary/objektuebersicht/objektuebersicht.component';
import { BeschaeftigungSummaryComponent } from './summary/persoenlichen-daten-summary/beschaeftigung-summary/beschaeftigung-summary.component';
import { FamilieSummaryComponent } from './summary/persoenlichen-daten-summary/familie-summary/familie-summary.component';
import { GrunddatenSummaryComponent } from './summary/persoenlichen-daten-summary/grunddaten-summary/grunddaten-summary.component';
import { KontaktdatenSummaryComponent } from './summary/persoenlichen-daten-summary/kontaktdaten-summary/kontaktdaten-summary.component';
import { KontodatenSummaryComponent } from './summary/persoenlichen-daten-summary/kontodaten-summary/kontodaten-summary.component';
import { PersoenlichenDatenSummaryComponent } from './summary/persoenlichen-daten-summary/persoenlichen-daten-summary.component';
import { ProduktSummaryComponent } from './summary/produkt-summary/produkt-summary.component';
import { BaufinanzierungComponent } from './summary/sonstige-ausgaben-vermoegen-summary/baufinanzierung/baufinanzierung.component';
import { EigeneImmobilienComponent } from './summary/sonstige-ausgaben-vermoegen-summary/eigene-immobilien/eigene-immobilien.component';
import { KrediteComponent } from './summary/sonstige-ausgaben-vermoegen-summary/kredite/kredite.component';
import { LeasingvertraegeComponent } from './summary/sonstige-ausgaben-vermoegen-summary/leasingvertraege/leasingvertraege.component';
import { SonstigeAusgabenVermoegenSummaryComponent } from './summary/sonstige-ausgaben-vermoegen-summary/sonstige-ausgaben-vermoegen-summary.component';
import { SonstigeVerpflichtungenComponent } from './summary/sonstige-ausgaben-vermoegen-summary/sonstige-verpflichtungen/sonstige-verpflichtungen.component';
import { UnbebauteGrundstueckeComponent } from './summary/sonstige-ausgaben-vermoegen-summary/unbebaute-grundstuecke/unbebaute-grundstuecke.component';
import { VermieteteImmobilienComponent } from './summary/sonstige-ausgaben-vermoegen-summary/vermietete-immobilien/vermietete-immobilien.component';
import { SummaryComponent } from './summary/summary.component';
import { AdobeApiService } from './shared/services/adobe-api.service';

registerLocaleData(localeDe, localeDeExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ProdmainComponent,
    HeaderBannerComponent,
    HeaderTabComponent,
    FooterBarComponent,
    NaviButtonBarComponent,
    LoanCheckOneComponent,
    LoanCheckTwoComponent,
    LoanCheckReschedulingComponent,
    BhwBuildingLoanSelectionComponent,
    LoanCheckRepaymentComponent,
    LoanCheckSummaryComponent,
    MandantComponent,
    PersonalNameBirthTaxComponent,
    PersonalResidentialComponent,
    PersonalWorkComponent,
    PersonalFamilyComponent,
    PersonalIncomeComponent,
    PersonalAdditionalIncomeComponent,
    PersonalOutgoingsComponent,
    PersonalAdditionalOutgoingsComponent,
    PersonalFortuneComponent,
    PersonalEmailNationalityComponent,
    PropertyComponent,
    PropertyPlotComponent,
    PropertyLandRegisterComponent,
    PropertyModernizationComponent,
    PropertyDetailsComponent,
    PersonalNameBirthTaxSecondComponent,
    PersonalEmailNationalitySecondComponent,
    PersonalResidentialSecondComponent,
    PersonalWorkSecondComponent,
    PersonalFamilySecondComponent,
    PersonalIncomeSecondComponent,
    PersonalAdditionalIncomeSecondComponent,
    PersonalOutgoingsSecondComponent,
    PersonalAdditionalOutgoingsSecondComponent,
    PersonalFortuneSecondComponent,
    BankAccountComponent,
    SummaryComponent,
    FinanzierungscheckSummaryComponent,
    ProduktSummaryComponent,
    PersoenlichenDatenSummaryComponent,
    HaushaltscheckSummaryComponent,
    ObjektdatenSummaryComponent,
    SonstigeAusgabenVermoegenSummaryComponent,
    KrediteComponent,
    BaufinanzierungComponent,
    EigeneImmobilienComponent,
    LeasingvertraegeComponent,
    SonstigeVerpflichtungenComponent,
    VermieteteImmobilienComponent,
    UnbebauteGrundstueckeComponent,
    GrunddatenSummaryComponent,
    KontaktdatenSummaryComponent,
    BeschaeftigungSummaryComponent,
    FamilieSummaryComponent,
    KontodatenSummaryComponent,
    GrundbuchComponent,
    ObjektuebersichtComponent,
    ObjektdetailsComponent,
    ObjektadresseComponent,
    LetzteModernisierungComponent,
    EinnahmenSummaryComponent,
    AusgabenSummaryComponent,
    VermoegenSummaryComponent,
    SummaryStatusComponent,
    ApplicationSelectionComponent,
    ConclusionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    DBTabsModule,
    DBIconModule,
    DBModalModule,
    DBRadioButtonModule,
    DBSliderModule,
    DBInputModule,
    DBSelectModule,
    DBSwitchModule,
    DBCheckboxModule,
    DBSelectModule,
    DBValidationModule,
    DBToggleableModule,
    DBLoadingOverlayModule,
    DBPopoverModule,
    DBInputDateModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    TranslateService,
    RouteGuard,
    ThemeService,
    ApplicationDataService,
    EnumOptionsService,
    TranslateListService,
    MandantService,
    PopupService,
    AuthService,
    DBDocsService,
    DBIconService,
    AdobeApiService,
    { provide: LOCALE_ID, useValue: 'de' },
    DBModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
