<div [ngClass]="{ error: error }">
  <db-modal-header-default *ngIf="title" [title]="title | translate" (close)="onClose()"> </db-modal-header-default>

  <db-modal-content-default>
    <div class="popup-content">
      <div *ngIf="contentText">
        {{ contentText | translate }}
      </div>
      <p *ngIf="!contentText && !content">
        Default popup content
      </p>
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </div>

    <div class="row popup-button-bar">
      <div *ngIf="leftButton" class="col">
        <div class="row left">
          <div class="col">
            <db-button design="secondary" [disabled]="isDisabled(leftButton)" (click)="onLeftClick()">
              {{ leftButton.title | translate }}
            </db-button>
          </div>
        </div>
      </div>

      <div *ngIf="centerButton" class="col">
        <div class="row center">
          <div class="col">
            <db-button design="primary" [disabled]="isDisabled(centerButton)" aria-haspopup="true" (click)="onCenterClick()">
              {{ centerButton.title | translate }}
            </db-button>
          </div>
        </div>
      </div>

      <div *ngIf="rightButton" class="col">
        <div class="row right">
          <div class="col">
            <db-button design="primary" [disabled]="isDisabled(rightButton)" (click)="onRightClick()">
              {{ rightButton.title | translate }}
            </db-button>
          </div>
        </div>
      </div>
    </div>
  </db-modal-content-default>
</div>
