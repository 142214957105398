import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DBButtonModule, DBIconModule, DBModalModule, DBSelectModule } from '@db/db-core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AntragComponent } from './components/antrag/antrag.component';
import { NaviButtonComponent } from './components/navi-button/navi-button.component';
import { PopupWindowComponent } from './components/popup-window/popup-window.component';
import { PopupWrapperComponent } from './components/popup-wrapper/popup-wrapper.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DBButtonModule,
    DBModalModule,
    DBIconModule,
    DBSelectModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [PopupWindowComponent, PopupWrapperComponent],
  declarations: [NaviButtonComponent, PopupWindowComponent, PopupWrapperComponent, PopupWrapperComponent, AntragComponent],
  providers: [],
  exports: [CommonModule, FormsModule, NaviButtonComponent, PopupWindowComponent, DBButtonModule]
})
export class SharedModule { }
