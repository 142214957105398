import { Injectable } from '@angular/core';

import { DisabledSelectBoxOption, EnumOptionsService, SelectBoxOption } from '../core/enum-options.service';
import { TranslateListService } from '../core/translate-list.service';
import { InterestPeriod, Nutzungsart, VerwendungsZweck } from '../shared/model/produkt-parameter';

@Injectable({
  providedIn: 'root'
})
export class ProductEnumOptionsService extends EnumOptionsService {
  constructor(translate: TranslateListService) {
    super(translate);
  }

  protected getOptionForEnumKey(typeName, enumKey: string): SelectBoxOption {
    switch (typeName[enumKey]) {
      // Labels for Nutzungsart
      case Nutzungsart.Eigennutzung: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.type-of-usage.personal-usage' };
      }
      case Nutzungsart.Fremdnutzung: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.type-of-usage.third-party-usage' };
      }
      case Nutzungsart.Eigen_und_Fremdnutzung: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.type-of-usage.personal-and-third-party-usage' };
      }

      // Labels for InterestPeriod
      case InterestPeriod.TwentyYears: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.fixed-interest-period.20' };
      }
      case InterestPeriod.FifteenYears: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.fixed-interest-period.15' };
      }
      case InterestPeriod.TenYears: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.fixed-interest-period.10' };
      }
      case InterestPeriod.FiveYears: {
        return { value: typeName[enumKey], label: 'loan-check-repayment.fixed-interest-period.5' };
      }
    }
  }

  protected getDisabledOptionForEnumKey(typeName, enumKey: string, disabledFunc: (value) => boolean): DisabledSelectBoxOption {
    switch (typeName[enumKey]) {
      // Labels for VerwendungsZweck
      case VerwendungsZweck.Modernization: {
        return { value: typeName[enumKey], label: 'financing-type.modernization', disabled: disabledFunc(typeName[enumKey]) };
      }
      case VerwendungsZweck.Rescheduling: {
        return { value: typeName[enumKey], label: 'financing-type.debt-rescheduling', disabled: disabledFunc(typeName[enumKey]) };
      }
      case VerwendungsZweck.NewBuild: {
        return { value: typeName[enumKey], label: 'financing-type.build', disabled: disabledFunc(typeName[enumKey]) };
      }
      case VerwendungsZweck.Purchase: {
        return { value: typeName[enumKey], label: 'financing-type.purchase', disabled: disabledFunc(typeName[enumKey]) };
      }
    }
  }
}
