import { Kind } from './kind';

export enum Anrede {
  Herr = 'HERR',
  Frau = 'FRAU'
}

export enum Titel {
  Prof = 'Prof.',
  Dr = 'Dr.'
}

export enum Wohnen {
  Miete = 'MIETE',
  Eigentum = 'EIGENTUM',
  Eltern = 'ELTERN'
}

export enum DauerdesVertragsverhaeltnissesTyp {
  Befristet = 'limited',
  Unbefristet = 'unlimited',
  Unkuendbar = 'permanent',
  Probezeit = 'trial'
}

export enum Familienstand {
  Ledig = 'LEDIG',
  Verheiratet = 'VERHEIRATET',
  GetrenntLebend = 'GETRENNT_LEBEND',
  Geschieden = 'GESCHIEDEN',
  Verwittwet = 'VERWITWET',
  EheaehnlicheLebensgemeinschaft = 'LEBENSGEMEINSCHAFT',
  EingetrageneLebensgemeinschaft = 'VERPARTNERT'
}

export enum Gueterstand {
  Zugewinngemeinschaft = 'ZUGEWINNGEMEINSCHAFT',
  Guetergemeinschaft = 'GUETERGEMEINSCHAFT',
  Guetertrennung = 'GUETERTRENNUNG',
  Unbekannt = 'UNBEKANNT'
}

export enum Anstellungsverhaeltnis {
  Employee = 'AS_SONSTIGE_ANGESTELLTE',
  PublicEmployee = 'AOE_ANGEST_I_OEFF_DIENST',
  Apprentice = 'SPAS_SONSTIGE_AUSZUBILDENDE',
  PublicServiceApprentice = 'SPAOE_AUSZUBILDENDE_IM_OEFFL_DIENST',
  CivilServant = 'BA_BEAMTE',
  GermanArmedForces = 'BW_BUNDESWEHR_ARMEE_SOLDATEN',
  GermaArmedForcesRetired = 'BWAD_BUNDESWEHR_ARMEE_SOLDATEN_A_D',
  Freelancers = 'SFB_FREIE_BERUFE',
  Housewife = 'SPH_HAUSFRAUEN',
  ChildcPupilStudent = 'SPKS_KINDER_SCHUELER_STUDENTEN',
  WageEarner = 'LS_SONSTIGE_LOHNEMPFAENGER',
  PublicSectorWageEarners = 'LOE_LOHNEMPF_I_OEFF_DIENST',
  NatoEntourage = 'GFAM_NATO_GEFOLGE_U_FAM_ANGEH',
  NatoTroops = 'BNAT_NATO_TRUPPEN',
  Pensioner = 'BAAD_PENSIONAERE',
  RetireePerson = 'R_RENTNER',
  SelfEmployedPerson = 'S_SELBSTAENDIGE',
  IndependentAgricultureForestry = 'SLF_SELBST_IN_LAND_U_FORSTW'
}

export class Personendaten {
  anrede: Anrede;
  titel: Titel;
  vorname: string;
  nachname: string;
  geburtsname: string;
  geburtsdatum: Date;
  geburtsort: string;
  geburtsland: string;
  steuerid: string;
  email: string;
  staatsangehoerigkeit: string;
  zweiteStaatsangehoerigkeitUSA: boolean;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  bundesland: string;
  telefonnummerVorwahl: string;
  telefonnummerDurchwahl: string;
  wohnverhaeltnis: Wohnen;
  anstellungsverhaeltnis: Anstellungsverhaeltnis;
  dauerdesVertragsverhaeltnissesTyp: DauerdesVertragsverhaeltnissesTyp;
  beschaeftigtSeit: Date;
  beschaeftigtBis: Date;
  arbeitgeber: string;
  beruf: string;
  familienstand: Familienstand;
  gueterstand: Gueterstand;
  kinder: Kind[];
  branche: string;

  constructor(person?: Personendaten) {
    this.anrede = person ? person.anrede : null;
    this.titel = person ? person.titel : null;
    this.vorname = person ? person.vorname : null;
    this.nachname = person ? person.nachname : null;
    this.geburtsname = person ? person.geburtsname : null;
    this.geburtsdatum = person && person.geburtsdatum ? new Date(person.geburtsdatum) : null;
    this.geburtsort = person ? person.geburtsort : null;
    this.geburtsland = person ? person.geburtsland : null;
    this.steuerid = person ? person.steuerid : null;
    this.email = person ? person.email : null;
    this.staatsangehoerigkeit = person ? person.staatsangehoerigkeit : null;
    this.zweiteStaatsangehoerigkeitUSA = person ? person.zweiteStaatsangehoerigkeitUSA : null;
    this.strasse = person ? person.strasse : null;
    this.hausnummer = person ? person.hausnummer : null;
    this.plz = person ? person.plz : null;
    this.ort = person ? person.ort : null;
    this.bundesland = person ? person.bundesland : null;
    this.telefonnummerVorwahl = person ? person.telefonnummerVorwahl : null;
    this.telefonnummerDurchwahl = person ? person.telefonnummerDurchwahl : null;
    this.wohnverhaeltnis = person ? person.wohnverhaeltnis : null;
    this.anstellungsverhaeltnis = person ? person.anstellungsverhaeltnis : null;
    this.dauerdesVertragsverhaeltnissesTyp = person ? person.dauerdesVertragsverhaeltnissesTyp : null;
    this.beschaeftigtSeit = person && person.beschaeftigtSeit ? new Date(person.beschaeftigtSeit) : null;
    this.beschaeftigtBis = person && person.beschaeftigtBis ? new Date(person.beschaeftigtBis) : null;
    this.arbeitgeber = person ? person.arbeitgeber : null;
    this.beruf = person ? person.beruf : null;
    this.familienstand = person ? person.familienstand : null;
    this.gueterstand = person ? person.gueterstand : null;
    this.kinder = new Array();
    if (person && person.kinder && person.kinder.length > 0) {
      person.kinder.forEach((kind, index) => {
        this.kinder[index] = new Kind(kind);
      });
    }
    this.branche = person ? person.branche : null;
  }
}
