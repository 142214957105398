<form [formGroup]="formValidation">
  <div class="ui-control-label ">
    {{ 'personal-work.work-situation.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-select
        [ngClass]="{
          'not-selected': !personendaten.anstellungsverhaeltnis
        }"
        placeholder="{{ 'personal-work.work-situation.type.placeholder' | translate }}"
        label="{{ 'personal-work.work-situation.label' | translate }}"
        formControlName="anstellungsverhaeltnis"
        [options]="anstellungsverhaeltnisList"
        [(model)]="personendaten.anstellungsverhaeltnis"
      >
      </db-select>
      <db-validation-errors for="anstellungsverhaeltnis" [messages]="errors.anstellungsverhaeltnis"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="isEmploymentFilled && isContractDurationVisible">
      <db-select
        [ngClass]="{
          'not-selected': !personendaten.dauerdesVertragsverhaeltnissesTyp
        }"
        label="{{ 'personal-work.work-situation.contractual-relationship.label' | translate }}"
        placeholder="{{ 'personal-work.work-situation.contractual-relationship.placeholder' | translate }}"
        formControlName="dauerdesVertragsverhaeltnissesTyp"
        [options]="dauerdesVertragsverhaeltnissesTypOptions"
        [(model)]="personendaten.dauerdesVertragsverhaeltnissesTyp"
      >
      </db-select>
      <db-validation-errors for="dauerdesVertragsverhaeltnissesTyp" [messages]="errors.dauerdesVertragsverhaeltnissesTyp"></db-validation-errors>
    </div>
  </div>

  <div class="row" *ngIf="isEmploymentFilled && isWorkVisible">
    <div class="col-sm-12 col-md-6 ui-control">
    <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='beschaeftigtSeit' (modelChange)="beschaeftigtSeitChange($event)"
        label="{{ 'personal-work.employed-since.placeholder' | translate }}"></db-input-date>
      <db-validation-errors for="beschaeftigtSeit" [messages]="errors.beschaeftigtSeit"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="isEmploymentFilled && !isUnlimited">
    <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='beschaeftigtBis' (modelChange)="beschaeftigtBisChange($event)"
        label="{{ 'personal-work.employed-until.placeholder' | translate }}"></db-input-date>
      <db-validation-errors for="beschaeftigtBis" [messages]="errors.beschaeftigtBis"></db-validation-errors>
    </div>
  </div>
  <div class="row" *ngIf="isEmploymentFilled && isWorkVisible">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input
        autocomplete="off"
        formControlName="arbeitgeber"
        [(model)]="personendaten.arbeitgeber"
        label="{{ 'personal-work.work-situation.name-of-employer.placeholder' | translate }}"
      >
      </db-input>
      <db-validation-errors for="arbeitgeber" [messages]="errors.arbeitgeber"></db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" formControlName="beruf" [(model)]="personendaten.beruf" label="{{ 'personal-work.work-situation.name-of-profession.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="beruf" [messages]="errors.beruf"></db-validation-errors>
    </div>
  </div>

  <div class="row" *ngIf="isEmploymentFilled">
    <div class="col-md-12">
      <div class="ui-control-label">
        {{ 'personal-work.retirement.label' | translate }}
      </div>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="!isRetired">
    <db-input-date dbDateTimePicker [dbDateTimeConfig]="datePickerConfig" formControlName='regulaererRentenEintritt'  disabled="true"
        label="{{ 'personal-work.retirement-age.label' | translate }}"></db-input-date>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="!isRetired">
    <db-input-date dbDateTimePicker [dbDateTimeConfig]="geplanterRentenEintrittConfig" formControlName='geplanterRentenEintritt' (modelChange)="geplanterRentenEintrittChange($event)"
        label="{{ 'personal-work.planned-retirement-age.placeholder' | translate }}"></db-input-date>
      <db-validation-errors for="geplanterRentenEintritt" [messages]="errors.geplanterRentenEintritt"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-6 ui-control" *ngIf="isRetired">
    <db-input-date dbDateTimePicker [dbDateTimeConfig]="tatsaechlichRentenEintrittConfig" formControlName='tatsaechlichRentenEintritt' (modelChange)="geplanterRentenEintrittChange($event)"
        label="{{ 'personal-work.retirement.retired-since.placeholder' | translate }}"></db-input-date>
      <db-validation-errors for="tatsaechlichRentenEintritt" [messages]="errors.tatsaechlichRentenEintritt"> </db-validation-errors>
    </div>
  </div>
</form>
