import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { TranslateListService } from '../../core/translate-list.service';
import { Kind } from '../../shared/model/kind';
import { Familienstand, Gueterstand } from '../../shared/model/personendaten';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';
import { BaseComponent } from './../../core/base.component';
import { OAPValidators } from './../../core/validators.directive';
import { ApplicationDataService } from './../../shared/services/application-data.service';
import { NavButtonBarService } from './../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-personal-family',
  templateUrl: './personal-family.component.html',
  styleUrls: ['./personal-family.component.scss'],
  providers: [FormBuilder]
})
export class PersonalFamilyComponent extends BaseComponent implements OnInit {
  private FAMILY_MATERIAL_STATE_VALUES: Familienstand[] = [Familienstand.GetrenntLebend, Familienstand.Verheiratet];

  public familienstandArray: any[] = [];
  public gueterstandArray: any[] = [];
  public isGueterstandVisible = false;

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private enumService: PersonalDataEnumOptionsService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  errors: any = {
    familienstand: [
      {
        rule: 'required',
        message: 'personal-family.family-circumstances.hint1'
      }
    ],
    gueterstand: [
      {
        rule: 'required',
        message: 'personal-family.matrimonial-estate.hint'
      }
    ],
    kinderGeburtsdatum: [
      {
        rule: 'required',
        message: 'personal-family.children-birthdate.format.hint'
      },
      {
        rule: 'date',
        message: 'general.input.wrong-date-format.hint',
        fieldName: 'Geburtsdatum',
        dateFormat: OAPValidators.DATE_FORMAT
      }
    ]
  };

  ngOnInit() {
    super.ngOnInit();
    this.isGueterstandVisible = this.checkForGuterstand();
    this.familienstandArray = this.enumService.getOptionsFor(Familienstand);
    this.gueterstandArray = this.enumService.getOptionsFor(Gueterstand);
    this.formSettings();
    this.headerService.changeText('personal-family.headline');
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_family", "personal data");
  }

  // public template methods
  public addKind() {
    if (!this.formValidation.invalid) {
      this.kinder.push(new Kind());
      this.addKindFormControls(this.kinder.length - 1);
    } else {
      DBValidatorsUtil.markFormAsDirtyAndTouched(this.formValidation);
    }
  }
  public removeKind(index: number) {
    this.kinder.splice(index, 1);
    this.removeKindFormControls(index);
  }
  public dateChange($event, index) {
    this.kinder[index].geburtsDatum = this.dateService.getDateModelFromDateUI($event);
  }
  getKinderGeburtstag(index: number): Date {
    if (this.kinder[index] && this.kinder[index].geburtsDatum) {
      return this.dateService.getDateUIFromModel(this.kinder[index].geburtsDatum);
    }
  }
  // private form builder methods
  private formSettings() {
    this.formValidation = this.formBuilder.group({
      familienstand: new FormControl(this.familienstand, DBValidators.required()),
      gueterstand: new FormControl(
        this.gueterstand,
        OAPValidators.conditionRequiredValidator(() => {
          return this.FAMILY_MATERIAL_STATE_VALUES.includes(this.familienstand);
        })
      ),
      kinder: new FormArray([])
    });
    this.initKinderFormGroup();
  }

  private initKinderFormGroup() {
    this.kinder.forEach(kind => this.addKindFormControls(this.kinder.indexOf(kind)));
  }
  private removeKindFormControls(index: number) {
    (this.formValidation.controls.kinder as FormArray).removeAt(index);
  }
  private addKindFormControls(index: number) {
    const requiredValidator = OAPValidators.conditionRequiredValidator(() => this.kinder.length > 0);
    (this.formValidation.controls.kinder as FormArray).push(
      this.formBuilder.group({
        geburtsDatum: [this.getKinderGeburtstag(index), DBValidatorsUtil.chain([requiredValidator, OAPValidators.dateFormat()])],
        erhaltKindergeld: [this.kinder[index].erhaltKindergeld, requiredValidator]
      })
    );
  }

  // private visibility method
  private checkForGuterstand() {
    if (this.familienstand && this.FAMILY_MATERIAL_STATE_VALUES.includes(this.familienstand)) {
      return true;
    } else {
      this.gueterstand = undefined;
      return false;
    }
  }

  // getter & setter
  get kinderFormArray() {
    return (this.formValidation.controls.kinder as FormArray).controls;
  }
  get kinder() {
    return this.personendaten.kinder;
  }
  get familienstand() {
    return this.personendaten.familienstand;
  }
  set familienstand(familienstand: Familienstand) {
    this.personendaten.familienstand = familienstand;
    this.isGueterstandVisible = this.checkForGuterstand();
  }
  get gueterstand() {
    return this.personendaten.gueterstand;
  }
  set gueterstand(gueterstand: Gueterstand) {
    this.personendaten.gueterstand = gueterstand;
  }
}
