import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { PopUpButton } from '../popup-window/popup-window.component';

@Component({
  selector: 'app-popup-wrapper',
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.scss']
})
export class PopupWrapperComponent implements OnInit {
  public title: string;
  public leftButton: PopUpButton;
  public centerButton: PopUpButton;
  public rightButton: PopUpButton;
  public contentText: string;
  public content: TemplateRef<any>;
  public error: boolean;
  public closeClick: () => {};

  @ViewChild('modalTemplate', {
    read: TemplateRef,
    static: true
  })
  public modalTemplateRef: TemplateRef<any>;
  constructor() {}

  ngOnInit() {}

  public onClose() {
    if (this.closeClick) {
      this.closeClick();
    }
  }
}
