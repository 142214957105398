import { Adresse } from './adresse';
import { Ausgaben } from './ausgaben';
import { Darlehensnehmer } from './darlehensnehmer';
import { Anrede, Anstellungsverhaeltnis } from './personendaten';
import { Produkt } from './produkt';
import { Telefonnummer } from './telefonnummer';

export enum AnfrageAnstellungsverhaeltnis {
  ANGESTELLT = 'ANGESTELLT',
  SELBSTSTAENDIG = 'SELBSTSTAENDIG'
}

export class Kunde {
  adresse: Adresse;
  anrede: Anrede;
  anstellungsVerhaeltnis: AnfrageAnstellungsverhaeltnis;
  anzahlKinder: number;
  ausgabenMonatlichOhneMiete: number;
  eigenKapital: number;
  email: string;
  geburtsDatum: Date;
  nachname: string;
  nettoEinkommenMonatlich: number;
  telefonMobil: Telefonnummer;
  telefonPrivat: Telefonnummer;
  vorname: string;

  constructor(darlehensnehmer: Darlehensnehmer, produkt: Produkt) {
    this.adresse = new Adresse();
    if (darlehensnehmer && darlehensnehmer.personendaten) {
      const persData = darlehensnehmer.personendaten;
      this.adresse.hausnummer = persData.hausnummer ? persData.hausnummer : null;
      this.adresse.ort = persData.ort ? persData.ort : null;
      this.adresse.postleitzahl = persData.plz ? persData.plz : null;
      this.adresse.strasse = persData.strasse ? persData.strasse : null;

      this.anrede = persData.anrede ? persData.anrede : Anrede.Herr;
      this.vorname = persData.vorname ? persData.vorname : '';
      this.nachname = persData.nachname ? persData.nachname : '';
      this.geburtsDatum = persData.geburtsdatum ? new Date(persData.geburtsdatum) : null;
      this.anstellungsVerhaeltnis = Kunde.getAnstellungsVerhaeltnis(persData.anstellungsverhaeltnis);
      this.anzahlKinder = persData.kinder.length ? persData.kinder.length : 0;
      this.email = persData.email ? persData.email : '';
      if (persData.telefonnummerVorwahl && persData.telefonnummerVorwahl) {
        this.telefonPrivat = new Telefonnummer(persData.telefonnummerVorwahl, persData.telefonnummerDurchwahl);
      } else {
        this.telefonPrivat = undefined;
      }
    } else {
      this.adresse = undefined;
      this.anrede = undefined;
      this.vorname = undefined;
      this.nachname = undefined;
      this.geburtsDatum = undefined;
      this.anstellungsVerhaeltnis = undefined;
      this.anzahlKinder = undefined;
      this.email = null;
      this.telefonPrivat = undefined;
    }

    if (produkt && produkt.produktParameter && produkt.produktParameter.eigenkapitalOhneBausparen) {
      this.eigenKapital = produkt.produktParameter.eigenkapitalOhneBausparen;
    } else {
      this.eigenKapital = 0;
    }

    if (
      darlehensnehmer &&
      darlehensnehmer.haushaltsrechnung &&
      darlehensnehmer.haushaltsrechnung.einnahmen &&
      darlehensnehmer.haushaltsrechnung.einnahmen.regelmaessigesNettoeinkommen
    ) {
      this.nettoEinkommenMonatlich = darlehensnehmer.haushaltsrechnung.einnahmen.regelmaessigesNettoeinkommen;
    } else {
      this.nettoEinkommenMonatlich = 0;
    }
    if (darlehensnehmer && darlehensnehmer.haushaltsrechnung && darlehensnehmer.haushaltsrechnung.ausgaben) {
      this.ausgabenMonatlichOhneMiete = this.calculateAusgaben(darlehensnehmer.haushaltsrechnung.ausgaben);
    } else {
      this.ausgabenMonatlichOhneMiete = 0;
    }
  }

  public static getAnstellungsVerhaeltnis(anstellungsVerhaeltnis: Anstellungsverhaeltnis): AnfrageAnstellungsverhaeltnis {
    if (anstellungsVerhaeltnis !== undefined) {
      if (
        anstellungsVerhaeltnis === Anstellungsverhaeltnis.SelfEmployedPerson || // 'S_SELBSTAENDIGE'
        anstellungsVerhaeltnis === Anstellungsverhaeltnis.IndependentAgricultureForestry // 'SLF_SELBST_IN_LAND_U_FORSTW'
      ) {
        return AnfrageAnstellungsverhaeltnis.SELBSTSTAENDIG; //'SELBSTSTAENDIG';
      } else {
        return AnfrageAnstellungsverhaeltnis.ANGESTELLT; //'ANGESTELLT';
      }
    } else {
      return null;
    }
  }

  private calculateAusgaben(ausgabenParameter: Ausgaben): number {
    let ausgaben = 0;
    if (ausgabenParameter.praemieKrankenversicherung) {
      ausgaben = ausgaben + ausgabenParameter.praemieKrankenversicherung;
    }
    if (ausgabenParameter.unterhaltszahlungen) {
      ausgaben = ausgaben + ausgabenParameter.unterhaltszahlungen;
    }
    if (ausgabenParameter.sonstAusgabenBetrag) {
      ausgaben = ausgaben + ausgabenParameter.sonstAusgabenBetrag;
    }
    ausgaben = ausgaben + this.calculateAusgabenFromKostenArray(ausgabenParameter.ratenkredit);
    ausgaben = ausgaben + this.calculateAusgabenFromKostenArray(ausgabenParameter.leasing);
    ausgaben = ausgaben + this.calculateAusgabenFromKostenArray(ausgabenParameter.baufinanzierung);
    ausgaben = ausgaben + this.calculateAusgabenFromKostenArray(ausgabenParameter.baufinanzierungAndereBank);
    ausgaben = ausgaben + this.calculateAusgabenFromKostenArray(ausgabenParameter.sonstMonatlicheKosten);
    return ausgaben;
  }

  private calculateAusgabenFromKostenArray(kostenArray): number {
    let ausgaben = 0;
    if (kostenArray && kostenArray instanceof Array) {
      for (const kosten of kostenArray) {
        if (kosten && kosten.monatlicheKosten) {
          ausgaben = ausgaben + parseFloat(kosten.monatlicheKosten);
        }
      }
    }
    return ausgaben;
  }
}
