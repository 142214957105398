import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SerializerService {
  static ignoreList: string[] = [];
  constructor() { }

  serialize(input: any): any {
    if (input === undefined) {
      return null;
    }
    let result: any = null;

    // Handle simple types: string, number, null, undefined
    if (null == input || 'object' !== typeof input) {
      return this.serializeResult(input);
    }

    // Handle Date
    if (input instanceof Date) {
      const userTimezoneOffset = input.getTimezoneOffset() * 60000;
      return this.serializeResult(input.getTime()) - userTimezoneOffset;
    }

    // Handle Array
    if (input instanceof Array) {
      result = [];
      input.forEach((item, index) => {
        result[index] = this.serialize(item);
      });
      return this.serializeResult(result);
    }

    // Handle Object
    if (input instanceof Object) {
      result = {};
      for (const attr in input) {
        if (input.hasOwnProperty(attr) && SerializerService.ignoreList.indexOf(attr) === -1) {
          if (attr.startsWith('_')) {
            result[attr.substr(1)] = this.serialize(input[attr]);
          } else {
            result[attr] = this.serialize(input[attr]);
          }
        }
      }
      return this.serializeResult(result);
    }
    console.error('Unable to serialize object %o', input);
    throw new Error('Unable to serialize object: ' + input.toStrong());
  }

  private serializeResult(result: any) {
    try {
      return JSON.parse(JSON.stringify(result));
    } catch (error) {
      console.error('Error by serialize of %o', result, error);
    }
  }
}

export function Ignore(target: any, propertyKey: string) {
  SerializerService.ignoreList.push(propertyKey);
}
