import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';

export interface ConditionalRequiredSettings {
  /**
   * Name of control to check
   */
  controlNames: string[];

  // either AND conditions
  andConditions?: {
    /**
     * Name of control on which the values of the required validation depends.
     */
    conditionalControlName: string;
    /**
     * Values of conditional control, for which the control are not required.
     */
    conditionalNotRequiredValues?: any[];
  }[];

  // or OR conditions
  orConditions?: {
    /**
     * Name of control on which the values of the required validation depends.
     */
    conditionalControlName: string;
    /**
     * Values of conditional control, for which the control are not required.
     */
    conditionalNotRequiredValues?: any[];
  }[];
}

export class OAPValidators {
  public static readonly DATE_FORMAT = 'DD.MM.YYYY';
  /**
   * Custom form validator for conditional required validation (dependent on values from othew contorl)
   * @param requiredSettings - settings for conditional required check
   */
  public static conditionalRequiredValuesFormValidator(requiredSettings: ConditionalRequiredSettings[]) {
    return (formGroup: FormGroup) => OAPValidators.conditionalRequiredFormValidator(formGroup, requiredSettings);
  }

  /**
   * Custom form control validator for conditional required validation (dependent on one condition: flag or boolean callback)
   * @param conditionRequired - condition for required (boolean or callback)
   */
  public static conditionRequiredValidator(conditionRequired?: boolean | (() => boolean)): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (control.errors && !control.errors.required) {
          return;
        }
        let isRequired: boolean;
        if (typeof conditionRequired === 'boolean') {
          isRequired = conditionRequired;
        } else if (typeof conditionRequired === 'function') {
          isRequired = conditionRequired();
        }
        return isRequired ? Validators.required(control) : null;
      } catch (e) {
        return null;
      }
    };
  }

  /**
   * Custom form control date validator for check: is before?
   * @param baseDateInput - date for before check
   */
  public static isDateBeforeValidator(baseDateInput?: Date | (() => Date)): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const baseDate: Date = typeof baseDateInput === 'function' ? baseDateInput() : baseDateInput;
      const now = moment(control.value, this.DATE_FORMAT).startOf('day');
      const minDate = moment(baseDate, this.DATE_FORMAT).startOf('day');
      if (control.value !== undefined && control.value !== null && baseDate !== undefined && baseDate !== null && now.isBefore(minDate)) {
        return { pastDate: true };
      }
      return null;
    };
  }

  public static dateFormat(format?: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      format = format ? format : this.DATE_FORMAT;
      if (control && control.value && !moment(control.value, format, true).isValid()) {
        return { date: true };
      }
      return null;
    };
  }

  /**
   * Custom form control date validator for check: is after?
   * @param baseDateInput - date for after check
   */
  public static isDateAfterValidator(baseDateInput?: Date | (() => Date)): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const baseDate: Date = typeof baseDateInput === 'function' ? baseDateInput() : baseDateInput;
      const now = moment(control.value, this.DATE_FORMAT).startOf('day');
      const maxDate = moment(baseDate, this.DATE_FORMAT).startOf('day');
      if (control.value !== undefined && control.value !== null && baseDate !== undefined && baseDate !== null && now.isAfter(maxDate)) {
        return { futureDate: true };
      }
      return null;
    };
  }

  /**
   * Custom form control validator for check : is multiply thousand?
   * @param control - co
   */
  public static multiplyThousandValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== null && control.value % 1000 !== 0) {
      return { multiplyThousand: true };
    }
    return null;
  }

  /**
   *
   *  Custom form control validator for match check : is the same value?
   * @param controlName - name of control to be checked
   * @param matchingControlName - name of control for match check
   */
  public static matchValidatort(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.match) {
        return;
      }

      if (matchingControl.value && control.value && control.value !== matchingControl.value) {
        matchingControl.setErrors({ match: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  // private help methods
  private static conditionalRequiredFormValidator(formGroup: FormGroup, requiredSettings: ConditionalRequiredSettings[]) {
    requiredSettings.forEach((requiredSetting: ConditionalRequiredSettings) => {
      requiredSetting.controlNames.forEach((controlName: string) => {
        this.setRequiredError(formGroup, controlName, requiredSetting);
      });
    });
  }

  private static setRequiredError(formGroup: FormGroup, controlName: string, requiredSetting: ConditionalRequiredSettings) {
    const orCombination = requiredSetting.andConditions ? false : true;
    const currentConditions = requiredSetting.andConditions ? requiredSetting.andConditions : requiredSetting.orConditions;
    const controlToCheck = formGroup.controls[controlName];
    currentConditions.forEach(condition => {
      const conditionalControl = formGroup.controls[condition.conditionalControlName];

      // if other errors do nothing
      if (controlToCheck.errors && !controlToCheck.errors.required) {
        return;
      }
      // is valid => reset all required errors
      if (controlToCheck.value) {
        controlToCheck.setErrors(null);
        return;
      }

      // continue if not valid and there are only reqiured errors or nor errors
      const customRequiredError = {
        required: { value: true, conditionalFieldName: condition.conditionalControlName }
      };
      let isRequired = false;

      if (!condition.conditionalNotRequiredValues || condition.conditionalNotRequiredValues.length === 0) {
        // no values => required for any values
        isRequired =
          conditionalControl.value !== null &&
          conditionalControl.value !== undefined &&
          conditionalControl.value !== '' &&
          conditionalControl.value !== 0 &&
          conditionalControl.value !== '0';
      } else {
        // there are values => required only for special values, that are not included in the array
        // if conditional control value is not defined go back: nothing todo
        if (!conditionalControl.value) {
          return;
        }
        isRequired = conditionalControl.value && !condition.conditionalNotRequiredValues.includes(conditionalControl.value);
      }

      if (isRequired) {
        // add a new rquired error
        controlToCheck.setErrors(customRequiredError);
      } else if (controlToCheck.errors) {
        // filter the errors, that belong to the required condition (if OR condition)
        // filter the all required errors (if AND condition)
        let errors: ValidationErrors;
        Object.keys(controlToCheck.errors).forEach((errorKey: any) => {
          const isNotBelongTo = controlToCheck.errors[errorKey].conditionalFieldName !== condition.conditionalControlName;
          const isNotRequiredError = errorKey !== 'required';
          if (isNotRequiredError || (orCombination && isNotBelongTo)) {
            if (!errors) {
              errors = {};
            }
            errors[errorKey] = controlToCheck.errors[errorKey];
          }
        });
        controlToCheck.setErrors(errors);
      }
    });
  }
}
