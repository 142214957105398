import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { OAPValidators } from 'src/app/core/validators.directive';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import { VerwendungsZweck } from '../../shared/model/produkt-parameter';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { MandantService } from '../../shared/services/mandant.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';
import { ProductEnumOptionsService } from '../product-enum-options.service';

@Component({
  selector: 'app-loan-check-rescheduling',
  templateUrl: './loan-check-rescheduling.component.html',
  styleUrls: ['./loan-check-rescheduling.component.scss']
})
export class LoanCheckReschedulingComponent extends BaseComponent implements OnInit, AfterContentChecked {
  public radioButtonVerwendungsZweck: any[] = [];
  public radioButtonAntragnehmer: any[] = [];
  public errors: any;
  public umschuldungsdatumConfig: any;

  private isBHW: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private enumService: ProductEnumOptionsService,
    dateService: DateService,
    private mandantService: MandantService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isBHW = this.isMandantBhw(this.mandantService);
    this.initErrors();
    this.umschuldungsdatumConfig = this.getDatePickerConfig(this.calculateMinUschuldungsdatum(), this.calculateMaxUmschuldungsdatum());
    this.radioButtonVerwendungsZweck = this.enumService.getDisabledOptionsFor(VerwendungsZweck, this.isVerwendungZweckOptionDisable.bind(this));
    this.radioButtonAntragnehmer = this.getRadioButtonAntragnehmer();
    this.produktParameter.eigenkapitalOhneBausparen = 0;
    this.produktParameter.eigenleistungen = 0;
    this.formValidation = this.formBuilder.group({
      anzahlAntragnehmer: new FormControl(this.antrag.anzahlAntragnehmer, DBValidators.required()),
      verwendungsZweck: new FormControl(this.getVerwendungsZweckOptionValue(this.verwendungsZweck), DBValidators.required()),
      umschuldungsdatumFormControl: new FormControl(
        this.umschuldungsdatum,
        DBValidatorsUtil.chain([
          DBValidators.required(),
          OAPValidators.dateFormat(),
          OAPValidators.isDateBeforeValidator(() => this.calculateMinUschuldungsdatum()),
          OAPValidators.isDateAfterValidator(() => this.calculateMaxUmschuldungsdatum())
        ])
      )
    });
    this.translateErrors(this.errors);
    console.log(this.umschuldungsdatumConfig.maxDate);
    this.adobe.pushAdobeEvent("Loan_check_rescheduling", "loan data");
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  private getRadioButtonAntragnehmer() {
    return [
      { name: '1', value: 1 },
      { name: '2', value: 2 }
    ];
  }

  get finanzierungsbedarf(): number {
    return this.convertToDBCore(this.produkt.produktParameter.finanzierungsbedarf);
  }

  set finanzierungsbedarf(finanzierungsbedarf: number) {
    this.produkt.produktParameter.finanzierungsbedarf = +finanzierungsbedarf > this.maxValue() ? this.maxValue() : +finanzierungsbedarf;
  }

  updateanzahlAntragnehmer($event) {
    this.antrag.anzahlAntragnehmer = this.convertFromDBCore($event.target.value, 'number');
    if (this.antrag.anzahlAntragnehmer < 2) {
      this.clearNebensdarlehensnehmer();
    }
  }

  get umschuldungsdatum() {
    if (this.produkt.produktParameter.umschuldungsdatum) {
      return this.convertToDBCore(this.produkt.produktParameter.umschuldungsdatum);
    }
    return;
  }

  set umschuldungsdatum(umschuldungsdatum: Date) {
    this.appDataService.setUmschulungsdatum(this.dateService.getDateModelFromDateUI(umschuldungsdatum));
  }

  umschuldungsdatumChange($event) {
    this.umschuldungsdatum = $event;
  }

  minValue() {
    return 10000;
  }

  maxValue() {
    return 750000;
  }

  private initErrors() {
    this.errors = {
      verwendungszweckValidation: [
        {
          rule: 'required',
          message: 'financing-type.hint'
        }
      ],
      umschuldungsdatumValidation: [
        {
          rule: 'required',
          message: 'loan-check-rescheduling.date.required.hint',
          minDate: this.calculateMinUschuldungsdatum(),
          maxDate: this.calculateMaxUmschuldungsdatum()
        },
        {
          rule: 'pastDate',
          message: 'loan-check-rescheduling.date.past-date.hint'
        },
        {
          rule: 'futureDate',
          message: 'loan-check-rescheduling.date.future-date.hint',
          maxDatum: this.calculateMaxUmschuldungsdatum()
        },
        {
          rule: 'date',
          message: 'general.input.wrong-date-format.hint',
          fieldName: 'Umschuldungsdatum',
          dateFormat: OAPValidators.DATE_FORMAT
        }
      ],
      anzahlAntragnehmerValidation: [
        {
          rule: 'required',
          message: 'loan-check-2.number-of-debtors.hint'
        }
      ]
    };
  }

  private calculateMinUschuldungsdatum(): Date {
    const minUmschuldungsdatum = moment().add(1, 'day');
    return this.dateService.getDateUIFromModel(minUmschuldungsdatum.toDate());
  }

  private calculateMaxUmschuldungsdatum(): Date {
    let maxUmschuldungsdatum: any;
    if (!this.isBHW) {
      maxUmschuldungsdatum = moment().add(4, 'years');
    } else {
      maxUmschuldungsdatum = moment()
        .add(4, 'years')
        .add(9, 'months');
    }
    return this.dateService.getDateUIFromModel(maxUmschuldungsdatum.toDate());
  }

  //overriden
  protected validationPopupIntern(): boolean {
    if (this.appDataService.berechneFinanzierungsbedarf() < 10000) {
      this.openValidationPopupWindow('alert.loan-too-low.text');
      return false;
    }
    return true;
  }
}
