import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ThemeService } from '../../../core/theme.service';
import * as routes from '../../../routing-path';
import { MandantService } from '../../services/mandant.service';

@Component({
  selector: 'app-mandant',
  templateUrl: './mandant.component.html',
  styleUrls: ['./mandant.component.scss']
})
export class MandantComponent implements OnInit {
  constructor(private router: Router, private themeService: ThemeService, private mandantService: MandantService) { }

  ngOnInit() {
    this.mandantService.initializeMandant();
    this.themeService.switchTheme();
    this.router.navigate([routes.PROD_MAIN_PATH]);
  }
}
