import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { ThemeService } from 'src/app/core/theme.service';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { environment } from 'src/environments/environment';

import * as routes from '../../../routing-path';
import { Antrag, AntragStatus } from '../../model/antrag';
import { ProdMainTyp } from '../../model/application-data';
import { VerwendungsZweck } from '../../model/produkt-parameter';
import { ApplicationDataService } from '../../services/application-data.service';
import { DateService } from '../../services/date.service';
import { HeaderService } from '../../services/header.service';
import { Mandant } from '../../services/mandant.service';
import { NavButtonBarService } from '../../services/nav-button-bar.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-antrag',
  templateUrl: './antrag.component.html',
  styleUrls: ['./antrag.component.scss']
})
export class AntragComponent extends BaseComponent implements OnInit {
  dbVGENummer = environment.vgeNumberDB;
  constructor(
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private router: Router,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    const antragId = this.route.snapshot.paramMap.get('antragId');
    let accessToken;
    if (antragId) {
      if (this.router.url.indexOf('access_token=') !== -1) {
        const accessTokenFromUrl = this.route.snapshot.queryParamMap.get('access_token');
        accessToken = accessTokenFromUrl ? accessTokenFromUrl : this.router.url.split('access_token=')[1].split('&')[0];
      }
      this.initAntrag(antragId, accessToken);
    } else {
      console.log('No antragId: GO TO PROD_MAIN');
      this.router.navigate([routes.PROD_MAIN_PATH]);
    }
  }

  private checkAntragStatus(antragId: string) {
    this.appDataService.getAntragStatus(antragId).subscribe((status: AntragStatus) => {
      let currPath = routes.PROD_MAIN_PATH;
      if (!status) {
        console.log('No status: GO TO PROD_MAIN');
      } else if (status === AntragStatus.InBearbeitung) {
        console.log('Status InBearbeitung...');
        currPath = routes.calculateNextPath(routes.PROD_MAIN_PATH, false, this.applicationData);
      } else {
        console.log('Status %s...', status);
        currPath = routes.SUMMARY_STATUS_PATH;
      }
      console.log('GO TO %s', currPath);
      this.goToApplication(currPath);
    });
  }

  private initAntrag(antragId: string, accessToken: any) {
    this.appDataService.loadAntrag(antragId, accessToken).subscribe(antragFromService => {
      this.appDataService.applicationData.antrag = new Antrag(antragFromService);
      console.log('Loaded antrag=%o', this.appDataService.applicationData.antrag);
      console.log('Loaded antragId=%o', this.appDataService.antragId);
      this.initTheme();
      this.applicationData.prodmainTyp = this.getProdmainTyp();
      this.checkAntragStatus(antragId);
    });
  }

  private initTheme() {
    if (this.antrag && this.antrag.vgeNummer) {
      localStorage.setItem('mandant', this.antrag.vgeNummer === this.dbVGENummer ? Mandant.DB : Mandant.BHW);
      this.themeService.switchTheme();
    }
  }

  private getProdmainTyp(): ProdMainTyp {
    const verwendungszweckRescheduling = [VerwendungsZweck.Rescheduling, VerwendungsZweck.InternRescheduling];
    if (this.verwendungsZweck === VerwendungsZweck.Modernization) {
      return ProdMainTyp.Modernization;
    } else if (verwendungszweckRescheduling.indexOf(this.verwendungsZweck) >= 0) {
      return ProdMainTyp.Rescheduling;
    } else {
      return ProdMainTyp.PurchaseNewBuild;
    }
  }

  private goToApplication(currPath: string) {
    this.router.navigate([currPath]);
    this.navBarService.currentPath = currPath;
  }
}
