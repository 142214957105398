import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Kernsanierung } from 'src/app/shared/model/objekt-wert';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

@Component({
  selector: 'app-property-modernization',
  templateUrl: './property-modernization.component.html',
  styleUrls: ['./property-modernization.component.scss'],
  providers: [FormBuilder]
})
export class PropertyModernizationComponent extends BaseComponent implements OnInit {
  modernisationsList: any[] = [];
  kernsanierungList: any[] = [];
  private readonly limitDate = '1900';
  jahrLetzteModMassnahme: number = undefined;

  errors: any = {
    jahrLetzteModMassnahme: [
      {
        rule: 'pastdate',
        message: 'property-modernization.last-modernization.time.hint'
      },
      {
        rule: 'pastdatelimit',
        message: 'general.field.pastdatelimit.hint',
        fieldName: 'Letzte Modernisierung',
        limitDate: this.limitDate
      },
      {
        rule: 'minlength',
        message: 'property-modernization.last-modernization.format.hint'
      },
      { rule: 'required', message: 'property-modernization.last-modernization.empty.hint' }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valuesService: ValuesService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initModernisations();
    this.jahrLetzteModMassnahme = this.getDateYearAsNumber(this.antrag.objektWert.jahrLetzteModMassnahme);
    this.headerService.changeText('property-modernization.headline');
    this.translateErrors(this.errors);
    this.formSettings();
    this.adobe.pushAdobeEvent("Property_moderization", "property data");
  }

  private formSettings() {
    this.formValidation = this.formBuilder.group({
      kernsanierung: new FormControl(this.objektWert.kernsanierung),
      jahrLetzteModMassnahme: new FormControl(this.jahrLetzteModMassnahme, [DBValidators.minLength(4), DBValidators.pastDate('YYYY', this.limitDate)]),
      dacherneuerung: new FormControl(this.objektWert.dacherneuerung),
      daemmungAussenwaende: new FormControl(this.objektWert.daemmungAussenwaende),
      modernisierungBaeder: new FormControl(this.objektWert.modernisierungBaeder),
      modernisierungHeizung: new FormControl(this.objektWert.modernisierungHeizung),
      modernisierungInnenausbau: new FormControl(this.objektWert.modernisierungInnenausbau),
      verbesserungFenster: new FormControl(this.objektWert.verbesserungFenster),
      verbesserungGrundriss: new FormControl(this.objektWert.verbesserungGrundriss)
    });
  }

  public hasKernsanierung(fieldName?: string): boolean {
    const hasKernsanierung = this.objektWert.kernsanierung === Kernsanierung.Umfassend;
    if (hasKernsanierung && fieldName) {
      this.formValidation.controls[fieldName].disable();
    } else if (fieldName) {
      this.formValidation.controls[fieldName].enable();
    }
    return hasKernsanierung;
  }

  public hasModernisierung(): boolean {
    const hasModernisierung =
      this.wasModernized('dacherneuerung') ||
      this.wasModernized('daemmungAussenwaende') ||
      this.wasModernized('modernisierungBaeder') ||
      this.wasModernized('modernisierungHeizung') ||
      this.wasModernized('modernisierungInnenausbau') ||
      this.wasModernized('verbesserungFenster') ||
      this.wasModernized('verbesserungGrundriss');
    if (hasModernisierung) {
      this.formValidation.controls.kernsanierung.disable();
    } else {
      this.formValidation.controls.kernsanierung.enable();
    }
    return hasModernisierung;
  }

  private initModernisations() {
    this.valuesService.loadModernisations().subscribe(modernisationsList => {
      this.modernisationsList = Object.assign([], modernisationsList);
      this.kernsanierungList = Object.assign([], modernisationsList).filter(item => item.value === Kernsanierung.Umfassend || item.value === Kernsanierung.Null);
    });
  }

  protected validationFormIntern(): boolean {
    if (this.checkIsFormYearInvalid() || this.checkIsFormTypeInvalid()) {
      DBValidatorsUtil.markFormAsDirtyAndTouched(this.formValidation);
      return false;
    }
    this.objektWert.jahrLetzteModMassnahme = this.getDateFromYear(this.jahrLetzteModMassnahme);
    return true;
  }

  checkIsFormYearInvalid(): boolean {
    return !this.jahrLetzteModMassnahme && (this.hasKernsanierung() || this.hasModernisierung());
  }

  checkIsFormTypeInvalid(): boolean {
    return this.jahrLetzteModMassnahme && !(this.hasKernsanierung() || this.hasModernisierung());
  }

  private wasModernized(field: string) {
    return this.objektWert[field] !== undefined && this.objektWert[field] !== null && this.objektWert[field] !== 'NULL' && this.objektWert[field] !== '';
  }
}
