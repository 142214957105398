<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.object-overview.object-type.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="objektArt === 'EINFAMILIENHAUS_DOPPELHAUSHAELFTE'">{{'property.object-type.single-family-house-semi-detached-house' | translate}}</span>
                <span *ngIf="objektArt === 'EINFAMILIENHAUS_FREISTEHEND'">{{'property.object-type.freestanding-single-family-house' | translate}}</span>
                <span *ngIf="objektArt === 'EINFAMILIENHAUS_REIHENMITTELHAUS'">{{'property.object-type.single-family-house-terraced-middle-house' | translate}}</span>
                <span *ngIf="objektArt === 'EINFAMILIENHAUS_REIHENENDHAUS'">{{'property.object-type.detached-house-terraced-house' | translate}}</span>
                <span *ngIf="objektArt === 'ZWEIFAMILIENHAUS_REIHENENDHAUS'">{{'property.object-type.detached-two-family-house-terraced-house' | translate}}</span>
                <span *ngIf="objektArt === 'ZWEIFAMILIENHAUS_REIHENMITTELHAUS'">{{'property.object-type.two-family-house-terraced-middle-house' | translate}}</span>
                <span *ngIf="objektArt === 'ZWEIFAMILIENHAUS_FREISTEHEND'">{{'property.object-type.freestanding-two-family-house' | translate}}</span>
                <span
                    *ngIf="objektArt === 'EIN_ZWEIFAMILIENHAUS_BIS_33_PROZENT_GEWERBE'">{{'property.object-type.one-family-house-and-two-family-house-up-to-33-percent-commercial' | translate}}</span>
                <span
                    *ngIf="objektArt === 'ERTRAGSWERTOBJEKT_MEHRFAMILIENHAUS_AB_DREI_MIETER'">{{'property.object-type.apartment-house-with-three-or-more-tenants' | translate}}</span>
                <span *ngIf="objektArt === 'WOHNHAUS_MFH_BIS_33_PROZENT_GEWERBE'">{{'property.object-type.multi-family-house-up-to-33-percent-commercial' | translate}}</span>
                <span *ngIf="objektArt === 'EIGENTUMSWOHNUNG'">{{'property.object-type.condo-apartment' | translate}}</span>
                <span *ngIf="objektArt === 'UNBEBAUTES_GRUNDSTUECK'">{{'property.object-type.undeveloped-property' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.object-overview.property-shares.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{antrag.objektWert.mitEigentumsAnteil}}/{{antrag.objektWert.gesamtEigentumsAnteile}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.object-overview.number-of-residential-units.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{anzahlWohneinheiten}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.object-overview.prefab.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="fertighaus">{{'general.yes' | translate}}</span>
                <span *ngIf="!fertighaus">{{'general.no' | translate}}</span>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.object-overview.usage-type.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <span *ngIf="nutzungsart === 'EIGENNUTZUNG'">{{'property.usage-type.personal-use' | translate}}</span>
                <span *ngIf="nutzungsart === 'FREMDNUTZUNG'">{{'property.usage-type.third-party-usage' | translate}}</span>
                <span *ngIf="nutzungsart === 'EIGEN_UND_FREMDNUTZUNG'">{{'property.usage-type.personal-and-third-party-usage' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{'summary.object-data.object-overview.purchase-price.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{kaufpreis | currency: 'EUR'}}
            </div>
        </div>

    </div>
</div>