<form [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'personal-name-birth-tax.salutation-selection.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 ui-control">
      <db-radio-group formControlName="anrede">
        <db-radio-button *ngFor="let item of anredeOptions" (change)="updateAnrede($event)" [model]="item.value">
          {{ item.label }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="anrede" [messages]="errors.anredeValidation"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-4">
      <db-select [ngClass]="{
          'not-selected': !personendaten.titel
        }" class="ui-control" [(model)]="personendaten.titel" placeholder="{{ 'general.select.placeholder' | translate }}"
        label="{{ 'personal-name-birth-tax.title-selection.label' | translate }}" [options]="titleOptions"></db-select>
    </div>
  </div>

  <div class="ui-control-label">
    {{ 'personal-name-birth-tax.name.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 ui-control">
      <db-input formControlName="vorname" [(model)]="personendaten.vorname" type="string" autocomplete="off"
        label="{{ 'personal-name-birth-tax.name.first-name.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="vorname" [messages]="errors.vornameValidation"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-4 ui-control">
      <db-input formControlName="nachname" [(model)]="personendaten.nachname" type="string" autocomplete="off"
        label="{{ 'personal-name-birth-tax.name.last-name.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="nachname" [messages]="errors.nachnameValidation"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-4 ui-control">
      <db-input [(model)]="personendaten.geburtsname" type="string" autocomplete="off" label="{{ 'personal-name-birth-tax.name.maiden-name.placeholder' | translate }}"> </db-input>
    </div>
  </div>

  <div class="ui-control-label">
    {{ 'personal-name-birth-tax.birth.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 ui-control">
    <db-input-date dbDateTimePicker formControlName='geburtsdatum' (modelChange)="geburtsdatumChange($event)" label="{{ 'personal-name-birth-tax.birth.date.placeholder' | translate }}"></db-input-date>
      <db-validation-errors for="geburtsdatum" [messages]="errors.geburtsdatumValidation"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-4 ui-control">
      <db-input formControlName="geburtsort" [(model)]="personendaten.geburtsort" type="string" autocomplete="off"
        label="{{ 'personal-name-birth-tax.birth.place.placeholder' | translate }}">
      </db-input>
      <db-validation-errors for="geburtsort" [messages]="errors.geburtsortValidation"> </db-validation-errors>
    </div>
    <div class="col-sm-12 col-md-4 ui-control">
      <db-select formControlName="geburtsland" [ngClass]="{
          'not-selected': !personendaten.geburtsland || personendaten.geburtsland === ''
        }" [(model)]="personendaten.geburtsland" placeholder="{{ 'general.select.placeholder' | translate }}"
        label="{{ 'personal-name-birth-tax.birth.country.label' | translate }}" [options]="countryList" (change)="countryChange($event)">
      </db-select>
      <db-validation-errors for="geburtsland" [messages]="errors.geburtslandValidation"> </db-validation-errors>
    </div>
  </div>

  <div class="ui-control-label">
    {{ 'personal-name-birth-tax.tax-id.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4">
      <db-input formControlName="steuerId" [(model)]="personendaten.steuerid" type="string" autocomplete="off"
        label="{{ 'personal-name-birth-tax.tax-id.placeholder' | translate }}"> </db-input>
      <db-validation-errors for="steuerId" [messages]="errors.steuerIdValidation"> </db-validation-errors>
    </div>
  </div>
</form>