<div class="toggle-container">
    <db-intro-preview>
        <db-toggleable id="objdSum0" [label]="'summary.object-data.object-overview.label' | translate" [open]="toggleObjektdatenArray[0]" iconPosition="right"
            (openChange)="toggleObjektdatenClick(0)">
            <app-objektuebersicht></app-objektuebersicht>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="objdSum1" [label]="'summary.object-data.object-address.label' | translate" [open]="toggleObjektdatenArray[1]" iconPosition="right"
            (openChange)="toggleObjektdatenClick(1)">
            <app-objektadresse></app-objektadresse>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="objdSum2" [label]="'summary.object-data.land-register.label' | translate" [open]="toggleObjektdatenArray[2]" iconPosition="right"
            (openChange)="toggleObjektdatenClick(2)">
            <app-grundbuch></app-grundbuch>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="objdSum3" [label]="'summary.object-data.last-modernization.label' | translate" [open]="toggleObjektdatenArray[3]" iconPosition="right"
            (openChange)="toggleObjektdatenClick(3)">
            <app-letzte-modernisierung></app-letzte-modernisierung>
        </db-toggleable>
    </db-intro-preview>
    <db-intro-preview>
        <db-toggleable id="objdSum4" [label]="'summary.object-data.object-details.label' | translate" [open]="toggleObjektdatenArray[4]" iconPosition="right"
            (openChange)="toggleObjektdatenClick(4)">
            <app-objektdetails>
            </app-objektdetails>
        </db-toggleable>
    </db-intro-preview>
</div>