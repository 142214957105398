<form #myForm="ngForm" [formGroup]="formValidation">
  <div class="ui-control-label">
    {{ 'loan-check-2.personal-contributions.label' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-9 col-lg-9  ui-control">
      <db-slider [step]="500" [min]="minValue()" [max]="maxValue()" [unit]="'€'" [(model)]="eigenleistungen"> </db-slider>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3 ui-control">
      <db-input [(model)]="eigenleistungen" type="number" autocomplete="off" label="{{ 'general.input.number.label' | translate }}"> </db-input>
    </div>
  </div>
  <div class="ui-control-label">
    {{ 'loan-check-2.other-financial-resources.title' | translate }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-9 col-lg-9 ui-control">
      <db-input type="text" [(model)]="sonstDarlehenText" label="{{ 'loan-check-2.other-financial-resources.type-input.placeholder' | translate }}" autocomplete="off"> </db-input>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3">
      <db-input [(model)]="sonstDarlehenBetrag" type="number" autocomplete="off" formControlName="sonstigeDarlehen" label="{{ 'general.input.number.label' | translate }}">
      </db-input>
      <db-validation-errors for="sonstigeDarlehen" [messages]="errors.sonstigeDarlehen"> </db-validation-errors>
    </div>
  </div>

  <div class="ui-control-label">
    {{ 'loan-check-2.number-of-debtors.label' | translate }}
  </div>
  <div class="ui-control">
    <db-radio-group formControlName="anzahloptions" [model]="anzahlAntragnehmer">
      <db-radio-button *ngFor="let item of anzahlAntragnehmerRadioArray" (change)="anzahlAntragnehmerChange($event)" [model]="item.value">
        {{ item.name | translate }}
      </db-radio-button>
    </db-radio-group>
    <db-validation-errors for="anzahloptions" [messages]="errors.anzahloptions"> </db-validation-errors>
  </div>
  <div *ngIf="isBaufiOptionsVisible">
    <div class="ui-control-label">
      {{ 'loan-check-2.previous-loan.label' | translate }}
    </div>
    <div class="ui-control">
      <db-radio-group formControlName="baufinaOptions">
        <db-radio-button *ngFor="let item of BaufinaRadioArray" (change)="baufinaChange($event)" [model]="item.value">
          {{ item.label | translate }}
        </db-radio-button>
      </db-radio-group>
      <db-validation-errors for="baufinaOptions" [messages]="errors.baufinaOptions"> </db-validation-errors>
    </div>
  </div>
</form>