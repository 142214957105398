<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4>{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div class="row" *ngIf="haushaltsrechnung.ausgaben.eigenmieteKalt">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.own-rent-cold.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.eigenmieteKalt | currency: 'EUR' }}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.ausgaben.eigenmieteNebenkosten">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.own-rent-additional-costs.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.eigenmieteNebenkosten | currency: 'EUR' }}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.ausgaben.praemieKrankenversicherung">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.private-health-insurance.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.praemieKrankenversicherung | currency: 'EUR' }}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.ausgaben.unterhaltszahlungen">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.alimony-payments.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.unterhaltszahlungen | currency: 'EUR' }}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.ausgaben.sonstAusgabenBetrag">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.other-expenses.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6" *ngIf="haushaltsrechnung.ausgaben.sonstAusgabenText && haushaltsrechnung.ausgaben.sonstAusgabenBetrag">
                {{ haushaltsrechnung.ausgaben.sonstAusgabenText }} {{ haushaltsrechnung.ausgaben.sonstAusgabenBetrag | currency: 'EUR' }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.living-costs.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.lebensHaltungsKosten | currency: 'EUR' }}
            </div>
        </div>
        <div class="row" *ngIf="haushaltsrechnung.ausgaben.freiwilligeSparLeistungen">
            <div class="col-sm-12 col-lg-6">
                {{ 'summary.personal-data.household-check.personal-outgoings.voluntary-savings.prefix' | translate }}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.freiwilligeSparLeistungen | currency: 'EUR' }}
            </div>
        </div>
        <!-- commenting according to https://gitlab.widas.de/deutsche-bank/oap/general/-/issues/557#note_818959 -->
        <!-- <div class="row" *ngIf="haushaltsrechnung.ausgaben.kfzAnzahl">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.other-expenditure-and-assets.number.of.vehicle.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.kfzAnzahl}}
            </div>
        </div> -->
        <!-- <div class="row" *ngIf="haushaltsrechnung.ausgaben.kfzKosten">
            <div class="col-sm-12 col-lg-6">
                {{'summary.personal-data.other-expenditure-and-assets.vehicle.cost.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-lg-6">
                {{ haushaltsrechnung.ausgaben.kfzKosten| currency: 'EUR' }}
            </div>
        </div> -->

        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr />
            <h4>{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
            <div class="row" *ngIf="haushaltsrechnungSecond.ausgaben.eigenmieteKalt">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.own-rent-cold.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6" *ngIf="haushaltsrechnungSecond.ausgaben.eigenmieteKalt">
                    {{ haushaltsrechnungSecond.ausgaben.eigenmieteKalt | currency: 'EUR' }}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.ausgaben.eigenmieteNebenkosten">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.own-rent-additional-costs.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6" *ngIf="haushaltsrechnungSecond.ausgaben.eigenmieteNebenkosten">
                    {{ haushaltsrechnungSecond.ausgaben.eigenmieteNebenkosten | currency: 'EUR' }}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.ausgaben.praemieKrankenversicherung">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.private-health-insurance.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{ haushaltsrechnungSecond.ausgaben.praemieKrankenversicherung | currency: 'EUR' }}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.ausgaben.unterhaltszahlungen">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.alimony-payments.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{ haushaltsrechnungSecond.ausgaben.unterhaltszahlungen | currency: 'EUR' }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.other-expenses.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6" *ngIf="haushaltsrechnungSecond.ausgaben.sonstAusgabenText && haushaltsrechnungSecond.ausgaben.sonstAusgabenBetrag">
                    {{ haushaltsrechnungSecond.ausgaben.sonstAusgabenText }} {{ haushaltsrechnungSecond.ausgaben.sonstAusgabenBetrag | currency: 'EUR' }}
                </div>
                <div class="col-sm-12 col-lg-6" *ngIf="!haushaltsrechnungSecond.ausgaben.sonstAusgabenBetrag">
                    {{ 'summary.personal-data.household-check.personal-outgoings.no-other-expenses.suffix' | translate }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.living-costs.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6">
                    {{ haushaltsrechnungSecond.ausgaben.lebensHaltungsKosten | currency: 'EUR' }}
                </div>
            </div>
            <div class="row" *ngIf="haushaltsrechnungSecond.ausgaben.freiwilligeSparLeistungen">
                <div class="col-sm-12 col-lg-6">
                    {{ 'summary.personal-data.household-check.personal-outgoings.voluntary-savings.prefix' | translate }}
                </div>
                <div class="col-sm-12 col-lg-6" *ngIf="haushaltsrechnungSecond.ausgaben.freiwilligeSparLeistungen">
                    {{ haushaltsrechnungSecond.ausgaben.freiwilligeSparLeistungen | currency: 'EUR' }}
                </div>
            </div>
        </div>
    </div>
</div>