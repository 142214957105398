import { Ausgaben } from './ausgaben';
import { Einnahmen } from './einnahmen';
import { Vermoegen } from './vermoegen';

export class Haushaltsrechnung {
  einnahmen: Einnahmen;
  ausgaben: Ausgaben;
  vermoegen: Vermoegen;

  constructor(haushaltsrechnung?: Haushaltsrechnung) {
    this.einnahmen = haushaltsrechnung ? new Einnahmen(haushaltsrechnung.einnahmen) : new Einnahmen();
    this.ausgaben = haushaltsrechnung ? new Ausgaben(haushaltsrechnung.ausgaben) : new Ausgaben();
    this.vermoegen = haushaltsrechnung ? new Vermoegen(haushaltsrechnung.vermoegen) : new Vermoegen();
  }
}
