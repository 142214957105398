export class BsvDaten {
  monatlicherSparbetrag: number;
  sparplanLaufzeitMonate: number;
  bsvTarif: string;
  sparplanSollzins: number;
  sparplanEffektivzins: number;
  teilbausparSumme: number;
  bausparSumme: number;
  sparAbschlussGebuehr: number;
  guthabenBeiZuteilung: number;
  sparplanAbDatum: Date;
  sparplanSumme: number;

  constructor(bsvDaten?: BsvDaten) {
    this.sparplanSollzins = bsvDaten ? bsvDaten.sparplanSollzins : 0;
    this.sparplanEffektivzins = bsvDaten ? bsvDaten.sparplanEffektivzins : 0;
    this.sparAbschlussGebuehr = bsvDaten ? bsvDaten.sparAbschlussGebuehr : 0;
    this.sparplanAbDatum = bsvDaten && bsvDaten.sparplanAbDatum ? new Date(bsvDaten.sparplanAbDatum) : null;
    this.sparplanLaufzeitMonate = bsvDaten ? bsvDaten.sparplanLaufzeitMonate : null;
    this.sparplanSumme = bsvDaten ? bsvDaten.sparplanSumme : 0;
    this.bsvTarif = bsvDaten ? bsvDaten.bsvTarif : null;
    this.monatlicherSparbetrag = bsvDaten ? bsvDaten.monatlicherSparbetrag : 0;
    this.guthabenBeiZuteilung = bsvDaten ? bsvDaten.guthabenBeiZuteilung : 0;
    this.bausparSumme = bsvDaten ? bsvDaten.bausparSumme : 0;
    this.teilbausparSumme = bsvDaten ? bsvDaten.teilbausparSumme : 0;
  }
}
