import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Tab {
  Product = 'product',
  PersonalData = 'personal-data',
  Summary = 'summary',
  Completion = 'completion'
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private titleKeySource = new BehaviorSubject('navbar.headline');
  currentTitle = this.titleKeySource.asObservable();
  private textKeySource = new BehaviorSubject('start-produkt.headline');
  currentText = this.textKeySource.asObservable();
  private activeTabSource = new BehaviorSubject(Tab.Product);
  currentTab = this.activeTabSource.asObservable();

  constructor() { }

  changeTitle(titleKey: string) {
    this.titleKeySource.next(titleKey);
  }

  changeTab(tabId: Tab) {
    this.activeTabSource.next(tabId);
  }

  changeText(textKey: string) {
    this.textKeySource.next(textKey);
  }
}
