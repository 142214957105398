import { ApplicationData } from './shared/model/application-data';
import { VerwendungsZweck } from './shared/model/produkt-parameter';

export const ANTRAG_PATH = 'antrag';
export const MANDANT_PATH = 'mandant';
export const PROD_MAIN_PATH = 'prodmain';
export const LOAN_CHECK_ONE_PATH = 'loan-check-1';
export const LOAN_CHECK_TWO_PATH = 'loan-check-2';
export const LOAN_CHECK_RESCHEDULING_PATH = 'loan-check-rescheduling';
export const BHW_BUILDING_LOAN_SELECTION_PATH = 'bhw-building-loan-selection';
export const LOAN_CHECK_REPAYMENT_PATH = 'loan-check-repayment';
export const LOAN_CHECK_SUMMARY_PATH = 'loan-check-summary';
export const PERSONAL_NAME_BIRTH_TAX_PATH = 'personal-name-birth-tax';
export const PERSONAL_EMAIL_NATIONALITY_PATH = 'personal-email-nationality';
export const PERSONAL_RESIDENTIAL_PATH = 'personal-residential';
export const PERSONAL_WORK_PATH = 'personal-work';
export const PERSONAL_FAMILY_PATH = 'personal-family';
export const PERSONAL_INCOME_PATH = 'personal-income';
export const PERSONAL_ADDITIONAL_INCOME_PATH = 'personal-additional-income';
export const PERSONAL_OUTGOINGS_PATH = 'personal-outgoings';
export const PERSONAL_ADDITIONAL_OUTGOINGS_PATH = 'personal-additional-outgoings';
export const PERSONAL_FORTUNE_PATH = 'personal-fortune';
export const PERSONAL_NAME_BIRTH_TAX_SECOND_PATH = 'personal-name-birth-tax-second';
export const PERSONAL_EMAIL_NATIONALITY_SECOND_PATH = 'personal-email-nationality-second';
export const PERSONAL_RESIDENTIAL_SECOND_PATH = 'personal-residential-second';
export const PERSONAL_WORK_SECOND_PATH = 'personal-work-second';
export const PERSONAL_FAMILY_SECOND_PATH = 'personal-family-second';
export const PERSONAL_INCOME_SECOND_PATH = 'personal-income-second';
export const PERSONAL_ADDITIONAL_INCOME_SECOND_PATH = 'personal-additional-income-second';
export const PERSONAL_OUTGOINGS_SECOND_PATH = 'personal-outgoings-second';
export const PERSONAL_ADDITIONAL_OUTGOINGS_SECOND_PATH = 'personal-additional-outgoings-second';
export const PERSONAL_FORTUNE_SECOND_PATH = 'personal-fortune-second';
export const PROPERTY_PATH = 'property';
export const PROPERTY_PLOT_PATH = 'property-plot';
export const PROPERTY_LAND_REGISTER_PATH = 'property-land-register';
export const PROPERTY_MODERNIZATION_PATH = 'property-modernization';
export const PROPERTY_DETAILS_PATH = 'property-details';
export const BANK_ACCOUNT_PATH = 'bank-account';
export const SUMMARY_PATH = 'summary';
export const SUMMARY_STATUS_PATH = 'summary-status';
export const APPLICATION_SELECTION_PATH = 'application-selection';
export const CONCLUSION_PATH = 'bhw-conclusion';

export const LOGIN_PATH = 'login';
export const SLASH = '/';
export const PARAM = SLASH + ':';
export const OTHERS = '**';
export const DEFAULT_PATH = '';

export function calculateNextPath(currentPath: string, isBuildingLoanSelectionNeeded: boolean, appData: ApplicationData): string {
  const verwendungszweck: VerwendungsZweck = appData.antrag.produkt.produktParameter.verwendungszweck;
  const verwendungsZweckUmschuldung = [VerwendungsZweck.InternRescheduling, VerwendungsZweck.Rescheduling];
  if (!currentPath || currentPath.trim() === DEFAULT_PATH) {
    currentPath = PROD_MAIN_PATH;
  }
  if (currentPath.substring(0, 1) === SLASH) {
    currentPath = currentPath.substring(1);
  }
  console.log('Calculate NEXT PATH: for currentPath = %s', currentPath);
  let nextPath = DEFAULT_PATH;
  switch (currentPath) {
    case PROD_MAIN_PATH: {
      if (verwendungszweck === VerwendungsZweck.Modernization) {
        nextPath = LOAN_CHECK_ONE_PATH;
      } else {
        if (verwendungsZweckUmschuldung.indexOf(verwendungszweck) >= 0) {
          nextPath = isBuildingLoanSelectionNeeded ? BHW_BUILDING_LOAN_SELECTION_PATH : LOAN_CHECK_RESCHEDULING_PATH;
        } else {
          nextPath = isBuildingLoanSelectionNeeded ? BHW_BUILDING_LOAN_SELECTION_PATH : LOAN_CHECK_ONE_PATH;
        }
      }
      break;
    }
    case LOAN_CHECK_ONE_PATH: {
      nextPath = LOAN_CHECK_TWO_PATH;
      break;
    }
    case LOAN_CHECK_TWO_PATH: {
      if (verwendungszweck === VerwendungsZweck.Modernization) {
        nextPath = LOAN_CHECK_SUMMARY_PATH;
      } else {
        nextPath = LOAN_CHECK_REPAYMENT_PATH;
      }
      break;
    }
    case LOAN_CHECK_RESCHEDULING_PATH: {
      nextPath = LOAN_CHECK_REPAYMENT_PATH;
      break;
    }
    case BHW_BUILDING_LOAN_SELECTION_PATH: {
      if (verwendungsZweckUmschuldung.indexOf(verwendungszweck) >= 0) {
        nextPath = LOAN_CHECK_RESCHEDULING_PATH;
      } else {
        nextPath = LOAN_CHECK_ONE_PATH;
      }
      break;
    }
    case LOAN_CHECK_REPAYMENT_PATH: {
      nextPath = LOAN_CHECK_SUMMARY_PATH;
      break;
    }
    case LOAN_CHECK_SUMMARY_PATH: {
      nextPath = PERSONAL_NAME_BIRTH_TAX_PATH;
      break;
    }
    case PERSONAL_NAME_BIRTH_TAX_PATH: {
      nextPath = PERSONAL_EMAIL_NATIONALITY_PATH;
      break;
    }
    case PERSONAL_EMAIL_NATIONALITY_PATH: {
      nextPath = PERSONAL_RESIDENTIAL_PATH;
      break;
    }
    case PERSONAL_RESIDENTIAL_PATH: {
      nextPath = PERSONAL_WORK_PATH;
      break;
    }
    case PERSONAL_WORK_PATH: {
      nextPath = PERSONAL_FAMILY_PATH;
      break;
    }
    case PERSONAL_FAMILY_PATH: {
      nextPath = PERSONAL_INCOME_PATH;
      break;
    }
    case PERSONAL_INCOME_PATH: {
      nextPath = PERSONAL_ADDITIONAL_INCOME_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_INCOME_PATH: {
      nextPath = PERSONAL_OUTGOINGS_PATH;
      break;
    }
    case PERSONAL_OUTGOINGS_PATH: {
      nextPath = PERSONAL_ADDITIONAL_OUTGOINGS_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_OUTGOINGS_PATH: {
      nextPath = PERSONAL_FORTUNE_PATH;
      break;
    }
    case PERSONAL_FORTUNE_PATH: {
      if (appData.antrag.anzahlAntragnehmer === 1) {
        nextPath = verwendungszweck === VerwendungsZweck.Modernization ? BANK_ACCOUNT_PATH : PROPERTY_PATH;
      } else {
        nextPath = PERSONAL_NAME_BIRTH_TAX_SECOND_PATH;
      }
      break;
    }
    case PERSONAL_NAME_BIRTH_TAX_SECOND_PATH: {
      nextPath = PERSONAL_EMAIL_NATIONALITY_SECOND_PATH;
      break;
    }
    case PERSONAL_EMAIL_NATIONALITY_SECOND_PATH: {
      nextPath = PERSONAL_RESIDENTIAL_SECOND_PATH;
      break;
    }
    case PERSONAL_RESIDENTIAL_SECOND_PATH: {
      nextPath = PERSONAL_WORK_SECOND_PATH;
      break;
    }
    case PERSONAL_WORK_SECOND_PATH: {
      nextPath = PERSONAL_FAMILY_SECOND_PATH;
      break;
    }
    case PERSONAL_FAMILY_SECOND_PATH: {
      nextPath = PERSONAL_INCOME_SECOND_PATH;
      break;
    }
    case PERSONAL_INCOME_SECOND_PATH: {
      nextPath = PERSONAL_ADDITIONAL_INCOME_SECOND_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_INCOME_SECOND_PATH: {
      nextPath = PERSONAL_OUTGOINGS_SECOND_PATH;
      break;
    }
    case PERSONAL_OUTGOINGS_SECOND_PATH: {
      nextPath = PERSONAL_ADDITIONAL_OUTGOINGS_SECOND_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_OUTGOINGS_SECOND_PATH: {
      nextPath = PERSONAL_FORTUNE_SECOND_PATH;
      break;
    }
    case PERSONAL_FORTUNE_SECOND_PATH: {
      if (verwendungszweck === VerwendungsZweck.Modernization) {
        nextPath = BANK_ACCOUNT_PATH;
      } else {
        nextPath = PROPERTY_PATH;
      }
      break;
    }
    case PROPERTY_PATH: {
      nextPath = PROPERTY_PLOT_PATH;
      break;
    }
    case PROPERTY_PLOT_PATH: {
      nextPath = PROPERTY_LAND_REGISTER_PATH;
      break;
    }
    case PROPERTY_LAND_REGISTER_PATH: {
      nextPath = PROPERTY_MODERNIZATION_PATH;
      break;
    }
    case PROPERTY_MODERNIZATION_PATH: {
      nextPath = PROPERTY_DETAILS_PATH;
      break;
    }
    case PROPERTY_DETAILS_PATH: {
      nextPath = BANK_ACCOUNT_PATH;
      break;
    }
    case BANK_ACCOUNT_PATH: {
      nextPath = SUMMARY_PATH;
      break;
    }
    case SUMMARY_PATH: {
      nextPath = APPLICATION_SELECTION_PATH;
      break;
    }
    default: {
      console.error('Unknown current url=%s go next to PRODMAIN page', currentPath);
      nextPath = PROD_MAIN_PATH;
      break;
    }
  }
  return SLASH + nextPath;
}

export function calculateBackPath(currentPath: string, isBHW: boolean, appData: ApplicationData): string {
  const verwendungszweck: VerwendungsZweck = appData.antrag.produkt.produktParameter.verwendungszweck;
  const verwendungsZweckUmschuldung = [VerwendungsZweck.InternRescheduling, VerwendungsZweck.Rescheduling];
  if (!currentPath || currentPath.trim() === DEFAULT_PATH) {
    currentPath = PROD_MAIN_PATH;
  }
  if (currentPath.substring(0, 1) === SLASH) {
    currentPath = currentPath.substring(1);
  }
  console.log('Calculate BACK PATH: for currentPath = %s', currentPath);
  let backPath = DEFAULT_PATH;
  switch (currentPath) {
    case PROD_MAIN_PATH: {
      backPath = PROD_MAIN_PATH;
      break;
    }
    case LOAN_CHECK_ONE_PATH: {
      if (isBHW && verwendungszweck !== VerwendungsZweck.Modernization) {
        backPath = BHW_BUILDING_LOAN_SELECTION_PATH;
      } else {
        backPath = PROD_MAIN_PATH;
      }
      break;
    }
    case LOAN_CHECK_TWO_PATH: {
      backPath = LOAN_CHECK_ONE_PATH;
      break;
    }
    case LOAN_CHECK_RESCHEDULING_PATH: {
      if (isBHW) {
        backPath = BHW_BUILDING_LOAN_SELECTION_PATH;
      } else {
        backPath = PROD_MAIN_PATH;
      }
      break;
    }
    case BHW_BUILDING_LOAN_SELECTION_PATH: {
      backPath = PROD_MAIN_PATH;
      break;
    }
    case LOAN_CHECK_REPAYMENT_PATH: {
      if (verwendungsZweckUmschuldung.indexOf(verwendungszweck) >= 0) {
        backPath = LOAN_CHECK_RESCHEDULING_PATH;
      } else {
        backPath = LOAN_CHECK_TWO_PATH;
      }
      break;
    }
    case LOAN_CHECK_SUMMARY_PATH: {
      if (verwendungszweck === VerwendungsZweck.Modernization) {
        backPath = LOAN_CHECK_TWO_PATH;
      } else {
        backPath = LOAN_CHECK_REPAYMENT_PATH;
      }
      break;
    }
    case PERSONAL_NAME_BIRTH_TAX_PATH: {
      backPath = LOAN_CHECK_SUMMARY_PATH;
      break;
    }
    case PERSONAL_EMAIL_NATIONALITY_PATH: {
      backPath = PERSONAL_NAME_BIRTH_TAX_PATH;
      break;
    }
    case PERSONAL_RESIDENTIAL_PATH: {
      backPath = PERSONAL_EMAIL_NATIONALITY_PATH;
      break;
    }
    case PERSONAL_WORK_PATH: {
      backPath = PERSONAL_RESIDENTIAL_PATH;
      break;
    }
    case PERSONAL_FAMILY_PATH: {
      backPath = PERSONAL_WORK_PATH;
      break;
    }
    case PERSONAL_INCOME_PATH: {
      backPath = PERSONAL_FAMILY_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_INCOME_PATH: {
      backPath = PERSONAL_INCOME_PATH;
      break;
    }
    case PERSONAL_OUTGOINGS_PATH: {
      backPath = PERSONAL_ADDITIONAL_INCOME_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_OUTGOINGS_PATH: {
      backPath = PERSONAL_OUTGOINGS_PATH;
      break;
    }
    case PERSONAL_FORTUNE_PATH: {
      backPath = PERSONAL_ADDITIONAL_OUTGOINGS_PATH;
      break;
    }
    case PERSONAL_NAME_BIRTH_TAX_SECOND_PATH: {
      backPath = PERSONAL_FORTUNE_PATH;
      break;
    }
    case PERSONAL_EMAIL_NATIONALITY_SECOND_PATH: {
      backPath = PERSONAL_NAME_BIRTH_TAX_SECOND_PATH;
      break;
    }
    case PERSONAL_RESIDENTIAL_SECOND_PATH: {
      backPath = PERSONAL_EMAIL_NATIONALITY_SECOND_PATH;
      break;
    }
    case PERSONAL_WORK_SECOND_PATH: {
      backPath = PERSONAL_RESIDENTIAL_SECOND_PATH;
      break;
    }
    case PERSONAL_FAMILY_SECOND_PATH: {
      backPath = PERSONAL_WORK_SECOND_PATH;
      break;
    }
    case PERSONAL_INCOME_SECOND_PATH: {
      backPath = PERSONAL_FAMILY_SECOND_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_INCOME_SECOND_PATH: {
      backPath = PERSONAL_INCOME_SECOND_PATH;
      break;
    }
    case PERSONAL_OUTGOINGS_SECOND_PATH: {
      backPath = PERSONAL_ADDITIONAL_INCOME_SECOND_PATH;
      break;
    }
    case PERSONAL_ADDITIONAL_OUTGOINGS_SECOND_PATH: {
      backPath = PERSONAL_OUTGOINGS_SECOND_PATH;
      break;
    }
    case PERSONAL_FORTUNE_SECOND_PATH: {
      backPath = PERSONAL_ADDITIONAL_OUTGOINGS_SECOND_PATH;
      break;
    }
    case PROPERTY_PATH: {
      if (appData.antrag.anzahlAntragnehmer === 1) {
        backPath = PERSONAL_FORTUNE_PATH;
      } else {
        backPath = PERSONAL_FORTUNE_SECOND_PATH;
      }
      break;
    }
    case PROPERTY_PLOT_PATH: {
      backPath = PROPERTY_PATH;
      break;
    }
    case PROPERTY_LAND_REGISTER_PATH: {
      backPath = PROPERTY_PLOT_PATH;
      break;
    }
    case PROPERTY_MODERNIZATION_PATH: {
      backPath = PROPERTY_LAND_REGISTER_PATH;
      break;
    }
    case PROPERTY_DETAILS_PATH: {
      backPath = PROPERTY_MODERNIZATION_PATH;
      break;
    }
    case BANK_ACCOUNT_PATH: {
      if (appData.antrag.anzahlAntragnehmer === 1) {
        backPath = verwendungszweck === VerwendungsZweck.Modernization ? PERSONAL_FORTUNE_PATH : PROPERTY_DETAILS_PATH;
      } else {
        backPath = verwendungszweck === VerwendungsZweck.Modernization ? PERSONAL_FORTUNE_SECOND_PATH : PROPERTY_DETAILS_PATH;
      }
      break;
    }
    case SUMMARY_PATH: {
      backPath = BANK_ACCOUNT_PATH;
      break;
    }
    case APPLICATION_SELECTION_PATH: {
      backPath = SUMMARY_PATH;
      break;
    }
    default: {
      console.error('Unknown current url=%s go back to PRODMAIN page', currentPath);
      break;
    }
  }
  return SLASH + backPath;
}
