import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { Auszahlungsweg } from '../../shared/model/kontodaten';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent extends BaseComponent implements OnInit {
  disbursementList: any[] = [];
  accountOwnerList: any[] = [];
  public errors: any = {
    iban: [
      {
        rule: 'required',
        message: 'general.iban.wrong.bank-code.hint'
      },
      {
        rule: 'pattern',
        message: 'general.iban.wrong.structure.hint'
      },
      {
        rule: 'iban',
        message: 'general.iban.wrong.bank-code.hint'
      },
      {
        rule: 'minlength',
        message: 'general.iban.wrong.structure.hint'
      },
      {
        rule: 'maxlength',
        message: 'general.iban.wrong.structure.hint'
      },
      {
        rule: 'ibancountrycode',
        message: 'general.iban.wrong.country.hint'
      }
    ],
    kontoinhaber: [
      {
        rule: 'required',
        message: 'bank-account.account-holder.hint'
      }
    ],
    auszahlungsweg: [
      {
        rule: 'required',
        message: 'bank-account.payout-channel.hint'
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private enumService: PersonalDataEnumOptionsService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.disbursementList = this.enumService.getOptionsFor(Auszahlungsweg);
    this.initOwnerList();
    this.formSettings();
    this.headerService.changeText('bank-account.headline');
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Bank_account", "personal data");
  }

  private initOwnerList() {
    this.accountOwnerList.push(this.buildOwner(this.fullName));
    if (this.antrag.anzahlAntragnehmer === 2) {
      this.accountOwnerList.push(this.buildOwner(this.fullNameSecond));
      this.accountOwnerList.push(this.buildOwner(this.fullName, this.fullNameSecond));
    }
  }
  private buildOwner(name: string, nameTwoPerson?: string): { value: string; label: string } {
    const ownerName = nameTwoPerson ? name + ' und ' + nameTwoPerson : name;
    return { value: ownerName, label: ownerName };
  }

  formSettings() {
    this.formValidation = this.formBuilder.group({
      iban: new FormControl(this.iban, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.ibanCountyCode(['DE']), DBValidators.iban(), DBValidators.exactLength(22)])),
      kontoinhaber: new FormControl(this.kontoinhaber, DBValidators.required()),
      auszahlungsweg: new FormControl(this.auszahlungsweg, DBValidators.required())
    });
  }

  get iban() {
    return this.antrag.kontodaten.iban;
  }
  set iban(iban: string) {
    this.antrag.kontodaten.iban = iban ? iban.toLocaleUpperCase() : iban;
  }
  get kontoinhaber() {
    return this.antrag.kontodaten.kontoinhaber;
  }
  set kontoinhaber(kontoinhaber: string) {
    this.antrag.kontodaten.kontoinhaber = kontoinhaber;
  }
  get auszahlungsweg() {
    return this.antrag.kontodaten.auszahlungsweg;
  }
  set auszahlungsweg(auszahlungsweg: Auszahlungsweg) {
    this.antrag.kontodaten.auszahlungsweg = auszahlungsweg;
  }
}
