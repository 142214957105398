import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import { TranslateListService } from '../../core/translate-list.service';
import { InterestPeriod, Nutzungsart, ProdukTyp, VerwendungsZweck } from '../../shared/model/produkt-parameter';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';
import { ProductEnumOptionsService } from '../product-enum-options.service';

@Component({
  selector: 'app-loan-check-repayment',
  templateUrl: './loan-check-repayment.component.html',
  styleUrls: ['./loan-check-repayment.component.scss'],
  providers: [FormBuilder]
})
export class LoanCheckRepaymentComponent extends BaseComponent implements OnInit, AfterContentChecked {
  radioButtonLaufzeit: any[] = [];
  NutzungsartList: any[] = [];
  showForProductTyp: boolean;
  isRescheduling: boolean;

  errors: any = {
    interestPeriod: [
      {
        rule: 'required',
        message: 'loan-check-repayment.fixed-interest-period.hint'
      }
    ],
    typeOfUsage: [
      {
        rule: 'required',
        message: 'loan-check-repayment.type-of-usage.hint'
      }
    ],
    anfaenglicherTilgungszinssatz: [
      {
        rule: 'number',
        message: 'general.input.only-integers'
      }
    ]
  };

  private readonly MONATLICHER_SPARBETRAG = 'monatlicherSparbetrag';
  private readonly MONATLICHER_TILGUNGS_BETRAG = 'monatlicherTilgungsbetrag';
  private readonly ANFAENGLICHER_TILGUNGS_ZINSSATZ = 'anfaenglicherTilgungszinssatz';

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private enumService: ProductEnumOptionsService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.radioButtonLaufzeit = this.enumService.getOptionsFor(InterestPeriod);
    this.NutzungsartList = this.enumService.getOptionsFor(Nutzungsart);
    this.formValidation = this.formBuilder.group({
      interestPeriod: new FormControl(this.laufzeit, DBValidators.required()),
      nutzungsart: new FormControl(this.nutzungsart, DBValidators.required()),
      anfaenglicherTilgungszinssatz: new FormControl(this.anfaenglicherTilgungszinssatz, DBValidators.number())
    });
    this.showForProductTyp = this.produktTyp === ProdukTyp.FH || this.produktTyp === ProdukTyp.ForwardFH;
    this.translateErrors(this.errors);
    const verwendungszweckRescheduling = [VerwendungsZweck.Rescheduling, VerwendungsZweck.InternRescheduling];
    this.isRescheduling = verwendungszweckRescheduling.indexOf(this.verwendungsZweck) >= 0;
    this.adobe.pushAdobeEvent("Loan_check_repayment", "loan data");
  }

  initAppData() {
    super.initAppData();
    if (this.produktTyp === ProdukTyp.FH || this.produktTyp === ProdukTyp.ForwardFH) {
      this.checkForSliderFHValue();
    } else {
      this.checkForSliderTHValue();
    }
  }

  checkForSliderFHValue() {
    if (!this.monatlicherSparbetrag || this.monatlicherSparbetrag === 0) {
      this.monatlicherSparbetrag = this.minValue(this.MONATLICHER_SPARBETRAG);
    }
    if (!this.monatlicherTilgungsbetrag || this.monatlicherTilgungsbetrag === 0) {
      this.monatlicherTilgungsbetrag = this.minValue(this.MONATLICHER_TILGUNGS_BETRAG);
    }
  }

  checkForSliderTHValue() {
    if (!this.anfaenglicherTilgungszinssatz || this.anfaenglicherTilgungszinssatz === 0) {
      this.anfaenglicherTilgungszinssatz = this.minValue(this.ANFAENGLICHER_TILGUNGS_ZINSSATZ);
    }
  }

  minValue(sliderName: string) {
    switch (sliderName) {
      case this.MONATLICHER_SPARBETRAG:
        return this.berechneMinBausparrate();
      case this.MONATLICHER_TILGUNGS_BETRAG:
        return this.berechneMinTilgungsbeitrag();
      case this.ANFAENGLICHER_TILGUNGS_ZINSSATZ:
        return 2;
      default:
        break;
    }
  }

  maxValue(sliderName: string) {
    switch (sliderName) {
      case this.MONATLICHER_SPARBETRAG:
        return this.berechneMaxBausparrate();
      case this.MONATLICHER_TILGUNGS_BETRAG:
        return this.berechneMaxTilgungsbeitrag();
      case this.ANFAENGLICHER_TILGUNGS_ZINSSATZ:
        return 8;
      default:
        break;
    }
  }

  interestPeriodChange($event) {
    this.produkt.produktParameter.laufzeit = this.convertFromDBCore($event.target.value, 'number');
  }

  private berechneMinBausparrate(): number {
    let rate: number;
    const ds = this.appDataService.berechneFinanzierungsbedarf();
    if (ds < 50000) {
      rate = (ds * 4) / 1000;
    } else {
      rate = ds / 1000;
    }
    return this.roundNumberToTen(rate);
  }

  private berechneMaxBausparrate(): number {
    let rate: number;
    rate = (this.appDataService.berechneFinanzierungsbedarf() * 20) / 1000;
    return this.roundNumberToTen(rate);
  }

  private berechneMinTilgungsbeitrag(): number {
    let rate;
    rate = (this.appDataService.berechneFinanzierungsbedarf() * 3) / 1000;
    return this.roundNumberToTen(rate);
  }

  private berechneMaxTilgungsbeitrag() {
    let rate;
    rate = (this.appDataService.berechneFinanzierungsbedarf() * 18) / 1000;
    return this.roundNumberToTen(rate);
  }

  private roundNumberToTen(num: number) {
    if (num % 10 === 0) {
      return num;
    } else {
      return (Math.floor(num / 10) + 1) * 10;
    }
  }

  get laufzeit() {
    return this.produkt.produktParameter.laufzeit;
  }

  get nutzungsart() {
    return this.objektWert.nutzungsart;
  }

  get monatlicherSparbetrag() {
    return this.produkt.bsvDaten.monatlicherSparbetrag;
  }

  set monatlicherSparbetrag(monatlicherSparbetrag: number) {
    this.produkt.bsvDaten.monatlicherSparbetrag =
      +monatlicherSparbetrag > this.maxValue(this.MONATLICHER_SPARBETRAG)
        ? this.maxValue(this.MONATLICHER_SPARBETRAG)
        : monatlicherSparbetrag < this.minValue(this.MONATLICHER_SPARBETRAG)
          ? this.minValue(this.MONATLICHER_SPARBETRAG)
          : +monatlicherSparbetrag;
  }

  get monatlicherTilgungsbetrag() {
    return this.produkt.produktParameter.monatlicherTilgungsbetrag;
  }

  set monatlicherTilgungsbetrag(monatlicherTilgungsbetrag: number) {
    this.produkt.produktParameter.monatlicherTilgungsbetrag =
      +monatlicherTilgungsbetrag > this.maxValue(this.MONATLICHER_TILGUNGS_BETRAG)
        ? this.maxValue(this.MONATLICHER_TILGUNGS_BETRAG)
        : monatlicherTilgungsbetrag < this.minValue(this.MONATLICHER_TILGUNGS_BETRAG)
          ? this.minValue(this.MONATLICHER_TILGUNGS_BETRAG)
          : +monatlicherTilgungsbetrag;
  }

  get anfaenglicherTilgungszinssatz() {
    return this.produkt.produktParameter.anfaenglicherTilgungszinssatz;
  }

  set anfaenglicherTilgungszinssatz(anfaenglicherTilgungszinssatz: number) {
    this.produkt.produktParameter.anfaenglicherTilgungszinssatz =
      +anfaenglicherTilgungszinssatz > this.maxValue(this.ANFAENGLICHER_TILGUNGS_ZINSSATZ)
        ? this.maxValue(this.ANFAENGLICHER_TILGUNGS_ZINSSATZ)
        : anfaenglicherTilgungszinssatz < this.minValue(this.ANFAENGLICHER_TILGUNGS_ZINSSATZ)
          ? this.minValue(this.ANFAENGLICHER_TILGUNGS_ZINSSATZ)
          : +anfaenglicherTilgungszinssatz;
  }

  usageChange($event) {
    this.objektWert.nutzungsart = $event.target.value;
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
}
