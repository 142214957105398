import * as moment from 'moment';

import { Adresse } from './adresse';
import { Antrag } from './antrag';
import { Immobiliennutzung } from './immobiliennutzung';
import { ObjektArt } from './objekt-wert';
import { Nutzungsart } from './produkt-parameter';

export enum AnfrageObjektArt {
  EIN_ODER_ZWEI_FAMILIEN_HAUS = 'EIN_ODER_ZWEI_FAMILIEN_HAUS',
  GRUNDSTUECK = 'GRUNDSTUECK',
  MEHR_FAMILIEN_HAUS = 'MEHR_FAMILIEN_HAUS',
  WOHNUNG = 'WOHNUNG'
}

export class AnfrageImmobilie {
  adresse: Adresse;
  bauJahr: number;
  grundStuecksFlaeche: number;
  kosten: number;
  nutzung: Immobiliennutzung;
  objektArt: AnfrageObjektArt;
  wohnFlaeche: number;

  constructor(antrag: Antrag) {
    this.adresse = new Adresse();
    if (antrag && antrag.objektWert) {
      this.adresse.hausnummer = antrag.objektWert.hausnummer ? '' + antrag.objektWert.hausnummer : null;
      this.adresse.ort = antrag.objektWert.ort ? antrag.objektWert.ort : null;
      this.adresse.postleitzahl = antrag.objektWert.plz ? antrag.objektWert.plz : null;
      this.adresse.strasse = antrag.objektWert.strasse ? antrag.objektWert.strasse : null;
    } else {
      this.adresse.hausnummer = null;
      this.adresse.ort = null;
      this.adresse.postleitzahl = null;
      this.adresse.strasse = null;
    }

    this.kosten = 15000;
    if (antrag.objektWert && antrag.objektWert.kaufPreis) {
      this.kosten = antrag.objektWert.kaufPreis;
    } else if (
      antrag.produkt &&
      antrag.produkt.produktParameter &&
      antrag.produkt.produktParameter.finanzierungsbedarf &&
      antrag.produkt.produktParameter.finanzierungsbedarf !== null
    ) {
      this.kosten = antrag.produkt.produktParameter.finanzierungsbedarf;
    }

    if (antrag.objektWert && antrag.objektWert.nutzungsart) {
      if (antrag.objektWert.nutzungsart === Nutzungsart.Eigennutzung) {
        this.nutzung = new Immobiliennutzung(true, false);
      } else if (antrag.objektWert.nutzungsart === Nutzungsart.Fremdnutzung) {
        this.nutzung = new Immobiliennutzung(false, true);
      } else if (antrag.objektWert.nutzungsart === Nutzungsart.Eigen_und_Fremdnutzung) {
        this.nutzung = new Immobiliennutzung(true, true);
      } else {
        this.nutzung = new Immobiliennutzung(true, false);
      }
    } else {
      this.nutzung = new Immobiliennutzung(true, false);
    }

    if (antrag.objektWert && antrag.objektWert.objektArt) {
      if (antrag.objektWert.objektArt === ObjektArt.Eigentumswohnung) {
        this.objektArt = AnfrageObjektArt.WOHNUNG;
      } else if (antrag.objektWert.objektArt === ObjektArt.MehrfamilienhausDreiMieter || antrag.objektWert.objektArt === ObjektArt.Mehrfamilienhaus33Gewerbe) {
        this.objektArt = AnfrageObjektArt.MEHR_FAMILIEN_HAUS;
      } else if (antrag.objektWert.objektArt === ObjektArt.UnbebautesGrundstueck) {
        this.objektArt = AnfrageObjektArt.GRUNDSTUECK;
      } else {
        this.objektArt = AnfrageObjektArt.EIN_ODER_ZWEI_FAMILIEN_HAUS;
      }
    } else {
      this.objektArt = AnfrageObjektArt.EIN_ODER_ZWEI_FAMILIEN_HAUS;
    }

    if (antrag.objektWert && antrag.objektWert.baujahr) {
      this.bauJahr = moment(antrag.objektWert.baujahr).year();
    }

    if (antrag.objektWert && antrag.objektWert.grundstuecksFlaeche) {
      this.grundStuecksFlaeche = antrag.objektWert.grundstuecksFlaeche;
    }

    if (antrag.objektWert && antrag.objektWert.wohnFlaecheEigennutz && antrag.objektWert.wohnFlaecheFremdnutz) {
      this.wohnFlaeche = antrag.objektWert.wohnFlaecheEigennutz + antrag.objektWert.wohnFlaecheFremdnutz;
    } else if (
      antrag.objektWert &&
      antrag.objektWert.wohnFlaecheEigennutz &&
      (antrag.objektWert.wohnFlaecheFremdnutz === undefined || antrag.objektWert.wohnFlaecheFremdnutz === null)
    ) {
      this.wohnFlaeche = antrag.objektWert.wohnFlaecheEigennutz;
    } else {
      this.wohnFlaeche = 0;
    }
  }
}
