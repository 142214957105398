import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { YesNoConfirmation } from 'src/app/shared/model/application-data';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';

@Component({
  selector: 'app-property-land-register',
  templateUrl: './property-land-register.component.html',
  styleUrls: ['./property-land-register.component.scss'],
  providers: [FormBuilder]
})
export class PropertyLandRegisterComponent extends BaseComponent implements OnInit {
  errors: any = {
    grundbuchVon: [
      {
        rule: 'required',
        message: 'property-land-register.origin.hint'
      }
    ],
    blattHeft: [
      {
        rule: 'required',
        message: 'property-land-register.sheet-magazine-number.hint'
      }
    ],
    flurstueck: [
      {
        rule: 'required',
        message: 'property-land-register.cadastral-unit.hint'
      }
    ],
    gpNrSG1: [
      {
        rule: 'required',
        message: 'property-land-register.prior-burdens.hint'
      }
    ]
  };

  gpNrSG1RadioArray: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private enumService: PersonalDataEnumOptionsService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getRatioButtonArray();
    this.headerService.changeText('property-land-register.headline');
    this.formSettings();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Property_land_register", "property data");
  }

  formSettings() {
    this.formValidation = this.formBuilder.group({
      grundbuchVon: new FormControl(this.grundbuch.grundbuchVon, DBValidators.required()),
      blattHeft: new FormControl(this.grundbuch.blattHeft, DBValidators.required()),
      flurstueck: new FormControl(this.grundbuch.flurstueck, DBValidators.required()),
      gpNrSG1: new FormControl(this.gpNrSG1, DBValidators.required())
    });
  }

  getRatioButtonArray() {
    this.gpNrSG1RadioArray = this.enumService.getOptionsFor(YesNoConfirmation);
  }

  get gpNrSG1(): string {
    return this.convertToDBCore(this.grundbuch.gpNrSG1);
  }
  set gpNrSG1(gpNrSG1: string) {
    this.grundbuch.gpNrSG1 = this.convertFromDBCore(gpNrSG1, 'boolean');
  }
}
