import * as moment from 'moment';

import { AnfrageImmobilie } from './anfrageImmobilie';
import { Antrag } from './antrag';
import { Finanzierungswunsch } from './finanzierungswunsch';
import { AnfrageAnstellungsverhaeltnis, Kunde } from './kunde';
import { Produkt } from './produkt';

export class Anfrage {
  anmerkungen: string;
  erfassungsWegZusatz: string;
  finanzierungsWunsch: Finanzierungswunsch;
  immobilie: AnfrageImmobilie;
  kunde: Kunde[];

  constructor(antrag: Antrag, rate: number) {
    this.erfassungsWegZusatz = this.createErfassungsweg(antrag);
    this.finanzierungsWunsch = new Finanzierungswunsch(antrag);
    this.immobilie = new AnfrageImmobilie(antrag);
    this.kunde = this.createCustomer(antrag);
    this.anmerkungen = this.createAnmerkungenToPost(antrag, rate);
  }

  private createCustomer(antrag: Antrag): Kunde[] {
    const result = [];
    result.push(new Kunde(antrag.hauptdarlehensnehmer, antrag.produkt));
    if (antrag && antrag.anzahlAntragnehmer && antrag.anzahlAntragnehmer > 1) {
      result.push(new Kunde(antrag.nebendarlehensnehmer, antrag.produkt));
    }
    return result;
  }

  private createErfassungsweg(antrag: Antrag): string {
    if (this.isDefined(antrag)) {
      if (this.isDefined(antrag.ewz) && antrag.ewz !== '') {
        return antrag.ewz;
      } else if (this.isDefined(antrag.vgeNummer)) {
        switch (antrag.vgeNummer) {
          case '18001381393':
            return 'BAUFI-LIVE - Postbank';
          case '07302026500':
            return 'QuickConnectOAP';
          case '08580171350':
          /* falls through */
          case '01571433996':
          /* falls through */
          default:
            return 'BAUFI-LIVE - BHW';
        }
      }
    }
  }

  private createAnmerkungenToPost(antrag: Antrag, rate: number) {
    let anmerkungen = '';

    if (this.isDefined(antrag.produkt) && this.isDefined(antrag.produkt.produktBerechnung) && this.isDefined(antrag.produkt.produktBerechnung.darlehenssumme)) {
      anmerkungen = anmerkungen + 'Darlehenssumme: ' + antrag.produkt.produktBerechnung.darlehenssumme + ' €\n';
    }

    if (
      this.isDefined(antrag.hauptdarlehensnehmer) &&
      this.isDefined(antrag.hauptdarlehensnehmer.personendaten) &&
      this.isDefined(antrag.hauptdarlehensnehmer.personendaten.anstellungsverhaeltnis)
    ) {
      const anstellung = Kunde.getAnstellungsVerhaeltnis(antrag.hauptdarlehensnehmer.personendaten.anstellungsverhaeltnis);
      if (anstellung === AnfrageAnstellungsverhaeltnis.SELBSTSTAENDIG) {
        anmerkungen = anmerkungen + 'Ich bin Selbstständiger';
      } else {
        anmerkungen = anmerkungen + 'Ich bin Angestellter';
      }
      if (
        this.isDefined(antrag.hauptdarlehensnehmer.haushaltsrechnung) &&
        this.isDefined(antrag.hauptdarlehensnehmer.haushaltsrechnung.einnahmen) &&
        this.isDefined(antrag.hauptdarlehensnehmer.haushaltsrechnung.einnahmen.regelmaessigesNettoeinkommen)
      ) {
        anmerkungen = anmerkungen + ' und verdiene ' + antrag.hauptdarlehensnehmer.haushaltsrechnung.einnahmen.regelmaessigesNettoeinkommen + ' €\n';
      } else {
        anmerkungen = anmerkungen + '.\n';
      }
    }

    if (this.isDefined(antrag.produkt) && this.isDefined(antrag.produkt.produktParameter) && this.isDefined(antrag.produkt.produktBerechnung)) {
      const produktdaten = this.formatProduktInfo(antrag.produkt, rate);
      anmerkungen = anmerkungen + produktdaten;
    }

    return anmerkungen;
  }

  private formatProduktInfo(produkt: Produkt, rate: number) {
    let retProdukt = 'Produkt-Informationen: \n';

    if (this.isDefined(produkt.produktParameter)) {
      if (this.isDefined(produkt.produktParameter.produkttyp) && produkt.produktParameter.produkttyp !== null) {
        retProdukt = retProdukt + 'Produkttyp: ' + produkt.produktParameter.produkttyp + '\n';
      }

      if (this.isDefined(produkt.produktParameter.zinsbindungsende) && produkt.produktParameter.zinsbindungsende !== null) {
        retProdukt = retProdukt + 'ZinsbindungsEnde ' + moment(produkt.produktParameter.zinsbindungsende).toISOString() + '\n';
      }

      if (this.isDefined(produkt.produktParameter.laufzeit)) {
        retProdukt = retProdukt + 'Gesamtlaufzeit: ' + produkt.produktParameter.laufzeit + ' Jahre\n';
      }
    }

    if (this.isDefined(rate) && rate !== null) {
      retProdukt = retProdukt + 'monatliche Rate: ' + rate + ' €\n';
    }

    if (this.isDefined(produkt.produktBerechnung)) {
      if (this.isDefined(produkt.produktBerechnung.tilgungSollzins)) {
        retProdukt = retProdukt + 'anfänglicher Nominalzins: ' + produkt.produktBerechnung.tilgungSollzins + '%\n';
      }

      if (this.isDefined(produkt.produktBerechnung.tilgungEffektivzins)) {
        retProdukt = retProdukt + 'anfänglicher Effektivzins: ' + produkt.produktBerechnung.tilgungEffektivzins + '%\n';
      }

      if (this.isDefined(produkt.produktParameter.anfaenglicherTilgungszinssatz)) {
        retProdukt = retProdukt + 'anfänglicher Tilgungszinssatz: ' + produkt.produktParameter.anfaenglicherTilgungszinssatz + '%\n';
      }

      if (
        this.isDefined(produkt.produktBerechnung.tilgungsplanSumme) &&
        this.isDefined(produkt.produktParameter) &&
        this.isDefined(produkt.produktParameter.produkttyp) &&
        (produkt.produktParameter.produkttyp === 'TH' || produkt.produktParameter.produkttyp === 'FORWARD_TH')
      ) {
        const restschuld = produkt.produktBerechnung.darlehenssumme - produkt.produktBerechnung.tilgungsplanSumme;
        retProdukt = retProdukt + 'Restschuld nach Zinsbindungsende: ' + restschuld + ' €\n';
      }

      if (!(produkt.produktParameter.produkttyp === 'TH' || produkt.produktParameter.produkttyp === 'FORWARD_TH')) {
        if (this.isDefined(produkt.produktBerechnung.tilgungAbDatum) && produkt.produktBerechnung.tilgungAbDatum !== null) {
          retProdukt = retProdukt + 'voraussichtliche Zuteilung: ' + moment(produkt.produktBerechnung.tilgungAbDatum).toISOString() + '\n';
        }
      }
    }

    if (this.isDefined(produkt.bsvDaten)) {
      retProdukt = retProdukt + 'BSV-Daten:\n';

      if (this.isDefined(produkt.bsvDaten.bsvTarif) && produkt.bsvDaten.bsvTarif !== null) {
        retProdukt = retProdukt + 'Bauspartarif: ' + produkt.bsvDaten.bsvTarif + '\n';
      }

      if (this.isDefined(produkt.bsvDaten.sparplanSollzins)) {
        retProdukt = retProdukt + 'Nominalzins nach Zuteilung: ' + produkt.bsvDaten.sparplanSollzins + '%\n';
      }

      if (this.isDefined(produkt.bsvDaten.sparplanEffektivzins)) {
        retProdukt = retProdukt + 'Effektivzins nach Zuteilung: ' + produkt.bsvDaten.sparplanEffektivzins + '%\n';
      }

      if (this.isDefined(produkt.bsvDaten.sparAbschlussGebuehr)) {
        retProdukt = retProdukt + 'Abschlussgebühr: ' + produkt.bsvDaten.sparAbschlussGebuehr + '€\n';
      }

      if (this.isDefined(produkt.bsvDaten.guthabenBeiZuteilung) && produkt.bsvDaten.guthabenBeiZuteilung !== null) {
        retProdukt = retProdukt + 'Guthaben bei Zuteilung: ' + produkt.bsvDaten.guthabenBeiZuteilung + ' €\n';
      }
    }

    return retProdukt;
  }

  private isDefined(obj: any): boolean {
    if (obj !== undefined && obj !== null) {
      return true;
    } else {
      return false;
    }
  }
}
