import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { AntragStatus } from '../shared/model/antrag';
import { AdobeApiService } from '../shared/services/adobe-api.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends BaseComponent implements OnInit {
  public toggleMainArray: any[] = [false, false, false, false, false, false];

  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(
      appDataService,
      navBarService,
      headerService,
      popupService,
      translateService,
      translateListService,
      dateService,
      appDataService.antragStatus !== AntragStatus.InBearbeitung
    );
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.status === AntragStatus.InBearbeitung) {
      let dynamicTextKey = 'summary.one-person.headline';
      let paramObject: any = this.correspondence;
      if (this.antrag.anzahlAntragnehmer === 2) {
        dynamicTextKey = 'summary.two-persons.headline';
        paramObject = this.correspondenceTwoPersons;
      }
      this.translateService.get(dynamicTextKey, paramObject).subscribe((res: string) => {
        this.headerService.changeText(res);
      });
    }
    this.adobe.pushAdobeEvent("Other:outcomes_incomes_summary", "Summary")
  }

  public toggleMainClick(index: any) {
    this.toggleMainArray = [false, false, false, false, false, false];
    this.toggleMainArray[index] = true;
    this.scrollToElementId('mainSum' + index);
  }
}
