export class Grundbuch {
    grundbuchVon: string;
    band: string;
    blattHeft: string;
    flur: string;
    flurstueck: string;
    gpNrSG1: boolean;

    constructor(grundbuch?: Grundbuch) {
        this.grundbuchVon = grundbuch ? grundbuch.grundbuchVon : null;
        this.band = grundbuch ? grundbuch.band : null;
        this.blattHeft = grundbuch ? grundbuch.blattHeft : null;
        this.flur = grundbuch ? grundbuch.flur : null;
        this.flurstueck = grundbuch ? grundbuch.flurstueck : null;
        this.gpNrSG1 = grundbuch ? grundbuch.gpNrSG1 : null;
    }
}
