import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { AddressBaseComponent } from 'src/app/core/address-base.component';
import { AddressValidator } from 'src/app/core/addressvalidator.service';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { OAPValidators } from 'src/app/core/validators.directive';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';
import { Wohnen } from './../../shared/model/personendaten';

@Component({
  selector: 'app-personal-residential-second',
  templateUrl: './personal-residential-second.component.html',
  styleUrls: ['./personal-residential-second.component.scss'],
  providers: [FormBuilder]
})
export class PersonalResidentialSecondComponent extends AddressBaseComponent implements OnInit, AfterContentChecked {
  wohnverhaeltnisList: any[] = [];
  bundeslandList: any[] = [];
  isUebernehmeAdressRadioArray: any[] = [];

  errors: any = {
    strasse: [
      {
        rule: 'required',
        message: 'personal-residential.adress.street.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-residential.adress.street.hint'
      }
    ],

    hausnummer: [
      {
        rule: 'required',
        message: 'personal-residential.adress.house-number.empty.hint'
      },
      {
        rule: 'pattern',
        message: 'general.house-number.format.hint'
      }
    ],
    plz: [
      {
        rule: 'required',
        message: 'personal-residential.adress.postcode.hint'
      },
      {
        rule: 'pattern',
        message: 'general.zip-code.format.hint'
      }
    ],
    ort: [
      {
        rule: 'required',
        message: 'personal-residential.adress.place.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-residential.adress.place.hint'
      }
    ],
    bundesLand: [
      {
        rule: 'required',
        message: 'personal-residential.adress.federal-state.hint'
      }
    ],
    telefonnummerVorwahl: [
      {
        rule: 'required',
        message: 'personal-residential.adress.phone-area-code.empty.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-residential.adress.phone-area-code.format.too-short.hint'
      },
      {
        rule: 'pattern',
        message: 'personal-residential.adress.phone-area-code.format.not-starting-with-zero.hint'
      },
      {
        rule: 'number',
        message: 'general.input.only-numbers.hint'
      }
    ],
    telefonnummerDurchwahl: [
      {
        rule: 'required',
        message: 'personal-residential.adress.phone-number.empty.hint'
      },
      {
        rule: 'minlength',
        message: 'personal-residential.adress.phone-number.format.hint'
      },
      {
        rule: 'pattern',
        message: 'general.input.only-numbers-and-special-characters.hint'
      }
    ],
    wohnverhaeltnis: [
      {
        rule: 'required',
        message: 'personal-residential.housing-situation.hint'
      }
    ],
    isUebernehmeAdress: [
      {
        rule: 'required',
        message: 'personal-residential-second.adopt-first-address.hint'
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valuesService: ValuesService,
    private enumService: PersonalDataEnumOptionsService,
    dateService: DateService,
    addressValidator: AddressValidator,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, addressValidator);
  }

  ngOnInit() {
    super.ngOnInit();
    this.wohnverhaeltnisList = this.enumService.getOptionsFor(Wohnen);
    this.isUebernehmeAdressRadioArray = [
      { name: 'general.yes', value: 'yes' },
      { name: 'general.no', value: 'no' }
    ];
    this.initRegions();
    this.formValidation = this.formBuilder.group({
      strasse: new FormControl(this.personendatenSecond.strasse, [DBValidators.required(), DBValidators.minLength(2)]),
      hausnummer: new FormControl(this.personendatenSecond.hausnummer, [DBValidators.pattern(/^[a-zA-Z0-9/\\-]*$/), DBValidators.required()]),
      plz: new FormControl(this.personendatenSecond.plz, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.pattern(/^[0-9]{5,5}$/)])),
      ort: new FormControl(this.personendatenSecond.ort, [DBValidators.required(), DBValidators.minLength(2)]),
      bundesLand: new FormControl(this.personendatenSecond.bundesland, DBValidators.required()),
      telefonnummerVorwahl: new FormControl(this.personendatenSecond.telefonnummerVorwahl, [
        DBValidators.pattern(/^0.*$/),
        DBValidators.required(),
        DBValidators.minLength(3),
        DBValidators.number()
      ]),
      telefonnummerDurchwahl: new FormControl(this.personendatenSecond.telefonnummerDurchwahl, [
        DBValidators.required(),
        DBValidators.minLength(3),
        DBValidators.pattern(/^[0-9/-]*$/)
      ]),
      wohnverhaeltnis: new FormControl(this.personendatenSecond.wohnverhaeltnis, DBValidators.required()),
      isUebernehmeAdress: new FormControl(
        null,
        OAPValidators.conditionRequiredValidator(() => !this.applicationData.isZweitePersonAdressAusgewaehlt && !this.personendatenSecond.strasse)
      )
    });
    this.headerService.changeText('personal-residential.headline');
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_residential_second", "personal data");
  }

  onBeforeFormSubmit() {
    this.onAdresseCheck('personendatenSecond', super.onBeforeFormSubmit.bind(this));
  }

  initRegions() {
    this.valuesService.loadRegions().subscribe(regionList => {
      this.bundeslandList.length = 0; // reset regions
      this.bundeslandList = regionList;
    });
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  isUebernehmeAdressChange($event: any) {
    this.applicationData.isZweitePersonAdressAusgewaehlt = true;
    if ($event.target.value === 'yes') {
      this.personendatenSecond.strasse = this.personendaten.strasse;
      this.personendatenSecond.hausnummer = this.personendaten.hausnummer;
      this.personendatenSecond.plz = this.personendaten.plz;
      this.personendatenSecond.bundesland = this.personendaten.bundesland;
      this.personendatenSecond.ort = this.personendaten.ort;
    }
  }
}
