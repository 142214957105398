<div *ngIf="!isRequested">
    <div class="ui-control-label">{{ 'final-summary-and-download.label' | translate }}</div>
    <app-summary></app-summary>
    <db-checkbox [(model)]="acceptTerms"></db-checkbox>{{ 'final-summary-and-download.terms-checkbox.text-1' | translate }}
    {{ 'final-summary-and-download.terms-checkbox.text-2' | translate }}
    <a [attr.href]="pdfErklaerungBHW" target="_blank">{{ 'final-summary-and-download.terms-checkbox.text-3' | translate }}</a>
    {{ 'final-summary-and-download.terms-checkbox.text-4' | translate }}

    <div class="summary-terms-description">
        {{ 'final-summary-and-download.terms.text-1' | translate }}
        <a [attr.href]="linkBhwConsentAgb" target="_blank">{{ 'final-summary-and-download.terms.text-2' | translate }}</a>
    </div>
    <div class="summary-terms-description">
        {{ 'final-summary-and-download.terms.text-3' | translate }}
        <a [attr.href]="pdfErklaerungBHW" target="_blank">{{ 'final-summary-and-download.terms.text-4' | translate }}</a>
        {{ 'final-summary-and-download.terms.text-5' | translate }}
    </div>

    <div class="summary-terms-description pull-right">
        <db-button design="primary" (click)="processAntrag()" [disabled]="!acceptTerms">
            {{ 'summary.submit.button.label' | translate }}
        </db-button>
    </div>
</div>

<div *ngIf="isRequested">
    <div *ngIf="statusProgress && !statusError">
        <div class="linear-activity">
            <div class="indeterminate"></div>
        </div>
        <div class="ui-control-label">{{ 'final-summary-and-download.waiting.text' | translate }}</div>
    </div>
    <div *ngIf="!statusProgress">
        <div class="ui-control-label">
            {{ 'summary.download.description.label' | translate }}
        </div>

        <div class="ui-control-label">
            {{ 'summary.download.description.text' | translate }}
        </div>

        <div class="ui-control-label" *ngIf="!downloadFileAvailable">
            <db-button design="secondary" (click)="loadDocs()">
                {{ 'summary.document.file.label' | translate }}
            </db-button>
        </div>

        <div class="ui-control-label" *ngIf="downloadFileAvailable">
            <db-button design="primary" (click)="download_file()">
                {{ 'summary.download.file.label' | translate }}
            </db-button>
        </div>
    </div>
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': statusError }">
    {{ 'final-summary-and-download.hint' | translate }}
</div>