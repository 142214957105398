<div class="row" *ngIf="!isTH">
  <div class="col-sm-12 col-md-6 col-xl-4">
    <div class="summary-title">
      {{ 'loan-check-summary.product-details.title' | translate }}
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.darlehenssumme | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.net-loan-amount.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ monatlicheRate | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.monthly-rate-until-allotment.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.tilgungSollzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.fixed-borrowing-rate.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.tilgungEffektivzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.initial-effective-interest-rate.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span [ngClass]="{ 'db-validation-errors__message': !applicationData.isZuteilungValid }" class="col-12 col-sm-4 is-visible" style="white-space: normal">{{
        zinsbindung
      }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.fixed-interest-rate.suffix' | translate }}</span>
    </div>

    <div class="row" *ngIf="isRescheduling">
      <span class="col-12 col-sm-4">{{ produkt.produktParameter.umschuldungsdatum | date: 'mediumDate' }}</span>
      <span *ngIf="applicationData.isShorterThreeMonths" class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.rescheduling.date.suffix' | translate }}</span>
      <span *ngIf="!applicationData.isShorterThreeMonths"
        class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.rescheduling.date.three.months.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ anzahlRaten }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.number-of-rates.suffix' | translate }}</span>
    </div>

    <div class="row" *ngIf="!isExpress">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.grundbuchKosten | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.land-registry-costs.suffix' | translate }}</span>
    </div>

    <div *ngIf="isExpress" class="row">
      <span class="col-12 col-sm-4">{{ bereitstellungszins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.commitment-interest.suffix' | translate }}</span>
    </div>

    <div *ngIf="isExpress" class="row">
      <span class="col-12 col-sm-4">{{ bereitstellungszinsfreieZeit }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.time-free-of-commitment-interest.suffix' | translate }}​</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.gesamtEffektivzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.total-effective-interest-rate.suffix' | translate }}</span>
    </div>
  </div>

  <div class="col-sm-12 col-md-6 col-xl-4">
    <div class="summary-title">{{ 'loan-check-summary.building-loan-agreement-details.title' | translate }}</div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.bsvDaten.bsvTarif }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.variant-of-building-society-tarif.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.bsvDaten.sparplanSollzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.fixed-borrowing-rate-after-allotment.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.bsvDaten.sparplanEffektivzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.effective-interest-rate-after-allotment.suffix' | translate }}</span>
    </div>

    <div class="row" *ngIf="isExpress">
      <span class="col-12 col-sm-4">{{ monatlicheRateNachZuteilung | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.redemption-fee.suffix' | translate }}</span>
    </div>

    <div class="row" *ngIf="!isExpress">
      <span class="col-12 col-sm-4">{{ produkt.produktParameter.monatlicherTilgungsbetrag | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.redemption-fee-bsv.suffix' | translate }}</span>
    </div>

    <div class="row" *ngIf="isFH">
      <span class="col-12 col-sm-4">{{ produkt.bsvDaten.bausparSumme | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.house-saving-sum.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.bsvDaten.sparAbschlussGebuehr | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.acquisition-fee.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span [ngClass]="{ 'db-validation-errors__message': !applicationData.isZuteilungValid }" class="col-12 col-sm-4 is-visible">{{ tilgungAbDatum }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.expected-allocation.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.bsvDaten.guthabenBeiZuteilung | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.building-loan-agreement-details.credit-at-allotment.suffix' | translate }}</span>
    </div>
  </div>

  <div class="col-sm-12 col-md-12 col-xl-4 mt-lg-4 mt-xl-0">
    <div class="vorteilsbox">
      <div class="summary-title">{{ 'loan-check-summary.advantages.title' | translate }}</div>

      <div *ngIf="isExpress">
        <div class="row">
          <span class="col-6">
            <db-icon size="lg" color="#2282C1">shield</db-icon>
          </span>
          <span class="col-6">
            <db-icon size="lg" color="#2282C1">check</db-icon>
          </span>
        </div>
        <div class="row">
          <span class="col-6">
            {{ 'loan-check-summary.advantages.interest-security.text' | translate }}
          </span>
          <span class="col-6">
            {{ 'Blankodarlehen, keine grundbuchliche Sicherung notwendig' | translate }}
          </span>
        </div>
      </div>

      <div *ngIf="!isExpress">
        <div class="row">
          <div class="col-12">
            <db-icon size="lg" color="#2282C1">check</db-icon>
          </div>
          <div class="col-12">
            {{ 'loan-check-summary.advantages.shortened-maturity.text' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isTH">
  <div class="col-sm-12 col-md-6 col-xl-4">
    <div class="summary-title">{{ 'loan-check-summary.product-details-annuity-loan.title' | translate: { produkttyp: produkttyplabel } }}</div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.darlehenssumme | currency: 'EUR' }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.net-loan-amount.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ auszahlungskurs | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.payout-rate.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.tilgungSollzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.fixed-borrowing-rate.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktBerechnung.tilgungEffektivzins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.initial-effective-interest-rate.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ produkt.produktParameter.anfaenglicherTilgungszinssatz | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.initial-repayment.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ monatlicheRate | number: '1.2-2' }} €</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.monthly-payment-amount.suffix' | translate }}</span>
    </div>
  </div>

  <div class="col-sm-12 col-md-6 col-xl-4">
    <div class="summary-title"></div>

    <div class="row">
      <span [ngClass]="{ 'db-validation-errors__message': !applicationData.isZuteilungValid }" class="col-12 col-sm-4 is-visible">{{ zinsbindung }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.fixed-interest-rate.suffix' | translate }}</span>
    </div>

    <div class="row">
      <span [ngClass]="{ 'db-validation-errors__message': !applicationData.isZuteilungValid }" class="col-12 col-sm-4 is-visible" style="white-space: normal">{{
        gesamtLaufzeit
      }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.total-duration' | translate }}</span>
    </div>

    <div class="row">
      <span class="col-12 col-sm-4">{{ bereitstellungszins | number: '1.0-2' }} %</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.commitment-interest.suffix' | translate }}</span>
    </div>

    <div *ngIf="applicationData.isShorterThreeMonths" class="row">
      <span class="col-12 col-sm-4">{{ bereitstellungszinsfreieZeit }}</span>
      <span class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.time-free-of-commitment-interest.suffix' | translate }}​</span>
    </div>

    <div class="row" *ngIf="isRescheduling">
      <span class="col-12 col-sm-4">{{ produkt.produktParameter.umschuldungsdatum | date: 'mediumDate' }}</span>
      <span *ngIf="applicationData.isShorterThreeMonths" class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.rescheduling.date.suffix' | translate }}</span>
      <span *ngIf="!applicationData.isShorterThreeMonths"
        class="col-12 col-sm-8">{{ 'loan-check-summary.product-details.rescheduling.date.three.months.suffix' | translate }}</span>
    </div>
  </div>

  <div class="col-sm-12 col-md-12 col-xl-4 mt-lg-4 mt-xl-0">
    <div class="vorteilsbox">
      <div class="summary-title">{{ 'loan-check-summary.advantages.title' | translate }}</div>

      <div *ngIf="!isExpress">
        <div class="row">
          <div class="col-12">
            <db-icon size="lg" color="#2282C1">check</db-icon>
          </div>
          <div class="col-12">
            {{ 'loan-check-summary.advantages.shortened-maturity.text' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': !applicationData.isZuteilungValid && isFH }">
  {{ 'loan-check-summary.allocation-before-end-of-fixed-interest-period.hint' | translate }}
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': !applicationData.isZuteilungValid && isTH }">
  {{ 'loan-check-summary.total-duration-shorter-than-fixed-interest-rate.hint' | translate }}
</div>

<div class="db-validation-errors__message" [ngClass]="{ 'is-visible': !applicationData.isTilgungsplanValid }">
  {{ 'loan-check-summary.constellation-of-inputs-invalid.hint' | translate }}
</div>

<div class="text-notice" *ngIf="!isExpress">
  {{ 'loan-check-summary.product-details.legal-notice.hint' | translate }}
</div>

<div class=" text-center text-notice" *ngIf="!isExpress">
  <b>{{ 'loan-check-summary.product-details.fundamental-debt' | translate }}</b>
</div>

<div class="bottom-card" *ngIf="(!isBhw && !isTH) || isDbModernization">
  {{ 'footer.supplier-notice-bhw-1' | translate }}
</div>

<div class="bottom-card" *ngIf="!isBhw && isTH">
  {{ 'footer.supplier-notice-bhw-2' | translate }}
</div>