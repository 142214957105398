<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4 *ngIf="liabilities.length > 0">{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div *ngFor="let liability of liabilities; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.other-obligations.sum.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{liability.zahlungssumme | currency: 'EUR'}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.other-obligations.monthly-rate.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{liability.monatlicheKosten | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.other-obligations.type.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{liability.zahlungsType | translate}}
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6"></div>
            </div>
        </div>
        <h4 *ngIf="liabilitiesSecond.length > 0">{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
        <div *ngFor="let liability of liabilitiesSecond; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.other-obligations.sum.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{liability.zahlungssumme | currency: 'EUR'}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.other-obligations.monthly-rate.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{liability.monatlicheKosten | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.other-obligations.type.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{liability.zahlungsType | translate}}
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6"></div>
            </div>
        </div>
    </div>
</div>