import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ViewChild, ViewRef } from '@angular/core';
import { DBTabsComponent } from '@db/db-core';

import { HeaderService } from './../../shared/services/header.service';

@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss']
})
export class HeaderTabComponent implements AfterViewInit, AfterContentChecked {
  @ViewChild(DBTabsComponent, { static: false })
  tabsComponent!: DBTabsComponent;

  constructor(private headerService: HeaderService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.headerService.currentTab.subscribe(activeTab => {
      setTimeout(() => {
        this.changeActiveTab(activeTab);
      }, 10);
    });
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  changeActiveTab(activeTab: string) {
    if (this.tabsComponent && !(this.cd as ViewRef).destroyed) {
      this.tabsComponent.activeTab = activeTab;
    }
  }
}
