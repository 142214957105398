import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBSelectOption, DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { YesNoConfirmation } from 'src/app/shared/model/application-data';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

import { BaseComponent } from '../../core/base.component';
import { TranslateListService } from '../../core/translate-list.service';
import { OAPValidators } from '../../core/validators.directive';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';
import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';

@Component({
  selector: 'app-personal-email-nationality',
  templateUrl: './personal-email-nationality.component.html',
  styleUrls: ['./personal-email-nationality.component.scss'],
  providers: [FormBuilder]
})
export class PersonalEmailNationalityComponent extends BaseComponent implements OnInit, OnDestroy {
  zweiteStaatsangehoerigkeitUSAOptions: any[] = [];
  showErrorMsg = false;
  nationalityList: Array<DBSelectOption> = [];
  _email: string;
  showEmailConfirmation: boolean;

  errors: any = {
    emailAddress: [
      {
        rule: 'required',
        message: 'personal-email-nationality.email.hint'
      },
      {
        rule: 'pattern',
        message: 'personal-email-nationality.email.hint'
      }
    ],
    confirmEmailAddress: [
      {
        rule: 'required',
        message: 'personal-email-nationality.email.confirm.hint'
      },
      {
        rule: 'pattern',
        message: 'personal-email-nationality.email.hint'
      },
      {
        rule: 'match',
        message: 'personal-email-nationality.compare.email-address-and-confirm-email-address.error-Msg'
      }
    ],
    staatsangehoerigkeit: [
      {
        rule: 'required',
        message: 'personal-email-nationality.nationality.hint'
      }
    ],
    zweiteStaatsangehoerigkeitUSA: [
      {
        rule: 'required',
        message: 'personal-email-nationality.second-us-american-nationality.hint'
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valuesService: ValuesService,
    private enumService: PersonalDataEnumOptionsService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initNationalities();
    this.showEmailConfirmation = !this.personendaten.email;
    this.formValidation = this.formBuilder.group(
      {
        emailAddress: new FormControl(this.personendaten.email, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.email()])),
        confirmEmailAddress: new FormControl(undefined, [DBValidators.email(), OAPValidators.conditionRequiredValidator(() => this.showEmailConfirmation)]),
        staatsangehoerigkeit: new FormControl(this.personendaten.staatsangehoerigkeit, DBValidators.required()),
        zweiteStaatsangehoerigkeitUSA: new FormControl(this.zweiteStaatsangehoerigkeitUSA, DBValidators.required())
      },
      {
        validator: OAPValidators.matchValidatort('emailAddress', 'confirmEmailAddress')
      }
    );
    this.headerService.changeText('personal-email-nationality.headline');
    this.zweiteStaatsangehoerigkeitUSAOptions = this.enumService.getOptionsFor(YesNoConfirmation);
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_email_nationality", "personal data");
  }

  get zweiteStaatsangehoerigkeitUSA(): string {
    return this.convertToDBCore(this.personendaten.zweiteStaatsangehoerigkeitUSA);
  }
  set zweiteStaatsangehoerigkeitUSA(zweiteStaatsangehoerigkeitUSA: string) {
    this.personendaten.zweiteStaatsangehoerigkeitUSA = this.convertFromDBCore(zweiteStaatsangehoerigkeitUSA, 'boolean');
  }
  get email(): string {
    return this._email;
  }

  set email(email: string) {
    this._email = email;
    this.showEmailConfirmation = true;
  }
  protected initAppData() {
    super.initAppData();
    this.email = this.personendaten.email;
  }

  initNationalities() {
    this.valuesService.loadNationalities().subscribe(nationalityList => {
      this.nationalityList.length = 0; // reset nationalities
      this.nationalityList = nationalityList;
    });
  }

  protected successValidation() {
    super.successValidation();
    this.personendaten.email = this.email;
  }
}
