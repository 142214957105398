import { Injectable } from '@angular/core';

import { IAction, IAdobeAnalytics, IPage, IProduct, ITransaction } from '../model/IAdobeAnalytics';
import { Mandant, MandantService } from './mandant.service';

@Injectable({
  providedIn: 'root'
})
export class AdobeApiService {
  public static ADOBESCRIPTID: string = 'adobeScript_ij3c23';
  adobeData: IAdobeAnalytics = {};
  _window: any = window;
  constructor(private mandantService: MandantService) {
    this.adobeData.page = <IPage>{};
    this.adobeData.product = <IProduct>{};
    this.adobeData.action = <IAction>{};
    this.adobeData.transaction = <ITransaction>{};
    this._window.adobeDataLayer = this._window.adobeDataLayer || [];
  }

  triggerAdobe(data, event?: string) {
    if (this.mandantService.getMandant() === Mandant.DB) {
      this.adobeData.page.tenant = 'deutschebank';
    } else {
      this.adobeData.page.tenant = 'bhw';
    }
    this._window.adobeDataLayer = this._window.adobeDataLayer || [];
    this.adobeData.page.pageName = data.pageName;
    this.adobeData.page.pageType = data.pageType;
    this.adobeData.page.URLfull = window.location.href;
    this.adobeData.page.URL = window.location.origin + window.location.pathname;
    this.adobeData.page.leadstage = data.leadstage;
    if (event) {
      this.adobeData.page.event = data.event;
    }
    this.adobeData.product.productID = data.productID;
    this.adobeData.product.productName = data.productName;
    this.adobeData.product.productCategory = data.productCategory;
    this.adobeData.action.actionName = data.actionName;
    this.adobeData.action.actionType = data.actionType;
    this.adobeData.transaction.opraId = data.opraId;
    this.triggerPageView(this.adobeData);
  }

  /**
   * Pushes an adobe event which states that this subpage has been loaded
   * @param subpage
   * @returns
   */
  pushAdobeEvent(subpage: string, funnelType: string) {
    // validation
    if (!subpage) {
      return;
    }
    if (!funnelType) {
      funnelType = 'unknown';
    }
    // See https://github.com/adobe/adobe-client-data-layer/wiki#pushing-an-event-object
    let adobeEvent: any = {
      event: 'view_load', // "view_load" was requested
      application: {
        funnelStepName: subpage,
        funnelType: funnelType
      }
    };

    let _window: any = window;

    _window.adobeDataLayer = _window.adobeDataLayer || [];
    _window.adobeDataLayer.push(adobeEvent);
  }

  triggerPageView(data: any): Promise<boolean> {
    // validation
    if (!data) {
      return;
    }
    let _window: any = window;

    // See https://github.com/adobe/adobe-client-data-layer/wiki#pushing-a-data-object
    _window.adobeDataLayer = _window.adobeDataLayer || [];
    _window.adobeDataLayer.push(data);
  }
}
