<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.year-of-construction.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{baujahr}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.owner-occupied-living-space.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.wohnFlaecheEigennutz}} qm
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.third-party-occupied-living-space.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.wohnFlaecheFremdnutz}} qm
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.location.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.lageStandort === 'EINFACH'">{{'property-details.location.simple' | translate}}</span>
                <span *ngIf="objektWert.lageStandort === 'MITTEL'">{{'property-details.location.medium' | translate}}</span>
                <span *ngIf="objektWert.lageStandort === 'GEHOBEN'">{{'property-details.location.hoisted' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.construction-style.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.bauweise === 'MASSIV'">{{'property-details.construction-style.massive' | translate}}</span>
                <span
                    *ngIf="objektWert.bauweise === 'FACHWERK_MIT_STROH_UND_LEHM'">{{'property-details.construction-style.half-timbered-house-with-straw-and-clay' | translate}}</span>
                <span *ngIf="objektWert.bauweise === 'FACHWERK_MIT_ZIEGEL'">{{'property-details.construction-style.half-timbered-house-with-bricks' | translate}}</span>
                <span *ngIf="objektWert.bauweise === 'GLAS_STAHL'">{{'property-details.construction-style.glass-and-steel' | translate}}</span>
                <span *ngIf="objektWert.bauweise === 'HOLZ'">{{'property-details.construction-style.wood' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.attic.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.dachgeschoss === 'NICHT_AUSGEBAUT'">{{'property-details.attic.undeveloped' | translate}}</span>
                <span *ngIf="objektWert.dachgeschoss === 'AUSGEBAUT_25'">{{'property-details.attic.25percent' | translate}}</span>
                <span *ngIf="objektWert.dachgeschoss === 'AUSGEBAUT_50'">{{'property-details.attic.50percent' | translate}}</span>
                <span *ngIf="objektWert.dachgeschoss === 'AUSGEBAUT_75'">{{'property-details.attic.75percent' | translate}}</span>
                <span *ngIf="objektWert.dachgeschoss === 'VOLLAUSGEBAUT'">{{'property-details.attic.fully-developed' | translate}}</span>
                <span *ngIf="objektWert.dachgeschoss === 'FLACHDACH'">{{'property-details.attic.flat-roof' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.basement.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.keller === 'NICHT_UNTERKELLERT'">{{'property-details.basement.no-cellar' | translate}}</span>
                <span *ngIf="objektWert.keller === 'UNTERKELLERT_25'">{{'property-details.basement.25percent' | translate}}</span>
                <span *ngIf="objektWert.keller === 'UNTERKELLERT_50'">{{'property-details.basement.50percent' | translate}}</span>
                <span *ngIf="objektWert.keller === 'UNTERKELLERT_75'">{{'property-details.basement.75percent' | translate}}</span>
                <span *ngIf="objektWert.keller === 'VOLL_UNTERKELLERT'">{{'property-details.basement.fully-developed' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.endowment.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.ausstattung === 'EINFACH'">{{'property-details.endowment.simple' | translate}}</span>
                <span *ngIf="objektWert.ausstattung === 'MITTEL'">{{'property-details.endowment.medium' | translate}}</span>
                <span *ngIf="objektWert.ausstattung === 'GEHOBEN'">{{'property-details.endowment.hoisted' | translate}}</span>
                <span *ngIf="objektWert.ausstattung === 'STARK_GEHOBEN'">{{'property-details.endowment.much-hoisted' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.utilizability.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.verwertbarkeit === 'SEHR_GUT'">{{'property-details.utilizability.very-good' | translate}}</span>
                <span *ngIf="objektWert.verwertbarkeit === 'GUT'">{{'property-details.utilizability.good' | translate}}</span>
                <span *ngIf="objektWert.verwertbarkeit === 'DURCHSCHNITT'">{{'property-details.utilizability.average' | translate}}</span>
                <span *ngIf="objektWert.verwertbarkeit === 'MAESSIG'">{{'property-details.utilizability.moderately' | translate}}</span>
                <span *ngIf="objektWert.verwertbarkeit === 'SCHLECHT'">{{'property-details.utilizability.bad' | translate}}</span>
                <span *ngIf="objektWert.verwertbarkeit === 'KATASTROPHAL'">{{'property-details.utilizability.cataclysmic' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.state.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <span *ngIf="objektWert.objektZustand === 'SEHR_GUT'">{{'property-details.state.very-good' | translate}}</span>
                <span *ngIf="objektWert.objektZustand === 'GUT'">{{'property-details.state.good' | translate}}</span>
                <span *ngIf="objektWert.objektZustand === 'DURCHSCHNITT'">{{'property-details.state.average' | translate}}</span>
                <span *ngIf="objektWert.objektZustand === 'MAESSIG'">{{'property-details.state.moderately' | translate}}</span>
                <span *ngIf="objektWert.objektZustand === 'SCHLECHT'">{{'property-details.state.bad' | translate}}</span>
                <span *ngIf="objektWert.objektZustand === 'KATASTROPHAL'">{{'property-details.state.cataclysmic' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="summary-title">{{'summary.object-data.object-details.parking-facilities.label' | translate}}</div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.carports.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.anzahlCarport}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.double-garage.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.anzahlDoppelgaragen}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.garage.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.anzahlGaragen}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.basement-garage.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.anzahlKellerGaragen}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.parking-space.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.anzahlStellplatz}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{'summary.object-data.object-details.underground-parking-space.prefix' | translate}}
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                {{objektWert.anzahlTGStellplatz}}
            </div>
        </div>
    </div>
</div>