import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss']
})
export class HeaderBannerComponent implements OnInit {
  titleKey: string;
  textKey: string;
  constructor(private headerService: HeaderService) {}

  ngOnInit() {
    this.headerService.currentTitle.subscribe(titleKey => (this.titleKey = titleKey));
    this.headerService.currentText.subscribe(textKey => (this.textKey = textKey));
  }
}
