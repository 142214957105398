export class Zahlungsinformation {
  zahlungsempfaenger: string;
  zahlungssumme: number;
  monatlicheKosten: number;
  aktuelleRestschuld: number;
  zinsbindungsEnde: Date;
  tilgungsEnde: Date;
  zahlungsType: string;

  constructor(zahlungsinformation?: Zahlungsinformation) {
    this.zahlungsempfaenger = zahlungsinformation ? zahlungsinformation.zahlungsempfaenger : undefined;
    this.zahlungssumme = zahlungsinformation ? zahlungsinformation.zahlungssumme : undefined;
    this.monatlicheKosten = zahlungsinformation ? zahlungsinformation.monatlicheKosten : undefined;
    this.aktuelleRestschuld = zahlungsinformation ? zahlungsinformation.aktuelleRestschuld : undefined;
    this.zinsbindungsEnde = zahlungsinformation && zahlungsinformation.zinsbindungsEnde ? new Date(zahlungsinformation.zinsbindungsEnde) : undefined;
    this.tilgungsEnde = zahlungsinformation && zahlungsinformation.tilgungsEnde ? new Date(zahlungsinformation.tilgungsEnde) : undefined;
    this.zahlungsType = zahlungsinformation && zahlungsinformation.zahlungsType ? zahlungsinformation.zahlungsType : undefined;
  }
}
