import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { AddressBaseComponent } from 'src/app/core/address-base.component';
import { AddressValidator } from 'src/app/core/addressvalidator.service';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ConditionalRequiredSettings, OAPValidators } from 'src/app/core/validators.directive';
import { YesNoConfirmation } from 'src/app/shared/model/application-data';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

import { PersonalDataEnumOptionsService } from '../personal-data-enum-options.service';

@Component({
  selector: 'app-property-plot',
  templateUrl: './property-plot.component.html',
  styleUrls: ['./property-plot.component.scss'],
  providers: [FormBuilder]
})
export class PropertyPlotComponent extends AddressBaseComponent implements OnInit {
  errors: any = {
    strasse: [
      {
        rule: 'required',
        message: 'property-plot.street.hint'
      },
      {
        rule: 'minlength',
        message: 'property-plot.street.hint'
      }
    ],
    hausnummer: [
      {
        rule: 'required',
        message: 'property-plot.house-number.hint'
      },
      {
        rule: 'pattern',
        message: 'general.house-number.format.hint'
      }
    ],
    plz: [
      {
        rule: 'required',
        message: 'property-plot.zip-code.hint'
      },
      {
        rule: 'pattern',
        message: 'general.zip-code.format.hint'
      }
    ],
    ort: [
      {
        rule: 'required',
        message: 'property-plot.place.hint'
      },
      {
        rule: 'minlength',
        message: 'property-plot.place.hint'
      }
    ],
    bundesLand: [
      {
        rule: 'required',
        message: 'property-plot.federal-state.hint'
      }
    ],
    grundstuecksFlaeche: [
      {
        rule: 'required',
        message: 'property-plot.property-area.hint'
      }
    ],
    erbbauRecht: [
      {
        rule: 'required',
        message: 'property-plot.heritable-building-right.not-selected.hint'
      }
    ],
    restlaufzeitErbbau: [
      {
        rule: 'required',
        message: 'property-plot.remaining-term.empty.hint'
      }
    ],
    hoeheErbbauZins: [
      {
        rule: 'required',
        message: 'property-plot.ground-rent.empty.hint'
      }
    ]
  };
  bundeslandList: any[] = [];
  erbbauRechtRadioArray: any[] = [];
  public showErbbauRecht = false;
  constructor(
    private formBuilder: FormBuilder,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    private valuesService: ValuesService,
    private enumService: PersonalDataEnumOptionsService,
    dateService: DateService,
    addressValidator: AddressValidator,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, addressValidator);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initRegions();
    this.erbbauRechtRadioArray = this.enumService.getOptionsFor(YesNoConfirmation);
    this.formSettings();
    this.headerService.changeText('property-plot.headline');
    this.translateErrors(this.errors);
    this.updateShowErbbauRecht();
    this.adobe.pushAdobeEvent("Property_plot", "property data");
  }

  private formSettings() {
    const requiredSetting: ConditionalRequiredSettings[] = new Array();
    requiredSetting.push({
      controlNames: ['restlaufzeitErbbau', 'hoeheErbbauZins'],
      andConditions: [{ conditionalControlName: 'erbbauRecht', conditionalNotRequiredValues: ['false'] }]
    });
    this.formValidation = this.formBuilder.group(
      {
        strasse: new FormControl(this.objektWert.strasse, [DBValidators.required(), DBValidators.minLength(2)]),
        hausnummer: new FormControl(this.objektWert.hausnummer, [DBValidators.pattern(/^[a-zA-Z0-9/\\-]*$/), DBValidators.required()]),
        plz: new FormControl(this.objektWert.plz, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.pattern(/^[0-9]{5,5}$/)])),
        ort: new FormControl(this.objektWert.ort, [DBValidators.required(), DBValidators.minLength(2)]),
        bundesLand: new FormControl(this.objektWert.bundesland, DBValidators.required()),
        grundstuecksFlaeche: new FormControl(this.objektWert.grundstuecksFlaeche, DBValidators.required()),
        erbbauRecht: new FormControl(this.erbbauRecht, DBValidators.required()),
        restlaufzeitErbbau: new FormControl(this.objektWert.restlaufzeitErbbau),
        hoeheErbbauZins: new FormControl(this.objektWert.hoeheErbbauZins)
      },
      {
        validator: OAPValidators.conditionalRequiredValuesFormValidator(requiredSetting)
      }
    );
  }

  onBeforeFormSubmit() {
    this.onAdresseCheck('objektWert', super.onBeforeFormSubmit.bind(this));
  }

  get erbbauRecht(): string {
    return this.convertToDBCore(this.objektWert.erbbauRecht);
  }

  set erbbauRecht(erbbauRecht: string) {
    this.objektWert.erbbauRecht = this.convertFromDBCore(erbbauRecht, 'boolean');
    this.updateShowErbbauRecht();
    if (!this.objektWert.erbbauRecht) {
      this.objektWert.restlaufzeitErbbau = null;
      this.objektWert.hoeheErbbauZins = null;
    }
  }

  public erbbauRechtChange($event) {
    this.objektWert.erbbauRecht = this.convertFromDBCore($event.target.value, 'boolean');
    this.updateShowErbbauRecht();
  }

  private initRegions() {
    this.valuesService.loadRegions().subscribe(regionList => {
      this.bundeslandList = Object.assign([], regionList);
    });
  }
  private updateShowErbbauRecht() {
    this.showErbbauRecht = this.objektWert.erbbauRecht !== undefined && this.objektWert.erbbauRecht !== null ? this.objektWert.erbbauRecht : null;
  }
}
