import { Grundbuch } from './grundbuch';
import { Nutzungsart } from './produkt-parameter';

export enum Kernsanierung {
  Umfassend = 'UMFASSEND',
  Null = 'NULL'
}

export enum ObjektArt {
  EinfamilienhausDoppelhaushälfte = 'EINFAMILIENHAUS_DOPPELHAUSHAELFTE',
  EinfamilienhausFreistehend = 'EINFAMILIENHAUS_FREISTEHEND',
  EinfamilienhausReihenmittelhaus = 'EINFAMILIENHAUS_REIHENMITTELHAUS',
  EinfamilienhausReihenendhaus = 'EINFAMILIENHAUS_REIHENENDHAUS',
  ZweifamilienhausReihenendhaus = 'ZWEIFAMILIENHAUS_REIHENENDHAUS',
  ZweifamilienhausReihenmittelhaus = 'ZWEIFAMILIENHAUS_REIHENMITTELHAUS',
  ZweifamilienhausFreistehend = 'ZWEIFAMILIENHAUS_FREISTEHEND',
  EinZweifamilienhaus33Gewerbe = 'EIN_ZWEIFAMILIENHAUS_BIS_33_PROZENT_GEWERBE',
  MehrfamilienhausDreiMieter = 'ERTRAGSWERTOBJEKT_MEHRFAMILIENHAUS_AB_DREI_MIETER',
  Mehrfamilienhaus33Gewerbe = 'WOHNHAUS_MFH_BIS_33_PROZENT_GEWERBE',
  Eigentumswohnung = 'EIGENTUMSWOHNUNG',
  UnbebautesGrundstueck = 'UNBEBAUTES_GRUNDSTUECK',
  Fertighaus = 'FERTIGHAUS'
}

export class ObjektWert {
  nutzungsart: Nutzungsart;
  modernisierungsKosten: number;
  objektArt: ObjektArt;
  vollGeschosse: string;
  mitEigentumsAnteil: number;
  gesamtEigentumsAnteile: number;
  fertighaus: boolean;
  anzahlWohnEinheiten: number;
  kaufPreis: number;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  bundesland: string;
  grundstuecksFlaeche: number;
  erbbauRecht: boolean;
  restlaufzeitErbbau: number;
  hoeheErbbauZins: number;
  entschaedigungsAnteilErbbau: number;
  grundbuch: Grundbuch;
  jahrLetzteModMassnahme: Date;
  kernsanierung: string;
  dacherneuerung: string;
  daemmungAussenwaende: string;
  modernisierungBaeder: string;
  modernisierungHeizung: string;
  modernisierungInnenausbau: string;
  verbesserungFenster: string;
  verbesserungGrundriss: string;
  baujahr: Date;
  wohnFlaecheEigennutz: number;
  wohnFlaecheFremdnutz: number;
  lageStandort: string;
  bauweise: string;
  dachgeschoss: string;
  keller: string;
  ausstattung: string;
  verwertbarkeit: string;
  objektZustand: string;
  ralsiegel: boolean;
  anzahlCarport: number;
  anzahlKellerGaragen: number;
  anzahlDoppelgaragen: number;
  anzahlStellplatz: number;
  anzahlGaragen: number;
  anzahlTGStellplatz: number;

  constructor(objektWert?: ObjektWert) {
    this.grundbuch = objektWert ? new Grundbuch(objektWert.grundbuch) : new Grundbuch();
    this.dacherneuerung = objektWert ? objektWert.dacherneuerung : null;
    this.daemmungAussenwaende = objektWert ? objektWert.daemmungAussenwaende : null;
    this.kernsanierung = objektWert ? objektWert.kernsanierung : null;
    this.modernisierungBaeder = objektWert ? objektWert.modernisierungBaeder : null;
    this.modernisierungHeizung = objektWert ? objektWert.modernisierungHeizung : null;
    this.modernisierungInnenausbau = objektWert ? objektWert.modernisierungInnenausbau : null;
    this.verbesserungFenster = objektWert ? objektWert.verbesserungFenster : null;
    this.verbesserungGrundriss = objektWert ? objektWert.verbesserungGrundriss : null;
    this.bundesland = objektWert ? objektWert.bundesland : null;
    this.hausnummer = objektWert ? objektWert.hausnummer : null;
    this.ort = objektWert ? objektWert.ort : null;
    this.plz = objektWert ? objektWert.plz : null;
    this.strasse = objektWert ? objektWert.strasse : null;
    this.anzahlWohnEinheiten = objektWert ? objektWert.anzahlWohnEinheiten : null;
    this.ausstattung = objektWert ? objektWert.ausstattung : null;
    this.baujahr = objektWert && objektWert.baujahr ? new Date(objektWert.baujahr) : null;
    this.bauweise = objektWert ? objektWert.bauweise : null;
    this.dachgeschoss = objektWert ? objektWert.dachgeschoss : null;
    this.entschaedigungsAnteilErbbau = objektWert ? objektWert.entschaedigungsAnteilErbbau : null;
    this.hoeheErbbauZins = objektWert ? objektWert.hoeheErbbauZins : null;
    this.restlaufzeitErbbau = objektWert ? objektWert.restlaufzeitErbbau : null;
    this.erbbauRecht = objektWert ? objektWert.erbbauRecht : null;
    this.jahrLetzteModMassnahme = objektWert && objektWert.jahrLetzteModMassnahme ? new Date(objektWert.jahrLetzteModMassnahme) : null;
    this.gesamtEigentumsAnteile = objektWert ? objektWert.gesamtEigentumsAnteile : 0;
    this.grundstuecksFlaeche = objektWert ? objektWert.grundstuecksFlaeche : null;
    this.kaufPreis = objektWert ? objektWert.kaufPreis : null;
    this.keller = objektWert ? objektWert.keller : null;
    this.lageStandort = objektWert ? objektWert.lageStandort : null;
    this.mitEigentumsAnteil = objektWert ? objektWert.mitEigentumsAnteil : 0;
    this.objektArt = objektWert ? objektWert.objektArt : null;
    this.fertighaus = objektWert ? objektWert.fertighaus : false;
    this.nutzungsart = objektWert ? objektWert.nutzungsart : null;
    this.objektZustand = objektWert ? objektWert.objektZustand : null;
    this.vollGeschosse = objektWert ? objektWert.vollGeschosse : null;
    this.wohnFlaecheEigennutz = objektWert ? objektWert.wohnFlaecheEigennutz : null;
    this.wohnFlaecheFremdnutz = objektWert ? objektWert.wohnFlaecheFremdnutz : null;
    this.anzahlCarport = objektWert ? objektWert.anzahlCarport : 0;
    this.anzahlDoppelgaragen = objektWert ? objektWert.anzahlDoppelgaragen : 0;
    this.anzahlGaragen = objektWert ? objektWert.anzahlGaragen : 0;
    this.anzahlKellerGaragen = objektWert ? objektWert.anzahlKellerGaragen : 0;
    this.anzahlStellplatz = objektWert ? objektWert.anzahlStellplatz : 0;
    this.anzahlTGStellplatz = objektWert ? objektWert.anzahlTGStellplatz : 0;
    this.modernisierungsKosten = objektWert ? objektWert.modernisierungsKosten : 0;
    this.ralsiegel = objektWert ? objektWert.ralsiegel : false;
    this.verwertbarkeit = objektWert ? objektWert.verwertbarkeit : null;
  }
}
