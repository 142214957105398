import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AddressValidierungObjekt } from '../shared/model/adress-validierung-objekt';
import { PopupService } from '../shared/services/popup.service';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AddressValidator extends BaseService {
  constructor(popupService: PopupService, http: HttpClient, authService: AuthService) {
    super(popupService, http, authService);
  }

  private createAdressValidationUrl(street: string, hno: string, zip: string, city: string) {
    return environment.adressValidationUrl
      .replace('{applicantStreet}', street)
      .replace('{applicantHno}', hno)
      .replace('{applicantZip}', zip)
      .replace('{applicantCity}', city);
  }

  public validiereAdresse(street: string, hno: string, zip: string, city: string): Observable<AddressValidierungObjekt> {
    const addressValidationResult: Subject<AddressValidierungObjekt> = new Subject<AddressValidierungObjekt>();
    this.http.get(this.createAdressValidationUrl(street, hno, zip, city), this.httpOptions).subscribe(
      addressResult => {
        console.log('Address validation result %o', addressResult);
        const address: AddressValidierungObjekt = addressResult[0];
        addressValidationResult.next(address);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        addressValidationResult.next(null);
      }
    );
    return addressValidationResult.asObservable();
  }
}
